import React, { useState } from "react";
import loginImg from "../../assets/image 3.jpg";
import styles from "./auth.module.css";
import logoimg from "../../assets/image 2.png";
import { BsFillPlayFill } from "react-icons/bs";
import { loginUser, validatemtoken } from "../../redux/slice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { useSessionTimeout } from "../../customHooks/useSessionTimeout";

const TokenLogin = () => {
  const [otp, setOtp] = useState(new Array(6).fill(""));

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    //focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const isLoggedIn = useSessionTimeout();
  
  const { isLoading, user, isSuccessful } = useSelector((state) => state.auth);
  // console.log("User State:", user);
  // console.log("User Token:", user.access_token);

  const location = useLocation();
  // const  userName = location.state?.username || "";
  const userName = user?.email || "";
 
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userOtp = async (e) => {
    e.preventDefault();
    // const mToken = otp.join("");
    const token = otp.join("");
    // console.log("token:",token, "userName:",userName);
    if (userName === "") {
      // If userName is empty, navigate to the root route ("/")
      navigate("/");
      return; // Exit the function to prevent further execution
    }
    try {
      dispatch(validatemtoken({token, userName}))
        .unwrap()
        .then((response) => {
          // console.log("isSuccessful:", isSuccessful);
          if (response.isSuccessful) {
            const accessToken = user?.access_token;
            // const accessToken = localStorage.getItem("accessToken");
            const roleName = localStorage.getItem("rolename");
            
  
            if (accessToken) {
              // navigate("/dashboard");

              if (roleName === "Auditor") {
                navigate("/vendor");
            } else if (roleName === "Platform Manager" || roleName === "IT Control" || roleName === "superadmin") {
                navigate("/dashboard");
            } else if (roleName === "Customer Care") {
                navigate("/customer");
            } else {
                toast.error("Role not recognized.");
            }
            } else {
              toast.error("Access token not found.")
              // console.log("Access token not found.");
            }
          } else {
            // Check if the error is an object with a status property
            if (response.responseCode === "99") {
              toast.error(response.responseMessage);
            } 
            // else if (isSuccessful && isSuccessful.error?.status === 500) {
            //   // If the status code is 500, navigate to the root route ("/") responseCode: "99"
            //   navigate("/");
            // } 
            else {
              // Handle other error cases
              // console.log("mToken validation failed.");
              toast.error(response.responseMessage)
              // throw new Error("mToken validation failed.");
              
            }
          }
        });
    } catch (err) {
      console.error(err);
      // Check if the error is a TypeError related to network issues
    if (err instanceof TypeError && err.message.includes("Cannot read properties of undefined")) {
      toast.error("Network connection error. Please check your internet connection.");
    } else {
      // Check if the error is an object with a message property
      const errorMessage = err.responseMessage || "An error occurred. Please try again.";

      toast.error(errorMessage); // Display a toast notification with the extracted error message
    }
  }
    // console.log(username, mToken);
    // console.log(typeof username, typeof mToken);
  };

  return (
    <>
      {isLoading && <Loader />}
      <section className={`container ${styles["image-container"]}`}>
        <div className={styles.loginImg}>
          <img src={loginImg} alt="loginImg" width={1050} />
          <div className={styles.overlay}>
            <div className={styles.text}>
              <h1>Great to have you here!</h1>
              <p>You can login to access your workspace.</p>
              <span>
                Copyright 2022 &nbsp; &nbsp; | &nbsp; &nbsp; Powered by Sterling
                Alternative Finance. All rights reserved.
              </span>
            </div>
          </div>
        </div>
        <div className={styles.form}>
        <div className={styles.logo}>
          <img src={logoimg} alt="logoimg" />
        </div>
        <h2>Input Token</h2>
        <form onSubmit={userOtp}>
          <div className={styles.otp}>
            {otp.map((data, index) => {
              return (
                <input
                  type="text"
                  id="otp"
                  name="otp"
                  maxLength="1"
                  required
                  key={index}
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                />
              );
            })}
          </div>
          {/* <p>{otp.join("")}</p> */}

          <button type="submit" className="--btn --btn-success" disabled={isLoading}
                  style={{
                    cursor: isLoading ? "not-allowed" : "pointer",
                  }}>
            <p>Log in to your Account</p>
            <span>
              <BsFillPlayFill />
            </span>
          </button>
        </form>
      </div>
      </section>
      
    </>
  );
};

export default TokenLogin;
