import React, { useEffect, useState } from "react";
import styles from "./Customer.module.css";
import { Header, Loader, Sidebar } from "../../components";
import { CiSearch } from "react-icons/ci";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Menu, MenuItem, styled } from "@mui/material";
import { RiArrowDownSFill } from "react-icons/ri";
import {
  ModalCustomerBlacklist,
  ModalCustomerDetail,
  ModalCustomerUnblacklist,
  ModalCustomerBlacklistReason,
} from "../../components/modal/Modal";
import agent from "../../api/agent";
import { useMutation, useQueryClient } from "react-query";
import { useCustomers } from "../../customHooks/useCustomer";
import { toast } from "react-hot-toast";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  width: "200px",
  padding: "19px 19px",
  borderBottom: "1px solid #f1f1f1",
  fontSize: "13px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#32c36c",
    color: "#fff",
  },
}));

const Customer = () => {
  const [modalCustomerDetail, setModalCustomerDetail] = useState(false);
  const [modalBlacklist, setModalBlacklist] = useState(false);
  const [modalUnblacklist, setModalUnblacklist] = useState(false);
  const [modalBlacklistReason, setModalBlacklistReason] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [anchorElMap, setAnchorElMap] = useState({});
  const [dropdown, setDropdown] = useState(false);

  const handletoggleCustomerDetail = () => {
    setModalCustomerDetail(!modalCustomerDetail);
  };
  const handletoggleBlacklist = (rowData) => {
    setSelectedRow(rowData);
    setModalBlacklist(!modalBlacklist);
    setAnchorElMap({});
  };
  const handletoggleUnblacklist = (rowData) => {
    setSelectedRow(rowData);
    setModalUnblacklist(!modalUnblacklist);
    setAnchorElMap({});
  };
  const handletoggleBlacklistReason = (rowData) => {
    setSelectedRow(rowData);
    setModalBlacklistReason(!modalBlacklistReason);
    setAnchorElMap({});
  };

  const handleCustomerDetail = (rowData) => {
    setSelectedRow(rowData);
    setModalCustomerDetail(!modalCustomerDetail);
    setAnchorElMap({});
  };

  const handleButtonClick = (event, rowId) => {
    event.stopPropagation();
    setAnchorElMap((prevMap) => ({ ...prevMap, [rowId]: event.currentTarget }));
  };
  const handleMenuClose = (rowId) => {
    setAnchorElMap((prevMap) => ({ ...prevMap, [rowId]: null }));
  };

  useEffect(() => {
    const handleCloseMenus = () => {
      setAnchorElMap({});
    };
    document.addEventListener("click", handleCloseMenus);
    return () => {
      document.removeEventListener("click", handleCloseMenus);
    };
  }, []);


  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 10
      }
    }
  };

  const [paginationModel, setPaginationModel] = useState(initialState.pagination.paginationModel);
  
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  // API CALL
  const blacklistCustomer = async ( { reason, customerId }) => {
    const status = true;
    // console.log("CustomerId:", customerId, "status:", status, "reason:", reason);
    const requestBody = {customerId, status,  reason}
    const response = await agent.customer.blacklistCustomer(requestBody);
    return response;
  };
  const unblacklistCustomer = async (customerId) => {
    const status = false;
    const reason = "Good"
    // console.log("CustomerId:", customerId, "status:", status, "reason:", reason);
    const requestBody = {customerId, status,  reason}
    const response = await agent.customer.unblacklistCustomer(requestBody);
    return response;
  };

  const blacklistMutipleCustomer = async (selectedRowIds) => {
    const status = true;
    // console.log("blacklistMutipleCustomer:", selectedRowIds, "status:", status);
    const requestBody = selectedRowIds.map((customerId) => ({
      customerId,
      status,
    }));
    const response = await agent.customer.blacklistMultipleCustomer(
      requestBody
    );
    return response;
  };
  const unblacklistMutipleCustomer = async (selectedRowIds) => {
    const status = false;
    // console.log("blacklistMutipleCustomer:", selectedRowIds, "status:", status);
    const requestBody = selectedRowIds.map((customerId) => ({
      customerId,
      status,
    }));
    const response = await agent.customer.unblacklistMultipleCustomer(
      requestBody
    );
    return response;
  };

  // React Query
  const queryClient = useQueryClient();
  const { customers, isLoading, error, refetch } = useCustomers(paginationModel);

  if (error) {
    // Handle error state
    return <div>Error loading brands</div>;
  }
  // console.log("customers:", customers);

  //React Query Mutate state Function
  const { mutate: blacklistedCustomer, isLoading: blacklistedLoading } = useMutation(blacklistCustomer, {
      // On success, invalidate the 'customers' query to refetch the data
      onSuccess: (data) => {
        queryClient.invalidateQueries("customers");

        if (data.responseCode === "99") {
          // console.log("Error blacklisting a customer:", data.responseMessage);
          toast.error(data.responseMessage);
          handletoggleBlacklistReason();
          handletoggleBlacklist();
        } else {
          // console.log("Customer successfully blacklisted");
          toast.success("Customer blacklisted successfully!");
          handletoggleBlacklistReason();
          handletoggleBlacklist();
        }
      },
      // On error, show an error toast
      onError: (error) => {
        // console.error("Error blacklisting a customer:", error);
        toast.error("Error blacklisting a customer. Please try again.");
      },
    });
  const { mutate: unblacklistedCustomer, isLoading: unblacklistedLoading } = useMutation(unblacklistCustomer, {
      // On success, invalidate the 'customers' query to refetch the data
      onSuccess: (data) => {
        queryClient.invalidateQueries("customers");

        if (data.responseCode === "99") {
          // console.log("Error unblacklisting a customer:", data.responseMessage);
          toast.error(data.responseMessage);
          handletoggleUnblacklist();
        } else {
          // console.log("Customer successfully unblacklisted");
          toast.success("Customer unblacklisted successfully!");
          handletoggleUnblacklist();
        }
      },
      // On error, show an error toast
      onError: (error) => {
        // console.error("Error unblacklisting a customer:", error);
        toast.error("Error unblacklisting a customer. Please try again.");
      },
    });
  const { mutate: blacklistedMutipleCustomer } = useMutation(
    blacklistMutipleCustomer,
    {
      // On success, invalidate the 'customers' query to refetch the data
      onSuccess: (data) => {
        queryClient.invalidateQueries("customers");

        if (data.responseCode === "99") {
          // console.log(
          //   "Error blacklisting Mutiple customers:",
          //   data.responseMessage
          // );
          toast.error(data.responseMessage);
        } else {
          // console.log("Mutiple Customers successfully blacklisted");
          toast.success("Mutiple Customers blacklisted successfully!");
        }
      },
      // On error, show an error toast
      onError: (error) => {
        // console.error("Error blacklisting Mutiple customers:", error);
        toast.error("Error blacklisting Mutiple customers. Please try again.");
      },
    }
  );
  const { mutate: unblacklistedMutipleCustomer } = useMutation(
    unblacklistMutipleCustomer,
    {
      // On success, invalidate the 'customers' query to refetch the data
      onSuccess: (data) => {
        queryClient.invalidateQueries("customers");
        if (data.responseCode === "99") {
          // console.log(
          //   "Error unblacklisting Mutiple customers:",
          //   data.responseMessage
          // );
          toast.error(data.responseMessage);
        } else {
          // console.log("Mutiple Customers successfully unblacklisted");
          toast.success("Mutiple Customers unblacklisted successfully!");
        }
      },
      // On error, show an error toast
      onError: (error) => {
        // console.error("Error unblacklisting Mutiple customers:", error);
        toast.error(
          "Error unblacklisting Mutiple customers. Please try again."
        );
      },
    }
  );

  //  Meun onClick Function
  const handleBlacklistedCustomer = (reason, customerId) => {
    // console.log("Blacklisted customerId", customerId, "reason:", reason);
    const rejectCustomerparameter = { reason, customerId };
    blacklistedCustomer(rejectCustomerparameter);
  };
  const handleUnblacklistedCustomer = ({ customerId }) => {
    // console.log("Blacklisted customerId", customerId);
    unblacklistedCustomer(customerId);
  };

  const handleblacklistMutipleCustomer = () => {
    blacklistedMutipleCustomer(selectedRowIds);
    // console.log("Blacklisted Mutiple customerId", selectedRowIds);
  };
  const handleunblacklistMutipleCustomer = () => {
    unblacklistedMutipleCustomer(selectedRowIds);
    // console.log("unBlacklisted Mutiple customerId", selectedRowIds);
  };

  let blacklistedCount = 0;
  if (customers) {
    const blacklistCounts = customers.reduce((counts, customers) => {
      const blacklist = customers.isBlocked;
      counts[blacklist] = (counts[blacklist] || 0) + 1;
      return counts;
    }, {});

    // console.log("Blacklist Counts:", blacklistCounts);

    // Update the count variables
    blacklistedCount = blacklistCounts[true] || 0;
  }

  const getRowId = (customer) => customer.customerId;

  const columns = [
    {
      field: "sn",
      headerName: "SN",
      width: 80,
      cellClassName: "name-column--cell",
    },
    {
      field: "customername",
      headerName: "Customer Name",
      width: 200,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <div>
          {params.row.firstName} {params.row.lastName}
        </div>
      ),
    },
    {
      field: "orderCount",
      headerName: "Order Count",
      width: 100,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: " Email",
      width: 280,
      cellClassName: "name-column--cell",
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 180,
      cellClassName: "name-column--cell",
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 120,
      cellClassName: "name-column--cell",
    },
    {
      width: 180,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => ActionButton(params),
    },
  ];

  const ActionButton = (params) => {
    const rowId = params.row.customerId;
    const anchorEl = anchorElMap[rowId];
    return (
      <>
        <Button
          className={styles["action-button"]}
          style={{
            marginLeft: 16,
            width: 100,
            height: 30,
            color: "#000",
            backgroundColor: "#ebf4fe",
          }}
          onClick={(event) => handleButtonClick(event, rowId)}
          aria-controls={`actions-menu-${rowId}`}
          aria-haspopup="true"
        >
          Action
          <RiArrowDownSFill style={{ marginLeft: 16 }} size={13} />
        </Button>
        <Menu
          id={`actions-menu-${rowId}`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleMenuClose(rowId)}
        >
          <StyledMenuItem onClick={() => handleCustomerDetail(params.row)}>
            View Order History
          </StyledMenuItem>

          <StyledMenuItem onClick={() => handletoggleBlacklist(params.row)}>
            Blacklist
          </StyledMenuItem>

          <StyledMenuItem onClick={() => handletoggleUnblacklist(params.row)}>
            Unblacklist
          </StyledMenuItem>
        </Menu>
      </>
    );
  };

  const [searchTerm, setSearchTerm] = useState("");

  let customerdatafilter = []; // Initialize an empty array

  if (customers) {
    // Check if customers is defined
    // Apply filtering logic here
    customerdatafilter = customers.filter((val) => {
      if (selectedFilter === "All") {
        return (
          val.firstName
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase()) ||
          val.lastName
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase()) ||
          val.customerId
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase())
        );
      } else {
        return (
          (val.firstName
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase()) ||
            val.lastName
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase()) ||
            val.customerId
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase())) &&
          val.isBlocked === selectedFilter
        );
      }
    });
  }

  return (
    <div className={styles.vendor}>
      {modalCustomerDetail ? (
        <ModalCustomerDetail
          onHandleToggleCustomerDetail={handletoggleCustomerDetail}
          customerdatafilter={selectedRow}
        />
      ) : null}
      {modalBlacklist ? (
        <ModalCustomerBlacklist
          onHandleToggleBlacklist={handletoggleBlacklist}
          customerdatafilter={selectedRow}
          onHandleToggleReason={handletoggleBlacklistReason}
          
        />
      ) : null}
      {modalUnblacklist ? (
        <ModalCustomerUnblacklist
          onHandleToggleBlacklist={handletoggleBlacklist}
          customerdatafilter={selectedRow}
          onHandleUnblacklistedCustomer={handleUnblacklistedCustomer}
          isLoading={unblacklistedLoading}
        />
      ) : null}
      {modalBlacklistReason ? (
        <ModalCustomerBlacklistReason
        onHandleToggle={handletoggleBlacklistReason}
        onHandleBlacklistedCustomerReason={handleBlacklistedCustomer}
        customerdata={selectedRow}
        isLoading={blacklistedLoading}
        />
      ) : null}

      <Header />
      <div className={styles["vendor-body"]}>
        <Sidebar />
        <div className={styles["vendor-container"]}>
          <div className={styles["vendor-title"]}>
            <h2>Customers</h2>
          </div>
          <div className={styles["vendor-subtitle-search"]}>
            <div className={styles.subtitle}>
              <div
                className={`${styles["vendor-subtitle-head"]} ${
                  selectedFilter === "All" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("All")}
              >
                <p>All Customers</p>
              </div>
              <div
                className={`${styles["vendor-active"]} ${
                  selectedFilter === true ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter(true)}
              >
                <p>Blacklisted Customer</p>
                <span>{blacklistedCount}</span>
              </div>
            </div>
            <div className={styles.search}>
              {selectedRowIds.length !== 0 && (
                <div className={styles["action-button"]}>
                  <Button
                    style={{
                      marginLeft: 16,
                      width: 100,
                      height: 40,
                      color: "#fff",
                      backgroundColor: "#595959",
                    }}
                    onClick={() => setDropdown(!dropdown)}
                  >
                    Options
                    <RiArrowDownSFill style={{ marginLeft: 16 }} size={13} />
                  </Button>
                  {dropdown ? (
                    <div
                      className={styles["newbrand-dropdown-container"]}
                      onClick={() => setDropdown(!dropdown)}
                    >
                      <div
                        className={styles["newbrand-dropdown-item"]}
                        onClick={() => handleblacklistMutipleCustomer()}
                      >
                        <p>Blacklist</p>
                      </div>
                      <div
                        className={styles["newbrand-dropdown-item"]}
                        onClick={() => handleunblacklistMutipleCustomer()}
                      >
                        <p>Unblacklist</p>
                      </div>
                    </div>
                  ) : (
                    " "
                  )}
                </div>
              )}
              <form
                className="--form-control"
                name="searchTerm"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              >
                <span className={styles.icon}>
                  <CiSearch size={17} />
                </span>
                <input placeholder="Search customer name, ID" />
              </form>
            </div>
          </div>
          <div className={styles["vendor-content"]} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
            {isLoading ? (
              <Loader />
            ) : (
              <Box
                sx={{
                  // width: {
                  //   xs: 445,
                  //   sm: 575,
                  //   md: 713,
                  //   lg: 945,
                  //   xl: 1200,
                  // },
                  height: "87%",
                  "& .MuiDataGrid-root": {
                    border: "none",
                    fontSize: " 13px",
                    fontFamily: "Montserrat",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders":
                    {
                      backgroundColor: "#32c36c",
                      color: "#fff",
                    },
                  "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate":
                    {
                      color: "#32c36c",
                    },
                  " & .css-12u49z-MuiDataGrid-root .MuiDataGrid-row.Mui-selected":
                    {
                      backgroundColor: "#ecfaf0",
                    },
                  " .MuiDataGrid-row.Mui-selected:hover, .css-12u49z-MuiDataGrid-root .MuiDataGrid-row.Mui-selected.Mui-hovered":
                    {
                      backgroundColor: "#ecfaf0",
                    },

                  "& .name-column--head": {
                    marginLeft: "40px",
                  },
                }}
              >
                <div className={styles["data-grid"]}>
                  <DataGrid
                    rows={customerdatafilter.map((row, index) => ({
                      ...row,

                      sn: index + 1 + paginationModel.page * paginationModel.pageSize,
                    }))}
                    columns={columns}
                    options={{}}
                    pagination
                     paginationMode="server"
                    //  pageSizeOptions={[10, 20, 50]}
                   paginationModel={paginationModel}
                   onPaginationModelChange={handlePaginationModelChange}
                    rowCount={1000}
                    loading={isLoading}
                    
                    checkboxSelection
                    getRowId={getRowId}
                    onRowSelectionModelChange={(ids) => {
                      const selectedIDs = new Set(ids);
                      const selectedRowData = customerdatafilter.filter((row) =>
                        selectedIDs.has(row.customerId.toString())
                      );
                      const customerIds = selectedRowData.map(
                        (row) => row.customerId
                      );
                      setSelectedRowIds(customerIds);
                      // console.log("Selected customerIds:", customerIds);
                    }}
                  />
                </div>
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customer;
