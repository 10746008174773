import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  loading: false,
  error: null,
  productCount: 0,
  liveCount: 0,
  approvedCount: 0,
  PendingCount: 0,
  rejectedCount: 0,
  disabledCount: 0,
  deletedCount: 0,
  outofstockCount: 0,
};
const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
        // Calculate the total number of vendors and update the vendorsCount
        state.productCount = action.payload.length;
        // Calculate the count for each status and update the corresponding counts
        state.approvedCount = action.payload.filter((product) => product.status === "Approved").length;
        state.PendingCount = action.payload.filter((product) => product.status === "Pending").length;
        state.disabledCount = action.payload.filter((product) => product.status === "disabled").length;
        state.rejectedCount = action.payload.filter((product) => product.status === "Declined").length;
        state.deletedCount = action.payload.filter((product) => product.status === "deleted").length;
        state.outofstockCount = action.payload.filter((product) => product.status === "outofstock").length;
        state.liveCount = action.payload.filter((product) => product.status === "live").length;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    
  },
});

export const { setProducts, setLoading, setError, addProduct } = productSlice.actions;
export default productSlice.reducer;
