import React, { useRef, useState } from "react";
import styles from "./Promotion.module.css";
import { Header, Sidebar } from "../../components";
import { FaSpinner } from "react-icons/fa";
import toast from "react-hot-toast";
import { BsArrowLeftCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import uploadimg from "../../assets/upload.png";
import agent from "../../api/agent";
import { useMutation, useQueryClient } from "react-query";
import { TextField } from "@mui/material";
const AddPromotion = () => {
  const [clickedImageIndex, setClickedImageIndex] = useState(-1);
  const [formData, setFormData] = useState({
    title: "",
    type: 0,
    category: 0,
    startDate: "",
    endDate: "",
    promotionImages: [],
    description: "",
  });
  // console.log("FormData to be sent:", formData);
  const categories = [
    {
      promotioncategoryId: 0,
      promotionCategory: "Seasonal",
    },
    {
      promotioncategoryId: 1,
      promotionCategory: "Weekly",
    },
  ];
  const Types = [
    {
      typeId: 0,
      promotionType: "Seasonal",
    },
    {
      typeId: 1,
      promotionType: "Weekly",
    },
  ];

  const promotioncreation = async (formData) => {
    // console.log("formData:", formData);
    const response = await agent.promotion.promotionCreation(formData);
    return response;
  };

  const queryClient = useQueryClient();
  const { mutate: createPromotionData, isLoading } = useMutation(
    promotioncreation,
    {
      // On success, invalidate the 'Promotions' query to refetch the data
      onSuccess: (data) => {
        queryClient.invalidateQueries("Promotions");
        if (data.responseCode === "99") {
          // console.log("Error creating Promotion:", data.responseMessage);
          toast.error(data.responseMessage);
          navigate("/promotion");
        } else {
         // console.log("Promotion created successfully");
        toast.success("Promotion created successfully!");
        navigate("/promotion");
        }
      },
      // On error, show an error toast
      onError: (error) => {
        // console.error("Error creating Promotion:", error);
        toast.error("Error creating Promotion. Please try again.");
      },
    }
  );
  const handleAddPromotion = async (e) => {
    e.preventDefault();
    createPromotionData(formData);
  };

  const imageInputRef = useRef(null);
  const handleImageUpload = (files) => {
    if (files) {
      const reader = new FileReader();
      const firstFile = files[0];

      reader.onload = (e) => {
        const imageUrl = e.target.result;

        if (clickedImageIndex !== -1) {
          // Replace the clicked image if an image is clicked
          const newMultipleImages = [...formData.promotionImages];
          newMultipleImages[clickedImageIndex] = imageUrl;
          setFormData((prevData) => ({
            ...prevData,
            promotionImages: newMultipleImages,
          }));
          setClickedImageIndex(-1); // Reset the clicked image index
        } else {
          setFormData((prevData) => ({
            ...prevData,
            promotionImages: [...prevData.promotionImages, imageUrl],
          }));
        }
      };

      reader.onerror = (error) => {
        // console.error("Error reading the file: ", error);
      };

      reader.readAsDataURL(firstFile);

      for (let i = 1; i < files.length; i++) {
        reader.readAsDataURL(files[i]);
      }
    }
  };
  const handleImageClick = (index) => {
    setClickedImageIndex(index); // Set the clicked image index
  };
  const navigate = useNavigate();

  const backToPromotion = () => {
    return navigate("/promotion");
  };

   // Get the current date in the format "YYYY-MM-DD"
   const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  return (
    <div className={styles.vendor}>
      <Header />
      <div className={styles["form-upload-body"]}>
        <Sidebar />
        <div className={styles["addproduct-container"]}>
          <div
            className={styles["addproduct-title"]}
            onClick={() => backToPromotion()}
          >
            <BsArrowLeftCircle size={30} style={{ cursor: "pointer" }} />
            <p>Go back</p>
          </div>
          <div className={styles["vendor-subtitle-search"]}>
            <div className={styles.subtitle}>
              <div className={styles["vendor-subtitle-head"]}>
                <h3>New Promotion</h3>
              </div>
            </div>
          </div>
          <div className={styles["form-upload-content"]}>
            <div className={styles["form-upload"]}>
              <form>
                {/*Campaign Title */}
                <div className={styles["name-category"]}>
                  <div className={styles["promotion-title"]}>
                    <label htmlFor="title">Campaign Title</label>
                    <input
                      type="text"
                      name="title"
                      value={formData.title}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          title: e.target.value,
                        })
                      }
                      placeholder="Christopher Dunkwu"
                    />
                  </div>
                </div>
                {/*Select Category- Select Type */}
                <div className={styles["name-category"]}>
                  <div className={styles["Product-name"]}>
                    <label htmlFor="category">Category</label>
                    <select
                      name="category"
                      value={formData.category}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          category: e.target.value,
                        })
                      }
                    >
                      <option selected disabled>
                        --Select promotion category --
                      </option>
                      {categories &&
                        categories.map((category) => (
                          <option
                            key={category.promotioncategoryId}
                            value={category.promotioncategoryId}
                          >
                            {category.promotionCategory}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className={styles.category}>
                    <label htmlFor="type">Type</label>
                    <select
                      name="type"
                      value={formData.type}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          type: e.target.value,
                        })
                      }
                    >
                      <option selected disabled>
                        -- Choose Type--
                      </option>
                      {Types &&
                        Types.map((type) => (
                          <option key={type.typeId} value={type.typeId}>
                            {type.promotionType}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                {/*Select Vendor name- Select category */}
                <div className={styles["name-category"]}>
                  <div className={styles["Product-name"]}>
                    <label htmlFor="startDate">From</label>
                    <input
                      type="date"
                      name="startDate"
                      value={formData.startDate}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          startDate: e.target.value,
                        })
                      }
                      min={getCurrentDate()} // Set the minimum date to the current date
                      placeholder="27, Sep 2023"
                    />
                  </div>
                  <div className={styles.endDate}>
                    <label htmlFor="endDate">To</label>
                    <input
                      type="date"
                      name="endDate"
                      value={formData.endDate}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          endDate: e.target.value,
                        })
                      }
                      placeholder="27, Sep 2023"
                    />
                  </div>
                </div>
              </form>
              <hr />
              <div className={styles["upload-section"]}>
                <div className="">
                  <p>Upload Pictures</p>
                  <div className={styles["upload-section-img"]}>
                    <div className={styles["upload-pic"]}>
                      <label
                        htmlFor="imageInput"
                        className={styles["image-upload-label"]}
                      >
                        {formData.promotionImages.map((imageUrl, index) => (
                          <img
                          key={index}
                          src={imageUrl}
                          alt={`Selected img ${index}`}
                          height={90}
                          onClick={() => handleImageClick(index)}
                          />
                        ))}
                        {formData.promotionImages.length < 3 && (
                          <img
                            src={uploadimg}
                            alt="Click to add images"
                            height={90}
                          />
                        )}
                        <input
                          id="imageInput"
                          type="file"
                          accept="image/*"
                          ref={imageInputRef}
                          style={{ display: "none" }}
                          multiple
                          onChange={(e) => handleImageUpload(e.target.files)}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className={styles["delivery-warranty"]}>
                  <label htmlFor="description">Campaign Description</label>
                  <TextField
                    multiline
                    name="description"
                    rows={8} // Adjust the number of rows as needed
                    placeholder="Write a Campaign  description about the Promotion"
                    value={formData.description}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        description: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            className={`--btn ${styles["form-upload-button"]}`}
            onClick={handleAddPromotion}
            disabled={isLoading}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              width: isLoading && "50px",
            }}
          >
            {isLoading ? (
              <>
                <FaSpinner className={styles["spin"]} />
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddPromotion;
