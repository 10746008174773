import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import agent from "../../api/agent";
import toast from "react-hot-toast";
const initialState = {
  username: "",
  password: "",
  browser: "",
  isLoading: false,
  error: null,
  loggedIn: false,
  user: null,
  isSuccessful: false,
};

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (_, { getState, rejectWithValue }) => {
    const { username, password, browser } = getState().auth;
    // console.log("username:", username,"password:", password,"browser:", browser);
    try {
      const response = await agent.auth.login(
        username,
        password,
        browser
      );
      // const userData = response.data; // Assuming the user data is nested under the "data" property
      // console.log("User Data:", response);
       // Check if the response was successful and handle accordingly
       if (response.responseCode === "00") {
        return response;
      } else {
        // Return a serializable error message
        return rejectWithValue({ message: response.responseMessage, code: response.responseCode });
      }
    } catch (error) {
      // console.log('error', error);
      // Return a serializable error message
      return rejectWithValue({ message: error.message });
    }
  }
);

export const logoutUser = createAsyncThunk("auth/logoutUser", async () => {
  try {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("fullName");
    localStorage.removeItem("rolename");
    localStorage.removeItem("approvalLevel");
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("activeTabs");
  } catch (error) {
    throw new Error(error);
  }
});

export const validatemtoken = createAsyncThunk(
  "auth/validateMToken",
  async ({token, userName }) => {
    try {
      const response = await agent.auth.validateMToken( token, userName );
      // console.log(token, userName);
      // const isSuccessful = response.isSuccessful;
      // console.log(response.isSuccessful);
      // console.log(response);
      // if (isSuccessful) {
      //   return isSuccessful;
      // } else {
      //   // throw new Error("mToken validation failed.");
      //   // toast.error("mToken validation failed.")
      // }
      return response;
    } catch (error) {
      // toast.error(error)
      throw new Error(error.response.responseMessage);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setBrowser: (state, action) => {
      state.browser = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.loggedIn = true;
        state.user = action.payload;
        state.error = null;
        state.username = "";
        state.password = "";
        // console.log("User Data:", action.payload);
        if (state.user && state.user.data.roleName) {
          const roleName = state.user.data.roleName
          const approvalLevel = state.user.data.approvalLevel
          
          localStorage.setItem("rolename", roleName);
          localStorage.setItem("approvalLevel", approvalLevel);
        
          // Log the user data
          // console.log("Logged in user:", state.user);
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.loggedIn = false;
        state.user = null;
        // console.error("Login Error:", action); // Log the error object
        state.error = action.payload; // Use a default message if 'message' is not available
      })
      .addCase(logoutUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.isLoading = false;
        state.loggedIn = false;
        state.user = null;
        state.error = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(validatemtoken.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(validatemtoken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccessful = action.payload;
        // console.log("user:", action.payload);

        // Check if state.user is not null and has access_token before accessing it
        if (state.user && state.user.access_token) {
          // console.log("access:", state.user.access_token);

          // Access the access_token property here
          const accessTokenData = state.user.access_token;
          const refreshToken = state.user.refresh_token;
          const userIdData = state.user.userId;
          // const roleName = state.user.roleName
          const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; //
          localStorage.setItem("accessToken", JSON.stringify(accessTokenData));
          localStorage.setItem(
            "refreshToken",
            JSON.stringify({ refreshToken, userId: userIdData, expirationTime })
          );
          localStorage.setItem("userId", JSON.stringify(userIdData));
          localStorage.setItem(
            "fullName",
            JSON.stringify({
              firstname: state.user.firstName,
              lastname: state.user.lastName,
            })
          );
          // localStorage.setItem("rolename", roleName);
          // Log the user data
          // console.log("Logged in user:", state.user);
        }

        // if (action.payload) {
        //   // Access the access_token property here
        //   const accessTokenData = state.user.access_token;
        //   const refreshToken = state.user.refresh_token;
        //   const userIdData = state.user.userId;
        //   const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; //
        //   localStorage.setItem("accessToken", JSON.stringify(accessTokenData));
        //   localStorage.setItem(
        //     "refreshToken",
        //     JSON.stringify({ refreshToken, userId: userIdData, expirationTime })
        //   );
        //   localStorage.setItem("userId", JSON.stringify(userIdData));
        //   localStorage.setItem(
        //     "fullName",
        //     JSON.stringify({
        //       firstname: state.user.firstName,
        //       lastname: state.user.lastName,
        //     })
        //   );
        // }
      })
      .addCase(validatemtoken.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "An error occurred"; // Use a default message if 'message' is not available
        // console.error("Validation Error:", action.error); // Log the error object
      });
  },
});

export const {
  setUsername,
  setPassword,
  setBrowser,
  clearError,
  setUser,
} = authSlice.actions;

export default authSlice.reducer;
