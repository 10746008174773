import React, { useState } from "react";
import styles from "./Reviews.module.css";
import { Header, Loader, Pagination, Sidebar } from "../../components";
import { reviewdata } from "../../components/tabledata";
import { SlOptionsVertical } from "react-icons/sl";
import { TbPointFilled } from "react-icons/tb";
import { ModalReviewAuthorize } from "../../components/modal/Modal";
import { useQueryClient, useMutation } from "react-query";
import { useReviews } from "../../customHooks/useReviews";
import agent from "../../api/agent";
import { toast } from "react-hot-toast";
const Reviews = () => {
  const [dropdown, setDropdown] = useState();
  const [modalReviewAuthorize, setModalReviewAuthorize] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedReview, setSelectedReview] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(6);
  const toggle = (reviewId) =>
    setDropdown(dropdown === reviewId ? undefined : reviewId);

  const handletoggleReviewAuthorize = () => {
    setModalReviewAuthorize(!modalReviewAuthorize);
  };
  // API Calls
  const approveReview = async (reviewId) => {
    const status = "published";
    // console.log("approveReview:", reviewId, "status:", status);
    const response = await agent.review.approveReview(status, reviewId);
    return response;
  };
  const rejectReview = async (reviewId) => {
    const status = "rejected";
    // console.log("approveReview:", reviewId, "status:", status);
    const response = await agent.review.rejectReview(status, reviewId);
    return response;
  };
  // React Query Functions
  const queryClient = useQueryClient();
  const { reviews, isLoading, error, hasNextPage, estimatedTotalPages } = useReviews({
    PageNumber: currentPage,
    PageSize: postsPerPage,
  });

  //React Query Mutate state Function
  const { mutate: reviewApprove, isLoading: approveLoading } = useMutation(
    approveReview,
    {
      // On success, invalidate the 'reviews' query to refetch the data
      onSuccess: (data) => {
        queryClient.invalidateQueries("reviews");


        if (data.responseCode === "99") {
          // console.log("Error approving review:", data.responseMessage);
          toast.error(data.responseMessage);
          handletoggleReviewAuthorize();
        } else {
          // console.log("review approved successfully");
          toast.success("review  approved successfully!");
          handletoggleReviewAuthorize();
        }
      },
      // On error, show an error toast
      onError: (error) => {
        // console.error("Error approving review:", error);
        toast.error("Error approving review. Please try again.");
      },
    }
  );
  const { mutate: reviewreject, isLoading: rejectLoading } = useMutation(
    rejectReview,
    {
      // On success, invalidate the 'reviews' query to refetch the data
      onSuccess: (data) => {
        queryClient.invalidateQueries("reviews");

        if (data.responseCode === "99") {
          // console.log("Error Rejecting review:", data.responseMessage);
          toast.error(data.responseMessage);
        } else {
          // console.log("review Rejected  successfully");
        toast.success("review Rejected  successfully!");
        }
      },
      // On error, show an error toast
      onError: (error) => {
        // console.error("Error Rejecting review:", error);
        toast.error("Error Rejecting review. Please try again.");
      },
    }
  );
  //  Meun onClick Function
  const handleApprovereview = ({ reviewId }) => {
    // console.log("reviewId", reviewId);
    reviewApprove(reviewId);
  };
  const handleReviewreview = (reviewId) => {
    // console.log("reviewId", reviewId);
    reviewreject(reviewId);
  };
  const handlePublishClick = (reviewId) => {
    // Find the selected review from your data (you can filter it by reviewId)
    const selectedReviewData = reviewdatafilter.find(
      (val) => val.reviewId === reviewId
    );
    if (selectedReviewData) {
      setSelectedReview(selectedReviewData);
    }
  };
  let reviewdatafilter = [];
  let currentPosts = [];
  //change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  if (reviews) {
    reviewdatafilter = reviews.filter((val) => {
      if (selectedFilter === "All") {
        return true; // Include all items
      } else {
        return val.status === selectedFilter;
      }
    });

    //Get Current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    currentPosts = reviewdatafilter.slice(indexOfFirstPost, indexOfLastPost);
    // console.log("try", currentPosts);
  }

  let PublishedCount = 0;
  let pendingCount = 0;
  let RejectedCount = 0;

  if (reviews) {
    const statusCounts = reviews.reduce((counts, reviews) => {
      const status = reviews.status;
      counts[status] = (counts[status] || 0) + 1;
      return counts;
    }, {});

    // console.log("Status Counts:", statusCounts);

    // Update the count variables

    PublishedCount = statusCounts["Published"] || 0;
    pendingCount = statusCounts["Pending"] || 0;
    RejectedCount = statusCounts["Rejected"] || 0;
  }

  return (
    <div className={styles.vendor}>
      {modalReviewAuthorize ? (
        <ModalReviewAuthorize
          onHandleToggle={handletoggleReviewAuthorize}
          selectedReview={selectedReview}
          onhandleApprovereview={handleApprovereview}
          isLoading={approveLoading}
        />
      ) : null}
      <Header />
      <div className={styles["vendor-body"]}>
        <Sidebar />
        <div className={styles["vendor-container"]}>
          <div className={styles["vendor-title"]}>
            <h2>Reviews</h2>
          </div>
          <div className={styles["vendor-subtitle-search"]}>
            <div className={styles.subtitle}>
              <div
                className={`${styles["vendor-subtitle-head"]} ${
                  selectedFilter === "All" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("All")}
              >
                <p>All Reviews</p>
              </div>
              <div
                className={`${styles["vendor-pending"]} ${
                  selectedFilter === "Pending" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Pending")}
              >
                <p>Pending</p>
                <span>{pendingCount}</span>
              </div>
              <div
                className={`${styles["vendor-rejected"]} ${
                  selectedFilter === "Rejected" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Rejected")}
              >
                <p>Rejected</p>
                <span>{RejectedCount}</span>
              </div>
              <div
                className={`${styles["vendor-locked"]} ${
                  selectedFilter === "Published" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Published")}
              >
                <p>Published</p>
                <span>{PublishedCount}</span>
              </div>
            </div>
          </div>
          <div className={styles["vendor-content"]}>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <div className={styles["no-rows"]}>Error fetching data</div>
            ) : reviewdatafilter.length === 0 ? (
              <div className={styles["no-rows"]}>No rows</div>
            ) : (
              reviewdatafilter.map((val, index) => {
                // Destructure properties from val
                const {
                  reviewId,
                  firstName,
                  lastName,
                  email,
                  phoneNumber,
                  title,
                  message,
                  createdAt,
                  status,
                } = val;
               // Calculate the index of the first post on the current page
               const indexOfFirstPost = (currentPage - 1) * postsPerPage;

                // Calculate SN (sequential number)
                const sn = indexOfFirstPost + index + 1;

                return (
                  <div key={reviewId} className={styles.review}>
                    <div className={styles["review-id-name-email-phone"]}>
                      <p>{sn}</p>

                      <div className={styles["review-name-email-phone"]}>
                        <p>
                          {firstName}
                          {""} {lastName}
                        </p>
                        <span>{email}</span>
                        <br />
                        <span>{phoneNumber}</span>
                      </div>
                    </div>

                    <div className={styles["review-title-message"]}>
                      <p>{title}</p>
                      <span>{message}</span>
                    </div>

                    <div className={styles["review-date-icon"]}>
                      <div>
                        <p className={styles.date}>{createdAt}</p>
                        {status === "Pending" && (
                          <span className={styles["review-status-pending"]}>
                            <TbPointFilled /> {status}
                          </span>
                        )}
                        {status === "Rejected" && (
                          <span className={styles["review-status-rejected"]}>
                            <TbPointFilled /> {status}
                          </span>
                        )}
                        {status === "Published" && (
                          <span className={styles["review-status-published"]}>
                            <TbPointFilled /> {status}
                          </span>
                        )}
                      </div>
                      <div className="">
                        <div
                          className={
                            dropdown === reviewId
                              ? styles["review-icon2"]
                              : styles["review-icon"]
                          }
                          onClick={() => toggle(reviewId)}
                        >
                          <SlOptionsVertical size={12} />
                        </div>
                        {dropdown === reviewId ? (
                          <div
                            className={styles["dropdown-container"]}
                            onClick={() => toggle(reviewId)}
                          >
                            <div
                              className={styles["dropdown-item"]}
                              onClick={() => {
                                handletoggleReviewAuthorize();
                                setSelectedReview(val);
                              }}
                            >
                              <p>Publish</p>
                            </div>
                            <div
                              className={styles["dropdown-item"]}
                              onClick={() => handleReviewreview(reviewId)}
                            >
                              <p>Reject</p>
                            </div>
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
            <div className={reviewdatafilter.length > 0 ? styles["pagination-item"] : styles["pagination-item-length"]}>
              <Pagination
                postsPerPage={postsPerPage}
                // totalPosts={reviewdatafilter.length}
                // totalPosts={reviews ? reviews.length : 0}
                // totalPosts={reviewdatafilter ? reviewdatafilter.length : 0}
                currentPage={currentPage}
                isLoading={isLoading}
                estimatedTotalPages={estimatedTotalPages}
                paginate={paginate}
                hasNextPage={hasNextPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
