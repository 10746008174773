import { useQuery } from "react-query";
import agent from "../api/agent";
// const fetchVendors = async () => {
//   const response = await agent.vendor.getAllVendors();
//   // Add a fullName property to each vendor object
//   const vendorsWithFullName = response.data.map((vendor) => ({
//     ...vendor,
//     fullName: `${vendor.firstName} ${vendor.lastName}`,
//   }));
//   return vendorsWithFullName;
// };

const fetchVendors = async ({ page = 0, pageSize = 1000}) => {
  // console.log("PageNumber:",page, "PageSize:",pageSize);
  // Adjust PageNumber based on page value
  const PageNumber = page + 1;
  const PageSize = pageSize;
  // console.log("PageNumber2:",PageNumber, "PageSize2:",pageSize);
  const response = await agent.vendor.getAllVendors(PageNumber, PageSize);
  // Add a fullName property to each vendor object
  const vendorsWithFullName = response.data.map((vendor) => ({
    ...vendor,
    fullName: `${vendor.firstName} ${vendor.lastName}`,
  }));
  return vendorsWithFullName;
};

// export const useVendors = () => {
//     const {
//       isLoading,
//       error,
//       data: vendors,
//     } = useQuery({
//       queryKey: ["vendors"],
//       queryFn: fetchVendors,
//     });
//     return { isLoading, error, vendors };
//   };

export const useVendors = ({ page, pageSize } = {}) => {
  const {
    isLoading,
    error,
    data: vendors,
    refetch,
  } = useQuery({
    queryKey: ["vendors", { page, pageSize }],
    queryFn: () => fetchVendors({ page, pageSize }),
  });

  return { isLoading, error, vendors, refetch };
};


export const useSelectVendors = ({ page, pageSize }) => {
  const fetchSelectVendors = async () => {
    const PageNumber = page ;
  const PageSize = pageSize
    try {
      const response = await agent.vendor.getAllVendors(PageNumber, PageSize);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch Vendors");
    }
  };

  return useQuery({
    queryKey: ['vendors', { page, pageSize }],
    queryFn: fetchSelectVendors,
  });
};