import { IoCheckbox } from "react-icons/io5";
export const PagesDatas = (adminName) => {
    
    if (adminName === "Auditor") {
        return [
          {
            title: "Vendors",
            icon: <IoCheckbox/>
          },
          {
            title: "Customers",
            icon: <IoCheckbox/>,
          },
          {
            title: "Orders",
            icon: <IoCheckbox/>,
          },
        ];
      }else if (adminName === "Platform Manager") {
        return [
          {
            title: "Dashboard",
            icon: <IoCheckbox/>,
          },
          {
            title: "Vendors",
            icon: <IoCheckbox/>,
          },
          {
            title: "Customers",
            icon: <IoCheckbox/>,
          },
          {
            title: "Product List",
            icon: <IoCheckbox/>,
          },
          {
            title: "Category",
            icon: <IoCheckbox/>,
          },
          {
            title: "Orders",
            icon: <IoCheckbox/>,
          },
          {
            title: "Brands",
            icon: <IoCheckbox/>,
          },
          {
            title: "Reviews",
            icon: <IoCheckbox/>,
          },
          {
            title: "Tickets",
            icon: <IoCheckbox/>,
          },
          {
            title: "Promotions",
            icon: <IoCheckbox/>,
          },
        ];
      } else if (adminName === "Customer Care") {
        return [
          {
            title: "Customers",
            icon: <IoCheckbox/>,
          },
          {
            title: "Vendors",
            icon: <IoCheckbox/>
          },
          {
            title: "Orders",
            icon: <IoCheckbox/>,
          },
        ];
      } else if (adminName === "IT Control") {
        return [
          {
            title: "Dashboard",
            icon: <IoCheckbox/>,
          },
          {
            title: "Vendors",
            icon: <IoCheckbox/>,
          },
          {
            title: "Customers",
            icon: <IoCheckbox/>,
          },
          {
            title: "Product List",
            icon: <IoCheckbox/>,
          },
          {
            title: "Category",
            icon: <IoCheckbox/>,
          },
          {
            title: "Orders",
            icon: <IoCheckbox/>,
          },
          {
            title: "Brands",
            icon: <IoCheckbox/>,
          },
          {
            title: "Reviews",
            icon: <IoCheckbox/>,
          },
          {
            title: "Tickets",
            icon: <IoCheckbox/>,
          },
          {
            title: "Promotions",
            icon: <IoCheckbox/>,
          },
        ];
      } else if (adminName === "superadmin") {
        return [
          {
            title: "Dashboard",
            icon: <IoCheckbox/>,
          },
          {
            title: "Vendors",
            icon: <IoCheckbox/>,
          },
          {
            title: "Customers",
            icon: <IoCheckbox/>,
          },
          {
            title: "Product List",
            icon: <IoCheckbox/>,
          },
          {
            title: "Category",
            icon: <IoCheckbox/>,
          },
          {
            title: "Orders",
            icon: <IoCheckbox/>,
          },
          {
            title: "Brands",
            icon: <IoCheckbox/>,
          },
          {
            title: "Reviews",
            icon: <IoCheckbox/>,
          },
          {
            title: "User Management",
            icon: <IoCheckbox/>,
          },
          {
            title: "Tickets",
            icon: <IoCheckbox/>,
          },
          {
            title: "Promotions",
            icon: <IoCheckbox/>,
          },
         
        ];
      } else {
        return [];
      }
}