import { useQuery } from "react-query";
import agent from "../api/agent";
// const fetchReviews = async () => {
//     const response = await agent.review.getAllreviews();
//     return response.data;
//   };


  
// export const useReviews = () => {
//     const {
//       isLoading,
//       error,
//       data: reviews,
//     } = useQuery({
//       queryKey: ["reviews"],
//       queryFn: fetchReviews,
//     });
//     return { isLoading, error, reviews };
//   }; 

const fetchReviews = async ({ PageNumber, PageSize }) => {
  const response = await agent.review.getAllreviews(PageNumber, PageSize);
  return response.data;
};

export const  useReviews = ({ PageNumber, PageSize } = {}) => {
  const { isLoading, error, data: reviews  } = useQuery({
    queryKey: ['reviews', { PageNumber, PageSize }],
    queryFn: () => fetchReviews({ PageNumber, PageSize }),
  });
  // Calculate the estimated total number of pages based on the current page's data
  const estimatedTotalPages = reviews
    ? Math.ceil(reviews.length / PageSize)
    : 0;
   // Determine if there's a next page based on the number of items received
   const hasNextPage = reviews && reviews.length === PageSize;

  return { isLoading, error, reviews, hasNextPage, estimatedTotalPages };
};

