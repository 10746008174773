import { useQuery } from "react-query";
import agent from "../api/agent";

// const fetchAdminData = async () => {
//     const response = await agent.userManagment.getAllAdmin();
//     return response.data;
//   };

// export const useAdmin = () => {
//     const {
//         isLoading,
//         isError,
//         data: usersdata,
//     } = useQuery({
//         queryKey: ["usersdata"],
//         queryFn: fetchAdminData ,
//       });
//     return {usersdata, isLoading, isError };
//   };

const fetchAdminData = async ({ PageNumber = 1, PageSize = 20 }) => {
  const response = await agent.userManagment.getAllAdmin(PageNumber, PageSize);
  return response.data;
};

export const useAdmin = ({ PageNumber, PageSize } = {}) => {
  const {
    isLoading,
    error,
    data: usersdata,
  } = useQuery({
    queryKey: ["usersdata", { PageNumber, PageSize }],
    queryFn: () => fetchAdminData({ PageNumber, PageSize }),
  });

  // Calculate the estimated total number of pages based on the current page's data
  const estimatedTotalPages = usersdata
    ? Math.ceil(usersdata.length / PageSize)
    : 0;
  // Determine if there's a next page based on the number of items received
  const hasNextPage = usersdata && usersdata.length === PageSize;

  return { isLoading, error, usersdata, hasNextPage, estimatedTotalPages };
};
