import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customers: [],
  acustomer: [],
  loading: false,
  error: null,
  customersCount: 0, 
  blacklistedCount: 0,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomers: (state, action) => {
      state.customers = action.payload;
      state.customersCount = action.payload.length;
      // Calculate the count for each status and update the corresponding counts
      state.blacklistedCount = action.payload.filter((customer) => customer.isBlocked === true).length;
    },
    setAcustomer: (state, action) => {
      state.acustomer = action.payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setCustomers,setAcustomer, setLoading, setError } = customerSlice.actions;

export default customerSlice.reducer;