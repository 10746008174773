import React, { useState, useEffect } from "react";
import styles from "./Promotion.module.css";
import { Header, Loader, Sidebar } from "../../components";
import { CiSearch } from "react-icons/ci";
import { promotiondata } from "../../components/tabledata";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Menu, MenuItem, Typography, styled } from "@mui/material";
import { TbPointFilled } from "react-icons/tb";
import { RiArrowDownSFill } from "react-icons/ri";
import {
  ModalPromotionDetail,
  ModalPromotionReject,
  ModalPromotionApprove,
} from "../../components/modal/Modal";
import { usePromotions } from "../../customHooks/usePromotion";
import { useMutation, useQueryClient } from "react-query";
import agent from "../../api/agent";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { IoIosAddCircleOutline } from "react-icons/io";
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  width: "200px",
  padding: "19px 19px",
  borderBottom: "1px solid #f1f1f1",
  fontSize: "13px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#32c36c",
    color: "#fff",
  },
}));

const Promotion = () => {
  const [modalPromotionDetail, setModalPromotionDetail] = useState(false);
  const [modalPromotionReject, setModalPromotionReject] = useState(false);
  const [modalPromotionApprove, setModalPromotionApprove] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorElMap, setAnchorElMap] = useState({});

  const handletogglePromotionDetail = (rowData) => {
    setSelectedRow(rowData);
    setModalPromotionDetail(!modalPromotionDetail);
    setAnchorElMap({});
  };
  const handletogglePromotionReject = (rowData) => {
    setSelectedRow(rowData);
    setModalPromotionReject(!modalPromotionReject);
    setModalPromotionDetail(false);
    setAnchorElMap({});
  };

  const handletogglePromotionApprove = (rowData) => {
    setSelectedRow(rowData);
    setModalPromotionApprove(!modalPromotionApprove);
    setModalPromotionDetail(false);
    setAnchorElMap({});
  };

  const handleButtonClick = (event, rowId) => {
    event.stopPropagation();
    setAnchorElMap((prevMap) => ({ ...prevMap, [rowId]: event.currentTarget }));
  };
  const handleMenuClose = (rowId) => {
    setAnchorElMap((prevMap) => ({ ...prevMap, [rowId]: null }));
  };

  useEffect(() => {
    const handleCloseMenus = () => {
      setAnchorElMap({});
    };
    document.addEventListener("click", handleCloseMenus);
    return () => {
      document.removeEventListener("click", handleCloseMenus);
    };
  }, []);

  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 10
      }
    }
  };

  const [paginationModel, setPaginationModel] = useState(initialState.pagination.paginationModel);
  
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  // API Calls
  const approvePromotion = async (promotionId) => {
    const status = "approved";
    const reason = "Approved";
    // console.log(
    //   "approvePromotion:",
    //   promotionId,
    //   "status:",
    //   status,
    //   "reason:",
    //   reason
    // );
    const response = await agent.promotion.approvePromotion(
      status,
      promotionId,
      reason
    );
    return response;
  };
  const rejectPromotion = async ({ reason, promotionId }) => {
    const status = "rejected";
    // console.log(
    //   "rejectProduct:",
    //   promotionId,
    //   "status:",
    //   status,
    //   "reason:",
    //   reason
    // ); // Add this line
    const response = await agent.promotion.rejectPromotion(
      status,
      promotionId,
      reason
    );
    return response;
  };
  const removepromotion = async (promotionId) => {
    // console.log("promotionId:", promotionId);
    const response = await agent.promotion.removePromotion(promotionId);
    return response;
  };

  // React Query Functions
  const { Promotions, isLoading, error } = usePromotions(paginationModel);
  // console.log("Promotions", Promotions);

  const queryClient = useQueryClient();
  const { mutate: promotionApprove, isLoading: approveLoading } = useMutation(
    approvePromotion,
    {
      // On success, invalidate the 'Promotions' query to refetch the data
      onSuccess: (data) => {
        queryClient.invalidateQueries("Promotions");

        if (data.responseCode === "99") {
          // console.log("Error approving promotion:", data.responseMessage);
          toast.error(data.responseMessage);
          handletogglePromotionApprove();
        } else {
          // console.log("promotion approved successfully");
          toast.success("promotion approved successfully!");
          handletogglePromotionApprove();
        }
      },
      // On error, show an error toast
      onError: (error) => {
        // console.error("Error approving promotion:", error);
        toast.error("Error approving promotion . Please try again.");
      },
    }
  );
  const { mutate: promotionreject, isLoading: rejectLoading } = useMutation(
    rejectPromotion,
    {
      // On success, invalidate the 'Promotions' query to refetch the data
      onSuccess: (data) => {
        queryClient.invalidateQueries("Promotions");

        if (data.responseCode === "99") {
          // console.log("Error Rejecting promotion:", data.responseMessage);
          toast.error(data.responseMessage);
          handletogglePromotionReject();
        } else {
          // console.log("promotion Rejected  successfully");
        toast.success("promotion Rejected  successfully!");
        handletogglePromotionReject();
        }
        
      },
      // On error, show an error toast
      onError: (error) => {
        // console.error("Error Rejecting promotion:", error);
        toast.error("Error Rejecting promotion. Please try again.");
      },
    }
  );
  const { mutate: deletepromotion } = useMutation(removepromotion, {
    // On success, invalidate the 'promotions' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("promotions");
      if (data.responseCode === "99") {
        // console.log("Error Rdeleting promotion:", data.responseMessage);
        toast.error(data.responseMessage);
        
      } else {
         // console.log("promotion successfully Deleted");
      toast.success("promotion successfully Deleted!");
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error deleting promotion:", error);
      toast.error("Error deleting promotion. Please try again.");
    },
  });

  //  Meun onClick Function
  const handleApprovepromotion = ({ promotionId }) => {
    // console.log("promotionId ", promotionId);
    promotionApprove(promotionId);
  };
  const handleRejectpromotion = (reason, promotionId) => {
    // console.log("promotionId:", promotionId, "reason:", reason);
    const rejectpromotionparameter = { reason, promotionId };
    promotionreject(rejectpromotionparameter);
  };
  const handleremovepromotion = ({ promotionId }) => {
    // console.log("promotionId:", promotionId);
    deletepromotion(promotionId);
  };
  const getRowId = (promotion) => promotion.promotionId;
  const columns = [
    {
      field: "sn",
      headerName: "SN",
      width: 80,
      cellClassName: "name-column--cell",
    },
    {
      field: "title",
      headerName: "Title",
      width: 180,
      cellClassName: "name-column--cell",
    },
    {
      field: "type",
      headerName: "Type",
      width: 180,
      cellClassName: "name-column--cell",
    },
    {
      field: "category",
      headerName: "Category",
      width: 130,
      cellClassName: "name-column--cell",
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 150,
      cellClassName: "name-column--cell",
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 150,
      // cellClassName: "name-column--cell",
    },
    {
      field: "status",
      headerName: "status  ",
      width: 150,
      align: "center",
      headerClassName: "name-column--head",
      renderCell: ({ row: { status } }) => {
        if (status === "Approved") {
          return (
            <Box
              width="65px"
              // mr="50px "
              // m="0 auto"
              p="4px"
              display="flex"
              justifyContent="center"
              backgroundColor="#f4fdf8"
              color="#32c36c"
              borderRadius="2px"
            >
              {status === "Approved" && <TbPointFilled />}
              {status === "Rejected" && <TbPointFilled />}
              {status === "Pending" && <TbPointFilled />}
              <Typography sx={{ ml: "2px" }}>
                {status === "Approved" && "Approved"}
                {status === "Rejected" && "Rejected"}
                {status === "Pending" && "Pending"}
              </Typography>
            </Box>
          );
        } else if (status === "Rejected") {
          return (
            <Box
              width="65px"
              // m="0 auto"
              // mr="90px "
              p="4px"
              display="flex"
              justifyContent="center"
              backgroundColor="#f9ebe8"
              color="#e23411"
              borderRadius="2px"
            >
              {status === "Approved" && <TbPointFilled />}
              {status === "Rejected" && <TbPointFilled />}
              {status === "Pending" && <TbPointFilled />}

              <Typography sx={{ ml: "2px" }}>
                {status === "Approved" && "Approved"}
                {status === "Rejected" && "Rejected"}
                {status === "Pending" && "Pending"}
              </Typography>
            </Box>
          );
        } else if (status === "Pending") {
          return (
            <Box
              width="65px"
              // m="0 auto"
              // mr="50px "
              p="4px"
              display="flex"
              justifyContent="center"
              backgroundColor="#fbf7e7"
              color="#f5ba69"
              borderRadius="2px"
            >
              {status === "Approved" && <TbPointFilled />}
              {status === "Rejected" && <TbPointFilled />}
              {status === "Pending" && <TbPointFilled />}

              <Typography sx={{ ml: "2px" }}>
                {status === "Approved" && "Approved"}
                {status === "Rejected" && "Rejected"}
                {status === "Pending" && "Pending"}
              </Typography>
            </Box>
          );
        }
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 180,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => ActionButton(params),
    },
  ];

  const ActionButton = (params) => {
    const rowId = params.row.promotionId;
    const anchorEl = anchorElMap[rowId];
    return (
      <>
        <Button
          className={styles["action-button"]}
          style={{
            marginLeft: 16,
            width: 100,
            height: 30,
            color: "#000",
            backgroundColor: "#ebf4fe",
          }}
          onClick={(event) => handleButtonClick(event, rowId)}
          aria-controls={`actions-menu-${rowId}`}
          aria-haspopup="true"
        >
          Action
          <RiArrowDownSFill style={{ marginLeft: 16 }} size={13} />
        </Button>

        <Menu
          id={`actions-menu-${rowId}`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleMenuClose(rowId)}
        >
          <StyledMenuItem
            onClick={() => handletogglePromotionDetail(params.row)}
          >
            View Details
          </StyledMenuItem>

          <StyledMenuItem
            onClick={() => handletogglePromotionApprove(params.row)}
          >
            Authorize
          </StyledMenuItem>

          <StyledMenuItem
            onClick={(event) => handletogglePromotionReject(params.row)}
          >
            Reject
          </StyledMenuItem>

          <StyledMenuItem onClick={() => handleremovepromotion(params.row)}>
            Delete
          </StyledMenuItem>
        </Menu>
      </>
    );
  };
  const navigate = useNavigate();
  const addPromotion = () => {
    return navigate("/promotion/Add-promotion");
  };
  let promotiondatafilter = [];
  const [searchTerm, setSearchTerm] = useState("");
  if (Promotions) {
    promotiondatafilter = Promotions.filter((val) => {
      if (selectedFilter === "All") {
        return (
          val.title
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase()) ||
          val.type
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase()) ||
          val.category
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase())
        );
      } else {
        return (
          (val.title
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase()) ||
            val.type
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase()) ||
            val.category
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase())) &&
          val.Status === selectedFilter
        );
      }
    });
  }

  let approvedCount = 0;
  let pendingCount = 0;
  let rejectedCount = 0;

  if (Promotions) {
    const statusCounts = Promotions.reduce((counts, Promotion) => {
      const status = Promotion.status;
      counts[status] = (counts[status] || 0) + 1;
      return counts;
    }, {});

    // console.log("Status Counts:", statusCounts);

    // Update the count variables
    approvedCount = statusCounts["Approved"] || 0;
    pendingCount = statusCounts["Pending"] || 0;
    rejectedCount = statusCounts["Rejected"] || 0;
  }
  return (
    <div className={styles.vendor}>
      {modalPromotionDetail ? (
        <ModalPromotionDetail
          onHandleTogglePromotionDetail={handletogglePromotionDetail}
          promotiondatafilter={selectedRow}
          onHandleToggle={handletogglePromotionApprove}
          onHandleTogglePromotionReject={handletogglePromotionReject}
        />
      ) : null}
      {modalPromotionApprove ? (
        <ModalPromotionApprove
          onHandleToggle={handletogglePromotionApprove}
          promotiondatafilter={selectedRow}
          onhandleApprovepromotion={handleApprovepromotion}
          isLoading={approveLoading}
        />
      ) : null}
      {modalPromotionReject ? (
        <ModalPromotionReject
          onHandleTogglePromotionReject={handletogglePromotionReject}
          promotiondatafilter={selectedRow}
          onhandleRejectpromotion={handleRejectpromotion}
          isLoading={rejectLoading}
        />
      ) : null}
      <Header />
      <div className={styles["vendor-body"]}>
        <Sidebar />
        <div className={styles["vendor-container"]}>
          <div className={styles["vendor-title"]}>
            <h2>Promotions</h2>
            <div className={styles["create-button"]}>
              <button
                className="--btn --btn-success "
                onClick={() => addPromotion()}
              >
                <p>New Promotion</p>
                <span>
                  <IoIosAddCircleOutline size={20} />
                </span>
              </button>
            </div>
          </div>
          <div className={styles["vendor-subtitle-search"]}>
            <div className={styles.subtitle}>
              <div
                className={`${styles["vendor-subtitle-head"]} ${
                  selectedFilter === "All" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("All")}
              >
                <p>All Promotions</p>
              </div>
              <div
                className={`${styles["vendor-active"]} ${
                  selectedFilter === "Approved" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Approved")}
              >
                <p>Approved</p>
                <span>{approvedCount} </span>
              </div>
              <div
                className={`${styles["vendor-pending"]} ${
                  selectedFilter === "Pending" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Pending")}
              >
                <p>Pending</p>
                <span>{pendingCount}</span>
              </div>
              <div
                className={`${styles["vendor-rejected"]} ${
                  selectedFilter === "Rejected" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Rejected")}
              >
                <p>Rejected</p>
                <span>{rejectedCount} </span>
              </div>
            </div>
            <div className={styles.search}>
              <form
                className="--form-control"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              >
                <span className={styles.icon}>
                  <CiSearch size={17} />
                </span>
                <input placeholder="Search Campaign title, type ..." />
              </form>
            </div>
          </div>
          <div className={styles["vendor-content"]} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
            {isLoading ? (
              <Loader />
            ) : (
              <Box
                sx={{
                  // width: {
                  //   xs: 445,
                  //   sm: 545,
                  //   md: 713,
                  //   lg: 945,
                  //   xl: 1200,
                  // },
                  height: "87%",
                  "& .MuiDataGrid-root": {
                    border: "none",
                    fontSize: " 13px",
                    fontFamily: "Montserrat",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders":
                    {
                      backgroundColor: "#32c36c",
                      color: "#fff",
                    },
                  "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate":
                    {
                      color: "#32c36c",
                    },
                  " & .css-12u49z-MuiDataGrid-root .MuiDataGrid-row.Mui-selected":
                    {
                      backgroundColor: "#ecfaf0",
                    },
                  " .MuiDataGrid-row.Mui-selected:hover, .css-12u49z-MuiDataGrid-root .MuiDataGrid-row.Mui-selected.Mui-hovered":
                    {
                      backgroundColor: "#ecfaf0",
                    },

                  "& .name-column--head": {
                    marginLeft: "40px",
                  },
                }}
              >
                <div className={styles["data-grid"]}>
                  <DataGrid
                    rows={promotiondatafilter.map((row, index) => ({
                      ...row,

                      sn: index + 1 + paginationModel.page * paginationModel.pageSize,
                    }))}
                    columns={columns}
                    options={{}}
                    pagination
                     paginationMode="server"
                    //  pageSizeOptions={[10, 20, 50]}
                   paginationModel={paginationModel}
                   onPaginationModelChange={handlePaginationModelChange}
                    rowCount={1000}
                    loading={isLoading}
                    getRowId={getRowId}
                  />
                </div>
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promotion;
