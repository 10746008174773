import React, { useEffect, useState } from "react";
import styles from "./Brands.module.css";
import Pagination from "@mui/material/Pagination";
import { Header, Sidebar, Loader } from "../../components";
import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import { AiOutlineCheckSquare, AiOutlineCloseSquare } from "react-icons/ai";
import { GiBackwardTime } from "react-icons/gi";
import { IoIosAddCircleOutline } from "react-icons/io";
import { RiArrowDownSFill } from "react-icons/ri";
import {
  ModalBrandDetail,
  ModalBulkUpload,
  ModalEditBrandDetail,
  ModalFullEditBrandDetail,
  ModalImageUpload,
} from "../../components/modal/Modal";
import agent from "../../api/agent";
import { toast } from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useBrands } from "../../customHooks/useBrands";
import { setBrands } from "../../redux/slice/brandSlice";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  width: "160px",
  padding: "15px 14px",
  borderBottom: "1px solid #f1f1f1",
  fontSize: "13px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#32c36c",
    color: "#fff",
  },
}));

const Brands = () => {
  const [modalBrandDetail, setModalBrandDetail] = useState(false);
  const [modalEditBrandDetail, setModalEditBrandDetail] = useState(false);

  const [modalImageUpload, setModalImageUpload] = useState(false);
  const [pageNumber, setPageNumber] = useState(1); // Define pageNumber in the component state
  const pageSize = 10; // Set your desired page size
  const [totalRows, setTotalRows] = useState(0);

  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorElMap, setAnchorElMap] = useState({});
  const handletoggleEditBrandDetail = (rowData, branddata) => {
    if (branddata) {
      setSelectedRow(branddata);
    } else {
      setSelectedRow(rowData);
    }
    setModalEditBrandDetail(!modalEditBrandDetail);
    setModalBrandDetail(false);
    setAnchorElMap({});
  };

  const handleToggleImageUpload = (rowData) => {
    setSelectedRow(rowData);
    setModalImageUpload(!modalImageUpload);
    setModalBrandDetail(false);
    setAnchorElMap({});
  };
  const handletoggleBrandDetail = (rowData) => {
    setSelectedRow(rowData);
    setModalBrandDetail(!modalBrandDetail);
    setAnchorElMap({});
  };
  const handleButtonClick = (event, rowId) => {
    event.stopPropagation();
    setAnchorElMap((prevMap) => ({ ...prevMap, [rowId]: event.currentTarget }));
  };
  const handleMenuClose = (rowId) => {
    setAnchorElMap((prevMap) => ({ ...prevMap, [rowId]: null }));
  };
  useEffect(() => {
    const handleCloseMenus = () => {
      setAnchorElMap({});
    };
    document.addEventListener("click", handleCloseMenus);
    return () => {
      document.removeEventListener("click", handleCloseMenus);
    };
  }, []);
  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 10
      }
    }
  };

  const [paginationModel, setPaginationModel] = useState(initialState.pagination.paginationModel);
  
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  // API Calls
  const removeBrand = async (brandId) => {
    // console.log("BrandId:", brandId);
    const BrandId = brandId;
    const response = await agent.brand.removeBrand(BrandId);
    return response;
  };
  const updateBrand = async (formData) => {
    // console.log("formData:", formData);
    const response = await agent.brand.updateBrand(formData);
    return response;
  };
  const brandCreation = async (formData) => {
    // console.log("formData:", formData);
    const response = await agent.brand.brandcreation(formData);
    return response;
  };

  const imageLink = async (imageData) => {
    // console.log("formData:", formData);
    const response = await agent.brand.imageLink(imageData);
    return response;
  };

  // React Query Functions
  const queryClient = useQueryClient();
  const { brands = [], isLoading, error, refetch } = useBrands(paginationModel);
  // console.log("brands",brands);

  if (error) {
    // Handle error state
    return <div>Error loading brands</div>;
  }

  //React Query Mutate state Function
  const { mutate: deleteBrand } = useMutation(removeBrand, {
    // On success, invalidate the 'brands' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("brands");

      if (data.responseCode === "99") {
        // console.log("Error deleting brand:", data.responseMessage);
        toast.error(data.responseMessage);
      } else {
        // console.log("Brand successfully Deleted");
        toast.success("Brand successfully Deleted!");
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error deleting brand:", error);
      toast.error("Error deleting brand. Please try again.");
    },
  });
  const { mutate: activeBrandData } = useMutation(updateBrand, {
    // On success, invalidate the 'brands' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("brands");
      if (data.responseCode === "99") {
        // console.log("Error activating brand:", data.responseMessage);
        toast.error(data.responseMessage);
      } else {
        // console.log("Brand activated successfully.");
        toast.success("Brand activated successfully.");
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error activating  brand:", error);
      toast.error("Error occurred while activating  the brand.");
    },
  });
  const { mutate: inactiveBrandData } = useMutation(updateBrand, {
    // On success, invalidate the 'brands' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("brands");
      if (data.responseCode === "99") {
        // console.log("Error inactivating brand:", data.responseMessage);
        toast.error(data.responseMessage);
      } else {
        // console.log("Brand inactivated successfully.");
        toast.success("Brand Inactivated successfully.");
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error inactivating  brand:", error);
      toast.error("Error occurred while Inactivating the brand.");
    },
  });
  //  Meun onClick Function
  const handleDeleteBrand = ({ brandId }) => {
    // console.log("deletebrandId", brandId);
    deleteBrand(brandId);
  };
  const handleActiveBrand = ({ brandId, brandName, image }) => {
    // console.log("active", brandId,"brandName", brandName,"image", image,);
    const status = "active";
    const formData = {
      brandId,
      brandName,
      image,
      status,
    };
    activeBrandData(formData);
  };
  const handleInactiveBrand = ({ brandId, brandName, image }) => {
    // console.log("active", brandId,"brandName", brandName,"image", image,);
    const status = "inactive";
    const formData = {
      brandId,
      brandName,
      image,
      status,
    };
    inactiveBrandData(formData);
  };

  const columns = [
    {
      field: "sn",
      headerName: "SN",
      width: 80,
      cellClassName: "name-column--cell",
    },
    {
      field: "image",
      headerName: "Brand Logo",
      width: 200,
      sortable: false,
      filterable: false,
      meun: false,
      renderCell: (params) => (
        <Avatar variant="square" src={params.row.image} />
      ),
      cellClassName: "name-column--cell",
    },
    {
      field: "brandName",
      headerName: "Brand name",
      width: 250,
      cellClassName: "name-column--cell",
    },

    {
      field: "Status",
      headerName: "status  ",
      width: 220,
      align: "center",
      headerClassName: "name-column--head",
      renderCell: ({ row: { status } }) => {
        if (status === "Active") {
          return (
            <Box
              width="95px"
              mr="105px "
              // m="0 auto"
              p="4px"
              display="flex"
              justifyContent="center"
              color="#32c36c"
              borderRadius="2px"
            >
              {status === "Active" && <FaThumbsUp />}
              {status === "inactive" && <FaThumbsDown />}

              <Typography sx={{ ml: "2px" }}>
                {status === "Active" && "Active"}
                {status === "inactive" && "Inactive"}
              </Typography>
            </Box>
          );
        } else if (status === "inactive") {
          return (
            <Box
              width="95px"
              // m="0 auto"
              mr="100px "
              p="4px"
              display="flex"
              justifyContent="center"
              color="#e23411"
              borderRadius="2px"
            >
              {status === "Active" && <FaThumbsUp />}
              {status === "inactive" && <FaThumbsDown />}

              <Typography sx={{ ml: "2px" }}>
                {status === "Active" && "Active"}
                {status === "inactive" && "Inactive"}
              </Typography>
            </Box>
          );
        }
      },
    },
    {
      field: "createdAt",
      headerName: "Date of Creation",
      width: 250,
      // cellClassName: "name-column--cell",
    },

    {
      field: "action",
      headerName: "Action",
      width: 180,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => ActionButton(params),
    },
  ];

  const ActionButton = (params) => {
    const rowId = params.row.brandId;
    const anchorEl = anchorElMap[rowId];

    return (
      <>
        <Button
          className={styles["action-button"]}
          style={{
            marginLeft: 16,
            width: 100,
            height: 30,
            color: "#000",
            backgroundColor: "#ebf4fe",
          }}
          onClick={(event) => handleButtonClick(event, rowId)}
          aria-controls={`actions-menu-${rowId}`}
          aria-haspopup="true"
        >
          Action
          <RiArrowDownSFill style={{ marginLeft: 16 }} size={13} />
        </Button>
        <Menu
          id={`actions-menu-${rowId}`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleMenuClose(rowId)}
        >
          <StyledMenuItem onClick={() => handletoggleBrandDetail(params.row)}>
            View
          </StyledMenuItem>

          <StyledMenuItem
            onClick={() => handletoggleEditBrandDetail(params.row)}
          >
            Edit
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleDeleteBrand(params.row)}>
            Delete
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleActiveBrand(params.row)}>
            Active
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleInactiveBrand(params.row)}>
            Inactive
          </StyledMenuItem>
        </Menu>
      </>
    );
  };
  const getRowId = (brand) => brand.brandId;
  const [dropdown, setDropdown] = useState(false);
  const [modalBulkUpload, setModalBulkUpload] = useState(false);
  const [modalAddBrandDetail, setModalAddBrandDetail] = useState(false);
  const handletoggleAddBrandDetail = () => {
    setModalAddBrandDetail(!modalAddBrandDetail);
  };
  const handletoggleBulkUpload = () => {
    setModalBulkUpload(!modalBulkUpload);
  };

  
  return (
  
    <div className={styles.vendor}>
      {modalBrandDetail ? (
        <ModalBrandDetail
          onHandleToggleBrandDetail={handletoggleBrandDetail}
          onHandleToggleEditBrandDetail={handletoggleEditBrandDetail}
          branddata={selectedRow}
        />
      ) : null}
      {modalEditBrandDetail ? (
        <ModalEditBrandDetail
          onHandleToggleEditBrandDetail={handletoggleEditBrandDetail}
          onHandleToggleChangeImage={handleToggleImageUpload}
          updateBrand={updateBrand}
          rowData={selectedRow}
        />
      ) : null}
      {modalAddBrandDetail ? (
        <ModalFullEditBrandDetail
          onHandleToggleAddBrandDetail={handletoggleAddBrandDetail}
          brandCreation={brandCreation}
          imageLink={imageLink}
        />
      ) : null}
      {modalBulkUpload ? (
        <ModalBulkUpload onHandleToggleBulkUpload={handletoggleBulkUpload} />
      ) : null}
      {modalImageUpload ? (
        <ModalImageUpload
          onHandleToggleImageUpload={handleToggleImageUpload}
          onImageSelected={handleImageSelected}
          onUpdateImage={onUpdateImage}
        />
      ) : null}

      <Header />
      <div className={styles["vendor-body"]}>
        <Sidebar />
        <div className={styles["vendor-container"]}>
          <div className={styles["vendor-title"]}>
            <h2>Brands</h2>
          </div>
          <div className={styles["vendor-subtitle-search"]}>
            <div className={styles.subtitle}>
              <div className={styles["vendor-subtitle-head"]}>
                <p>All Brands</p>
              </div>
            </div>
            <div className={styles["create-button"]}>
              <button
                className="--btn --btn-success "
                onClick={() => setDropdown(!dropdown)}
              >
                <p>Create New Brand</p>
                <span>
                  <IoIosAddCircleOutline size={20} />
                </span>
              </button>
              {dropdown ? (
                <div
                  className={styles["newbrand-dropdown-container"]}
                  onClick={() => setDropdown(!dropdown)}
                >
                  <div
                    className={styles["newbrand-dropdown-item"]}
                    onClick={() => handletoggleBulkUpload()}
                  >
                    <p>Bulk Upload</p>
                  </div>
                  <div
                    className={styles["newbrand-dropdown-item"]}
                    onClick={() => handletoggleAddBrandDetail()}
                  >
                    <p>Add new</p>
                  </div>
                </div>
              ) : (
                " "
              )}
            </div>
          </div>
          <div
           className={styles["vendor-content"]} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
            onClick={() => setDropdown(false)}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <Box
                sx={{
                  // width: {
                  //   xs: 445,
                  //   sm: 545,
                  //   md: 713,
                  //   lg: 945,
                  //   xl: 1200,
                  // },
                  height: "87%",
                  "& .MuiDataGrid-root": {
                    border: "none",
                    fontSize: " 13px",
                    fontFamily: "Montserrat",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders":
                    {
                      backgroundColor: "#32c36c",
                      color: "#fff",
                    },
                  "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate":
                    {
                      color: "#32c36c",
                    },
                  " & .css-12u49z-MuiDataGrid-root .MuiDataGrid-row.Mui-selected":
                    {
                      backgroundColor: "#ecfaf0",
                    },
                  " .MuiDataGrid-row.Mui-selected:hover, .css-12u49z-MuiDataGrid-root .MuiDataGrid-row.Mui-selected.Mui-hovered":
                    {
                      backgroundColor: "#ecfaf0",
                    },

                  "& .name-column--head": {
                    marginLeft: "20px",
                  },
                }}
                
              >
                <div className={styles["data-grid"]}>
                  <DataGrid
                    rows={
                      brands && brands.length > 0
                        ? brands.map((row, index) => ({
                            ...row,
                            sn: index + 1 + paginationModel.page * paginationModel.pageSize,
                          }))
                        : []
                    }
                    columns={columns}
                    options={{}}
                    pagination
                     paginationMode="server"
                    //  pageSizeOptions={[10, 20, 50]}
                   paginationModel={paginationModel}
                   onPaginationModelChange={handlePaginationModelChange}
                    rowCount={1000}
                    loading={isLoading}
                    getRowId={getRowId}
                  />
                
                </div>
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brands;
