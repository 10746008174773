import React from "react";
import Papa from "papaparse";
import { useRef } from "react";
import { useState } from "react";
import * as XLSX from "xlsx";
const BrandBulkUploadinputs = ({
  setExcelFileError,
  setFileDetailsList,
  setFormData,
}) => {
  const fileInputRef = useRef(null);
  const [fileInputKey, setFileInputKey] = useState(0);

  const parseCSV = (csvData) => {
    try {
      // Parse the CSV data
      Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          if (result.errors.length === 0) {
            // Valid CSV data
            const csvData = result.data;

            // Validate and map CSV data to form data
            const formData = csvData.map((row, index) => {
              const errors = [];
              // Custom validation logic

              if (!row.Brand) {
                errors.push(`Row ${index + 2}: Brand Cell is required`);
              }

              if (row.ImageUrl1 && !row.ImageUrl1.startsWith("https://")) {
                errors.push(
                  `Row ${index + 2}: ImageUrl1 must start with "https://"`
                );
              }

              if (errors.length === 0) {
                // Create the form data object
                return {
                  BrandName: row.Brand,
                  Image: row.ImageUrl1 || "",
                  // multipleProductImageUrl: [row.ImageUrl1].filter((url) => url),
                  FileType:"jpeg"
                };
              } else {
                setExcelFileError(`Validation errors: ${errors.join(", ")}`);
                return null;
              }
            });

            // Remove null values from the array
            const validFormData = formData.filter((data) => data !== null);
            // Update the form data with the CSV data
            setFormData(validFormData);
          } else {
            const errorMessage = result.errors
              .map((error) => `Row ${error.row}: ${error.message}`)
              .join(", ");
            setExcelFileError(`Validation errors: ${errorMessage}`);
          }
        },
      });
    } catch (error) {
      setExcelFileError(`Error parsing CSV: ${error}`);
    }
  };

  const handleFileChange = (e) => {
    const fileInput = fileInputRef.current;
    setFileInputKey(fileInputKey + 1);

    const file = e.target.files[0];

    if (file) {
      if (file.size > 10485760) {
        setExcelFileError(
          "File is too large. Please upload a file less than 10 MB"
        );
        return;
      } else {
        setExcelFileError(""); // Clear any previous error
      }

      setFileDetailsList([{ name: file.name, size: file.size }]);

      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (fileExtension === "xlsx") {
        // Handle .xlsx file
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const firstSheetName = workbook.SheetNames[0];
            const csvData = XLSX.utils.sheet_to_csv(
              workbook.Sheets[firstSheetName]
            );
            parseCSV(csvData);
          } catch (error) {
            setExcelFileError(`Error converting .xlsx to .csv: ${error}`);
          }
        };
        reader.readAsArrayBuffer(file);
      } else if (fileExtension === "csv") {
        // Handle .csv file
        const reader = new FileReader();
        reader.onload = (e) => {
          const csvData = e.target.result;
          parseCSV(csvData);
        };
        reader.readAsText(file);
      }
    }
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
        Drag and drop or <span>browse</span> your files
      </label>
      <input
        type="file"
        accept=".csv, .xlsx"
        id="fileInput"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default BrandBulkUploadinputs;
