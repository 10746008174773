import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice"
import vendorReducer from "./slice/vendorSlice";
import customerReducer from "./slice/customerSlice";
import orderReducer from "./slice/orderSlice";
import productReducer from "./slice/productSlice";
import brandReducer from "./slice/brandSlice";
import categoryReducer from "./slice/categorySlice";

const rootReducer = combineReducers({
    auth : authReducer,
    vendor: vendorReducer,
    customer: customerReducer,
    order: orderReducer,
    product: productReducer,
    brand: brandReducer,
    category: categoryReducer,
});

  const store = configureStore({
  reducer: rootReducer,
});

export default store;
