import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'; // Assuming you're using Redux for state management
import { logoutUser } from "../redux/slice/authSlice"; // Import your logout action creator
import { toast } from 'react-toastify'; // Import toast notification library
import agent from '../api/agent';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

export  const useSessionTimeout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Timeout duration in milliseconds
  const TIMEOUT_DURATION = 4 * 60 * 1000; // 4 minutes
  const WARNING_TIMEOUT = 10 * 1000; // 10 seconds

  const logOut = async () => {
    const response = await agent.auth.logout();
    // console.log("logOut:",response);
    return response;
  };

  // Perform the logout mutation using react-query 

  const { mutate: logOutData, isLoading } = useMutation(logOut, {
    // On success, logout the user 
    onSuccess: (data) => {
      if (data.responseCode === "99") {
        // console.log("Error logging out:", data.responseMessage);
        toast.error(data.responseMessage);
      } else {
      // toast.success(data.responseMessage);
      dispatch(logoutUser());
      setIsLoggedIn(false);
      // Redirect to login page
      navigate("/");
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Errorlogging out:", error);
      toast.error("Error logging out . Please try again.");
    },
  });
  

   // Function to handle user logout
  const handleLogout = () => {
    logOutData(); // Trigger the logout mutation
  };

  useEffect(() => {
    let timeout;
    let warningTimeout;

    const resetTimeout = () => {
      clearTimeout(timeout);
      clearTimeout(warningTimeout);
      timeout = setTimeout(() => {
        handleLogout();
      }, TIMEOUT_DURATION);
      warningTimeout = setTimeout(() => {
        toast.error('Your Session Timed Out Due To Inactivity ');
      }, TIMEOUT_DURATION - WARNING_TIMEOUT);
    };

    const clearAllTimeouts = () => {
      clearTimeout(timeout);
      clearTimeout(warningTimeout);
    };

    const handleUserActivity = () => {
      resetTimeout();
    };

    // Initial setup
    resetTimeout();

    // Event listeners
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);

    // Cleanup
    return () => {
      clearAllTimeouts();
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
    };
  }, []);

  return isLoggedIn;
};

