import { useQuery } from "react-query";
import agent from "../api/agent";

const fetchProducts = async ({ page, pageSize }) => {
  // Fetch product and vendor data separately
  const PageNumber = page + 1;
    const PageSize = pageSize

  const response = await agent.product.getAllProducts(PageNumber, PageSize );
  return response.data;
};

// export const useProducts = () => {
//   const {
//     isLoading, // Make sure this property is correct
//     error,
//     data: products,
//   } = useQuery({
//     queryKey: ["products"],
//     queryFn: fetchProducts,
//   });
//   return { isLoading, error, products };
// };

export const useProducts = ({ page, pageSize } = {}) => {
  const { isLoading, error, data: products, refetch } = useQuery({
    queryKey: ['products', { page, pageSize }],
    queryFn: () => fetchProducts({ page, pageSize }),
  });

  return { isLoading, error, products, refetch };
};