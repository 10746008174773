import { useQuery } from "react-query";
import agent from "../api/agent";

// const fetchBrands = async () => {
//     const response = await agent.brand.getAllBrands();
//     return response.data;
//   };

  const fetchBrands = async ({ page = 0, pageSize = 1000 }) => {
    // console.log("PageNumber:",page, "PageSize:",pageSize);
    // Adjust PageNumber based on page value
    // const PageNumber = page === 0 ? 1 : page;
    const PageNumber = page + 1;
    const PageSize = pageSize
    // console.log("PageNumber2:",PageNumber, "PageSize2:",pageSize);
    const response = await agent.brand.getAllBrands(PageNumber, PageSize );
    return response.data;
  };

// export const useBrands = () => {
//     const {
//       isLoading,
//       error,
//       data: brands,
//     } = useQuery({
//       queryKey: ["brands"],
//       queryFn: fetchBrands ,
//     });
//     return { isLoading, error, brands };
//   }; 

  export const useBrands = ({ page, pageSize } = {}) => {
    const { isLoading, error, data: brands, refetch } = useQuery({
      queryKey: ['brands', { page, pageSize }],
      queryFn: () => fetchBrands({ page, pageSize }),
    });
  
    return { isLoading, error,brands, refetch };
  };

  export const useSelectBrands = ({ page, pageSize }) => {
    const fetchSelectBrands = async () => {
      const PageNumber = page ;
    const PageSize = pageSize
      try {
        const response = await agent.brand.getAllBrands(PageNumber, PageSize );
        return response.data;
      } catch (error) {
        throw new Error("Failed to fetch Brands");
      }
    };
  
    return useQuery({
      queryKey: ['brands', { page, pageSize }],
      queryFn: fetchSelectBrands,
    });
  };