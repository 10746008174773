import React from "react";
import PropTypes from "prop-types";
import { PieChart, Pie,  Cell } from "recharts";
import { useDashboardMatrix } from "../../../customHooks/useDashboardMatrix";



const Piechart = ({ data }) => {
  return (
    <div>
      <PieChart width={200} height={120}>
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={50}
          outerRadius={55}
          fill="#8884d8"
          paddingAngle={2}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
};


Piechart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const DashboardPieChart = () => {
  const { dashboardmatrix, isLoading, error } = useDashboardMatrix();

  let data = [
    { name: "Red", value: 100, color: "#db353a" },
    { name: "Green", value: 400, color: "#32c36c" },
    { name: "Yellow", value: 80, color: "#f0c419" },
  ];

  if (!isLoading && !error && dashboardmatrix && dashboardmatrix.orderMatrix) {
    data = [
      { name: "Red", value: dashboardmatrix.orderMatrix.cancelled, color: "#db353a" },
      { name: "Green", value: dashboardmatrix.orderMatrix.completed, color: "#32c36c" },
      { name: "Yellow", value: dashboardmatrix.orderMatrix.pending, color: "#f0c419" },
    ];
  }

  return <Piechart data={data} />;
};

export default DashboardPieChart;
