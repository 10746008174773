import { useQuery } from "react-query";
import agent from "../api/agent";

// const fetchOrders = async () => {
//     const response = await agent.order.getAllOrders();
//     return response.data;
//   };
  
const fetchOrders = async ({ page, pageSize }) => {
  // console.log("PageNumber:",page, "PageSize:",pageSize);
  // Adjust PageNumber based on page value
  // const PageNumber = page === 0 ? 1 : page;
  const PageNumber = page + 1;
  const PageSize = pageSize
  // console.log("PageNumber2:",PageNumber, "PageSize2:",pageSize);
  const response = await agent.order.getAllOrders(PageNumber, PageSize );
  return response.data;
};

// export const useOrders = () => {
//     const {
//       isLoading,
//       error,
//       data: orders,
//     } = useQuery({
//       queryKey: ["orders"],
//       queryFn: fetchOrders,
//     });
//     return { isLoading, error, orders };
//   }; 

  export const useOrders = ({ page, pageSize } = {}) => {
  const { isLoading, error, data: orders, refetch } = useQuery({
    queryKey: ['orders', { page, pageSize }],
    queryFn: () => fetchOrders({ page, pageSize }),
  });

  return { isLoading, error,orders, refetch };
};