import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brands: [],
  loading: false,
  error: null,
};
const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    setBrands: (state, action) => {
      state.brands = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },

    updateBrands: (state, action) => {
      // Find the index of the brand to be updated
      const index = state.brands.findIndex(
        (brand) => brand.brandId === action.payload.brandId
      );

      // If found, update the brand data
      if (index !== -1) {
        state.brands[index] = action.payload;
      }
    },
  },
});

export const { setBrands, setLoading, updateBrands, setError } =
  brandSlice.actions;
export default brandSlice.reducer;
