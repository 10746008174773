import React, { useEffect, useState } from "react";
import styles from "./ProductList.module.css";
import { Header, Sidebar, Loader } from "../../components";
import { CiSearch, CiStreamOn } from "react-icons/ci";
import { GiBackwardTime } from "react-icons/gi";
import { BiDislike, BiLike } from "react-icons/bi";
import { TbSquareOff } from "react-icons/tb";
// import { FiTrash } from "react-icons/fi";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { DataGrid } from "@mui/x-data-grid";
import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import { RiArrowDownSFill } from "react-icons/ri";
import { productdata } from "../../components/tabledata";
import { IoIosAddCircleOutline } from "react-icons/io";
import {
  ModalProdutListAuthorize,
  ModalProdutListReason,
  ModalProdutListReject,
  ModalProdutUpload,
} from "../../components/modal/Modal";
import { useNavigate } from "react-router-dom";
import agent from "../../api/agent";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setProducts } from "../../redux/slice/productSlice";
import toast from "react-hot-toast";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useProducts } from "../../customHooks/useProducts";
import { useVendors } from "../../customHooks/useVendors";
import { setVendors} from "../../redux/slice/vendorSlice"
import { setBrands} from "../../redux/slice/brandSlice"
import { setCategories} from "../../redux/slice/categorySlice"
import { useBrands } from "../../customHooks/useBrands";
import { useCategories } from "../../customHooks/useCategories";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  width: "200px",
  padding: "19px 19px",
  borderBottom: "1px solid #f1f1f1",
  fontSize: "13px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#32c36c",
    color: "#fff",
  },
}));

const ProductList = () => {
  const [modalProdutListAuthorize, setModalProdutListAuthorize] =
    useState(false);
  const [modalProdutListReject, setModalProdutListReject] = useState(false);
  const [modalProdutListReason, setModalProdutListReason] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorElMap, setAnchorElMap] = useState({});

  const handletoggleProdutListAuthorize = (rowData) => {
    setSelectedRow(rowData);
    setModalProdutListAuthorize(!modalProdutListAuthorize);
    setAnchorElMap({});
  };
  const handletoggleProdutListReject = (rowData) => {
    setSelectedRow(rowData);
    setModalProdutListReject(!modalProdutListReject);
    setAnchorElMap({});
  };
  const handletoggleProdutListReason = (productData) => {
    setSelectedRow(productData);
    setModalProdutListReason(!modalProdutListReason);
    setAnchorElMap({});
  };
  const handleButtonClick = (event, rowId) => {
    event.stopPropagation();
    setAnchorElMap((prevMap) => ({ ...prevMap, [rowId]: event.currentTarget }));
  };
  const handleMenuClose = (rowId) => {
    setAnchorElMap((prevMap) => ({ ...prevMap, [rowId]: null }));
  };
  const updateProduct = (rowData) => {
    setSelectedRow(rowData); // Set the selectedRow state
    setAnchorElMap({}); // Clear the anchor element map if needed
    // Navigate to the update-product route and pass the rowData as state
    navigate("/product-list/update-product", { state: rowData });
  };

  useEffect(() => {
    const handleCloseMenus = () => {
      setAnchorElMap({});
    };
    document.addEventListener("click", handleCloseMenus);
    return () => {
      document.removeEventListener("click", handleCloseMenus);
    };
  }, []);
  const dispatch = useDispatch();
  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 10
      }
    }
  };
  const [paginationModel, setPaginationModel] = useState(initialState.pagination.paginationModel);
  
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  // API Calls
  const approveProduct = async (productId) => {
    const status = "approved";
    const reason = "Approved";
    // console.log(
    //   "approveProduct:",
    //   productId,
    //   "status:",
    //   status,
    //   "reason:",
    //   reason
    // );
    const response = await agent.product.approveProduct(
      productId,
      status,
      reason
    );
    return response;
  };
  const rejectProduct = async ({reason, productId}) => {
    const status = "declined";
    // console.log(
    //   "rejectProduct:",
    //   productId,
    //   "status:",
    //   status,
    //   "reason:",
    //   reason
    // ); // Add this line
    const response = await agent.product.rejectProduct(
      productId,
      status,
      reason
    );
    return response;
  };
  const productTopDeal = async (productId) => {
    // console.log("productId:", productId);
    const response = await agent.product.updateproductotopdeal(productId);
    return response;
  };
  const productDisabled = async (productId) => {
    // console.log("productId:", productId);
    const response = await agent.product.productodisabled(productId);
    return response;
  };

  const removeproduct = async (productId) => {
    // console.log("productId:", productId);
    const response = await agent.product.removeproduct(productId);
    return response;
  };


  // React Query Functions
 
const { isLoading, error, products } = useProducts(paginationModel);

// console.log("products",products);

const { brands } = useBrands();
const {vendors } = useVendors();

const { categories } = useCategories();

dispatch(setBrands(brands))
dispatch(setVendors(vendors))
dispatch(setCategories(categories))
  if (error) {
    // Handle error state
    return <div>Error loading brands</div>;
  }

  const queryClient = useQueryClient();
  const { mutate: productApprove, isLoading: approveLoading } = useMutation(
    approveProduct,
    {
      // On success, invalidate the 'products' query to refetch the data
      onSuccess: (data) => {
        queryClient.invalidateQueries("products");

        if (data.responseCode === "99") {
          // console.log("Error approving Product:", data.responseMessage);
          toast.error(data.responseMessage);
          handletoggleProdutListAuthorize();
        } else {
          // console.log("Product approved successfully");
          toast.success("Product approved successfully!");
          handletoggleProdutListAuthorize();
        }
      },
      // On error, show an error toast
      onError: (error) => {
        // console.error("Error approving Product:", error);
        toast.error("Error approving Product . Please try again.");
      },
    }
  );
  const { mutate: productreject, isLoading: rejectLoading } = useMutation(
    rejectProduct,
    {
      // On success, invalidate the 'products' query to refetch the data
      onSuccess: (data) => {
        queryClient.invalidateQueries("products");

        if (data.responseCode === "99") {
          // console.log("Error Rejecting Product:", data.responseMessage);
          toast.error(data.responseMessage);
          handletoggleProdutListReason();
          handletoggleProdutListReject();
        } else {
          // console.log("Product Rejected  successfully");
          toast.success("Product Rejected  successfully!");
          handletoggleProdutListReason();
          handletoggleProdutListReject();
        }
      },
      // On error, show an error toast
      onError: (error) => {
        // console.error("Error Rejecting Product:", error);
        toast.error("Error Rejecting Product . Please try again.");
      },
    }
  );
  const { mutate: moveProductTopDeal } = useMutation(productTopDeal, {
    // On success, invalidate the 'products' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("products");

      if (data.responseCode === "99") {
        // console.log("Error updating Product to top deal:", data.responseMessage);
        toast.error(data.responseMessage);
        
      } else {
        // console.log("Product to Top Deal successfully");
      toast.success("Product to Top Deal successfully!");
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error updating Product to top deal:", error);
      toast.error("Error updating Product to top deal. Please try again.");
    },
  });
  const { mutate: Disabledproduct } = useMutation(productDisabled, {
    // On success, invalidate the 'products' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("products");

      if (data.responseCode === "99") {
        // console.log("Error Disabling Product:", data.responseMessage);
        toast.error(data.responseMessage);
      } else {
        // console.log("Product successfully Disable");
        toast.success("Product successfully Disable!");
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error Disabling Product:", error);
      toast.error("Error Disabling Product. Please try again.");
    },
  });
  const { mutate: deleteProduct } = useMutation(removeproduct, {
    // On success, invalidate the 'products' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("products");

      if (data.responseCode === "99") {
        // console.log("Error deleting Product:", data.responseMessage);
        toast.error(data.responseMessage);
      } else {
      // console.log("Product successfully Deleted");
      toast.success("Product successfully Deleted!");
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error deleting Product:", error);
      toast.error("Error deleting Product. Please try again.");
    },
  });

  //  Meun onClick Function
  const handleApproveProduct = ({ productId }) => {
    // console.log("productId ", productId);
    productApprove(productId);
  };
  const handleRejectProduct = (reason, productId) => {
    // console.log("productId :", productId, "reason:", reason);
    const rejectProductparameter = { reason, productId };
    productreject(rejectProductparameter);
  };
  const handleupdateproductotopdeal = ({ productId }) => {
    // console.log("productId:", productId);
    moveProductTopDeal(productId);
  };
  const handleproductodisabled = ({ productId }) => {
    // console.log("productId:", productId);
    Disabledproduct(productId);
  };
  const handleremoveproduct = ({ productId }) => {
    // console.log("productId:", productId);
    deleteProduct(productId);
  };

  let liveCount = 0;
  let approvedCount = 0;
  let pendingCount = 0;
  let disabledCount = 0;
  let rejectedCount = 0;
  let outofstockCount = 0;

  if (products) {
    const statusCounts = products.reduce((counts, product) => {
      const status = product.status;
      counts[status] = (counts[status] || 0) + 1;
      return counts;
    }, {});

    // console.log("Status Counts:", statusCounts);

    // Update the count variables
    liveCount = statusCounts["live"] || 0;
    approvedCount = statusCounts["Approved"] || 0;
    pendingCount = statusCounts["Pending"] || 0;
    disabledCount = statusCounts["Disabled"] || 0;
    rejectedCount = statusCounts["Declined"] || 0;
    outofstockCount = statusCounts["outofstock"] || 0;
  }

  // Define a function to format the price
function formatPrice(price) {
  // Assuming price is a number
  const formattedPrice = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(price);

  return `${formattedPrice}`;
}

  const columns = [
    {
      field: "sn",
      headerName: "SN",
      width: 20,
      cellClassName: "name-column--cell",
    },
    {
      width: 4,
      cellClassName: "name-column--ll",
      sortable: false,
      filterable: false,
      meun: false,
      renderCell: (params) => (
        <Avatar variant="square" src={params.row.productPrimaryImageUrl} />
      ),
    },
    {
      field: "productName",
      headerName: "Product",
      width: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "vendorName",
      headerName: "Vendor",
      width: 190,
      cellClassName: "name-column--cell",
    },
    {
      field: "productQuantity",
      headerName: "Quantity",
      width: 100,
      cellClassName: "name-column--cell",
      valueFormatter: (params) => {
        // Use JavaScript's toLocaleString method to format the number
        return params.value.toLocaleString();
      },
    },
    {
      field: "productCurrentPrice",
      headerName: "Price",
      width: 130,
      cellClassName: "name-column--cell",
      valueGetter: (params) => formatPrice(params.value),
    },

    {
      field: "status",
      headerName: "status  ",
      width: 110,
      align: "center",
      headerClassName: "name-column--head",
      renderCell: ({ row: { status } }) => {
        if (status === "Approved") {
          return (
            <Box
              width="65px"
              // mr="50px "
              // m="0 auto"
              p="4px"
              display="flex"
              justifyContent="center"
              color="#32c36c"
              borderRadius="2px"
            >
              {status === "Approved" && <BiLike />}
              {status === "Declined" && <BiDislike />}
              {status === "Pending" && <GiBackwardTime />}
              {status === "Live" && <CiStreamOn />}
              {status === "Disabled" && <TbSquareOff />}
              {status === "Out of Stock" && <MdOutlineAddShoppingCart />}
              <Typography sx={{ ml: "2px" }}>
                {status === "Approved" && "Approved"}
                {status === "Declined" && "Rejected"}
                {status === "Pending" && "Pending"}
                {status === "Live" && "Live"}
                {status === "Disabled" && "Disabled"}
                {status === "Out of Stock" && "Out of Stock"}
              </Typography>
            </Box>
          );
        } else if (status.toLowerCase() === "declined") {
          return (
            <Box
              width="65px"
              // m="0 auto"
              // mr="90px "
              p="4px"
              display="flex"
              justifyContent="center"
              color="#e23411"
              borderRadius="2px"
            >
              {status === "Approved" && <BiLike />}
              {status === "Declined" && <BiDislike />}
              {status === "Pending" && <GiBackwardTime />}
              {status === "Live" && <CiStreamOn />}
              {status === "Disabled" && <TbSquareOff />}
              {status === "Out of Stock" && <MdOutlineAddShoppingCart />}
              <Typography sx={{ ml: "2px" }}>
                {status === "Approved" && "Approved"}
                {status === "Declined" && "Rejected"}
                {status === "Pending" && "Pending"}
                {status === "Live" && "Live"}
                {status === "Disabled" && "Disabled"}
                {status === "Out of Stock" && "Out of Stock"}
              </Typography>
            </Box>
          );
        } else if (status === "Pending") {
          return (
            <Box
              width="65px"
              // m="0 auto"
              // mr="50px "
              p="4px"
              display="flex"
              justifyContent="center"
              color="#f5ba69"
              borderRadius="2px"
            >
              {status === "Approved" && <BiLike />}
              {status === "Declined" && <BiDislike />}
              {status === "Pending" && <GiBackwardTime />}
              {status === "Live" && <CiStreamOn />}
              {status === "Disabled" && <TbSquareOff />}
              {status === "Out of Stock" && <MdOutlineAddShoppingCart />}
              <Typography sx={{ ml: "2px" }}>
                {status === "Approved" && "Approved"}
                {status === "Declined" && "Rejected"}
                {status === "Pending" && "Pending"}
                {status === "Live" && "Live"}
                {status === "Disabled" && "Disabled"}
                {status === "Out of Stock" && "Out of Stock"}
              </Typography>
            </Box>
          );
        } else if (status === "Live") {
          return (
            <Box
              width="65px"
              // m="0 auto"
              // mr="50px "
              p="4px"
              display="flex"
              justifyContent="center"
              color="#32c36c"
              borderRadius="2px"
            >
              {status === "Approved" && <BiLike />}
              {status === "Declined" && <BiDislike />}
              {status === "Pending" && <GiBackwardTime />}
              {status === "Live" && <CiStreamOn />}
              {status === "Disabled" && <TbSquareOff />}
              {status === "Out of Stock" && <MdOutlineAddShoppingCart />}
              <Typography sx={{ ml: "2px" }}>
                {status === "Approved" && "Approved"}
                {status === "Declined" && "Rejected"}
                {status === "Pending" && "Pending"}
                {status === "Live" && "Live"}
                {status === "Disabled" && "Disabled"}
                {status === "Out of Stock" && "Out of Stock"}
              </Typography>
            </Box>
          );
        } else if (status === "Disabled") {
          return (
            <Box
              width="65px"
              // m="0 auto"
              // mr="50px "
              p="4px"
              display="flex"
              justifyContent="center"
              color="#e23411"
              borderRadius="2px"
            >
              {status === "Approved" && <BiLike />}
              {status === "Declined" && <BiDislike />}
              {status === "Pending" && <GiBackwardTime />}
              {status === "Live" && <CiStreamOn />}
              {status === "Disabled" && <TbSquareOff />}
              {status === "Out of Stock" && <MdOutlineAddShoppingCart />}
              <Typography sx={{ ml: "2px" }}>
                {status === "Approved" && "Approved"}
                {status === "Declined" && "Rejected"}
                {status === "Pending" && "Pending"}
                {status === "Live" && "Live"}
                {status === "Disabled" && "Disabled"}
                {status === "Out of Stock" && "Out of Stock"}
              </Typography>
            </Box>
          );
        } else if (status === "Out of Stock") {
          return (
            <Box
              width="85px"
              // m="0 auto"
              // mr="50px "
              p="4px"
              display="flex"
              justifyContent="center"
              color="#c6c6c6"
              borderRadius="2px"
            >
              {status === "Approved" && <BiLike />}
              {status === "Declined" && <BiDislike />}
              {status === "Pending" && <GiBackwardTime />}
              {status === "Live" && <CiStreamOn />}
              {status === "Disabled" && <TbSquareOff />}
              {status === "Out of Stock" && <MdOutlineAddShoppingCart />}
              <Typography sx={{ ml: "2px" }}>
                {status === "Approved" && "Approved"}
                {status === "Declined" && "Rejected"}
                {status === "Pending" && "Pending"}
                {status === "Live" && "Live"}
                {status === "Disabled" && "Disabled"}
                {status === "Out of Stock" && "Out of Stock"}
              </Typography>
            </Box>
          );
        }
      },
    },
    {
      field: "dateCreated",
      headerName: "Date/Time",
      width: 150,
      // cellClassName: "name-column--cell",
    },

    {
      field: "action",
      headerName: "Action",
      width: 180,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => ActionButton(params),
    },
  ];

  const ActionButton = (params) => {
    const rowId = params.row.productId;
    const anchorEl = anchorElMap[rowId];

    return (
      <>
        <Button
          className={styles["action-button"]}
          style={{
            marginLeft: 16,
            width: 100,
            height: 30,
            color: "#000",
            backgroundColor: "#ebf4fe",
          }}
          onClick={(event) => handleButtonClick(event, rowId)}
          aria-controls={`actions-menu-${rowId}`}
          aria-haspopup="true"
        >
          Action
          <RiArrowDownSFill style={{ marginLeft: 16 }} size={13} />
        </Button>
        <Menu
          id={`actions-menu-${rowId}`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleMenuClose(rowId)}
        >
          <StyledMenuItem
            onClick={() => handletoggleProdutListAuthorize(params.row)}
          >
            Approve
          </StyledMenuItem>

          <StyledMenuItem
            onClick={() => handletoggleProdutListReject(params.row)}
          >
            Reject
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => handleupdateproductotopdeal(params.row)}
          >
            Add to top Deals
          </StyledMenuItem>

          <StyledMenuItem onClick={() => updateProduct(params.row)}>
            Edit
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleproductodisabled(params.row)}>
            Disable
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleremoveproduct(params.row)}>
            Delete
          </StyledMenuItem>
        </Menu>
      </>
    );
  };
  const getRowId = (product) => product.productId;
  const [dropdown, setDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  let productdatafilter = []; // Initialize an empty array

  if (products) {
    // Check if products is defined
    // Apply filtering logic here
    productdatafilter = products.filter((val) => {
      if (selectedFilter === "All") {
        return (
          val.productName
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase()) ||
          val.vendorName
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase())
        );
      } else {
        return (
          (val.productName
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase()) ||
            val.vendorName
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase())) &&
          val.status === selectedFilter
        );
      }
    });
  }
  const [modalProdutUpload, setModalProdutUpload] = useState(false);
  const handletoggleProdutUpload = () => {
    setModalProdutUpload(!modalProdutUpload);
  };
  const navigate = useNavigate();
  const addProduct = () => {
    return navigate("/product-list/Add-product");
  };

  return (
    <div className={styles.vendor}>
      {modalProdutListAuthorize ? (
        <ModalProdutListAuthorize
          onHandleToggle={handletoggleProdutListAuthorize}
          onHandleApproveProduct={handleApproveProduct}
          isLoading={approveLoading}
          productdatafilter={selectedRow}
        />
      ) : null}
      {modalProdutListReject ? (
        <ModalProdutListReject
          onHandleToggleProdutListReject={handletoggleProdutListReject}
          onHandleToggleReason={handletoggleProdutListReason}
          productdatafilter={selectedRow}
        />
      ) : null}
      {modalProdutListReason ? (
        <ModalProdutListReason
          onHandleToggle={handletoggleProdutListReason}
          onHandleRejectReason={handleRejectProduct}
          isLoading={rejectLoading}
          productData={selectedRow}
        />
      ) : null}
      {modalProdutUpload ? (
        <ModalProdutUpload
          onHandleToggleProdutUpload={handletoggleProdutUpload}
        />
      ) : null}
      <Header />
      <div className={styles["vendor-body"]}>
        <Sidebar />
        <div className={styles["vendor-container"]}>
          <div className={styles["vendor-title"]}>
            <h2>Product List</h2>
            <div className={styles["create-button"]}>
              <button
                className="--btn --btn-success "
                onClick={() => setDropdown(!dropdown)}
              >
                <p>New Product</p>
                <span>
                  <IoIosAddCircleOutline size={20} />
                </span>
              </button>
              {dropdown ? (
                <div
                  className={styles["newbrand-dropdown-container"]}
                  onClick={() => setDropdown(!dropdown)}
                >
                  <div
                    className={styles["newbrand-dropdown-item"]}
                    onClick={() => addProduct()}
                  >
                    <p>Add a product</p>
                  </div>
                  <div
                    className={styles["newbrand-dropdown-item"]}
                    onClick={() => handletoggleProdutUpload()}
                  >
                    <p>File Upload</p>
                  </div>
                </div>
              ) : (
                " "
              )}
            </div>
          </div>
          <div className={styles["vendor-subtitle-search"]}>
            <div className={styles.subtitle}>
              <div
                className={`${styles["vendor-subtitle-head"]} ${
                  selectedFilter === "All" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("All")}
              >
                <p>All Products</p>
              </div>
              <div
                className={`${styles["vendor-active"]} ${
                  selectedFilter === "live" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("live")}
              >
                <p>Live</p>
                <span>{liveCount}</span>
              </div>
              <div
                className={`${styles["vendor-pending"]} ${
                  selectedFilter === "Pending" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Pending")}
              >
                <p>Pending</p>
                <span>{pendingCount} </span>
              </div>
              <div
                className={`${styles["vendor-inactive"]} ${
                  selectedFilter === "Approved" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Approved")}
              >
                <p>Approved</p>
                <span>{approvedCount} </span>
              </div>
              <div
                className={`${styles["vendor-rejected"]} ${
                  selectedFilter === "Declined" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Declined")}
              >
                <p>Rejected</p>
                <span>{rejectedCount} </span>
              </div>
              <div
                className={`${styles["vendor-locked"]} ${
                  selectedFilter === "Disabled" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Disabled")}
              >
                <p>Disabled</p>
                <span>{disabledCount} </span>
              </div>
              <div
                className={`${styles["vendor-locked"]} ${
                  selectedFilter === "outofstock" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("outofstock")}
              >
                <p>Out of Stock</p>
                <span>{outofstockCount}</span>
              </div>
            </div>
            <div className={styles.search}>
              <form
                className="--form-control"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              >
                <span className={styles.icon}>
                  <CiSearch size={17} />
                </span>
                <input placeholder="Search Product, Vendor" />
              </form>
            </div>
          </div>
          <div className={styles["vendor-content"]} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
            {/* <CiStreamOn/>
        <GiBackwardTime/>
        <BiLike/>
        <BiDislike/>
        <TbSquareOff/>
        <FiTrash/>
        <MdOutlineAddShoppingCart/> */}
            {isLoading ? (
              <Loader />
            ) : (
              <Box
                sx={{
                  // width: {
                  //   xs: 445,
                  //   sm: 545,
                  //   md: 713,
                  //   lg: 945,
                  //   xl: 1200,
                  // },
                  height: "90%",
                  "& .MuiDataGrid-root": {
                    border: "none",
                    fontSize: " 13px",
                    fontFamily: "Montserrat",
                    color: "#0b0b0b",
                  },
                  "& .MuiDataGrid-cell": {
                    border: "none",
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders":
                    {
                      backgroundColor: "#32c36c",
                      color: "#fff",
                    },
                  "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate":
                    {
                      color: "#32c36c",
                    },
                  " & .css-12u49z-MuiDataGrid-root .MuiDataGrid-row.Mui-selected":
                    {
                      backgroundColor: "#ecfaf0",
                    },
                  " .MuiDataGrid-row.Mui-selected:hover, .css-12u49z-MuiDataGrid-root .MuiDataGrid-row.Mui-selected.Mui-hovered":
                    {
                      backgroundColor: "#ecfaf0",
                    },

                  "& .name-column--head": {
                    marginLeft: "40px",
                  },
                  "& .MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell ":
                    {
                      whiteSpace: "wrap",
                      textWrap: "wrap",
                    },
                  "& .name-column--ll": {
                    justifyContent: "flex-end !important",
                  },
                }}
              >
                <div className={styles["data-grid"]}>
                  <DataGrid
                    rows={productdatafilter.map((row, index) => ({
                      ...row,

                      sn: index + 1 + paginationModel.page * paginationModel.pageSize,
                    }))}
                    columns={columns}
                    options={{}}
                    pagination
                     paginationMode="server"
                    //  pageSizeOptions={[10, 20, 50]}
                   paginationModel={paginationModel}
                   onPaginationModelChange={handlePaginationModelChange}
                    rowCount={1000}
                    loading={isLoading}
                    getRowId={getRowId}
                  />
                </div>
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
