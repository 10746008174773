import { useQuery } from "react-query";
import agent from "../api/agent";
// const fetchTickets = async () => {
//     const response = await agent.ticket.getAllTickets();
//     return response.data;
//   };
  
// export const useTickets = () => {
//     const {
//       isLoading,
//       error,
//       data: tickets,
//     } = useQuery({
//       queryKey: ["tickets"],
//       queryFn: fetchTickets,
//     });
//     return {tickets, isLoading, error,};
//   }; 

const fetchTickets = async ({ PageNumber, PageSize }) => {
  const response = await agent.ticket.getAllTickets(PageNumber, PageSize);
  return response.data;
};

export const  useTickets = ({ PageNumber, PageSize } = {}) => {
  const { isLoading, error, data: tickets  } = useQuery({
    queryKey: ['tickets', { PageNumber, PageSize }],
    queryFn: () => fetchTickets({ PageNumber, PageSize }),
  });
   // Calculate the estimated total number of pages based on the current page's data
   const estimatedTotalPages = tickets
   ? Math.ceil(tickets.length / PageSize)
   : 0;
  // Determine if there's a next page based on the number of items received
  const hasNextPage = tickets && tickets.length === PageSize;
  return { isLoading, error, tickets, hasNextPage, estimatedTotalPages };
};
