

export const vendordata = [
    {
      id: "1",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      Status: "Active",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "2",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      Status: "Inactive",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "3",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      Status: "Locked",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "4",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      Status: "Pending",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "5",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      Status: "Active",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "6",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      Status: "Locked",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "7",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      Status: "Locked",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "8",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      Status: "Pending",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "9",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      Status: "Inactive",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "10",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      Status: "Active",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "11",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      Status: "Pending",
      Date: "13, Jul 2022 | 21:55:12	",
    },
  ]


  export const customerdata = [
    {
      id: "1",
      name:"Adekunle Ciroma" ,
      order : "3",
      email: "ciroma...007@gmail.com",
      Phone: "08178772891",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "2",
      name:"Adekunle Ciroma" ,
      order : "2",
      email: "ciroma...007@gmail.com",
      Phone: "08178772891",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "3",
      name:"Adekunle Ciroma" ,
      order : "6",
      email: "ciroma...007@gmail.com",
      Phone: "08178772891",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "4",
      name:"Adekunle Ciroma" ,
      order : "1",
      email: "ciroma...007@gmail.com",
      Phone: "08178772891",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "5",
      name:"Adekunle Ciroma" ,
      order : "9",
      email: "ciroma...007@gmail.com",
      Phone: "08178772891",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "6",
      name:"Adekunle Ciroma" ,
      order : "5",
      email: "ciroma...007@gmail.com",
      Phone: "08178772891",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "7",
      name:"Adekunle Ciroma" ,
      order : "1",
      email: "ciroma...007@gmail.com",
      Phone: "08178772891",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "8",
      name:"Adekunle Ciroma" ,
      order : "2",
      email: "ciroma...007@gmail.com",
      Phone: "08178772891",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "9",
      name:"Adekunle Ciroma" ,
      order : "3",
      email: "ciroma...007@gmail.com",
      Phone: "08178772891",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "10",
      name:"Adekunle Ciroma" ,
      order : "5",
      email: "ciroma...007@gmail.com",
      Phone: "08178772891",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "11",
      name:"Adekunle Ciroma" ,
      order : "9",
      email: "ciroma...007@gmail.com",
      Phone: "08178772891",
      Date: "13, Jul 2022 | 21:55:12	",
    },
  ]

  export const orderdata = [
    {
      id: "1",
      orderN:"00771622",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      quantity: "1",
      price: "#3,350,000.000",
      Status: "Cancelled",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "2",
      orderN:"00771622",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      quantity: "1",
      price: "#3,350,000.000",
      Status: "Pending",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "3",
      orderN:"00771622",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      quantity: "3",
      price: "#3,350,000.000",
      Status: "Completed",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "4",
      orderN:"00771622",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      quantity: "3",
      price: "#3,350,000.000",
      Status: "Completed",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "5",
      orderN:"00771622",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      quantity: "19",
      price: "#3,350,000.000",
      Status: "Completed",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "6",
      orderN:"00771622",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      quantity: "5",
      price: "#3,350,000.000",
      Status: "Pending",
      Date: "13, Jul 2022 | 21:55:12		",
    },
    {
      id: "7",
      orderN:"00771622",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      quantity: "2",
      price: "#3,350,000.000",
      Status: "Cancelled",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "8",
      orderN:"00771622",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      quantity: "5",
      price: "#3,350,000.000",
      Status: "Completed",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "9",
      orderN:"00771622",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      quantity: "6",
      price: "#3,350,000.000",
      Status: "Completed",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "10",
      orderN:"00771622",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      quantity: "8",
      price: "#3,350,000.000",
      Status: "Cancelled",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "11",
      orderN:"00771622",
      name:"Adekunle Ciroma" ,
      Phone: "08178772891",
      quantity: "10",
      price: "#3,350,000.000",
      Status: "Completed",
      Date: "13, Jul 2022 | 21:55:12",
    },
  ]

  export const logdata = [
    {
      id: "1",
      name:"Adekunle Ciroma" ,
      narration : "I cannot seem to upload  product pictures. You guys seem not to have gotten hold of this your platform. I am not even sure I want to continue using it because ...",
      Date: "13, Jul 2022 | 21:55:12",
      status: "Customer Log",
    },
    {
      id: "2",
      name:"Adekunle Ciroma" ,
      narration : "I cannot seem to upload  product pictures. You guys seem not to have gotten hold of this your platform. I am not even sure I want to continue using it because ...",
      Date: "13, Jul 2022 | 21:55:12",
      status: "Vendor log",
    },
    {
      id: "3",
      name:"Adekunle Ciroma" ,
      narration : "I cannot seem to upload  product pictures. You guys seem not to have gotten hold of this your platform. I am not even sure I want to continue using it because ...",
      Date: "13, Jul 2022 | 21:55:12",
      status: "Card transactions log",
    },
    {
      id: "4",
      name:"Adekunle Ciroma" ,
      narration : "I cannot seem to upload  product pictures. You guys seem not to have gotten hold of this your platform. I am not even sure I want to continue using it because ...",
      Date: "13, Jul 2022 | 21:55:12",
      status: "SAF log",
    },
    {
      id: "5",
      name:"Adekunle Ciroma" ,
      narration : "I cannot seem to upload  product pictures. You guys seem not to have gotten hold of this your platform. I am not even sure I want to continue using it because ...",
      Date: "13, Jul 2022 | 21:55:12	",
      status: "Vendor log",
    },
    {
      id: "6",
      name:"Adekunle Ciroma" ,
      narration : "I cannot seem to upload  product pictures. You guys seem not to have gotten hold of this your platform. I am not even sure I want to continue using it because ...",
      Date: "13, Jul 2022 | 21:55:12		",
      status: "Customer Log",
    },
    {
      id: "7",
      name:"Adekunle Ciroma" ,
      narration : "I cannot seem to upload  product pictures. You guys seem not to have gotten hold of this your platform. I am not even sure I want to continue using it because ...",
      Date: "13, Jul 2022 | 21:55:12	",
      status: "Card transactions log",
    },
    {
      id: "8",
      name:"Adekunle Ciroma" ,
      narration : "I cannot seem to upload  product pictures. You guys seem not to have gotten hold of this your platform. I am not even sure I want to continue using it because ...",
      Date: "13, Jul 2022 | 21:55:12	",
      status: "SAF log",
    },
    {
      id: "9",
      name:"Adekunle Ciroma" ,
      narration : "I cannot seem to upload  product pictures. You guys seem not to have gotten hold of this your platform. I am not even sure I want to continue using it because ...",
      Date: "13, Jul 2022 | 21:55:12	",
      status: "SAF log",
    },
  ]
  export const promotiondata = [
    {
      id: "1",
      title:"Black Friday",
      type:"Seasonal" ,
      category: "Seasonal",
      sdate: "13, Jul 2022 ",
      edate: "13, Jul 2022 ",
      Status: "Pending",
    },
    {
      id: "2",
      title:"Tech Monday",
      type:"Weekly" ,
      category: "Weekly",
      sdate: "13, Jul 2022 ",
      edate: "13, Jul 2022 ",
      Status: "Approved",
    },
    {
      id: "3",
      title:"Christmas Sale",
      type:"Seasonal" ,
      category: "Weekly",
      sdate: "13, Jul 2022 ",
      edate: "13, Jul 2022 ",
      Status: "Approved",
    },
    {
      id: "4",
      title:"New Year Sale",
      type:"Seasonal" ,
      category: "Weekly",
      sdate: "13, Jul 2022 ",
      edate: "13, Jul 2022 ",
      Status: "Rejected",
    },
    {
      id: "5",
      title:"Easter Sale",
      type:"Seasonal" ,
      category: "Weekly",
      sdate: "13, Jul 2022 ",
      edate: "13, Jul 2022 ",
      Status: "Pending",
    },
    {
      id: "6",
      title:"Ramadan Sale",
      type:"Seasonal" ,
      category: "Weekly",
      sdate: "13, Jul 2022 ",
      edate: "13, Jul 2022 ",
      Status: "Rejected",
    },
    {
      id: "7",
      title:"August Visitor Sale",
      type:"Seasonal" ,
      category: "Weekly",
      sdate: "13, Jul 2022 ",
      edate: "13, Jul 2022 ",
      Status: "Pending",
    },
    {
      id: "8",
      title:"Democracy Discount",
      type:"Seasonal" ,
      category: "Weekly",
      sdate: "13, Jul 2022 ",
      edate: "13, Jul 2022 ",
      Status: "Rejected",
    },
    {
      id: "9",
      title:"Clearance Sale",
      type:"Seasonal" ,
      category: "Weekly",
      sdate: "13, Jul 2022 ",
      edate: "13, Jul 2022 ",
      Status: "Approved",
    },
    {
      id: "10",
      title:"10% Promo",
      type:"Weekly" ,
      category: "Weekly",
      sdate: "13, Jul 2022 ",
      edate: "13, Jul 2022 ",
      Status: "Pending",
    },
    {
      id: "11",
      title:"Black Friday",
      type:"Seasonal" ,
      category: "Weekly",
      sdate: "13, Jul 2022 ",
      edate: "13, Jul 2022 ",
      Status: "Rejected",
    },
  ]

  export const productdata = [
    {
      id: "1",
      img:"/images/1.png",
      product:"5kva Solar Hybrid Inverter System - 8 Batteries" ,
      vendor:"Lumos Global",
      quantity: "30",
      price: "#3,350,000.000",
      Status: "Approved",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "2",
      img:"/images/1.png",
      product:"5kva Solar Hybrid Inverter System - 8 Batteries" ,
      vendor:"Lumos Global",
      quantity: "30",
      price: "#3,350,000.000",
      Status: "Pending",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "3",
      img:"/images/1.png",
      product:"5kva Solar Hybrid Inverter System - 8 Batteries" ,
      vendor:"Lumos Global",
      quantity: "30",
      price: "#3,350,000.000",
      Status: "Live",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "4",
      img:"/images/1.png",
      product:"5kva Solar Hybrid Inverter System - 8 Batteries" ,
      vendor:"Lumos Global",
      quantity: "30",
      price: "#3,350,000.000",
      Status: "Rejected",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "5",
      img:"/images/1.png",
      product:"5kva Solar Hybrid Inverter System - 8 Batteries" ,
      vendor:"Lumos Global",
      quantity: "30",
      price: "#3,350,000.000",
      Status: "Disabled",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "6",
      img:"/images/1.png",
      product:"5kva Solar Hybrid Inverter System - 8 Batteries" ,
      vendor:"Lumos Global",
      quantity: "30",
      price: "#3,350,000.000",
      Status: "Out of Stock",
      Date: "13, Jul 2022 | 21:55:12		",
    },
    {
      id: "7",
      img:"/images/1.png",
      product:"5kva Solar Hybrid Inverter System - 8 Batteries" ,
      vendor:"Lumos Global",
      quantity: "30",
      price: "#3,350,000.000",
      Status: "Approved",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "8",
      img:"/images/1.png",
      product:"5kva Solar Hybrid Inverter System - 8 Batteries" ,
      vendor:"Lumos Global",
      quantity: "30",
      price: "#3,350,000.000",
      Status: "Approved",
      Date: "13, Jul 2022 | 21:55:12	",
    },
    {
      id: "9",
      img:"/images/1.png",
      product:"5kva Solar Hybrid Inverter System - 8 Batteries" ,
      vendor:"Lumos Global",
      quantity: "30",
      price: "#3,350,000.000",
      Status: "Approved",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "10",
      img:"/images/1.png",
      product:"5kva Solar Hybrid Inverter System - 8 Batteries" ,
      vendor:"Lumos Global",
      quantity: "30",
      price: "#3,350,000.000",
      Status: "Approved",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "11",
      img:"/images/1.png",
      product:"5kva Solar Hybrid Inverter System - 8 Batteries" ,
      vendor:"Lumos Global",
      quantity: "30",
      price: "#3,350,000.000",
      Status: "Approved",
      Date: "13, Jul 2022 | 21:55:12",
    },
  ]
  export const categorydata = [
    {
      id: "1",
      img:"/images/1.png",
      category:"Batteries" ,
      percentage:"20%",
      Status: "Approved",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "2",
      img:"/images/1.png",
      category:"Generators" ,
      percentage:"20%",
      Status: "Approved",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "3",
      img:"/images/1.png",
      category:"Inverters" ,
      percentage:"20%",
      Status: "Approved",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "4",
      img:"/images/1.png",
      category:"Solar Panels" ,
      percentage:"20%",
      Status: "Approved",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "5",
      img:"/images/1.png",
      category:"Stabilizer" ,
      percentage:"20%",
      Status: "Approved",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "6",
      img:"/images/1.png",
      category:"UPS" ,
      percentage:"20%",
      Status: "Approved",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "7",
      img:"/images/1.png",
      category:"Monitors & Meters" ,
      percentage:"20%",
      Status: "Approved",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "8",
      img:"/images/1.png",
      category:"Batteries" ,
      percentage:"20%",
      Status: "Approved",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "9",
      img:"/images/1.png",
      category:"Batteries" ,
      percentage:"20%",
      Status: "Approved",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "10",
      img:"/images/1.png",
      category:"Batteries" ,
      percentage:"20%",
      Status: "Approved",
      Date: "13, Jul 2022 | 21:55:12",
    },
    {
      id: "11",
      img:"/images/1.png",
      category:"Batteries" ,
      percentage:"20%",
      Status: "Approved",
      Date: "13, Jul 2022 | 21:55:12",
    },
  ]

  export const reviewdata = [
    {
      id: "1",
      name:"John Doe Longnameisthis",
      email:"thelongestemailcanbelikethis@gmail.com" ,
      Phone:"07031881447",
      title: "I loved every bit of it.",
      message: "These mental tools have been super helpful for setting me up to actually achieve the goals I set myself - I hope this helps you out too...!",
      Date: "13, Jul 2022 | 21:55:12",
      Status: "Pending",
      
    },
    {
      id: "2",
      name:"John Doe Longnameisthis",
      email:"thelongestemailcanbelikethis@gmail.com" ,
      Phone:"07031881447",
      title: "I loved every bit of it.",
      message: "These mental tools have been super helpful for setting me up to actually achieve the goals I set myself - I hope this helps you out too...!",
      Date: "13, Jul 2022 | 21:55:12",
      Status: "Pending",
    
    },
    {
      id: "3",
      name:"John Doe Longnameisthis",
      email:"thelongestemailcanbelikethis@gmail.com" ,
      Phone:"07031881447",
      title: "I loved every bit of it.",
      message: "These mental tools have been super helpful for setting me up to actually achieve the goals I set myself - I hope this helps you out too...!",
      Date: "13, Jul 2022 | 21:55:12",
      Status: "Rejected",
      
    },
    {
      id: "4",
      name:"John Doe Longnameisthis",
      email:"thelongestemailcanbelikethis@gmail.com" ,
      Phone:"07031881447",
      title: "I loved every bit of it.",
      message: "These mental tools have been super helpful for setting me up to actually achieve the goals I set myself - I hope this helps you out too...!",
      Date: "13, Jul 2022 | 21:55:12",
      Status: "Pending",
    
    },
    {
      id: "5",
      name:"John Doe Longnameisthis",
      email:"thelongestemailcanbelikethis@gmail.com" ,
      Phone:"07031881447",
      title: "I loved every bit of it.",
      message: "These mental tools have been super helpful for setting me up to actually achieve the goals I set myself - I hope this helps you out too...!",
      Date: "13, Jul 2022 | 21:55:12",
      Status: "Pending",
      
    },
    {
      id: "6",
      name:"John Doe Longnameisthis",
      email:"thelongestemailcanbelikethis@gmail.com" ,
      Phone:"07031881447",
      title: "I loved every bit of it.",
      message: "These mental tools have been super helpful for setting me up to actually achieve the goals I set myself - I hope this helps you out too...!",
      Date: "13, Jul 2022 | 21:55:12",
      Status: "Published",
     
    },
    {
      id: "7",
      name:"John Doe Longnameisthis",
      email:"thelongestemailcanbelikethis@gmail.com" ,
      Phone:"07031881447",
      title: "I loved every bit of it.",
      message: "These mental tools have been super helpful for setting me up to actually achieve the goals I set myself - I hope this helps you out too...!",
      Date: "13, Jul 2022 | 21:55:12",
      Status: "Pending",
     
    },
    {
      id: "8",
      name:"John Doe Longnameisthis",
      email:"thelongestemailcanbelikethis@gmail.com" ,
      Phone:"07031881447",
      title: "I loved every bit of it.",
      message: "These mental tools have been super helpful for setting me up to actually achieve the goals I set myself - I hope this helps you out too...!",
      Date: "13, Jul 2022 | 21:55:12",
      Status: "Pending",
      
    },
    {
      id: "9",
      name:"John Doe Longnameisthis",
      email:"thelongestemailcanbelikethis@gmail.com" ,
      Phone:"07031881447",
      title: "I loved every bit of it.",
      message: "These mental tools have been super helpful for setting me up to actually achieve the goals I set myself - I hope this helps you out too...!",
      Date: "13, Jul 2022 | 21:55:12",
      Status: "Pending",
    
    },

]

export const ticketdata = [
 { id: "1",
  title: "Re: Cannot Upload Products",
  message: "I cannot seem to upload  product pictures. You guys seem not to have gotten hold of this your platform. I am not even sure I want to continue using it because you don't even seem to reply my messages. This is the last time I will be sending a meesage.",
  notification:"2",
  img1:"/images/2.png",
  img2:"/images/3.png",
  img3:"/images/4.png",
  Date: "13, Jul 2022 | 21:55:12",
  Status: "Cancelled",
},
 { id: "2",
  title: "Re: Cannot Upload Products",
  message: "I cannot seem to upload  product pictures. You guys seem not to have gotten hold of this your platform. I am not even sure I want to continue using it because you don't even seem to reply my messages. This is the last time I will be sending a meesage.",
  notification:"2",
  img1:"/images/2.png",
  img2:"/images/3.png",
  img3:"/images/4.png",
  Date: "13, Jul 2022 | 21:55:12",
  Status: "Pending",
},
 { id: "3",
  title: "Re: Cannot Upload Products",
  message: "I cannot seem to upload  product pictures. You guys seem not to have gotten hold of this your platform. I am not even sure I want to continue using it because you don't even seem to reply my messages. This is the last time I will be sending a meesage.",
  notification:"2",
  img1:"/images/2.png",
  img2:"/images/3.png",
  img3:"/images/4.png",
  Date: "13, Jul 2022 | 21:55:12",
  Status: "Resolved",
},
 { id: "4",
  title: "Re: Cannot Upload Products",
  message: "I cannot seem to upload  product pictures. You guys seem not to have gotten hold of this your platform. I am not even sure I want to continue using it because you don't even seem to reply my messages. This is the last time I will be sending a meesage.",
  notification:"2",
  img1:"/images/2.png",
  img2:"/images/3.png",
  img3:"/images/4.png",
  Date: "13, Jul 2022 | 21:55:12",
  Status: "Resolved",
},
 { id: "5",
  title: "Re: Cannot Upload Products",
  message: "I cannot seem to upload  product pictures. You guys seem not to have gotten hold of this your platform. I am not even sure I want to continue using it because you don't even seem to reply my messages. This is the last time I will be sending a meesage.",
  notification:"2",
  img1:"/images/2.png",
  img2:"/images/3.png",
  img3:"/images/4.png",
  Date: "13, Jul 2022 | 21:55:12",
  Status: "Cancelled",
},
 { id: "6",
  title: "Re: Cannot Upload Products",
  message: "I cannot seem to upload  product pictures. You guys seem not to have gotten hold of this your platform. I am not even sure I want to continue using it because you don't even seem to reply my messages. This is the last time I will be sending a meesage.",
  notification:"2",
  img1:"/images/2.png",
  img2:"/images/3.png",
  img3:"/images/4.png",
  Date: "13, Jul 2022 | 21:55:12",
  Status: "Pending",
},
 { id: "7",
  title: "Re: Cannot Upload Products",
  message: "I cannot seem to upload  product pictures. You guys seem not to have gotten hold of this your platform. I am not even sure I want to continue using it because you don't even seem to reply my messages. This is the last time I will be sending a meesage.",
  notification:"2",
  img1:"/images/2.png",
  img2:"/images/3.png",
  img3:"/images/4.png",
  Date: "13, Jul 2022 | 21:55:12",
  Status: "Resolved",
},
]

export const usersdata = [
{
id:"1",
img:"/images/female.png",
name:"Jane Doe",
email:"standupguyxxx@gmail.com",
depart:"Customer Relationship Officer",
Date: "Created On:13, Jul 2022 | 21:55:12",
Status: "Active",
Phone:"07056288890",
gender:"female",
},
{
id:"2",
img:"/images/malefull.png",
name:"Jane Doe",
email:"standupguyxxx@gmail.com",
depart:"Product Officer",
Date: "Created On:13, Jul 2022 | 21:55:12",
Status: "Active",
Phone:"07056288890",
gender:"male",
},
{
id:"3",
img:"/images/female.png",
name:"Jane Doe",
email:"standupguyxxx@gmail.com",
depart:"Business Manager",
Date: "Created On:13, Jul 2022 | 21:55:12",
Status: "Inactive",
Phone:"07056288890",
gender:"female",
},
{
id:"4",
img:"/images/maleface.png",
name:"Jane Doe",
email:"standupguyxxx@gmail.com",
depart:"Business Manager",
Date: "Created On:13, Jul 2022 | 21:55:12",
Status: "Inactive",
Phone:"07056288890",
gender:"male",
},
{
id:"5",
img:"/images/female.png",
name:"Jane Doe",
email:"standupguyxxx@gmail.com",
depart:"Customer Relationship Officer",
Date: "Created On:13, Jul 2022 | 21:55:12",
Status: "Active",
Phone:"07056288890",
gender:"female",
},
{
id:"6",
img:"/images/female.png",
name:"Jane Doe",
email:"standupguyxxx@gmail.com",
depart:"Retail Officer",
Date: "Created On:13, Jul 2022 | 21:55:12",
Status: "Inactive",
Phone:"07056288890",
gender:"female",
},
{
id:"7",
img:"/images/female.png",
name:"Jane Doe",
email:"standupguyxxx@gmail.com",
depart:"Retail Officer II",
Date: "Created On:13, Jul 2022 | 21:55:12",
Status: "Inactive",
Phone:"07056288890",
gender:"female",
},
]

export const branddata = [
  {
    id:"1",
    img:"/images/brand1.png",
    name:"Luminous",
    Status: "Approved",
    Date: "13, Jul 2022 | 21:55:12",
    },
  {
    id:"2",
    img:"/images/brand2.png",
    name:"Luminous",
    Status: "Disapproved",
    Date: "13, Jul 2022 | 21:55:12",
    },
  {
    id:"3",
    img:"/images/brand2.png",
    name:"Luminous",
    Status: "Approved",
    Date: "13, Jul 2022 | 21:55:12",
    },
  {
    id:"4",
    img:"/images/brand3.png",
    name:"Luminous",
    Status: "Pending Approval",
    Date: "13, Jul 2022 | 21:55:12",
    },
  {
    id:"5",
    img:"/images/brand3.png",
    name:"Luminous",
    Status: "Pending Approval",
    Date: "13, Jul 2022 | 21:55:12",
    },
  {
    id:"6",
    img:"/images/brand3.png",
    name:"Luminous",
    Status: "Pending Approval",
    Date: "13, Jul 2022 | 21:55:12",
    },
  {
    id:"7",
    img:"/images/brand4.png",
    name:"Luminous",
    Status: "Pending Approval",
    Date: "13, Jul 2022 | 21:55:12",
    },
  {
    id:"8",
    img:"/images/brand4.png",
    name:"Luminous",
    Status: "Disapproved",
    Date: "13, Jul 2022 | 21:55:12",
    },
  {
    id:"9",
    img:"/images/brand4.png",
    name:"Luminous",
    Status: "Disapproved",
    Date: "13, Jul 2022 | 21:55:12",
    },
]

export const notificationsdata = [
  {
    id: "1",
    title: "Customer",
    message:"A customer just made a review on a product on altpower",
    Date: "13, Jul 2022 | 21:55:12",
  },
  {
    id: "2",
    title: "Product",
    message:"A customer just made a review on a product on altpower",
    Date: "13, Jul 2022 | 21:55:12",
  
  },
  {
    id: "3",
    title: "Ticket",
    message:"A customer just made a review on a product on altpower",
    Date: "13, Jul 2022 | 21:55:12",
  },
  {
    id: "4",
    title: "Vendor",
    message:"A customer just made a review on a product on altpower",
    Date: "13, Jul 2022 | 21:55:12",
  
  },
  {
    id: "5",
    title: "Order",
    message:"A customer just made a review on a product on altpower",
    Date: "13, Jul 2022 | 21:55:12",
  },
  {
    id: "6",
    title: "Review",
    message:"A customer just made a review on a product on altpower",
    Date: "13, Jul 2022 | 21:55:12",
  
  },
]
export const csvData = [
  [
    "Name",
    "Brand",
    "Vendor",
    "CurrentPrice",
    "PreviousPrice",
    "Category",
    "Description",
    "Quantity",
    "Note",
    "Weight",
    "ImageUrl1",
    "ImageUrl2",
    "ImageUrl3",
    "ImageUrl4",
    "ImageUrl5",
  ],
  [
    "Product 1",
    "Example 1",
    "Vendor Name",
    20000,
    10000,
    "Category1",
    "Description example 1",
    20,
    "Note example 1",
    500,
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
  ],
  [
    "Product 2",
    "Example 2",
    "Vendor Name",
    20000,
    10000,
    "Category2",
    "Description example 2",
    20,
    "Note example 2",
    500,
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
  ],
  [
    "Product 3",
    "Example 3",
    "Vendor Name",
    20000,
    10000,
    "Category3",
    "Description example 3",
    20,
    "Note example 3",
    500,
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
  ],
];
export const brandCsvData =[
  [
    "Brand",
    "ImageUrl1",
  ],
  [
    "Example 1",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
  ],
  [
    "Example 2",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
  ],
  [
    "Example 3",
    "https://upload.wikimedia.org/wikipedia/en/6/66/Generac_Industrial_Diesel_Genset.jpg",
  ],
];