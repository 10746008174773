import React, { useState } from "react";
import styles from "./Ticket.module.css";
import { Header, Sidebar } from "../../components";
import { BsArrowLeftCircle } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { TbPointFilled } from "react-icons/tb";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import {
  ModalViewImg,
  ModalViewticketAuthorize,
} from "../../components/modal/Modal";
import { TextField } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import agent from "../../api/agent";
import { FaSpinner } from "react-icons/fa";
import toast from "react-hot-toast";
const ViewTicket = () => {
  const [modalViewTicketImg, setModalViewTicketImg] = useState(false);
  const [modalViewTicketAuthorize, setModalViewTicketAuthorize] =
    useState(false);
  const [viewedImgUrl, setViewedImgUrl] = useState("");
  const handletoggleviewimg = (imageUrl) => {
    setModalViewTicketImg(!modalViewTicketImg);
    setViewedImgUrl(imageUrl);
  };
  const handletoggleviewauthorize = (e) => {
    e.preventDefault();
    setModalViewTicketAuthorize(!modalViewTicketAuthorize);
  };
  const navigate = useNavigate();
  const backToTicket = () => {
    return navigate("/ticket");
  };
  const location = useLocation();
  const selectedTicket = location.state.ticket;
  if (!selectedTicket) {
    return null; // Return nothing if no ticket is selected
  }

  const { ticketId, title, createdAt, status, priority } = selectedTicket;
  const [replyData, setReplyData] = useState({
    ticketId: selectedTicket.ticketId,
    message: "",
  });
  const ticketreply = async (replyData) => {
    // console.log("replyData:", replyData);
    const response = await agent.ticket.replymessage(replyData);
    return response;
  };

  const queryClient = useQueryClient();
  const { mutate: ticketreplyData, isLoading } = useMutation(ticketreply, {
    // On success, invalidate the 'tickets' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("tickets");

      if (data.responseCode === "99") {
        // console.log("Error reply ticket", data.responseMessage);
        toast.error(data.responseMessage);
      } else {
        // console.log("reply ticket successfully");
      toast.success("reply ticket successfully!");
      navigate("/ticket");
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error reply ticket:", error);
      toast.error("Error reply ticket . Please try again.");
    },
  });

  const handleticketreply = async (e) => {
    e.preventDefault();
    ticketreplyData(replyData);
  };

  return (
    <div className={styles.vendor}>
      {modalViewTicketImg ? (
        <ModalViewImg
          onHandleToggleViewImg={handletoggleviewimg}
          images={[{ ticketImagesId: 1, ticketImageUrl: viewedImgUrl }]}
        />
      ) : null}
      {modalViewTicketAuthorize ? (
        <ModalViewticketAuthorize
          onHandleToggleViewAuthorize={handletoggleviewauthorize}
        />
      ) : null}
      <Header />
      <div className={styles["vendor-body"]}>
        <Sidebar />
        <div className={styles["vendor-container"]}>
          <div
            className={styles["viewticket-title"]}
            onClick={() => backToTicket()}
          >
            <BsArrowLeftCircle size={30} style={{ cursor: "pointer" }} />
            <p>Go back</p>
          </div>
          <div className={styles["vendor-subtitle-search"]}>
            <div className={styles.subtitle}>
              <div className={styles["vendor-subtitle-head"]}>
                <h3>View Tickets</h3>
              </div>
            </div>
          </div>
          <div className={styles["view-content"]}>
            <div className={styles["viewticket-header"]}>
              <div className={styles["viewticket-content"]}>
                <h3>{title}</h3>
                <span>
                  <TbPointFilled />
                  {priority}
                </span>
              </div>
              <div className={styles["viewticket-content2"]}>
                <p>{createdAt}</p>
                <span>
                  {status}
                  <MdOutlineKeyboardArrowDown />
                </span>
              </div>
            </div>
            <div className={styles["viewticket-details"]}>
              <div className={styles["viewticket-img-name"]}>
                <img src="/images/female.png" alt="" />
                <div className="">
                  <p>John Doe</p>
                  <span>Ticket ID: {ticketId}</span>
                </div>
              </div>
              <hr />
              <div className={styles["viewticket-img-message"]}>
                <p>{selectedTicket.message[0].message}</p>
                <div className={styles["viewticket-img"]}>
                  {selectedTicket.ticketImages.map((image, key) => {
                    const { ticketImagesId, ticketImageUrl } = image; // Destructure the ticketImagesId
                    return (
                      <div key={ticketImagesId}>
                        <img
                          src={ticketImageUrl}
                          alt={ticketImageUrl}
                          width={30}
                          onClick={() => handletoggleviewimg(ticketImageUrl)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={styles["viewticket-textarea-button"]}>
                <form>
                  <TextField
                    multiline
                    name="message"
                    rows={8} // Adjust the number of rows as needed
                    placeholder="reply message here..."
                    value={replyData.message}
                    onChange={(e) =>
                      setReplyData({
                        ...replyData,
                        message: e.target.value,
                      })
                    }
                  />
                  <div
                    className={styles["viewticket-button"]}
                    onClick={handleticketreply}
                  >
                    <button
                      className={`--btn --btn-success ${styles["send-button"]}`}
                      disabled={isLoading}
                      style={{
                        cursor: isLoading ? "not-allowed" : "pointer",
                        width: isLoading ? "80px" : "auto",
                      }}
                    >
                      {isLoading ? (
                        <>
                          <FaSpinner className={styles["spin"]} />
                        </>
                      ) : (
                        "Send"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTicket;
