import { useQuery } from "react-query";
import agent from "../api/agent";
const fetchdashboardmatrix = async () => {
    const response = await agent.dashboard.getAllMatrix();
    return response.data;
  };
  
export const useDashboardMatrix = () => {
    const {
      isLoading,
      error,
      data: dashboardmatrix,
    } = useQuery({
      queryKey: ["dashboardmatrix"],
      queryFn: fetchdashboardmatrix ,
    });
    return {dashboardmatrix, isLoading, error,};
  }; 