import React, { useState } from "react";
import styles from "./Category.module.css";
import { Header, Loader, Sidebar } from "../../components";
import { CiSearch, CiStreamOn } from "react-icons/ci";
import { GiBackwardTime } from "react-icons/gi";
import { BiDislike, BiLike } from "react-icons/bi";
import { TbSquareOff } from "react-icons/tb";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { DataGrid } from "@mui/x-data-grid";
import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import { RiArrowDownSFill } from "react-icons/ri";
import {
  ModalAddSubcategory,
  ModalDeletecategory,
  ModalEditcategory,
  ModalAddcategory,
} from "../../components/modal/Modal";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useCategories } from "../../customHooks/useCategories";
import agent from "../../api/agent";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-hot-toast";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  width: "200px",
  padding: "19px 19px",
  borderBottom: "1px solid #f1f1f1",
  fontSize: "13px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#32c36c",
    color: "#fff",
  },
}));
const Category = () => {
  const [modalEditcategory, setModalEditcategory] = useState(false);
  const [modalAddcategory, setModalAddcategory] = useState(false);
  const [modalAddSubcategory, setModalAddSubcategory] = useState(false);
  const [modalDeletecategory, setModalDeletecategory] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorElMap, setAnchorElMap] = useState({});

  const handletoggleAddcategory = () => {
    setModalAddcategory(!modalAddcategory);
  };
  const handletoggleEditcategory = (rowData) => {
    setSelectedRow(rowData);
    setModalEditcategory(!modalEditcategory);
    setAnchorElMap({});
  };
  const handletoggleAddSubcategory = (rowData) => {
    setSelectedRow(rowData);
    setModalAddSubcategory(!modalAddSubcategory);
    setAnchorElMap({});
  };
  const handletoggleDeletecategory = (rowData) => {
    setSelectedRow(rowData);
    setModalDeletecategory(!modalDeletecategory);
    setAnchorElMap({});
  };
  const handleButtonClick = (event, rowId) => {
    event.stopPropagation();
    setAnchorElMap((prevMap) => ({ ...prevMap, [rowId]: event.currentTarget }));
  };
  const handleMenuClose = (rowId) => {
    setAnchorElMap((prevMap) => ({ ...prevMap, [rowId]: null }));
  };

  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 10
      }
    }
  };

  const [paginationModel, setPaginationModel] = useState(initialState.pagination.paginationModel);
  
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };
// API Calls
const removecategory = async (categoryId) => {
  // console.log("categoryId:", categoryId);
  const response = await agent.category.removeCategory(categoryId);
  return response;
};

// React Query Functions
  const { categories, isLoading, error } = useCategories(paginationModel);
// console.log("home:",categories);
  const queryClient = useQueryClient();
  const { mutate: deleteCategory, isLoading:loadingCategory } = useMutation(removecategory, {
    // On success, invalidate the 'categories' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("categories");

      if (data.responseCode === "99") {
        // console.log("Error deleting category:", data.responseMessage);
        toast.error(data.responseMessage);
        handletoggleDeletecategory()
      } else {
        // console.log("category successfully Deleted");
        toast.success("category successfully Deleted!");
        handletoggleDeletecategory()
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error deleting category:", error);
      toast.error("Error deleting category. Please try again."); 
    },
  });
  //  Meun onClick Function
  const handleremovecategory = ({ categoryId }) => {
    // console.log("categoryId:", categoryId);
    deleteCategory(categoryId);
  };

  const columns = [
    {
      field: "sn",
      headerName: "SN",
      width: 10,
      cellClassName: "name-column--cell",
    },
    {
      headerName: "Category Image",
      width: 120,
      sortable: false,
      filterable: false,
      meun: false,
      renderCell: (params) => <Avatar variant="square" src={params.row.banner} />,
    },
    {
      field: "category",
      headerName: "Root Category",
      width: 230,
      cellClassName: "name-column--cell",
    },
    {
      field: "Subcategory",
      headerName: "Sub Category",
      width: 220,
      cellClassName: "name-column--cell",
    },
    {
      field: "commision",
      headerName: "Percentage Commission",
      width: 120,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        const commission = params.value; // Assuming your data field is named "commision"
        // Assuming commission is a decimal representing the percentage (e.g., 0.15 for 15%)
        const percentage = `${Math.round(commission)}%`;
  
        return <span>{percentage}</span>;
      },
    },

    // {
    //   field: "status",
    //   headerName: "status  ",
    //   width: 130,
    //   align: "center",
    //   headerClassName: "name-column--head",
    //   renderCell: ({ row: { status } }) => {
    //     if (status === "Approved") {
    //       return (
    //         <Box
    //           width="65px"
    //           // mr="50px "
    //           // m="0 auto"
    //           p="4px"
    //           display="flex"
    //           justifyContent="center"
    //           color="#32c36c"
    //           borderRadius="2px"
    //         >
    //           {status === "Approved" && <BiLike />}
    //           {status === "Rejected" && <BiDislike />}
    //           {status === "Pending" && <GiBackwardTime />}
    //           {status === "Live" && <CiStreamOn />}
    //           {status === "Disabled" && <TbSquareOff />}
    //           {status === "Out of Stock" && <MdOutlineAddShoppingCart />}
    //           <Typography sx={{ ml: "2px" }}>
    //             {status === "Approved" && "Approved"}
    //             {status === "Rejected" && "Rejected"}
    //             {status === "Pending" && "Pending"}
    //             {status === "Live" && "Live"}
    //             {status === "Disabled" && "Disabled"}
    //             {status === "Out of Stock" && "Out of Stock"}
    //           </Typography>
    //         </Box>
    //       );
    //     } else if (status === "Rejected") {
    //       return (
    //         <Box
    //           width="65px"
    //           // m="0 auto"
    //           // mr="90px "
    //           p="4px"
    //           display="flex"
    //           justifyContent="center"
    //           color="#e23411"
    //           borderRadius="2px"
    //         >
    //           {status === "Approved" && <BiLike />}
    //           {status === "Rejected" && <BiDislike />}
    //           {status === "Pending" && <GiBackwardTime />}
    //           {status === "Live" && <CiStreamOn />}
    //           {status === "Disabled" && <TbSquareOff />}
    //           {status === "Out of Stock" && <MdOutlineAddShoppingCart />}
    //           <Typography sx={{ ml: "2px" }}>
    //             {status === "Approved" && "Approved"}
    //             {status === "Rejected" && "Rejected"}
    //             {status === "Pending" && "Pending"}
    //             {status === "Live" && "Live"}
    //             {status === "Disabled" && "Disabled"}
    //             {status === "Out of Stock" && "Out of Stock"}
    //           </Typography>
    //         </Box>
    //       );
    //     } else if (status === "Pending") {
    //       return (
    //         <Box
    //           width="65px"
    //           // m="0 auto"
    //           // mr="50px "
    //           p="4px"
    //           display="flex"
    //           justifyContent="center"
    //           color="#f5ba69"
    //           borderRadius="2px"
    //         >
    //           {status === "Approved" && <BiLike />}
    //           {status === "Rejected" && <BiDislike />}
    //           {status === "Pending" && <GiBackwardTime />}
    //           {status === "Live" && <CiStreamOn />}
    //           {status === "Disabled" && <TbSquareOff />}
    //           {status === "Out of Stock" && <MdOutlineAddShoppingCart />}
    //           <Typography sx={{ ml: "2px" }}>
    //             {status === "Approved" && "Approved"}
    //             {status === "Rejected" && "Rejected"}
    //             {status === "Pending" && "Pending"}
    //             {status === "Live" && "Live"}
    //             {status === "Disabled" && "Disabled"}
    //             {status === "Out of Stock" && "Out of Stock"}
    //           </Typography>
    //         </Box>
    //       );
    //     } else if (status === "Live") {
    //       return (
    //         <Box
    //           width="65px"
    //           // m="0 auto"
    //           // mr="50px "
    //           p="4px"
    //           display="flex"
    //           justifyContent="center"
    //           color="#32c36c"
    //           borderRadius="2px"
    //         >
    //           {status === "Approved" && <BiLike />}
    //           {status === "Rejected" && <BiDislike />}
    //           {status === "Pending" && <GiBackwardTime />}
    //           {status === "Live" && <CiStreamOn />}
    //           {status === "Disabled" && <TbSquareOff />}
    //           {status === "Out of Stock" && <MdOutlineAddShoppingCart />}
    //           <Typography sx={{ ml: "2px" }}>
    //             {status === "Approved" && "Approved"}
    //             {status === "Rejected" && "Rejected"}
    //             {status === "Pending" && "Pending"}
    //             {status === "Live" && "Live"}
    //             {status === "Disabled" && "Disabled"}
    //             {status === "Out of Stock" && "Out of Stock"}
    //           </Typography>
    //         </Box>
    //       );
    //     } else if (status === "Disabled") {
    //       return (
    //         <Box
    //           width="65px"
    //           // m="0 auto"
    //           // mr="50px "
    //           p="4px"
    //           display="flex"
    //           justifyContent="center"
    //           color="#e23411"
    //           borderRadius="2px"
    //         >
    //           {status === "Approved" && <BiLike />}
    //           {status === "Rejected" && <BiDislike />}
    //           {status === "Pending" && <GiBackwardTime />}
    //           {status === "Live" && <CiStreamOn />}
    //           {status === "Disabled" && <TbSquareOff />}
    //           {status === "Out of Stock" && <MdOutlineAddShoppingCart />}
    //           <Typography sx={{ ml: "2px" }}>
    //             {status === "Approved" && "Approved"}
    //             {status === "Rejected" && "Rejected"}
    //             {status === "Pending" && "Pending"}
    //             {status === "Live" && "Live"}
    //             {status === "Disabled" && "Disabled"}
    //             {status === "Out of Stock" && "Out of Stock"}
    //           </Typography>
    //         </Box>
    //       );
    //     } else if (status === "Out of Stock") {
    //       return (
    //         <Box
    //           width="85px"
    //           // m="0 auto"
    //           // mr="50px "
    //           p="4px"
    //           display="flex"
    //           justifyContent="center"
    //           color="#c6c6c6"
    //           borderRadius="2px"
    //         >
    //           {status === "Approved" && <BiLike />}
    //           {status === "Rejected" && <BiDislike />}
    //           {status === "Pending" && <GiBackwardTime />}
    //           {status === "Live" && <CiStreamOn />}
    //           {status === "Disabled" && <TbSquareOff />}
    //           {status === "Out of Stock" && <MdOutlineAddShoppingCart />}
    //           <Typography sx={{ ml: "2px" }}>
    //             {status === "Approved" && "Approved"}
    //             {status === "Rejected" && "Rejected"}
    //             {status === "Pending" && "Pending"}
    //             {status === "Live" && "Live"}
    //             {status === "Disabled" && "Disabled"}
    //             {status === "Out of Stock" && "Out of Stock"}
    //           </Typography>
    //         </Box>
    //       );
    //     }
    //   },
    // },
    {
      field: "createdAt",
      headerName: "Date/Time",
      width: 250,
      // cellClassName: "name-column--cell",
    },

    {
      field: "action",
      headerName: "Action",
      width: 180,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => ActionButton(params),
    },
  ];
  const getRowId = (category) => category.categoryId;
  const ActionButton = (params) => {
    const rowId = params.row.categoryId;
    const anchorEl = anchorElMap[rowId];

    return (
      <>
        <Button
          className={styles["action-button"]}
          style={{
            marginLeft: 16,
            width: 100,
            height: 30,
            color: "#000",
            backgroundColor: "#ebf4fe",
          }}
          onClick={(event) => handleButtonClick(event, rowId)}
          aria-controls={`actions-menu-${rowId}`}
          aria-haspopup="true"
        >
          Action
          <RiArrowDownSFill style={{ marginLeft: 16 }} size={13} />
        </Button>
        <Menu
          id={`actions-menu-${rowId}`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleMenuClose(rowId)}
        >
          <StyledMenuItem
            onClick={() => handletoggleAddSubcategory(params.row)}
          >
            Add Sub-category
          </StyledMenuItem>

          <StyledMenuItem onClick={() => handletoggleEditcategory(params.row)}>
            Edit
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => handletoggleDeletecategory(params.row)}
          >
            Delete
          </StyledMenuItem>
        </Menu>
      </>
    );
  };
  let categorydatafilter = []
  const [searchTerm, setSearchTerm] = useState("");
  if(categories){
    categorydatafilter = categories.filter((val) => {
      // if (searchTerm === "") {
      //   return val;
      // } else if (
      //   val.category
      //     .toLocaleLowerCase()
      //     .includes(searchTerm.toLocaleLowerCase()) ||
      //   val.commision
      //     .toLocaleLowerCase()
      //     .includes(searchTerm.toLocaleLowerCase())
      // ) {
      //   return val;
      // }
      if (searchTerm === "") {
        return (
          val.category
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase()) 
        );
      } else {
        return (
          (val.category
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase())) 
        );
      }

    });
  }
   

  return (
    <div className={styles.vendor}>
      {modalAddcategory ? (
        <ModalAddcategory onHandleToggle={handletoggleAddcategory} />
      ) : null}
      {modalEditcategory ? (
        <ModalEditcategory
          onHandleToggle={handletoggleEditcategory}
          categorydatafilter={selectedRow}
        />
      ) : null}
      {modalAddSubcategory ? (
        <ModalAddSubcategory
          onHandleToggle={handletoggleAddSubcategory}
          categorydatafilter={selectedRow}
        />
      ) : null}
      {modalDeletecategory ? (
        <ModalDeletecategory
          onHandleToggleDeletecategory={handletoggleDeletecategory}
          categorydatafilter={selectedRow}
          isLoading={loadingCategory}
          onhandleremovecategory = {handleremovecategory}
        />
      ) : null}
      <Header />
      <div className={styles["vendor-body"]}>
        <Sidebar />
        <div className={styles["vendor-container"]}>
          <div className={styles["vendor-title"]}>
            <h2>Category</h2>
            <div className={styles["create-button"]}>
              <button
                className="--btn --btn-success"
                onClick={() => handletoggleAddcategory()}
              >
                <p>Add Category</p>
                <span>
                  <IoIosAddCircleOutline size={20} />
                </span>
              </button>
            </div>
          </div>
          <div className={styles["vendor-subtitle-search"]}>
            <div className={styles.subtitle}>
              <div className={styles["vendor-subtitle-head"]}>
                <p>All Categories</p>
              </div>
            </div>
            <div className={styles.search}>
              <form
                className="--form-control"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              >
                <span className={styles.icon}>
                  <CiSearch size={17} />
                </span>
                <input placeholder="Search Product, Vendor" />
              </form>
            </div>
          </div>
          <div className={styles["vendor-content"]} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
            {/* <CiStreamOn/>
          <GiBackwardTime/>
          <BiLike/>
          <BiDislike/>
          <TbSquareOff/>
          <FiTrash/>
          <MdOutlineAddShoppingCart/> */}
          {isLoading ? (
              <Loader />
            ) : (
            <Box
              sx={{
                // width: {
                //   xs: 445,
                //   sm: 545,
                //   md: 713,
                //   lg: 945,
                //   xl: 1200,
                // },
                height: "87%",
                "& .MuiDataGrid-root": {
                  border: "none",
                  fontSize: " 13px",
                  fontFamily: "Montserrat",
                },
                "& .MuiDataGrid-cell": {
                  border: "none",
                  borderBottom: "none",
                },
                "& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders":
                  {
                    backgroundColor: "#32c36c",
                    color: "#fff",
                  },
                "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate":
                  {
                    color: "#32c36c",
                  },
                " & .css-12u49z-MuiDataGrid-root .MuiDataGrid-row.Mui-selected":
                  {
                    backgroundColor: "#ecfaf0",
                  },
                " .MuiDataGrid-row.Mui-selected:hover, .css-12u49z-MuiDataGrid-root .MuiDataGrid-row.Mui-selected.Mui-hovered":
                  {
                    backgroundColor: "#ecfaf0",
                  },

                "& .name-column--head": {
                  marginLeft: "40px",
                },
                "& .MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell ":
                  {
                    whiteSpace: "wrap",
                    textWrap: "wrap",
                  },
                "& .name-column--ll": {
                  justifyContent: "flex-end !important",
                },
              }}
            >
              <div className={styles["data-grid"]}>
                <DataGrid
                  rows={categorydatafilter.map((row, index) => ({
                    ...row,

                    sn: index + 1 + paginationModel.page * paginationModel.pageSize,
                  }))}
                  columns={columns}
                  options={{}}
                  pagination
                  paginationMode="server"
                 //  pageSizeOptions={[10, 20, 50]}
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationModelChange}
                 rowCount={1000}
                 loading={isLoading}
                  getRowId={getRowId}
                />
              </div>
            </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
