import React, { useState } from "react";
import styles from "./Ticket.module.css";
import { Header, Loader, Sidebar } from "../../components";
import { CiSearch } from "react-icons/ci";
import { BsChatLeftText } from "react-icons/bs";
import { ticketdata } from "../../components/tabledata";
import { TbPointFilled } from "react-icons/tb";
import { SlOptionsVertical } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { useTickets } from "../../customHooks/useTicket";
import agent from "../../api/agent";
import toast from "react-hot-toast";
import { Pagination } from "../../components";

const Ticket = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  const approveTicket = async (ticketId) => {
    const status = "resolved";
    // console.log("approveTicket:", ticketId, "status:", status);
    const response = await agent.ticket.approveTicket(status, ticketId);
    return response;
  };
  const rejectTicket = async (ticketId) => {
    const status = "cancelled";
    // console.log("approveTicket:", ticketId, "status:", status);
    const response = await agent.ticket.rejectTicket(status, ticketId);
    return response;
  };

  // React Query Functions
  const queryClient = useQueryClient();
  const { tickets, isLoading, error, hasNextPage, estimatedTotalPages } = useTickets({
    PageNumber: currentPage,
    PageSize: postsPerPage,
  });
  // console.log("tickets", tickets);
  const { mutate: approveTicketData } = useMutation(approveTicket, {
    // On success, invalidate the 'tickets' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("tickets");

      if (data.responseCode === "99") {
        // console.log("Error Resolving ticket", data.responseMessage);
        toast.error(data.responseMessage);
      } else {
        // console.log("Resolved ticket successfully");
        toast.success("Resolved ticket successfully!");
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error Resolving ticket:", error);
      toast.error("Error Resolving ticket. Please try again.");
    },
  });
  const { mutate: rejectTicketData } = useMutation(rejectTicket, {
    // On success, invalidate the 'tickets' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("tickets");

      if (data.responseCode === "99") {
        // console.log("Error Cancelling ticket", data.responseMessage);
        toast.error(data.responseMessage);
      } else {
        // console.log("Cancelled ticket successfully");
        toast.success("Cancelled ticket successfully!");
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error Cancelling ticket:", error);
      toast.error("Error Cancelling ticket. Please try again.");
    },
  });

  const handleApproveticket = ({ ticketId }) => {
    // console.log("ticketId", ticketId);
    approveTicketData(ticketId);
  };
  const handleRejectticket = ({ ticketId }) => {
    // console.log("ticketId", ticketId);
    rejectTicketData(ticketId);
  };

  let ticketdatafilter = [];
  let currentPosts = [];
  //change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (tickets) {
    ticketdatafilter = tickets.filter((val) => {
      if (selectedFilter === "All") {
        return val.title.toLowerCase().includes(searchTerm.toLowerCase());
      } else {
        return (
          val.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
          val.status === selectedFilter
        );
      }
    });
    //Get Current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    currentPosts = ticketdatafilter.slice(indexOfFirstPost, indexOfLastPost);
    // console.log("try", currentPosts);
  }
  let newCount = 0;
  let resolvedCount = 0;
  let pendingCount = 0;
  let cancelledCount = 0;
  let totalTicket = 0;

  if (tickets) {
    const statusCounts = tickets.reduce((counts, ticket) => {
      const status = ticket.status;
      counts[status] = (counts[status] || 0) + 1;
      return counts;
    }, {});

    // console.log("Status Counts:", statusCounts);

    // Update the count variables
    newCount = statusCounts["New"] || 0;
    resolvedCount = statusCounts["Resolved"] || 0;
    pendingCount = statusCounts["Pending"] || 0;
    cancelledCount = statusCounts["Cancelled"] || 0;
    // Calculate the total ticket count
    totalTicket = newCount + resolvedCount + pendingCount + cancelledCount;
  }

  const navigate = useNavigate();
  const viewTicket = (ticket) => {
    console.log("ticket:", ticket);
    setSelectedTicket(ticket);
    navigate("/ticket/view-ticket", { state: { ticket } });
  };
  const [dropdown, setDropdown] = useState();
  const toggle = (ticketId) =>
    setDropdown(dropdown === ticketId ? undefined : ticketId);
  return (
    <div className={styles.vendor}>
      <Header />
      <div className={styles["vendor-body"]}>
        <Sidebar />
        <div className={styles["vendor-container"]}>
          <div className={styles["vendor-title"]}>
            <h2>Tickets</h2>
          </div>
          <div className={styles["vendor-subtitle-search"]}>
            <div className={styles.subtitle}>
              <div
                className={`${styles["vendor-subtitle-head"]} ${
                  selectedFilter === "All" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("All")}
              >
                <p>All Tickets</p>
              </div>
              <div
                className={`${styles["vendor-active"]} ${
                  selectedFilter === "New" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("New")}
              >
                <p>New</p>
                <span>{newCount}</span>
              </div>
              <div
                className={`${styles["vendor-pending"]} ${
                  selectedFilter === "Pending" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Pending")}
              >
                <p>Pending</p>
                <span>{pendingCount}</span>
              </div>
              <div
                className={`${styles["vendor-rejected"]} ${
                  selectedFilter === "Resolved" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Resolved")}
              >
                <p>Resolved</p>
                <span>{resolvedCount}</span>
              </div>
              <div
                className={`${styles["vendor-locked"]} ${
                  selectedFilter === "Cancelled" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Cancelled")}
              >
                <p>Cancelled</p>
                <span>{cancelledCount}</span>
              </div>
            </div>
            <div className={styles.search}>
              <form
                className="--form-control"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              >
                <span className={styles.icon}>
                  <CiSearch size={17} />
                </span>
                <input placeholder="Search Ticket ID" />
              </form>
            </div>
          </div>
          <div className={styles["vendor-content"]}>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <div className={styles["no-rows"]}>Error fetching data</div>
            ) : ticketdatafilter.length === 0 ? (
              <div className={styles["no-rows"]}>No rows</div>
            ) : (
              ticketdatafilter.map((val, index) => {
                const { ticketId, title, createdAt, status } = val;
                // Calculate SN (sequential number)
                const sn = index + 1;

                return (
                  <div className={styles.ticket} key={ticketId}>
                    <div
                      className={
                        styles["ticket-title-message-notification-img"]
                      }
                    >
                      <p>{title} </p>
                      <span>{val.message[0].message} </span>
                      <div className={styles["ticket-notification-img"]}>
                        <div className={styles["ticket-notification-icon"]}>
                          <BsChatLeftText
                            size={20}
                            style={{ fontWeight: "bold" }}
                          />
                          <span>{totalTicket} </span>
                        </div>
                        <div className={styles["ticket-img"]}>
                          {val.ticketImages.map((image, key) => {
                            const { ticketImagesId, ticketImageUrl } = image; // Destructure the ticketImagesId
                            return (
                              <div key={ticketImagesId}>
                                <img
                                  src={ticketImageUrl}
                                  alt={ticketImageUrl}
                                  width={30}
                                />
                              </div>
                            );
                          })}
                          <span className={styles.img3}>+{totalTicket}</span>
                        </div>
                      </div>
                    </div>
                    <div className={styles["ticket-date-icon"]}>
                      <div>
                        <p className={styles.date}>{createdAt}</p>
                        {status === "Pending" && (
                          <span className={styles["ticket-status-pending"]}>
                            <TbPointFilled /> {status}
                          </span>
                        )}
                        {status === "Cancelled" && (
                          <span className={styles["ticket-status-cancelled"]}>
                            <TbPointFilled /> {status}
                          </span>
                        )}
                        {status === "Resolved" && (
                          <span className={styles["ticket-status-resolved"]}>
                            <TbPointFilled /> {status}
                          </span>
                        )}
                        {status === "New" && (
                          <span className={styles["ticket-status-new"]}>
                            <TbPointFilled /> {status}
                          </span>
                        )}
                      </div>
                      <div className="">
                        <div
                          className={
                            dropdown === ticketId
                              ? styles["ticket-icon2"]
                              : styles["ticket-icon"]
                          }
                          onClick={() => toggle(ticketId)}
                        >
                          <SlOptionsVertical size={12} />
                        </div>
                        {dropdown === ticketId ? (
                          <div
                            className={styles["dropdown-container"]}
                            onClick={() => toggle(ticketId)}
                          >
                            <div
                              className={styles["dropdown-item"]}
                              onClick={() => viewTicket(val)}
                            >
                              <p>View</p>
                            </div>
                            <div
                              className={styles["dropdown-item"]}
                              onClick={() => handleApproveticket(val)}
                            >
                              <p>Resolve</p>
                            </div>
                            <div
                              className={styles["dropdown-item"]}
                              onClick={() => handleRejectticket(val)}
                            >
                              <p>Close</p>
                            </div>
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
            <div className={styles["pagination-item"]}>
              <Pagination
                postsPerPage={postsPerPage}
                // totalPosts={ticketdatafilter ? ticketdatafilter.length : 0}
                currentPage={currentPage}
                isLoading={isLoading}
                estimatedTotalPages={estimatedTotalPages}
                paginate={paginate}
                hasNextPage={hasNextPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ticket;
