import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import { Link, useNavigate } from "react-router-dom";
import headerlogo from "../../assets/image 2.png";
import InputSearch from "../inputSearch/InputSearch";
import { BiBell } from "react-icons/bi";
import { AiOutlineBars } from "react-icons/ai";
import { RxCaretDown, RxCaretUp } from "react-icons/rx";
import { FiLogOut } from "react-icons/fi";
import { FaTimes } from "react-icons/fa";
import { SidebarDatas } from "../sidebar/SidebarDatas";
import { BsPersonCircle } from "react-icons/bs";
import { logoutUser } from "../../redux/slice/authSlice";
import { useDispatch } from "react-redux";
import { useNotifications } from "../../customHooks/useNotifications";
import agent from "../../api/agent";
import { useMutation, useQueryClient } from "react-query";
import { useSessionTimeout } from "../../customHooks/useSessionTimeout";
const logo = (
  <div className={styles.logo}>
    <Link to="/dashboard">
      <img src={headerlogo} alt="Alt-Power-Logo" />
    </Link>
  </div>
);
const Header = () => {
  const navigate = useNavigate();
  const [showMemu, setShowMemu] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  const toggleMenu = () => {
    setShowMemu(!showMemu);
  };

  const hideMenu = () => {
    setShowMemu(false);
  };

  // Access the firstName and lastName properties of the user data from local storage
  const savedFullName = localStorage.getItem("fullName");
  const userFullName = savedFullName ? JSON.parse(savedFullName) : null;
  // Function to capitalize the first character of a string
  const capitalizeFirstLetter = (string) => {
    if (typeof string !== "string") return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const dispatch = useDispatch();
  const isLoggedIn = useSessionTimeout();

  const logOut = async () => {
    const response = await agent.auth.logout();
    // console.log("logOut:",response);
    return response;
  };

  // const queryClient = useQueryClient();
  const { mutate: logOutData, isLoading } = useMutation(logOut, {
    // On success, logout the user
    onSuccess: (data) => {
      // queryClient.invalidateQueries("logout");

      if (data.responseCode === "99") {
        // console.log("Error logging out:", data.responseMessage);
        toast.error(data.responseMessage);
      } else {
        // toast.success(data.responseMessage);
        dispatch(logoutUser());
        // setIsLoggedIn(false);
        sessionStorage.clear();
        localStorage.clear();
        navigate("/", { replace: true });
        window.history.pushState(null, null, window.location.href);
        window.addEventListener('popstate', function(event) {
          window.history.pushState(null, null, window.location.href);
        });
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Errorlogging out:", error);
      toast.error("Error logging out . Please try again.");
    },
  });

  const handleLogout = () => {
    logOutData();
    // localStorage.removeItem("accessToken");
  };
  const roleName = localStorage.getItem("rolename");

  // console.log("roleNamegffg",roleName);
  const sidebarData = SidebarDatas(roleName || "");
  const { notificationsdata, totalCount } = useNotifications();
  // console.log("notificationsdata", notificationsdata);
  const showNotifications = notificationsdata && notificationsdata.length > 0;
  return (
    <header>
      <div className={styles.header}>
        {logo}
        <nav
          className={
            showMemu ? `${styles["show-nav"]}` : `${styles["hide-nav"]}`
          }
        >
          <div
            className={
              showMemu
                ? `${styles["nav-wrapper"]} ${styles["show-nav-wrapper"]}`
                : `${styles["nav-wrapper"]}`
            }
            onClick={hideMenu}
          ></div>
          <ul>
            <li>
              <div className={styles["logo-mobile"]}>
                {logo}
                <FaTimes size={22} color="#fff" onClick={hideMenu} />
              </div>
            </li>
            {window.location.pathname === "/dashboard" ? (
              <li className={styles.search}>
                <div>{/* <InputSearch /> */}</div>
              </li>
            ) : (
              ""
            )}
            <li
              className={styles.notification}
              onClick={() => setDropdown(!dropdown)}
            >
              <div>
                <span className={styles.bell}>
                  <BiBell size={17} />
                  <p>{totalCount}</p>
                </span>
              </div>
            </li>
            {dropdown ? (
              <div
                className={
                  window.location.pathname === "/dashboard"
                    ? `${styles["notification-dropdown-container"]}`
                    : `${styles["notification-dropdown-container1"]}`
                }
                onClick={() => setDropdown(!dropdown)}
              >
                <div
                  className={
                    showNotifications
                      ? `${styles["vendor-content"]}`
                      : `${styles["vendor-content-container1"]}`
                  }
                >
                  <h4>Notification</h4>
                  <p
                    className={
                      showNotifications ? styles["vendor-content-p"] : ""
                    }
                  >
                    No Notification
                  </p>
                  <div className={styles["vendor-content1"]}>
                    {notificationsdata &&
                      notificationsdata.map((val, index) => {
                        const {
                          notificationId,
                          title,
                          message,
                          adminRoleName,
                        } = val;
                        // Calculate SN (sequential number)
                        const sn = index + 1;

                        return (
                          <div className={styles.users} key={notificationId}>
                            <div className={styles["users-id-img-name-depart"]}>
                              <div className={styles.img}>
                                <p>{<BiBell size={27} color="#F2C94C" />}</p>
                              </div>
                              <div className={styles["title-message"]}>
                                <span>{title}</span>
                                <p>
                                  {message}{" "}
                                  <span className={styles.notificationsLink}>
                                    {title === "Customer" && (
                                      <Link to="/customer">View Customer</Link>
                                    )}
                                    {title === "Vendor" && (
                                      <Link to="/vendor">View Vendor</Link>
                                    )}
                                    {title === "Product" && (
                                      <Link to="/product-list">
                                        View Product
                                      </Link>
                                    )}
                                    {title === "Category" && (
                                      <Link to="/category">View Category</Link>
                                    )}
                                    {title === "Order" && (
                                      <Link to="/order">View Order</Link>
                                    )}
                                    {title === "Brand" && (
                                      <Link to="/brand">View Brand</Link>
                                    )}
                                    {title === "Ticket" && (
                                      <Link to="/ticket">View Ticket</Link>
                                    )}
                                    {title === "Review" && (
                                      <Link to="/review">View Review</Link>
                                    )}
                                    {title === "Promotion" && (
                                      <Link to="/promotion">
                                        View Promotion
                                      </Link>
                                    )}
                                    {title === "Admin" && (
                                      <Link to="/user-management">
                                        View User Management
                                      </Link>
                                    )}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className={styles["users-date-icon"]}>
                              <div>
                                <p className={styles.date}>{adminRoleName}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            ) : (
              " "
            )}
            <li>
              <div
                className={
                  window.location.pathname !== "/dashboard"
                    ? styles["user-name"]
                    : styles["user-name-d"]
                }
              >
                <span className="--flex-between ">
                  <BsPersonCircle size={25} />
                  {userFullName ? (
                    <p className="--p">
                      Welcome, {capitalizeFirstLetter(userFullName.firstname)}{" "}
                      {""}
                      {capitalizeFirstLetter(userFullName.lastname)}
                    </p>
                  ) : (
                    <p className="--p">Welcome, Guest</p>
                  )}
                  <RxCaretDown size={20} />
                </span>
              </div>
            </li>
            <hr className={styles.line} />
            <li
              className={
                window.location.pathname !== "/dashboard"
                  ? styles["logout"]
                  : styles["logout-d"]
              }
              onClick={handleLogout}
            >
              Log Out
              <FiLogOut size={20} color="#db353a" className={styles.icon} />
            </li>
            <ul className={styles["sidebar-list"]}>
              {sidebarData.map((val, key) => {
                return (
                  <li
                    key={key}
                    className={styles.row}
                    id={
                      window.location.pathname === val.link
                        ? `${styles.active}`
                        : "" || window.location.pathname === val.link2
                        ? `${styles.active}`
                        : "" || window.location.pathname === val.link3
                        ? `${styles.active}`
                        : ""
                    }
                    onClick={() => {
                      val.supheader
                        ? (window.Clipboard = null)
                        : navigate(val.link);
                    }}
                  >
                    <div id={styles.supheader}>{val.supheader}</div>
                    <div id={styles.icon}>{val.icon}</div>
                    <p id={styles.title}>{val.title}</p>
                  </li>
                );
              })}
            </ul>
          </ul>
        </nav>
        <div className={styles["menu-icon"]}>
          <AiOutlineBars size={28} onClick={toggleMenu} />
        </div>
      </div>
    </header>
  );
};

export default Header;
