import React from "react";
import styles from "./chart.module.css";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useDashboardMatrix } from "../../../customHooks/useDashboardMatrix";
const generateChartData = (monthlyTransaction) => {
  return [
    { name: "JAN", total: monthlyTransaction.jan },
    { name: "MAR", total: monthlyTransaction.mar },
    { name: "MAY", total: monthlyTransaction.may },
    { name: "JUL", total: monthlyTransaction.jul },
    { name: "SEP", total: monthlyTransaction.sep },
    { name: "NOV", total: monthlyTransaction.nov },
  ];
};

const Areachart = () => {
  const { dashboardmatrix, isLoading, error } = useDashboardMatrix();

  

  const data = generateChartData(
    dashboardmatrix && dashboardmatrix.monthlyTransaction
      ? dashboardmatrix.monthlyTransaction
      : {}
  );
  return (
    <div>
      <ResponsiveContainer width="100%" aspect={1.2}>
        <AreaChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="10%" stopColor="#ebf6ef" stopOpacity={0.8} />
              <stop offset="90%" stopColor="#edf7f1" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <Tooltip />
          <Area
            type="monotone"
            dataKey="total"
            stroke="#32c36c"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Areachart;
