import { useQuery } from "react-query";
import agent from "../api/agent";

// const fetchPromotions = async () => {
//     const response = await agent.promotion.getAllPromotions();
//     return response.data;
//   };
  const fetchPromotions = async ({ page, pageSize }) => {
    // console.log("PageNumber:",page, "PageSize:",pageSize);
    // Adjust PageNumber based on page value
    // const PageNumber = page === 0 ? 1 : page;
    const PageNumber = page + 1;
    const PageSize = pageSize
    // console.log("PageNumber2:",PageNumber, "PageSize2:",pageSize);
    const response = await agent.promotion.getAllPromotions(PageNumber, PageSize );
    return response.data;
  };

// export const usePromotions = () => {
//     const {
//       isLoading,
//       error,
//       data: Promotions,
//     } = useQuery({
//       queryKey: ["Promotions"],
//       queryFn: fetchPromotions,
//     });
//     return {Promotions, isLoading, error,};
//   }; 

  export const usePromotions = ({ page, pageSize } = {}) => {
    const { isLoading, error, data: Promotions, refetch } = useQuery({
      queryKey: ['Promotions', { page, pageSize }],
      queryFn: () => fetchPromotions({ page, pageSize }),
    });
  
    return { isLoading, error,Promotions, refetch };
  };