const setLocalStorageItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  
  // Function to get an item from localStorage
 const getLocalStorageItem = (key) => {
  const item = localStorage.getItem(key);
  // console.log('getLocalStorageItem called. Item:', item);
  return item ? JSON.parse(item) : null;
};
  
  // Function to remove an item from localStorage
  const removeLocalStorageItem = (key) => {
    localStorage.removeItem(key);
  };
  
  export const setRefreshToken = (refreshToken, userId) => {
    const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours
    setLocalStorageItem('refreshToken', {refreshToken, userId, expirationTime });
  };
  
  export const setAccessToken = (accessToken) => {
    setLocalStorageItem('accessToken', accessToken);
    
  };
  
  export const getRefreshToken = () => {
    const refreshToken= getLocalStorageItem('refreshToken');
    // console.log('getRefreshToken  called. Refresh  Token:', refreshToken);
    return refreshToken;

  };
  
  export const getAccessToken = () => {
    const accessToken = getLocalStorageItem('accessToken');
    // console.log('getAccessToken called. Access Token:', accessToken);
    return accessToken;
  };
  
  export const clearRefreshToken = () => {
    removeLocalStorageItem('refreshToken');
  };
  
  export const clearAccessToken = () => {
    removeLocalStorageItem('accessToken');
  };