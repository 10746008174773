import React, { useEffect, useState } from "react";
import styles from "./Sidebar.module.css";
import { SidebarDatas } from "./SidebarDatas";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  // const [roleName, setRoleName ] = useState("")
  const roleName = localStorage.getItem("rolename");

  let sidebarData = SidebarDatas(roleName);

  // console.log("Filtered Sidebar Data:", sidebarData);
  return (
    <div className={styles.sidebar}>
      <div className={styles.empty}></div>
      <ul className={styles["sidebar-list"]}>
        {sidebarData.map((val, key) => {
          return (
            <li
              key={key}
              className={styles.row}
              id={
                window.location.pathname === val.link
                  ? `${styles.active}`
                  : "" || window.location.pathname === val.link2
                  ? `${styles.active}`
                  : "" || window.location.pathname === val.link3
                  ? `${styles.active}`
                  : ""
              }
              onClick={() => {
                val.supheader ? (window.Clipboard = null) : navigate(val.link);
              }}
            >
              <div id={styles.supheader}>{val.supheader}</div>
              <div id={styles.icon}>{val.icon}</div>
              <p id={styles.title}>{val.title}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
