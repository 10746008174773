import React, { useState, useEffect } from "react";
import loginImg from "../../assets/image 3.jpg";
import styles from "./auth.module.css";
import logoimg from "../../assets/image 2.png";
import { BsEye, BsEyeSlash, BsFillPlayFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components";
import { loginUser, setUsername, setPassword, setIpAddress, setBrowser, clearError, setUser } from "../../redux/slice/authSlice"
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { createFingerprint } from "./createFingerprint"
// import { useIpAddress } from "../../customHooks/useIpAddress";
const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { username, password, isLoading, error } = useSelector(
    (state) => state.auth
  );


  // const {  ipAddress } = useIpAddress();
  // if (ipAddress) {
  //   const userIpAddress = ipAddress;
  //     dispatch(setIpAddress(userIpAddress));
  //     // console.log("User IP Address:", userIpAddress);
  // }
  useEffect(() => {
    // Get browser ID 
    const { visitorId } = createFingerprint();
    dispatch(setBrowser(visitorId));
    // console.log("User Browser ID:", visitorId);
  }, []);


  const togglePassword = () => {
    setShowPassword(!showPassword);
  };



  const handleUsernameChange = (e) => {
    dispatch(setUsername(e.target.value));
  };

  const handlePasswordChange = (e) => {
    dispatch(setPassword(e.target.value));
  };

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(loginUser())
      .unwrap()
      .then((response) => {
        if (!response.data) {
          // toast.error("Login failed");
          const responseMessage = response.responseMessage;
        toast.error(responseMessage);
          return;
        }
        // Assuming userData contains the user data after successful login
        dispatch(setUser(response.data));

        
        // console.log("yes oh",dispatch(setUser(userData)));
        dispatch(clearError());
        navigate("/token", { state: { username: username } });
      })
      .catch((err) => {
        // console.error(err);
        toast.error(err.message);
      });
     
  };


  

  return (
    <>
    <ToastContainer />
      {isLoading && <Loader />}
      <section className={`container ${styles["image-container"]}`}>
        <div className={styles.loginImg}>
          <img src={loginImg} alt="loginImg" width={1050}  />
          <div className={styles.overlay}>
            <div className={styles.text}>
              <h1>Great to have you here!</h1>
              <p>You can login to access your workspace.</p>
              <span>
                Copyright 2022 &nbsp; &nbsp; | &nbsp; &nbsp; Powered by Sterling
                Alternative Finance. All rights reserved.
              </span>
            </div>
          </div>
        </div>
        <div className={styles.form}>
        <div className={styles.logo}>
          <img src={logoimg} alt="logoimg"  />
        </div>
        <h2>Administrator Log In </h2>
        <form onSubmit={handleLogin}>
          <div className={styles.email}>
            <label htmlFor="username">Enter Your Username </label>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Username"
              required
              value={username}
              onChange={handleUsernameChange}
            />
          </div>
          <div className={styles.password}>
            <label htmlFor="password">Enter Password</label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              required
              value={password}
              onChange={handlePasswordChange}
            />
            <span className={styles.icon} onClick={togglePassword}>
              {showPassword ? <BsEyeSlash /> : <BsEye />}
            </span>
          </div>

          <button type="submit" className="--btn --btn-success "  disabled={isLoading} 
          style={{
                    cursor: isLoading ? "not-allowed" : "pointer",
                  }}>
            <p>Log in to your Account</p>
            <span>
              <BsFillPlayFill />
            </span>
          </button>
        </form>
      </div>
      </section>
     
     
    </>
  );
};

export default Login;
