import React from "react";
import {
  MdHomeFilled,
  MdOutlineStore,
  MdOutlineLibraryBooks,
} from "react-icons/md";
import { FiUsers, FiFilm, FiSettings } from "react-icons/fi";
import { BiCart, BiEnvelope } from "react-icons/bi";
import { AiOutlinePicture, AiOutlineCheckSquare } from "react-icons/ai";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { RiFileList2Fill } from "react-icons/ri";
// export const SidebarDatas = [
//   {
//     title: "Dashboard",
//     icon: <MdHomeFilled />,
//     link: "/dashboard",
//   },
//   {
//     title: "Vendors",
//     icon: <MdOutlineStore />,
//     link: "/vendor",
//   },
//   {
//     title: "Customers",
//     icon: <FiUsers />,
//     link: "/customer",
//   },
//   {
//     title: "Product List",
//     icon: <BiCart />,
//     link: "/product-list",
//     link2: "/product-list/Add-product",
//     link3: "/product-list/update-product",
//   },
//   {
//     title: "Category",
//     icon: <RiFileList2Fill />,
//     link: "/category",
//   },
//   {
//     title: "Orders",
//     icon: <AiOutlineCheckSquare />,
//     link: "/order",
//   },
//   {
//     title: "Brands",
//     icon: <AiOutlinePicture />,
//     link: "/brand",
//   },
//   {
//     title: "Reviews",
//     icon: <FaRegMoneyBillAlt />,
//     link: "/review",
//   },
//   // {
//   //   title: "Logs ",
//   //   icon: <FiFilm />,
//   //   link: "/log",
//   // },
//   {
//     title: "User Management",
//     icon: <FiSettings />,
//     link: "/user-management",
//   },
//   {
//     title: "Tickets",
//     icon: <BiEnvelope />,
//     link: "/ticket",
//     link2: "/ticket/view-ticket",
//   },
//   {
//     title: "Promotions",
//     icon: <MdOutlineLibraryBooks />,
//     link: "/promotion",
//     link2: "/promotion/Add-promotion",
//   },

// ];
export const SidebarDatas = (roleName) => {
  if (roleName === "Auditor") {
    return [
      {
        title: "Vendors",
        icon: <MdOutlineStore />,
        link: "/vendor",
      },
      {
        title: "Customers",
        icon: <FiUsers />,
        link: "/customer",
      },
      {
        title: "Orders",
        icon: <AiOutlineCheckSquare />,
        link: "/order",
      },
    ];
  } else if (roleName === "Platform Manager") {
    return [
      {
        title: "Dashboard",
        icon: <MdHomeFilled />,
        link: "/dashboard",
      },
      {
        title: "Vendors",
        icon: <MdOutlineStore />,
        link: "/vendor",
      },
      {
        title: "Customers",
        icon: <FiUsers />,
        link: "/customer",
      },
      {
        title: "Product List",
        icon: <BiCart />,
        link: "/product-list",
        link2: "/product-list/Add-product",
        link3: "/product-list/update-product",
      },
      {
        title: "Category",
        icon: <RiFileList2Fill />,
        link: "/category",
      },
      {
        title: "Orders",
        icon: <AiOutlineCheckSquare />,
        link: "/order",
      },
      {
        title: "Brands",
        icon: <AiOutlinePicture />,
        link: "/brand",
      },
      {
        title: "Reviews",
        icon: <FaRegMoneyBillAlt />,
        link: "/review",
      },
      {
        title: "Tickets",
        icon: <BiEnvelope />,
        link: "/ticket",
        link2: "/ticket/view-ticket",
      },
      {
        title: "Promotions",
        icon: <MdOutlineLibraryBooks />,
        link: "/promotion",
        link2: "/promotion/Add-promotion",
      },
    ];
  } else if (roleName === "Customer Care") {
    return [
      {
        title: "Customers",
        icon: <FiUsers />,
        link: "/customer",
      },
      {
        title: "Orders",
        icon: <AiOutlineCheckSquare />,
        link: "/order",
      },
      {
        title: "Vendors",
        icon: <MdOutlineStore />,
        link: "/vendor",
      },
    ];
  } else if (roleName === "IT Control") {
    return [
      {
        title: "Dashboard",
        icon: <MdHomeFilled />,
        link: "/dashboard",
      },
      {
        title: "Vendors",
        icon: <MdOutlineStore />,
        link: "/vendor",
      },
      {
        title: "Customers",
        icon: <FiUsers />,
        link: "/customer",
      },
      {
        title: "Product List",
        icon: <BiCart />,
        link: "/product-list",
      },
      {
        title: "Category",
        icon: <RiFileList2Fill />,
        link: "/category",
      },
      {
        title: "Orders",
        icon: <AiOutlineCheckSquare />,
        link: "/order",
      },
      {
        title: "Brands",
        icon: <AiOutlinePicture />,
        link: "/brand",
      },
      {
        title: "Reviews",
        icon: <FaRegMoneyBillAlt />,
        link: "/review",
      },
      {
        title: "Tickets",
        icon: <BiEnvelope />,
        link: "/ticket",
        link2: "/ticket/view-ticket",
      },
      {
        title: "Promotions",
        icon: <MdOutlineLibraryBooks />,
        link: "/promotion",
        link2: "/promotion/Add-promotion",
      },
    ];
  } else if (roleName === "superadmin") {
    return [
      {
        title: "Dashboard",
        icon: <MdHomeFilled />,
        link: "/dashboard",
      },
      {
        title: "Vendors",
        icon: <MdOutlineStore />,
        link: "/vendor",
      },
      {
        title: "Customers",
        icon: <FiUsers />,
        link: "/customer",
      },
      {
        title: "Product List",
        icon: <BiCart />,
        link: "/product-list",
        link2: "/product-list/Add-product",
        link3: "/product-list/update-product",
      },
      {
        title: "Category",
        icon: <RiFileList2Fill />,
        link: "/category",
      },
      {
        title: "Orders",
        icon: <AiOutlineCheckSquare />,
        link: "/order",
      },
      {
        title: "Brands",
        icon: <AiOutlinePicture />,
        link: "/brand",
      },
      {
        title: "Reviews",
        icon: <FaRegMoneyBillAlt />,
        link: "/review",
      },
      {
        title: "User Management",
        icon: <FiSettings />,
        link: "/user-management",
      },
      {
        title: "Tickets",
        icon: <BiEnvelope />,
        link: "/ticket",
        link2: "/ticket/view-ticket",
      },
      {
        title: "Promotions",
        icon: <MdOutlineLibraryBooks />,
        link: "/promotion",
        link2: "/promotion/Add-promotion",
      },
     
    ];
  } else {
    return [];
  }
};
