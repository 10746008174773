import PBKDF2 from "crypto-js/pbkdf2";
import Utf8 from "crypto-js/enc-utf8";
import AES from "crypto-js/aes";

// Constants 
// axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const PassPhrase = String(process.env.REACT_APP_PassPhrase);
const SaltValue = String(process.env.REACT_APP_SaltValue);
const InitVector = String(process.env.REACT_APP_InitialVector);
const PasswordIterations = Number.parseInt(String(process.env.REACT_APP_PasswordIterations));
const Blocksize = Number.parseInt(String(process.env.REACT_APP_BlockSize));



// Encryption Service
export const encryptRequest = (request) => {
  // console.log("request:", request) ;
  var key = PBKDF2(PassPhrase, Utf8.parse(SaltValue), {
    keySize: 256 / Blocksize,
    iterations: PasswordIterations,
  });
  var parsedIV = Utf8.parse(InitVector);
 
  // Encrypt
  var encrypted = AES.encrypt(JSON.stringify(request), key, {
    iv: parsedIV,
    // mode: CryptoJS.mode.CFB,
    // padding: CryptoJS.pad.AnsiX923
  }).toString();
  // console.log(request);
  // console.log("encrypted:",encrypted);
  return encrypted;
};
 
// Decryption Service
export const decryptResponse = (response) => {
  // console.log("Response:", response) ;
  var key = PBKDF2(PassPhrase, Utf8.parse(SaltValue), {
    keySize: 256 / Blocksize,
    iterations: PasswordIterations,
  });
  var parsedIV = Utf8.parse(InitVector);
 
  // Decrypt
  var bytes = AES.decrypt(response, key, { iv: parsedIV });
  var decryptedResponse = bytes.toString(Utf8);
  // console.log("decryptedResponse:",decryptedResponse);
  try {
    return JSON.parse(decryptedResponse);
  } catch (error) {
    return decryptedResponse;
  }
};

// Test Decryption
// const testResponse = "6plADh+vmUTxtOF8WsFPV6wIE/c9TR8i9LWD2uzxKJNzpLEYwp4531nyc+esr5hxLOCHtTm4Ab2bvzgy8lmst/yspT6p9XFhtw+Qedrlvupj/BDf27Qp77f4y8XtiP69";
// let yes = decryptResponse(testResponse);
// console.log("yes", yes);