import React from "react";

import styles from "./Dashboard.module.css";
import { Header, Sidebar } from "../../components";
import { Link } from "react-router-dom";
import { TbCurrencyNaira, TbSquareOff } from "react-icons/tb";
import {
  AiOutlineCheckSquare,
  AiOutlineCloseSquare,
  AiOutlineLineChart,
} from "react-icons/ai";
import {
  MdOutlineAddShoppingCart,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineMarkEmailRead,
} from "react-icons/md";
import { GiBackwardTime, GiMailbox } from "react-icons/gi";
import { HiOutlineMail } from "react-icons/hi";
import { GoMail } from "react-icons/go";
import { BiCart, BiDislike, BiLike } from "react-icons/bi";
import { FiLock, FiTrash, FiUsers } from "react-icons/fi";
import { CiStreamOn } from "react-icons/ci";
import { BsPersonSquare } from "react-icons/bs";
import { TfiGift } from "react-icons/tfi";
import Areachart from "./chart/Areachart";
import Piechart from "./chart/Piechart";
import { useDashboardMatrix } from "../../customHooks/useDashboardMatrix";
import { useNotifications } from "../../customHooks/useNotifications";
import femaleimg from "../../assets/female.png";
import maleimg from "../../assets/maleface.png";
import agent from "../../api/agent";
import { useAdmin } from "../../customHooks/useAdmin";

const Dashboard = () => {
  // React Query Functions
  const { dashboardmatrix, isLoading, error } = useDashboardMatrix();
  // console.log("dashboardmatrix", dashboardmatrix);
  let totalCustomerCount = 0;
  let blockedCustomer = 0;
  let totalProductCount = 0;
  let Productapproved = 0;
  let Productdisabled = 0;
  let ProductoutOfStock = 0;
  let Productpending = 0;
  let Productrejected = 0;
  let totalOrder = 0;
  let Ordercancelled = 0;
  let Ordercompleted = 0;
  let Orderpending = 0;
  let totalTicket = 0;
  let Ticketopened = 0;
  let Ticketpending = 0;
  let Ticketresolved = 0;
  let totalVendor = 0;
  let vendoractive = 0;
  let vendorinActive = 0;
  let vendorlocked = 0;
  let vendorpending = 0;
  let vendorrejected = 0;
  let totalTransaction = 0;

  if (dashboardmatrix) {
    totalCustomerCount = dashboardmatrix.customerMatrix.totalCustomerCount;
    blockedCustomer = dashboardmatrix.customerMatrix.blockedCustomer;
    totalProductCount = dashboardmatrix.productMatrix.totalProductCount;
    Productapproved = dashboardmatrix.productMatrix.approved;
    Productdisabled = dashboardmatrix.productMatrix.disabled;
    ProductoutOfStock = dashboardmatrix.productMatrix.outOfStock;
    Productpending = dashboardmatrix.productMatrix.pending;
    Productrejected = dashboardmatrix.productMatrix.rejected;
    totalOrder = dashboardmatrix.orderMatrix.totalOrder;
    Ordercancelled = dashboardmatrix.orderMatrix.cancelled;
    Ordercompleted = dashboardmatrix.orderMatrix.completed;
    Orderpending = dashboardmatrix.orderMatrix.pending;
    totalTicket = dashboardmatrix.ticketMatrix.totalTicket;
    Ticketopened = dashboardmatrix.ticketMatrix.opened;
    Ticketpending = dashboardmatrix.ticketMatrix.pending;
    Ticketresolved = dashboardmatrix.ticketMatrix.resolved;
    totalVendor = dashboardmatrix.vendorMatrix.totalVendor;
    vendoractive = dashboardmatrix.vendorMatrix.active;
    vendorinActive = dashboardmatrix.vendorMatrix.inActive;
    vendorlocked = dashboardmatrix.vendorMatrix.locked;
    vendorpending = dashboardmatrix.vendorMatrix.pending;
    vendorrejected = dashboardmatrix.vendorMatrix.rejected;
    totalTransaction = dashboardmatrix.monthlyTransaction.totalTransaction
  }
  const { notificationsdata } = useNotifications();
  // console.log("notificationsdata", notificationsdata);
  const showNotifications = notificationsdata && notificationsdata.length > 0;
  const { usersdata, isError } = useAdmin();
  return (
    <div className={styles.Dashboard}>
      <Header />
      <div className={styles["Dashboard-body"]}>
        <Sidebar />
        <div className={styles["Dashboard-container"]}>
          <h2>Dashboard</h2>
          <div className={styles["Dashboard-content"]}>
            <div className={styles.row1}>
              <div className={styles["amount-chart"]}>
                <div className={styles.amount}>
                  <TbCurrencyNaira className={styles.naira} />
                  <div className="">
                    <h2>{totalTransaction}</h2>
                    <span>All Transactions made</span>
                  </div>
                </div>
                <div className={styles.chart}>
                  <div className={styles["chart-header"]}>
                    <div className={styles["transaction-head"]}>
                      <AiOutlineLineChart size={20} />
                      <h3>All Transactions</h3>
                    </div>
                    <div className={styles["transaction-month"]}>
                      <p>Monthly</p>
                      <MdOutlineKeyboardArrowDown />
                    </div>
                  </div>
                  <div className={styles["chart-body"]}>
                    <Areachart />
                  </div>
                </div>
              </div>
              <div className={styles.ticket}>
                <div className={styles["ticket-head"]}>
                  <div className={styles["ticket-icon"]}>
                    <GiMailbox />
                  </div>
                  <div className={styles["ticket-title"]}>
                    <h2>Tickets</h2>
                  </div>
                </div>
                <div className={styles["ticket-content"]}>
                  <div className={styles["ticket-content1"]}>
                    <h2>{totalTicket}</h2>
                    <span>All Tickets</span>
                  </div>
                  <div className={styles["ticket-pending-resolved-opened"]}>
                    <div className={styles["ticket-pending"]}>
                      <div className={styles["ticket-pending-icon"]}>
                        <HiOutlineMail size={15} />
                      </div>
                      <div className={styles["ticket-pending-content"]}>
                        <h2>{Ticketpending}</h2>
                        <span>Pending</span>
                      </div>
                    </div>
                    <div className={styles["ticket-resolved"]}>
                      <div className={styles["ticket-resolved-icon"]}>
                        <MdOutlineMarkEmailRead size={15} />
                      </div>
                      <div className={styles["ticket-resolved-content"]}>
                        <h2>{Ticketresolved}</h2>
                        <span>Resolved</span>
                      </div>
                    </div>
                    <div className={styles["ticket-opened"]}>
                      <div className={styles["ticket-opened-icon"]}>
                        <GoMail size={15} />
                      </div>
                      <div className={styles["ticket-opened-content"]}>
                        <h2>{Ticketopened}</h2>
                        <span>Opened</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles["ticket-link"]}>
                  <Link to="/ticket">View all</Link>
                  <div className={styles["ticket-link-icon"]}>
                    <MdOutlineKeyboardDoubleArrowRight size={15} />
                  </div>
                </div>
              </div>
              <div className={styles.vendor}>
                <div className={styles["vendor-header"]}>
                  <div className={styles["vendor-icon-title"]}>
                    <div className={styles["vendor-icon"]}>
                      <BiCart />
                    </div>
                    <div className={styles["vendor-title"]}>
                      <h2>Vendors</h2>
                    </div>
                  </div>
                  <div className={styles["vendor-month"]}>
                    <p>Monthly</p>
                    <MdOutlineKeyboardArrowDown />
                  </div>
                </div>
                <div className={styles["vendor-content1"]}>
                  <div className={styles["all-vendor"]}>
                    <h2>{totalVendor}</h2>
                    <span>All Vendors</span>
                  </div>
                  <div className={styles["vendor-link"]}>
                    <Link to="/vendor">View all</Link>
                    <div className={styles["vendor-link-icon"]}>
                      <MdOutlineKeyboardDoubleArrowRight size={15} />
                    </div>
                  </div>
                </div>
                <div
                  className={
                    styles["vendor-pending-active-inactive-rejected-locked"]
                  }
                >
                  <div className={styles["vendor-pending"]}>
                    <div className={styles["vendor-pending-icon"]}>
                      <GiBackwardTime size={17} />
                    </div>
                    <div className={styles["vendor-pending-content"]}>
                      <h2>{vendorpending}</h2>
                      <span>Pending</span>
                    </div>
                  </div>
                  <div className={styles["vendor-active"]}>
                    <div className={styles["vendor-active-icon"]}>
                      <AiOutlineCheckSquare size={15} />
                    </div>
                    <div className={styles["vendor-active-content"]}>
                      <h2>{vendoractive}</h2>
                      <span>Active</span>
                    </div>
                  </div>
                  <div className={styles["vendor-inactive"]}>
                    <div className={styles["vendor-inactive-icon"]}>
                      <AiOutlineCloseSquare size={15} />
                    </div>
                    <div className={styles["vendor-inactive-content"]}>
                      <h2>{vendorinActive}</h2>
                      <span>Inactive</span>
                    </div>
                  </div>
                  <div className={styles["vendor-rejected"]}>
                    <div className={styles["vendor-rejected-icon"]}></div>
                    <div className={styles["vendor-rejected-content"]}>
                      <h2>{vendorrejected}</h2>
                      <span>Rejected</span>
                    </div>
                  </div>
                  <div className={styles["vendor-locked"]}>
                    <div className={styles["vendor-locked-icon"]}>
                      <FiLock size={15} />
                    </div>
                    <div className={styles["vendor-locked-content"]}>
                      <h2>{vendorlocked}</h2>
                      <span>Locked</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.row2}>
              <div className={styles.product}>
                <div className={styles["product-header"]}>
                  <div className={styles["product-icon-title"]}>
                    <div className={styles["product-icon"]}>
                      <TfiGift />
                    </div>
                    <div className={styles["product-title"]}>
                      <h2>Products</h2>
                    </div>
                  </div>
                  <div className={styles["product-month"]}>
                    <p>Monthly</p>
                    <MdOutlineKeyboardArrowDown />
                  </div>
                </div>
                <div className={styles["product-content"]}>
                  <div className={styles["product-content1-link"]}>
                    <div className={styles["product-content1"]}>
                      <h2>{totalProductCount}</h2>
                      <span>Total Products</span>
                    </div>

                    <div className={styles["product-link"]}>
                      <Link to="/product-list">View all</Link>
                      <div className={styles["product-link-icon"]}>
                        <MdOutlineKeyboardDoubleArrowRight size={15} />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      styles[
                        "product-live-pending-approved-rejected-disabled-deleted-outofstock"
                      ]
                    }
                  >
                    <div className={styles["product-live"]}>
                      <div className={styles["product-live-icon"]}>
                        <CiStreamOn size={15} />
                      </div>
                      <div className={styles["product-live-content"]}>
                        <h2>{Productapproved}</h2>
                        <span>Live</span>
                      </div>
                    </div>
                    <div className={styles["product-pending"]}>
                      <div className={styles["product-pending-icon"]}>
                        <GiBackwardTime size={17} />
                      </div>
                      <div className={styles["product-pending-content"]}>
                        <h2>{Productpending}</h2>
                        <span>Pending</span>
                      </div>
                    </div>
                    <div className={styles["product-approved"]}>
                      <div className={styles["product-approved-icon"]}>
                        <BiLike size={15} />
                      </div>
                      <div className={styles["product-approved-content"]}>
                        <h2>{Productapproved}</h2>
                        <span>Approved</span>
                      </div>
                    </div>
                    <div className={styles["product-rejected"]}>
                      <div className={styles["product-rejected-icon"]}>
                        <BiDislike size={15} />
                      </div>
                      <div className={styles["product-rejected-content"]}>
                        <h2>{Productrejected}</h2>
                        <span>Rejected</span>
                      </div>
                    </div>
                    <div className={styles["product-disabled"]}>
                      <div className={styles["product-disabled-icon"]}>
                        <TbSquareOff size={15} />
                      </div>
                      <div className={styles["product-disabled-content"]}>
                        <h2>{Productdisabled}</h2>
                        <span>Disabled</span>
                      </div>
                    </div>
                    <div className={styles["product-deleted"]}>
                      <div className={styles["product-deleted-icon"]}>
                        <FiTrash size={15} />
                      </div>
                      <div className={styles["product-deleted-content"]}>
                        <h2>{Productrejected}</h2>
                        <span>Deleted</span>
                      </div>
                    </div>
                    <div className={styles["product-outofstock"]}>
                      <div className={styles["product-outofstock-icon"]}>
                        <MdOutlineAddShoppingCart size={15} />
                      </div>
                      <div className={styles["product-outofstock-content"]}>
                        <h2>{ProductoutOfStock}</h2>
                        <span>Out of Stock</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.order}>
                <div className={styles["order-header"]}>
                  <div className={styles["order-icon-title"]}>
                    <div className={styles["order-icon"]}>
                      <TfiGift />
                    </div>
                    <div className={styles["order-title"]}>
                      <h2>Orders</h2>
                    </div>
                  </div>
                  <div className={styles["order-month"]}>
                    <p>Monthly</p>
                    <MdOutlineKeyboardArrowDown />
                  </div>
                </div>
                <div className={styles["order-content"]}>
                  <div className={styles.piechart}>
                    <Piechart />
                  </div>
                  <div className={styles["order-content1"]}>
                    <h2>{totalOrder}</h2>
                    <span>Total Orders</span>
                  </div>
                  <div className={styles["order-pending-cancelled-completed"]}>
                    <div className={styles["order-pending"]}>
                      <div className={styles["order-pending-icon"]}>
                        <GiBackwardTime size={17} />
                      </div>
                      <div className={styles["order-pending-content"]}>
                        <h2>{Orderpending}</h2>
                        <span>Pending</span>
                      </div>
                    </div>
                    <div className={styles["order-cancelled"]}>
                      <div className={styles["order-cancelled-icon"]}>
                        <AiOutlineCloseSquare size={15} />
                      </div>
                      <div className={styles["order-cancelled-content"]}>
                        <h2>{Ordercancelled}</h2>
                        <span>Cancelled</span>
                      </div>
                    </div>
                    <div className={styles["order-completed"]}>
                      <div className={styles["order-completed-icon"]}>
                        <AiOutlineCheckSquare size={15} />
                      </div>
                      <div className={styles["order-completed-content"]}>
                        <h2>{Ordercompleted}</h2>
                        <span>Completed</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles["order-link"]}>
                  <Link to="/order">View all</Link>
                  <div className={styles["order-link-icon"]}>
                    <MdOutlineKeyboardDoubleArrowRight size={15} />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.row3}>
              <div className={styles.customer}>
                <div className={styles["customer-header"]}>
                  <div className={styles["customer-icon-title"]}>
                    <div className={styles["customer-icon"]}>
                      <TfiGift />
                    </div>
                    <div className={styles["customer-title"]}>
                      <h2>Customers</h2>
                    </div>
                  </div>
                </div>
                <div className={styles["customer-content"]}>
                  <div className={styles["all-customer"]}>
                    <div className={styles["all-customer-icon"]}>
                      <FiUsers size={15} />
                    </div>
                    <div className="">
                      <span>All Customer</span>
                      <h2>{totalCustomerCount}</h2>
                    </div>
                  </div>
                  <div className={styles["blacklised-customer"]}>
                    <div className={styles["blacklised-customer-icon"]}>
                      <BsPersonSquare size={15} />
                    </div>
                    <div className="">
                      <span>Blacklised Customer</span>
                      <h2>{blockedCustomer}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.user}>
                <div className={styles["user-header"]}>
                  <div className={styles["user-icon-title"]}>
                    <div className={styles["user-icon"]}>
                      <TfiGift />
                    </div>
                    <div className={styles["user-title"]}>
                      <h2>Users</h2>
                    </div>
                  </div>
                </div>
                <div className={styles["user-content"]}>
                  {usersdata &&
                    usersdata.slice(0, 7).map((user, index) => (
                      <div
                        className={styles["user" + (index + 1)]}
                        key={user.adminId}
                      >
                        <div className={styles["user" + (index + 1) + "-icon"]}>
                          <img
                            src={
                              user.gender.toLowerCase() === "male" || user.gender.toLowerCase() === "m"
                                ? maleimg
                                : femaleimg
                            }
                            alt=""
                            width={20}
                          />
                        </div>
                        <div className={styles["user" + (index + 1) + "-name"]}>
                          <p>
                            {user.firstName} {user.lastName}
                          </p>
                          <span>{user.adminName}</span>
                        </div>
                      </div>
                    ))}
                  <div className={styles.user8}>
                    <p>{usersdata && usersdata.length > 7 && `+${usersdata.length - 7}`}</p>
                  </div>
                </div>
              </div>
              <div className={styles.notifcation}>
                <div className={styles["notifcation-header"]}>
                  <div className={styles["notifcation-icon-title"]}>
                    <div className={styles["notifcation-icon"]}>
                      <TfiGift />
                    </div>
                    <div className={styles["notifcation-title"]}>
                      <h2>Notifcations</h2>
                    </div>
                  </div>
                  <div className={styles["notifcation-link"]}>
                    <Link to="/promotion">View all</Link>
                    <div className={styles["notifcation-link-icon"]}>
                      <MdOutlineKeyboardDoubleArrowRight size={15} />
                    </div>
                  </div>
                </div>

                <div className={styles["notifcation-list"]}>
                  {!showNotifications && (
                    <div className={styles["no-list"]}>
                      <p>No Notification</p>
                    </div>
                  )}
                  {notificationsdata &&
                    notificationsdata.map((notification) => (
                      <div
                        className={styles["notifcation-list-content1"]}
                        key={notification.notificationId}
                      >
                        <p>{notification.message}</p>
                        <div className={styles["notification-list-link"]}>
                          {notification.title === "Customer" && (
                            <Link to="/customer">View </Link>
                          )}
                          {notification.title === "Vendor" && (
                            <Link to="/vendor">View </Link>
                          )}
                          {notification.title === "Product" && (
                            <Link to="/product-list">View </Link>
                          )}
                          {notification.title === "Category" && (
                            <Link to="/category">View </Link>
                          )}
                          {notification.title === "Order" && (
                            <Link to="/order">View </Link>
                          )}
                          {notification.title === "Brand" && (
                            <Link to="/brand">View </Link>
                          )}
                          {notification.title === "Ticket" && (
                            <Link to="/ticket">View </Link>
                          )}
                          {notification.title === "Review" && (
                            <Link to="/review">View </Link>
                          )}
                          {notification.title === "Promotion" && (
                            <Link to="/promotion">View </Link>
                          )}
                          {notification.title === "Admin" && (
                            <Link to="/user-management">View </Link>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
