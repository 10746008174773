import React, { useState, useRef, useEffect } from "react";
import styles from "./ProductList.module.css";
import { Header, Sidebar } from "../../components";
import { BsArrowLeftCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import uploadimg from "../../assets/upload.png";
import { addProduct } from "../../redux/slice/productSlice";
import { useDispatch, useSelector } from "react-redux";
import agent from "../../api/agent";
import { setLoading, setProducts } from "../../redux/slice/productSlice";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FaSpinner } from "react-icons/fa";
import toast from "react-hot-toast";
import {
  Radio,
  RadioGroup,
  Checkbox,
  TextField,
  FormControlLabel,
  styled,
} from "@mui/material";
import { useBrands, useSelectBrands } from "../../customHooks/useBrands";
import { useCategories, useSelectCategories } from "../../customHooks/useCategories";
import { useSelectVendors, useVendors } from "../../customHooks/useVendors";
import { AsyncPaginate } from 'react-select-async-paginate';

const GreenRadio = styled(Radio)(({ theme }) => ({
  color: "#000000",
  "&.Mui-checked": {
    color: "#32c36c", // Change the color of the checked radio button
  },
}));
// Define a custom styled Checkbox
const GreenCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#000000", // Unchecked color
  "&.Mui-checked": {
    color: "#32c36c", // Checked color
  },
}));
const textFieldStyle = {
  fontSize: "16px", // Adjust the font size as needed
};

const customStyles = {
  control: (provided, state) => ({
      ...provided,
      borderRadius: '5px',
      border: '1px solid #000000',
      fontSize: '14px', // Set font size to 28px
  }),
  option: (provided, state) => ({
      ...provided,
      // backgroundColor: state.isFocused ? '#3699FF' : null,
      // color: state.isFocused ? 'white' : null,
      fontSize: '14px', // Set font size to 28px
  }),
};

const AddProduct = () => {
  const [clickedImageIndex, setClickedImageIndex] = useState(-1);
  const [formData, setFormData] = useState({
    productName: "",
    brandId: "",
    categoryId: "",
    vendorId: "",
    subCategoryId: "",
    productPrimaryImageUrl: "",
    multipleProductImageUrl: [],
    productSpecification: "",
    installationFee: "",
    buyerNote: "",
    weight: "",
    slug: "",
    shortDescription: "",
    longDescription: "",
    monthlyFinancing: "",
    productDescription: "",
    productCurrentPrice: "",
    productPreviousPrice: "",
    productQuantity: "",
    isWarranty: "",
    applyVat: "",
    isDeliveryCarryOut: "",
  });
  // console.log("FormData to be sent:", formData);
  // const { brands } = useBrands();
  // const { categories } = useCategories();
  // const { vendors } = useVendors();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { data: brands, isLoading:SelectbrandsLoading } = useSelectBrands({ page, pageSize });
  const { data: categories, isLoading:SelectcategoriesLoading  } = useSelectCategories({ page, pageSize });
  const { data: vendors, isLoading:SelectvendorsLoading } = useSelectVendors({ page, pageSize });

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, []);

  // const handleScroll = () => {
  //   const scrolledToBottom = (
  //     window.innerHeight + document.documentElement.scrollTop ===
  //     document.documentElement.offsetHeight
  //   );

  //   if (scrolledToBottom) {
  //     setPage(prevPage => prevPage + 1);
  //   }
  // };
  const [value, setValue] = useState({
    value: "",
    label: "-- Select brand --"
  });
  const [valueCat, setValueCat] = useState({
    value: "",
    label: "-- Select product category --"
  });
  const [valueVen, setValueVen] = useState({
    value: "",
    label: "-- Choose Vendor --"
  });
// console.log("value",value);
  // Function to handle brand selection change
  const handleBrandChange = (selectedBrand) => {
    // console.log("selectedBrand",selectedBrand);
    setFormData({
      ...formData,
      brandId: selectedBrand.value,
    });
    setValue({
      value: selectedBrand.value,
      label: selectedBrand.label
    });
  };
  const handleCategoryChange = (selectedCategory) => {
    // console.log("selectedCategory",selectedCategory);
    setFormData({
      ...formData,
      categoryId: selectedCategory.value,
    });
    setValueCat({
      value: selectedCategory.value,
      label: selectedCategory.label
    });
  };
  const handleVendorChange = (selectedVendor) => {
    // console.log("selectedVendor",selectedVendor);
    setFormData({
      ...formData,
      vendorId: selectedVendor.value,
    });
    setValueVen({
      value: selectedVendor.value,
      label: selectedVendor.label
    });
  };

  // Define the loadOptions function
  const loadOptions = async (search, loadedOptions, { page }) => {
    const response = await agent.brand.getAllBrands(page, pageSize)
    // console.log('Response data:', response.data); 
    return {
      options: response.data.map(brand => ({ label: brand.brandName, value: brand.brandId })),
      hasMore: response.data.length === pageSize, // Assume has more if data length equals page size
      additional: {
        page: page + 1,
      },
    };
  };
  const loadOptionsCat = async (search, loadedOptions, { page }) => {
    const response = await agent.category.getAllcategories(page, pageSize)
    // console.log('Response data:', response.data); 
    return {
      options: response.data.map(category => ({ label:category.category, value:category.categoryId })),
      hasMore: response.data.length === pageSize, // Assume has more if data length equals page size
      additional: {
        page: page + 1,
      },
    };
  };
  const loadOptionsVen = async (search, loadedOptions, { page }) => {
    const response = await agent.vendor.getAllVendors(page, pageSize)
    // console.log('Response data:', response.data); 
    return {
      options: response.data.map(vendor => ({ label: `${vendor.firstName} ${vendor.lastName}`, value: vendor.vendorId })),
      hasMore: response.data.length === pageSize, // Assume has more if data length equals page size
      additional: {
        page: page + 1,
      },
    };
  };


  // const handleCategoriesChange = (event) => {
  //   const selectedCategoryId = event.target.value;
  //   setFormData({ ...formData, categoryId: selectedCategoryId });
  // };
  // const handleBrandsChange = (event) => {
  //   const selectedBrandId = event.target.value;
  //   setFormData({ ...formData, brandId: selectedBrandId });
  // };
  // const handleVendorChange = (event) => {
  //   const selectedVendorId = event.target.value;
  //   setFormData({ ...formData, vendorId: selectedVendorId });
  // };
  // Set default values for categoryId, brandId, and productPrimaryImageUrl, subCategoryId, productSpecification, slug, shortDescription, longDescription, installationFee, monthlyFinancing  when empty

  if (formData.subCategoryId === "") {
    formData.subCategoryId = "string";
  }
  if (formData.productSpecification === "") {
    formData.productSpecification = "string";
  }
  if (formData.slug === "") {
    formData.slug = "string";
  }
  if (formData.installationFee === "") {
    formData.installationFee = 0;
  }
  if (formData.monthlyFinancing === "") {
    formData.monthlyFinancing = 0;
  }
  if (formData.shortDescription === "") {
    formData.shortDescription = "string";
  }
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Convert the value to an integer if it's not an empty string and matches the specified fields
    let parsedValue = value;
    if (
      [
        "weight",
        "productCurrentPrice",
        "productPreviousPrice",
        "productQuantity",
      ].includes(name)
    ) {
      // Remove commas from the value 
      parsedValue = value.replace(/,/g, "");
      
      parsedValue = parsedValue !== "" ? parseInt(parsedValue) : "";
    }

    if (name === "productDescription" || name === "longDescription") {
      // Only set productDescription and longDescription to the same value
      setFormData({
        ...formData,
        productDescription: parsedValue,
        longDescription: parsedValue,
      });
    } else {
      // For other fields, set them without affecting productDescription and longDescription
      setFormData({
        ...formData,
        [name]: parsedValue,
      });
    }
  };

  const productcreation = async (formData) => {
    // console.log("formData:", formData);
    const response = await agent.product.productcreation(formData);
    return response;
  };

  const queryClient = useQueryClient();
  const { mutate: createProductData, isLoading } = useMutation(
    productcreation,
    {
      // On success, invalidate the 'products' query to refetch the data
      onSuccess: (data) => {
        queryClient.invalidateQueries("products");

        if (data.responseCode === "99") {
          // console.log("Error creating Product:", data.responseMessage);
          toast.error(data.responseMessage);
        } else {
          // console.log("Product created successfully");
          toast.success("Product created successfully!");
          navigate("/product-list");
        }
      },
      // On error, show an error toast
      onError: (error) => {
        // console.error("Error creating Product:", error);
        toast.error("Error creating Product . Please try again.");
      },
    }
  );

  const handleAddProduct = async (e) => {
    e.preventDefault();
    createProductData(formData);
  };

  const imageInputRef = useRef(null);
  const handleImageUpload = (files) => {
    if (files) {
      const reader = new FileReader();
      const firstFile = files[0];

      reader.onload = (e) => {
        const imageUrl = e.target.result;

        if (clickedImageIndex !== -1) {
          // Replace the clicked image if an image is clicked
          const newMultipleImages = [...formData.multipleProductImageUrl];
          newMultipleImages[clickedImageIndex] = imageUrl;
          setFormData((prevData) => ({
            ...prevData,
            productPrimaryImageUrl: imageUrl,
            multipleProductImageUrl: newMultipleImages,
          }));
          setClickedImageIndex(-1); // Reset the clicked image index
        } else {
          setFormData((prevData) => ({
            ...prevData,
            productPrimaryImageUrl: imageUrl,
            multipleProductImageUrl: [
              ...prevData.multipleProductImageUrl,
              imageUrl,
            ],
          }));
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading the file: ", error);
      };

      reader.readAsDataURL(firstFile);

      for (let i = 1; i < files.length; i++) {
        reader.readAsDataURL(files[i]);
      }
    }
  };

  const handleImageClick = (index) => {
    setClickedImageIndex(index); // Set the clicked image index
  };
  const navigate = useNavigate();

  const backToProduct = () => {
    return navigate("/product-list");
  };

  return (
    <div className={styles.vendor}>
      <Header />
      <div className={styles["form-upload-body"]}>
        <Sidebar />
        <div className={styles["addproduct-container"]}>
          <div
            className={styles["addproduct-title"]}
            onClick={() => backToProduct()}
          >
            <BsArrowLeftCircle size={30} style={{ cursor: "pointer" }} />
            <p>Go back</p>
          </div>
          <div className={styles["vendor-subtitle-search"]}>
            <div className={styles.subtitle}>
              <div className={styles["vendor-subtitle-head"]}>
                <h3>Add new Product</h3>
              </div>
            </div>
          </div>
          <div className={styles["form-upload-content"]}>
            <div className={styles["form-upload"]}>
              <form>
                {/*Select Vendor name- Select category */}
                <div className={styles["name-category"]}>
                  <div className={styles["Product-name"]}>
                    <label htmlFor="vendorId">Vendor</label>
                    <div className={styles.categoryvendorPaginate}> 
                    <AsyncPaginate
                       value={valueVen}
                       onChange={handleVendorChange}
                        loadOptions={loadOptionsVen} // Pass the loadOptions function
                        isSearchable={false}
                        placeholder="-- Choose Vendor --"
                        additional={{
                          page: 1, // Initial page
                        }}
                        styles={customStyles}
                      />
                    </div>
                    {/* <select
                      name="vendorId"
                      value={formData.vendorId}
                      onChange={handleVendorChange}
                    >
                      <option selected disabled>
                        -- Choose Vendor --
                      </option>
                      {vendors &&
                        vendors.map((vendor) => (
                          <option key={vendor.vendorId} value={vendor.vendorId}>
                            {vendor.firstName} {vendor.lastName}
                          </option>
                        ))}
                    </select> */}
                  </div>
                  <div className={styles.categorybrand}>
                    <label htmlFor="categoryId">Category</label>
                    <div className={styles.categorybrandPaginate}> 
                    <AsyncPaginate
                       value={valueCat}
                       onChange={handleCategoryChange}
                        loadOptions={loadOptionsCat} // Pass the loadOptions function
                        isSearchable={false}
                        placeholder="-- Select product category --"
                        additional={{
                          page: 1, // Initial page
                        }}
                        styles={customStyles}
                      />
                    </div>
                    {/* <select
                      name="categoryId"
                      value={formData.categoryId}
                      onChange={handleCategoriesChange}
                    >
                      <option selected disabled>
                        -- Select product category --
                      </option>
                      {categories &&
                        categories.map((category) => (
                          <option
                            key={category.categoryId}
                            value={category.categoryId}
                          >
                            {category.category}
                          </option>
                        ))}
                    </select> */}
                  </div>
                </div>
                {/*Product name- Select brand */}
                <div className={styles["name-category"]}>
                  <div className={styles["Product-name"]}>
                    <label htmlFor="productName">Product name</label>
                    <input
                      type="text"
                      name="productName"
                      value={formData.productName}
                      onChange={handleInputChange}
                      placeholder="Christopher Dunkwu"
                    />
                  </div>
                  <div className={styles.categorybrand}>
                    <label htmlFor="brandId">Brand</label>
                    <div className={styles.categorybrandPaginate}>
                     <AsyncPaginate
                       value={value}
                       onChange={handleBrandChange}
                        loadOptions={loadOptions} // Pass the loadOptions function
                        isSearchable={false}
                        placeholder="-- Select brand --"
                        additional={{
                          page: 1, // Initial page
                        }}
                        styles={customStyles}
                      />
                     </div>
                    {/* <select
                      name="brandId"
                      value={formData.brandId}
                      onChange={handleBrandsChange}
                    >
                      <option selected disabled>
                        -- Select brand --
                      </option>
                      {brands &&
                        brands.map((brand) => (
                          <option key={brand.brandId} value={brand.brandId}>
                            {brand.brandName}
                          </option>
                        ))}
                    </select> */}
                  </div>
                </div>
                {/*productDescription - buyerNote */}
                <div className={styles["name-category-pb"]}>
                  <div className={styles["actual-Price"]}>
                    <label htmlFor="productDescription">
                      Product description
                    </label>
                    <TextField
                      multiline
                      name="productDescription"
                      rows={4} // Adjust the number of rows as needed
                      value={formData.productDescription }
                      onChange={handleInputChange}
                      placeholder="Write a description about this product"
                      style={textFieldStyle}
                    />
                  </div>
                  <div className={styles["sales-Price"]}>
                    <label htmlFor="buyerNote">
                      Buyer's note (input in bullet points)
                    </label>
                    <TextField
                      multiline
                      name="buyerNote"
                      rows={4} // Adjust the number of rows as needed
                      value={formData.buyerNote}
                      onChange={handleInputChange}
                      placeholder="Write a note about the products"
                    />
                  </div>
                </div>

                {/*Weight- Warrant */}
                <div className={styles["name-category"]}>
                  <div className={styles.weight}>
                    <label htmlFor="weight">Shipping weight</label>
                    <input
                      type="text"
                      name="weight"
                      value={formData.weight}
                      onChange={handleInputChange}
                      placeholder="kg"
                    />
                  </div>
                  <div className="">
                    <div className={styles["warranty-head"]}>
                      <span>Warranty</span>
                    </div>
                    <RadioGroup
                      value={formData.isWarranty}
                      onChange={(e) => {
                        // console.log("Radio Button Changed:", e.target.value);
                        setFormData({
                          ...formData,
                          isWarranty: e.target.value === "true",
                        });
                      }}
                    >
                      <div className={styles["custom-radio"]}>
                        <FormControlLabel
                          value="true"
                          control={<GreenRadio />}
                          label={<span style={{ color: "#000000" }}>Yes</span>}
                        />
                        <FormControlLabel
                          value="false"
                          control={<GreenRadio />}
                          label={<span style={{ color: "#000000" }}>No</span>}
                        />
                      </div>
                    </RadioGroup>
                  </div>
                </div>
                {/*currentprice- previousprice- productquantity*/}
                <div className={styles["name-category"]}>
                  <div className={styles.currentprice}>
                    <label htmlFor="productCurrentPrice">
                      Product current price
                    </label>
                    <input
                      type="text"
                      name="productCurrentPrice"
                      value={
                        formData.productCurrentPrice !== ""
                          ? Number(formData.productCurrentPrice).toLocaleString(
                              "en-NG"
                            )
                          : ""
                      }
                      onChange={handleInputChange}
                      placeholder="₦"
                    />
                  </div>
                  <div className={styles["previousprice"]}>
                    <label htmlFor="productPreviousPrice">
                      Product previous price
                    </label>
                    <input
                      type="text"
                      name="productPreviousPrice"
                      value={
                        formData.productPreviousPrice !== ""
                          ? Number(
                              formData.productPreviousPrice
                            ).toLocaleString("en-NG")
                          : ""
                      }
                      onChange={handleInputChange}
                      placeholder="₦"
                    />
                  </div>
                  <div className={styles["productquantity"]}>
                    <label htmlFor="productQuantity">productquantity</label>
                    <input
                      type="text"
                      name="productQuantity"
                      value={
                        formData.productQuantity !== ""
                          ? Number(formData.productQuantity).toLocaleString(
                              "en-NG"
                            )
                          : ""
                      }
                      onChange={handleInputChange}
                      placeholder="₦"
                    />
                  </div>
                </div>
              </form>
              <hr />
              <div className={styles["upload-section"]}>
                <div className="">
                  <p>Upload Pictures</p>
                  <div className={styles["upload-section-img"]}>
                    <div className={styles["upload-pic"]}>
                      <label
                        htmlFor="imageInput"
                        className={styles["image-upload-label"]}
                      >
                        {formData.multipleProductImageUrl.map(
                          (imageUrl, index) => (
                            <img
                              key={index}
                              src={imageUrl}
                              alt={`Selected img ${index}`}
                              height={90}
                              onClick={() => handleImageClick(index)}
                            />
                          )
                        )}
                        {formData.multipleProductImageUrl.length < 5 && (
                          <img
                            src={uploadimg}
                            alt="Click to add images"
                            height={90}
                          />
                        )}
                        <input
                          id="imageInput"
                          type="file"
                          accept="image/*"
                          ref={imageInputRef}
                          style={{ display: "none" }}
                          multiple
                          onChange={(e) => handleImageUpload(e.target.files)}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className={styles["delivery-warranty"]}>
                  <div className="">
                    <div className={styles["delivery-warranty-head"]}>
                      <span>
                        Would you like to carry out the delivery of this product
                      </span>
                    </div>
                    <RadioGroup
                      value={formData.isDeliveryCarryOut}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          isDeliveryCarryOut: e.target.value === "true",
                        })
                      }
                    >
                      <div className={styles["custom-radio"]}>
                        <FormControlLabel
                          value="true"
                          control={<GreenRadio />}
                          label={<span style={{ color: "#000000" }}>Yes</span>}
                        />
                        <FormControlLabel
                          value="false"
                          control={<GreenRadio />}
                          label={<span style={{ color: "#000000" }}>No</span>}
                        />
                      </div>
                    </RadioGroup>
                  </div>

                  <div className={styles["vat"]}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={formData.applyVat}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              applyVat: e.target.checked,
                            })
                          }
                        />
                      }
                      label={
                        <span style={{ color: "#000000" }}>
                          Apply VAT on product
                        </span>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className={`--btn ${styles["form-upload-button"]}`}
            onClick={handleAddProduct}
            disabled={isLoading}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              width: isLoading && "50px",
            }}
          >
            {isLoading ? (
              <>
                <FaSpinner className={styles["spin"]} />
              </>
            ) : (
              " Add Product"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
