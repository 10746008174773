import { SHA256 } from "crypto-js";
// import { UAParser } from "useragent-parser";

// Function to create a browser fingerprint
export const createFingerprint = () => {

  // const parser = new UAParser();
  // const uaResult = parser.getResult();

  // return {
  //   name: uaResult.browser.name,
  //   version: uaResult.browser.version,
  // };





  const fingerprint = {};
  const ua = window.navigator.userAgent;
  fingerprint.userAgent = ua;
  fingerprint.colorDepth = window.screen.colorDepth;
  fingerprint.deviceMemory = navigator.deviceMemory || null;
  fingerprint.pixelRatio = window.devicePixelRatio || 1;
  fingerprint.screenResolution = `${window.screen.width}x${window.screen.height}`;
  fingerprint.timeZone = new Date().getTimezoneOffset();
  fingerprint.touchPoints = window.navigator.maxTouchPoints || 0;
  fingerprint.hardwareConcurrency = navigator.hardwareConcurrency || null;
  fingerprint.language = window.navigator.language;
  fingerprint.plugins = [];
  for (let i = 0; i < navigator.plugins.length; i++) {
    const plugin = navigator.plugins[i];
    fingerprint.plugins.push({
      name: plugin.name,
      description: plugin.description,
    });
  }
  // Hash the fingerprint object to create a visitor ID
  const visitorId = hash(JSON.stringify(fingerprint));
  return { visitorId, fingerprint };
};
 
// Function to create a unique hash from a string using SHA-256 algorithm from CryptoJS
const hash = (str) => {
  const hash = SHA256(str).toString();
  return hash;
};