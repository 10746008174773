import React, { useEffect, useRef, useState } from "react";
import styles from "./Modal.module.css";
import { TbPointFilled } from "react-icons/tb";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { VscFilePdf } from "react-icons/vsc";
import iceimg from "../../assets/ice.png";
import tomsimg from "../../assets/tomsups.png";
import uploadimg from "../../assets/upload.png";
import bhimg from "../../assets/b-h.png";
import bimgpng from "../../assets/b-img.png";
import femaleheadpng from "../../assets/female-head.png";
import maleheadpng from "../../assets/male-head.png";
import { RiFileList2Fill } from "react-icons/ri";
import {
  Box,
  LinearProgress,
  linearProgressClasses,
  styled,
} from "@mui/material";
import agent from "../../api/agent";
import { toast } from "react-hot-toast";
import { FaSpinner } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useACustomer } from "../../customHooks/useCustomer";
import {
  useCategories,
  useSelectCategories,
} from "../../customHooks/useCategories";
import { FiUser } from "react-icons/fi";
import { GoMail } from "react-icons/go";
import { BsTelephone } from "react-icons/bs";
import { PiScrollLight } from "react-icons/pi";
import { useAdminRoles } from "../../customHooks/useAdminRoles";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { csvData } from "../../components/tabledata";
import { brandCsvData } from "../../components/tabledata";
import ProductBulkUploadInputs from "../card/ProductBulkUploadInputs";
import { useSelector } from "react-redux";
import BrandBulkUploadinputs from "../card/BrandBulkUploadinputs";
import { useProducts } from "../../customHooks/useProducts";
import fi from "../../assets/trylogo.png"
import { LuCreditCard } from "react-icons/lu";
import {PagesDatas} from "./PagesDatas"
const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, valueBuffer }) => ({
    height: 4,
    borderRadius: 5,
    width: `${valueBuffer}%`,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#32c36c" : "#308fe8",
    },
    // [`&.${linearProgressClasses.buffer}`]: {
    //   width: valueBuffer,
    // },
  })
);

//ModalVendorDetail
export const ModalVendorDetail = ({ onHandleToggle, vendordatafilter }) => {
  const vendorData = vendordatafilter;
  // console.log("modalData:", vendorData);
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggle}></div>
      <div className={styles["modalvendordetails-container"]}>
        <div className={styles["vendordetail-modal-content"]}>
          <div className={styles["vendordetail-modal-header"]}>
            <div className="">
              <h3>Vendor Detail</h3>
            </div>
            <p onClick={onHandleToggle}>
              <AiOutlineClose
                color="#8b8b8b"
                size={25}
                style={{ cursor: "pointer" }}
              />
            </p>
          </div>
          <hr className={styles.line} />
          <div className={styles["vendordetail-subheader"]}>
            <div className={styles["vendordetail-img"]}>
              <img src={vendorData.storeLogo} alt="" width={80} />
            </div>
            <div className="">
              <div className={styles["vendordetail-lumos-web"]}>
                <span>storeUrl</span>
                <p>
                  <BsBoxArrowUpRight />
                </p>
              </div>
              <div className={styles["vendordetail-lumos"]}>
                <h4>
                  <img src={vendorData.storeHeader} alt="" width={60} />
                </h4>
                <h4>
                  {vendorData.storeName}
                </h4>
                <span>
                  Lumos is a market leading provider of high-quality solar home
                  systems and is in the business of revolutionising the African
                  market with a reliable and clean power solution, accessible to
                  everyone.
                </span>
              </div>
            </div>
          </div>
          <div className={styles["vendordetail-item"]}>
            <div className={styles["vendordetail-item-content"]}>
              <div className="">
                <p>Email</p>
                <span>{vendorData.email}</span>
              </div>
              <div className="">
                <p>Phone Number</p>
                <span>{vendorData.phoneNumber}</span>
              </div>
            </div>
            <div className={styles["vendordetail-item-content"]}>
              <div className="">
                <p>Date of Creation</p>
                <span>{vendorData.createdAt}</span>
              </div>
              <div className={styles["vendordetail-item-content-status"]}>
                <p>Status</p>
                {vendorData.status === "pending" && (
                  <Box
                    width="65px"
                    p="4px"
                    display="flex"
                    justifyContent="center"
                    backgroundColor="#fbf7e7"
                    color="#f5ba69"
                    borderRadius="2px"
                  >
                    <TbPointFilled />
                    {vendorData.status}
                  </Box>
                )}
                {vendorData.status === "active" && (
                  <Box
                    width="65px"
                    p="4px"
                    display="flex"
                    justifyContent="center"
                    backgroundColor="#e6f2eb"
                    color="#32c36c"
                    borderRadius="2px"
                  >
                    <TbPointFilled />
                    {vendorData.status}
                  </Box>
                )}
                {vendorData.status === "inactive" && (
                  <Box
                    width="65px"
                    p="4px"
                    display="flex"
                    justifyContent="center"
                    backgroundColor="#fbf7e7"
                    color="#e23411"
                    borderRadius="2px"
                  >
                    <TbPointFilled />
                    {vendorData.status}
                  </Box>
                )}
                {vendorData.status === "rejected" && (
                  <Box
                    width="65px"
                    p="4px"
                    display="flex"
                    justifyContent="center"
                    backgroundColor="#fbf7e7"
                    color="#e23411"
                    borderRadius="2px"
                  >
                    <TbPointFilled />
                    {vendorData.status}
                  </Box>
                )}
                {vendorData.status === "locked" && (
                  <Box
                    width="65px"
                    p="4px"
                    display="flex"
                    justifyContent="center"
                    backgroundColor="#fbf7e7"
                    color="#bdbdbd"
                    borderRadius="2px"
                  >
                    <TbPointFilled />
                    {vendorData.status}
                  </Box>
                )}
              </div>
            </div>
          </div>
          <div className={styles["vendoradmin-item"]}>
            <div className="">
              <p>Vendor Admin</p>
            </div>
            <div className={styles["vendoadmin-item"]}>
              <div className={styles["vendordetail-item-content"]}>
                <div className="">
                  <p>Firstname</p>
                  <span>{vendorData.firstName} </span>
                </div>
                <div className={styles["vendoardmin-item-lastname"]}>
                  <p>Lastname</p>
                  <span>{vendorData.lastName}</span>
                </div>
              </div>
              <div className={styles["vendordetail-item-content"]}>
                <div className="">
                  <p>Email</p>
                  <span>{vendorData.email}</span>
                </div>
                <div className={styles["vendoardmin-item-Phone"]}>
                  <p>Phone Number</p>
                  <span>{vendorData.phoneNumber}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className={`--btn ${styles["vendoradmin-button"]}`}
          onClick={onHandleToggle}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

//ModalVendorAuthorize
export const ModalVendorAuthorize = ({
  onHandleToggle,
  onHandleApproveVendor,
  vendordatafilter,
  isLoading,
}) => {
  const vendorData = vendordatafilter;

  // console.log("modalData:", vendorData);
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggle}></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["vendorauthorize-modal-content"]}>
          <div className="">
            <img src={tomsimg} alt="" width={120} />
          </div>
          <div className={styles["vendorauthorize-content-mes"]}>
            <p>You are attempting to authorize the selected vendors</p>
          </div>
        </div>
        <div className={styles["vendorauthorize-button"]}>
          <div className={styles["vendorauthorize-content-button"]}>
            <button
              className={`--btn ${styles["admin-decline-button"]}`}
              onClick={onHandleToggle}
            >
              Decline
            </button>
            <button
              className={`--btn --btn-success ${styles["admin-yes-button"]}`}
              onClick={() => onHandleApproveVendor(vendorData)}
              disabled={isLoading}
              style={{
                cursor: isLoading ? "not-allowed" : "pointer",
                width: isLoading ? "80px" : "auto",
              }}
            >
              {isLoading ? (
                <>
                  <FaSpinner className={styles["spin"]} />
                </>
              ) : (
                "Yes, Please do!"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

//ModalVendorReject
export const ModalVendorReject = ({
  onHandleToggleVendorReject,
  onHandleToggleReason,
  vendordatafilter,
}) => {
  const vendorData = vendordatafilter;

  // console.log("modalData:", vendorData);
  return (
    <div className={styles.modalvendordetail}>
      <div
        className={styles.overlay}
        onClick={onHandleToggleVendorReject}
      ></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["vendorauthorize-modal-content"]}>
          <div className="">
            <img src={iceimg} alt="" width={120} />
          </div>
          <div className={styles["vendorauthorize-content-mes"]}>
            <p>You are attempting to reject the selected vendor.</p>
          </div>
        </div>
        <div className={styles["vendorauthorize-button"]}>
          <div className={styles["vendorauthorize-content-button"]}>
            <button
              className={`--btn ${styles["admin-decline-button"]}`}
              onClick={onHandleToggleVendorReject}
            >
              Decline
            </button>
            <button
              className={`--btn --btn-success ${styles["admin-yes-button"]}`}
              onClick={() => onHandleToggleReason(vendorData)}
            >
              Yes, Please do!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

//ModalVendorReason
export const ModalVendorReason = ({
  onHandleToggle,
  onHandleRejectReason,
  vendorData,
  isLoading,
}) => {
  // console.log("vendorData:", vendorData);
  const [reason, setReason] = useState("");

  const handleReasonChange = (event) => {
    const newReason = event.target.value;
    // console.log("New Reason:", newReason);
    setReason(newReason);
  };
  const handleRejectClick = async (event) => {
    event.preventDefault(); // Prevent form submission
    // console.log("Submitting Reason:", reason); // Check if reason is set

    // Call onHandleRejectReason with the current reason value
    onHandleRejectReason(reason, vendorData.vendorId); // Pass the reason to the parent component's function
  };
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggle}></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["modalvendorreason-content"]}>
          <p>Reason</p>
          <form onSubmit={handleRejectClick}>
            <textarea
              placeholder="why are you rejecting this application?"
              value={reason}
              onChange={handleReasonChange}
            />
            <div className={styles["vendorreason-button"]}>
              <div className={styles["vendorreason-content-button"]}>
                <button
                  className={`--btn --btn-success ${styles["admin-yes-button"]}`}
                  type="submit"
                  disabled={isLoading}
                  style={{
                    cursor: isLoading ? "not-allowed" : "pointer",
                    width: isLoading ? "80px" : "auto",
                  }}
                >
                  {isLoading ? (
                    <>
                      <FaSpinner className={styles["spin"]} />
                    </>
                  ) : (
                    "Decline"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

//ModalVendorBlock
export const ModalVendorBlock = ({
  onHandleToggleVendorReject,
  onHandleToggleReason,
  vendordatafilter,
}) => {
  const vendorData = vendordatafilter;

  // console.log("modalData:", vendorData);
  return (
    <div className={styles.modalvendordetail}>
      <div
        className={styles.overlay}
        onClick={onHandleToggleVendorReject}
      ></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["vendorauthorize-modal-content"]}>
          <div className="">
            <img src={iceimg} alt="" width={120} />
          </div>
          <div className={styles["vendorauthorize-content-mes"]}>
            <p>You are attempting to Block the selected vendor.</p>
          </div>
        </div>
        <div className={styles["vendorauthorize-button"]}>
          <div className={styles["vendorauthorize-content-button"]}>
            <button
              className={`--btn ${styles["admin-decline-button"]}`}
              onClick={onHandleToggleVendorReject}
            >
              Decline
            </button>
            <button
              className={`--btn --btn-success ${styles["admin-yes-button"]}`}
              onClick={() => onHandleToggleReason(vendorData)}
            >
              Yes, Please do!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

//ModalVendorBlockReason
export const ModalVendorBlockReason = ({
  onHandleToggle,
  onHandleRejectReason,
  vendorData,
  isLoading,
}) => {
  // console.log("vendorData:", vendorData);
  const [reason, setReason] = useState("");

  const handleReasonChange = (event) => {
    const newReason = event.target.value;
    // console.log("New Reason:", newReason);
    setReason(newReason);
  };
  const handleRejectClick = async (event) => {
    event.preventDefault(); // Prevent form submission
    // console.log("Submitting Reason:", reason); // Check if reason is set

    // Call onHandleRejectReason with the current reason value
    onHandleRejectReason(reason, vendorData.vendorId); // Pass the reason to the parent component's function
  };
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggle}></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["modalvendorreason-content"]}>
          <p>Reason</p>
          <form onSubmit={handleRejectClick}>
            <textarea
              placeholder="why are you rejecting this application?"
              value={reason}
              onChange={handleReasonChange}
            />
            <div className={styles["vendorreason-button"]}>
              <div className={styles["vendorreason-content-button"]}>
                <button
                  className={`--btn --btn-success ${styles["admin-yes-button"]}`}
                  type="submit"
                  disabled={isLoading}
                  style={{
                    cursor: isLoading ? "not-allowed" : "pointer",
                    width: isLoading ? "80px" : "auto",
                  }}
                >
                  {isLoading ? (
                    <>
                      <FaSpinner className={styles["spin"]} />
                    </>
                  ) : (
                    "Decline"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

//ModalVendorUnblock
export const ModalVendorUnblock = ({
  onHandleToggleBlacklist,
  vendordatafilter,
  onHandleUnblockVendor,
  isLoading,
}) => {
  const vendorData = vendordatafilter;
  // console.log("modalData:", customerData);
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggleBlacklist}></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["vendorauthorize-modal-content"]}>
          <div className="">
            <img src={iceimg} alt="" width={120} />
          </div>
          <div className={styles["vendorauthorize-content-mes"]}>
            <p>You are attempting to Unblock the selected vendor.</p>
          </div>
        </div>
        <div className={styles["vendorauthorize-button"]}>
          <div className={styles["vendorauthorize-content-button"]}>
            <button
              className={`--btn ${styles["admin-decline-button"]}`}
              onClick={onHandleToggleBlacklist}
            >
              Decline
            </button>
            <button
              className={`--btn --btn-success ${styles["admin-yes-button"]}`}
              onClick={() => onHandleUnblockVendor(vendorData)}
              disabled={isLoading}
              style={{
                cursor: isLoading ? "not-allowed" : "pointer",
                width: isLoading ? "80px" : "auto",
              }}
            >
              {isLoading ? (
                <>
                  <FaSpinner className={styles["spin"]} />
                </>
              ) : (
                "Yes, Please do!"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

//ModalVendorLock
export const ModalVendorLock = ({
  onHandleToggleVendorReject,
  onHandleToggleReason,
  vendordatafilter,
}) => {
  const vendorData = vendordatafilter;

  // console.log("modalData:", vendorData);
  return (
    <div className={styles.modalvendordetail}>
      <div
        className={styles.overlay}
        onClick={onHandleToggleVendorReject}
      ></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["vendorauthorize-modal-content"]}>
          <div className="">
            <img src={iceimg} alt="" width={120} />
          </div>
          <div className={styles["vendorauthorize-content-mes"]}>
            <p>You are attempting to Lock the selected vendor.</p>
          </div>
        </div>
        <div className={styles["vendorauthorize-button"]}>
          <div className={styles["vendorauthorize-content-button"]}>
            <button
              className={`--btn ${styles["admin-decline-button"]}`}
              onClick={onHandleToggleVendorReject}
            >
              Back
            </button>
            <button
              className={`--btn --btn-success ${styles["admin-yes-button"]}`}
              onClick={() => onHandleToggleReason(vendorData)}
            >
              Yes, Please do!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

//ModalVendorLockReason
export const ModalVendorLockReason = ({
  onHandleToggle,
  onHandleRejectReason,
  vendorData,
  isLoading,
}) => {
  // console.log("vendorData:", vendorData);
  const [reason, setReason] = useState("");

  const handleReasonChange = (event) => {
    const newReason = event.target.value;
    // console.log("New Reason:", newReason);
    setReason(newReason);
  };
  const handleRejectClick = async (event) => {
    event.preventDefault(); // Prevent form submission
    // console.log("Submitting Reason:", reason); // Check if reason is set

    // Call onHandleRejectReason with the current reason value
    onHandleRejectReason(reason, vendorData.vendorId); // Pass the reason to the parent component's function
  };
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggle}></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["modalvendorreason-content"]}>
          <p>Reason</p>
          <form onSubmit={handleRejectClick}>
            <textarea
              placeholder="why are you rejecting this application?"
              value={reason}
              onChange={handleReasonChange}
            />
            <div className={styles["vendorreason-button"]}>
              <div className={styles["vendorreason-content-button"]}>
                <button
                  className={`--btn --btn-success ${styles["admin-yes-button"]}`}
                  type="submit"
                  disabled={isLoading}
                  style={{
                    cursor: isLoading ? "not-allowed" : "pointer",
                    width: isLoading ? "80px" : "auto",
                  }}
                >
                  {isLoading ? (
                    <>
                      <FaSpinner className={styles["spin"]} />
                    </>
                  ) : (
                    "Lock"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

//ModalCustomerDetail
export const ModalCustomerDetail = ({
  onHandleToggleCustomerDetail,
  customerdatafilter,
}) => {
  const customerData = customerdatafilter;
  // console.log("modalData:", customerData);
  const productdata = [
    {
      id: "1",
      img: "/images/1.png",
      product: "12V 200AH Quanta Amaron Battery - Dry Cell",
      vendor: "Lumos Global",
      quantity: "15",
      price: "NGN 350,000",
      Status: "Approved",
      Date: "03 July 2022 | 22:00:42",
    },
    {
      id: "2",
      img: "/images/1.png",
      product: "5kva Solar Hybrid Inverter System - 8 Batteries",
      vendor: "Lumos Global",
      quantity: "1",
      price: "NGN 350,000",
      Status: "Pending",
      Date: "03 July 2022 | 22:00:42",
    },
  ];
  const singleCustomer = async () => {
    const customerId = customerData.customerId;
    // console.log("CustomerId:", customerId);
    const response = await agent.customer.getACustomer(customerId);
    return response.data;
  };
  const { data: customer } = useQuery("customer", singleCustomer);
  const customerOrder = customer && customer.getOrderResponse;
  // console.log("customerOrder:", customerOrder);
  // console.log("customer:", customer);


  // Define a function to format the price
  function formatPrice(price) {
    // Assuming price is a number
    const formattedPrice = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(price);

    return `${formattedPrice}`;
  }

  return (
    <div className={styles.modalvendordetail}>
      <div
        className={styles.overlay}
        onClick={onHandleToggleCustomerDetail}
      ></div>
      <div className={styles["modalvendordetail-container"]}>
        <div className={styles["vendordetail-modal-content"]}>
          <div className={styles["vendordetail-modal-header"]}>
            <div className="">
              <h3>Customer Detail</h3>
            </div>
            <p onClick={onHandleToggleCustomerDetail}>
              <AiOutlineClose
                color="#8b8b8b"
                size={25}
                style={{ cursor: "pointer" }}
              />{" "}
            </p>
          </div>
          <hr className={styles.line} />
          <div className={styles["customerdetail-item"]}>
            <div className={styles["vendordetail-item-content"]}>
              <div className="">
                <p>Firstname</p>
                <span>{customerData.firstName}</span>
              </div>
              <div className={styles["customer-item-lastname"]}>
                <p>Lastname</p>
                <span>{customerData.lastName}</span>
              </div>
            </div>
            <div className={styles["vendordetail-item-content"]}>
              <div className="">
                <p>Email</p>
                <span>{customerData.email}</span>
              </div>
              <div className={styles["customer-item-Phone"]}>
                <p>Phone Number</p>
                <span>{customerData.phoneNumber}</span>
              </div>
            </div>
          </div>
          <div className={styles["customerinfo-item"]}>
            <div className="">
              <p>Order Information</p>
            </div>
            <div className={styles["customerinfo-table"]}>
              <div className={styles["customerinfo-table-head"]}>
                <div className=""> </div>
                <div className="">Product name </div>
                <div className="">Qty </div>
                <div className="">Amount </div>
                <div className="">Purchase Date </div>
              </div>
              {customerOrder ? (
    // Proceed with your logic here
    customerOrder.slice(0, 4).map((val, key) => (
      <div className={styles["customerinfo-table-content"]} key={val.orderId}>
        <div className="">
          <img src={val.productImage} alt="" width={45} />
        </div>
        <div className={styles["customerinfo-table-product"]}>
          <span>{val.productName} </span>
        </div>
        <div className={styles["customerinfo-table-item"]}>
          <span>{val.totalQuantity} </span>
        </div>
        <div className={styles["customerinfo-table-item"]}>
          <span>{formatPrice(val.amount)} </span>
        </div>
        <div className={styles["customerinfo-table-item"]}>
          <span>{val.dateCreated} </span>
        </div>
      </div>
    ))
  ) : (
    // Handle the case where customer or getOrderResponse is undefined
    <div className={styles["customerinfo-table-content"]}>
      <span>No items in order</span>
    </div>
  )}
            </div>
          </div>
        </div>
        <button
          className={`--btn ${styles["vendoradmin-button"]}`}
          onClick={onHandleToggleCustomerDetail}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

//ModalCustomerBlacklist
export const ModalCustomerBlacklist = ({
  onHandleToggleBlacklist,
  customerdatafilter,
  onHandleToggleReason,
}) => {
  const customerData = customerdatafilter;
  // console.log("modalData:", customerData);
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggleBlacklist}></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["vendorauthorize-modal-content"]}>
          <div className="">
            <img src={iceimg} alt="" width={120} />
          </div>
          <div className={styles["vendorauthorize-content-mes"]}>
            <p>You are attempting to blacklist the selected customer</p>
          </div>
        </div>
        <div className={styles["vendorauthorize-button"]}>
          <div className={styles["vendorauthorize-content-button"]}>
            <button
              className={`--btn ${styles["admin-decline-button"]}`}
              onClick={onHandleToggleBlacklist}
            >
              Decline
            </button>
            <button
              className={`--btn --btn-success ${styles["admin-yes-button"]}`}
              onClick={() => onHandleToggleReason(customerData)}
            >
              Yes, Please do!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

//ModalCustomerBlacklistReason
export const ModalCustomerBlacklistReason = ({
  onHandleToggle,
  onHandleBlacklistedCustomerReason,
  customerdata,
  isLoading,
}) => {
  // console.log("customerdata:", customerdata);
  const [reason, setReason] = useState("");

  const handleReasonChange = (event) => {
    const newReason = event.target.value;
    // console.log("New Reason:", newReason);
    setReason(newReason);
  };
  const handleRejectClick = async (event) => {
    event.preventDefault(); // Prevent form submission
    // console.log("Submitting Reason:", reason); // Check if reason is set

    // Call onHandleRejectReason with the current reason value
    onHandleBlacklistedCustomerReason(reason, customerdata.customerId); // Pass the reason to the parent component's function
  };
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggle}></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["modalvendorreason-content"]}>
          <p>Reason</p>
          <form onSubmit={handleRejectClick}>
            <textarea
              placeholder="why are you Blacklisting this Customer?"
              value={reason}
              onChange={handleReasonChange}
            />
            <div className={styles["vendorreason-button"]}>
              <div className={styles["vendorreason-content-button"]}>
                <button
                  className={`--btn --btn-success ${styles["admin-yes-button"]}`}
                  type="submit"
                  disabled={isLoading}
                  style={{
                    cursor: isLoading ? "not-allowed" : "pointer",
                    width: isLoading ? "80px" : "auto",
                  }}
                >
                  {isLoading ? (
                    <>
                      <FaSpinner className={styles["spin"]} />
                    </>
                  ) : (
                    "Blacklist"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

//ModalCustomerUnblacklist
export const ModalCustomerUnblacklist = ({
  onHandleToggleBlacklist,
  customerdatafilter,
  onHandleUnblacklistedCustomer,
  isLoading,
}) => {
  const customerData = customerdatafilter;
  // console.log("modalData:", customerData);
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggleBlacklist}></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["vendorauthorize-modal-content"]}>
          <div className="">
            <img src={iceimg} alt="" width={120} />
          </div>
          <div className={styles["vendorauthorize-content-mes"]}>
            <p>You are attempting to unblacklist the selected customer</p>
          </div>
        </div>
        <div className={styles["vendorauthorize-button"]}>
          <div className={styles["vendorauthorize-content-button"]}>
            <button
              className={`--btn ${styles["admin-decline-button"]}`}
              onClick={onHandleToggleBlacklist}
            >
              Decline
            </button>
            <button
              className={`--btn --btn-success ${styles["admin-yes-button"]}`}
              onClick={() => onHandleUnblacklistedCustomer(customerData)}
              disabled={isLoading}
              style={{
                cursor: isLoading ? "not-allowed" : "pointer",
                width: isLoading ? "80px" : "auto",
              }}
            >
              {isLoading ? (
                <>
                  <FaSpinner className={styles["spin"]} />
                </>
              ) : (
                "Yes, Please do!"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

//ModalProdutListAuthorize
export const ModalProdutListAuthorize = ({
  onHandleToggle,
  onHandleApproveProduct,
  productdatafilter,
  isLoading,
}) => {
  const productData = productdatafilter;
  // console.log("modalData:", productData);
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggle}></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["vendorauthorize-modal-content"]}>
          <div className="">
            <img src={tomsimg} alt="" width={120} />
          </div>
          <div className={styles["vendorauthorize-content-mes"]}>
            <p>You are attempting to approve the selected product.</p>
          </div>
        </div>
        <div className={styles["vendorauthorize-button"]}>
          <div className={styles["vendorauthorize-content-button"]}>
            <button
              className={`--btn ${styles["admin-decline-button"]}`}
              onClick={onHandleToggle}
            >
              Decline
            </button>
            <button
              className={`--btn --btn-success ${styles["admin-yes-button"]}`}
              onClick={() => onHandleApproveProduct(productData)}
              disabled={isLoading}
              style={{
                cursor: isLoading ? "not-allowed" : "pointer",
                width: isLoading ? "80px" : "auto",
              }}
            >
              {isLoading ? (
                <>
                  <FaSpinner className={styles["spin"]} />
                </>
              ) : (
                "Yes, Please do!"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
//ModalProdutListReject
export const ModalProdutListReject = ({
  onHandleToggleProdutListReject,
  onHandleToggleReason,
  productdatafilter,
}) => {
  const productData = productdatafilter;

  // console.log("modalData:", productData);
  return (
    <div className={styles.modalvendordetail}>
      <div
        className={styles.overlay}
        onClick={onHandleToggleProdutListReject}
      ></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["vendorauthorize-modal-content"]}>
          <div className="">
            <img src={iceimg} alt="" width={120} />
          </div>
          <div className={styles["vendorauthorize-content-mes"]}>
            <p>You are attempting to reject the selected product.</p>
          </div>
        </div>
        <div className={styles["vendorauthorize-button"]}>
          <div className={styles["vendorauthorize-content-button"]}>
            <button
              className={`--btn ${styles["admin-decline-button"]}`}
              onClick={onHandleToggleProdutListReject}
            >
              Decline
            </button>
            <button
              className={`--btn --btn-success ${styles["admin-yes-button"]}`}
              onClick={() => onHandleToggleReason(productData)}
            >
              Yes, Please do!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
//ModalProdutListReason
export const ModalProdutListReason = ({
  onHandleToggle,
  onHandleRejectReason,
  productData,
  isLoading,
}) => {
  // console.log("productData:", productData);
  const [reason, setReason] = useState("");

  const handleReasonChange = (event) => {
    const newReason = event.target.value;
    // console.log("New Reason:", newReason);
    setReason(newReason);
  };
  const handleRejectClick = async (event) => {
    event.preventDefault(); // Prevent form submission
    // console.log("Submitting Reason:", reason); // Check if reason is set

    // Call onHandleRejectReason with the current reason value
    onHandleRejectReason(reason, productData.productId); // Pass the reason to the parent component's function
  };

  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggle}></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["modalvendorreason-content"]}>
          <p>Reason</p>
          <form onSubmit={handleRejectClick}>
            <textarea
              placeholder="why are you rejecting this application?"
              value={reason}
              onChange={handleReasonChange}
            />
            <div className={styles["vendorreason-button"]}>
              <div className={styles["vendorreason-content-button"]}>
                <button
                  className={`--btn --btn-success ${styles["admin-yes-button"]}`}
                  type="submit"
                  disabled={isLoading}
                  style={{
                    cursor: isLoading ? "not-allowed" : "pointer",
                    width: isLoading ? "80px" : "auto",
                  }}
                >
                  {isLoading ? (
                    <>
                      <FaSpinner className={styles["spin"]} />
                    </>
                  ) : (
                    "Decline"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

//ModalProdutUpload
export const ModalProdutUpload = ({ onHandleToggleProdutUpload }) => {
  const [formData, setFormData] = useState([
    {
      productName: "",
      vendorId: "",
      brandId: "",
      categoryId: "",
      vendorId: "",
      productPrimaryImageUrl: "",
      multipleProductImageUrl: [],
      productSpecification: "",
      installationFee: 0,
      buyerNote: "",
      weight: "",
      slug: "",
      shortDescription: "",
      longDescription: "",
      productCode: "",
      monthlyFinancing: 0,
      productQuantity: "",
      productEnabled: true,
      isWarranty: true,
      productCurrentPrice: "",
      productPreviousPrice: "",
      applyVat: true,
      isDeliveryCarryOut: true,
    },
  ]);
  // console.log("formData:", formData);
  const [close, setClose] = useState(false);

  const [fileDetailsList, setFileDetailsList] = useState([]);
  // console.log("fileDetailsList", fileDetailsList);
  const [excelFileError, setExcelFileError] = useState("");
  const closeProgress = () => {
    setClose(true);
  };
  const saveMultiForm = async () => {
    // console.log("formData:", formData);
    const response = await agent.product.addMultipleProducts(formData);
    return response;
  };
  const queryClient = useQueryClient();
  const { mutate: MultiProductData, isLoading } = useMutation(saveMultiForm, {
    // On success, invalidate the 'products' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("products");
      if (data.responseCode === "99") {
        // console.log("Error creating Multiple Product:", data.responseMessage);
        toast.error(data.responseMessage);
        onHandleToggleProdutUpload();
      } else {
        // console.log("Multiple Product created successfully");
        toast.success("Multiple Product created successfully!");
        onHandleToggleProdutUpload();
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error creating Multiple Product:", error);
      toast.error("Error creating Multiple Product . Please try again.");
    },
  });

  const handleAddMultiProduct = async () => {
    MultiProductData(formData);
  };

  const { brands } = useSelector((state) => state.brand);
  const { categories } = useSelector((state) => state.category);
  const { vendors } = useSelector((state) => state.vendor);

  // console.log("brands", brands);
  // console.log("categories", categories);
  // console.log("vendors", vendors);

  const getUnits = (bytes) => {
    const sizes = ["bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
  };
  return (
    <div className={styles.modalvendordetail}>
      <div
        className={styles.overlay}
        onClick={onHandleToggleProdutUpload}
      ></div>
      <div className={styles["modalbulkUpload-container"]}>
        <div className={styles["vendordetail-modal-header"]}>
          <div className={styles["bulkUpload-modal-subhead"]}>
            <h3>File Upload</h3>
          </div>
          <p onClick={onHandleToggleProdutUpload}>
            <AiOutlineClose
              size={20}
              color="#8b8b8b"
              style={{ cursor: "pointer" }}
            />
          </p>
        </div>
        <div className={styles["modalbulkUpload-modal-content"]}>
          <div className={styles["modalbulkUpload-filebox"]}>
            <div className="">
              <VscFilePdf size={40} />
            </div>
            <div className={styles["modalbulkUpload-filebox-mes"]}>
              <ProductBulkUploadInputs
                setExcelFileError={setExcelFileError}
                excelFileError={excelFileError}
                brandsList={brands ? brands : []}
                vendorsList={vendors ? vendors : []}
                categoryList={categories ? categories : []}
                fileDetailsList={fileDetailsList}
                setFileDetailsList={setFileDetailsList}
                setFormData={setFormData}
              ></ProductBulkUploadInputs>
            </div>
          </div>
          {fileDetailsList.length > 0 ? (
            // Conditionally render this content when fileDetailsList has items
            <>
              <span style={{ color: "red" }}>{excelFileError}</span>

              {/* The rest of your code that should be displayed when fileDetailsList has items */}
            </>
          ) : (
            // Render something else when fileDetailsList is empty
            // For example, you can display an instruction to upload a file
            <p>Upload a file to get started.</p>
          )}
          <div className={styles["modalprodutUpload-sample-file"]}>
            {/* Place the CSVLink component here */}
            <CSVLink
              filename={"MulitpleProductTemplate.csv"}
              data={csvData}
              className={styles.sterlinks}
            >
              Download sample file
            </CSVLink>
          </div>
          {fileDetailsList.length > 0
            ? fileDetailsList.map((fileDetails, index) => {
                return (
                  <>
                    <div
                      className={
                        close
                          ? styles["hidden"]
                          : styles["modalbulkUpload-content-mes"]
                      }
                    >
                      <div className="">
                        <RiFileList2Fill size={40} color="#32c36c" />
                      </div>
                      <div className="">
                        <div className="">
                          <p>{fileDetails.name}</p>
                        </div>
                        <BorderLinearProgress
                          variant="determinate"
                          value={100}
                          valueBuffer={95}
                        />
                        <div
                          className={styles["modalbulkUpload-content-progress"]}
                        >
                          <p>{getUnits(fileDetails.size)}</p>
                          <p>100%</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        close
                          ? styles["hidden"]
                          : styles["modalprodutUpload-content-progress-close"]
                      }
                    >
                      <AiOutlineClose
                        size={13}
                        style={{ cursor: "pointer" }}
                        onClick={closeProgress}
                      />
                    </div>
                  </>
                );
              })
            : null}

          <button
            className={`--btn --btn-success ${styles["Upload-button"]}`}
            onClick={() => handleAddMultiProduct()}
            disabled={isLoading}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              width: isLoading ? "80px" : "auto",
            }}
          >
            {isLoading ? (
              <>
                <FaSpinner className={styles["spin"]} />
              </>
            ) : (
              " Upload"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

//ModalOrderDetail
export const ModalOrderDetail = ({
  onHandleToggleOrderDetail,
  orderdatafilter,
  paginationModel,
}) => {
  const productdata = [
    {
      id: "1",
      img: "/images/1.png",
      product: "12V 200AH Quanta Amaron Battery - Dry Cell",
      vendor: "Lumos Global",
      quantity: "15",
      price: "NGN 350,000",
      Status: "Approved",
      Date: "03 July 2022 | 22:00:42",
    },
    {
      id: "2",
      img: "/images/1.png",
      product: "5kva Solar Hybrid Inverter System - 8 Batteries",
      vendor: "Lumos Global",
      quantity: "1",
      price: "NGN 350,000",
      Status: "Pending",
      Date: "03 July 2022 | 22:00:42",
    },
  ];
  // console.log("orderdatafilter:", orderdatafilter);
  const { products } = useProducts(paginationModel);
  const hasOrderItems =
    Array.isArray(orderdatafilter.orderItems) &&
    orderdatafilter.orderItems.length > 0;
  // Define a function to format the price
  function formatPrice(price) {
    // Assuming price is a number
    const formattedPrice = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(price);

    return `${formattedPrice}`;
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  };
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggleOrderDetail}></div>
      <div className={styles["modalvendordetail-container"]}>
        <div className={styles["vendordetail-modal-content"]}>
          <div className={styles["vendordetail-modal-header"]}>
            <div className="">
              <h3>Order Detail</h3>
            </div>
            <p onClick={onHandleToggleOrderDetail}>
              <AiOutlineClose
                color="#8b8b8b"
                size={25}
                style={{ cursor: "pointer" }}
              />{" "}
            </p>
          </div>
          <hr className={styles.line} />
          <div className={styles["orderdetail-item"]}>
            <div className={styles["vendordetail-item-content"]}>
              <div className="">
                <p>Customer Name</p>
                <span>
                  {orderdatafilter.customerFirstName}{" "}
                  {orderdatafilter.customerLastName}
                </span>
              </div>
              <div className={styles["order-item-email"]}>
                <p>Email</p>
                <span>{orderdatafilter.customer.email}</span>
              </div>
            </div>
            <div className={styles["vendordetail-item-content"]}>
              <div className="">
                <p>Phone Number</p>
                <span>{orderdatafilter.customerPhoneNumber}</span>
              </div>
              <div className={styles["order-item-address"]}>
                <p>Address</p>
                <span>{orderdatafilter.address}</span>
              </div>
              <div className={styles["order-item-address"]}>
                <p>Payment Mode</p>
                <div className={styles.modelogo}>
                <span className={styles["order-item-logo"]}>{orderdatafilter.paymentMethod === "Pay Now" ?  <LuCreditCard /> : <img src={fi} alt="" /> }</span>
                <span>{orderdatafilter.paymentMethod === "Pay Now" ?  "Card" : "Financing" }</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles["customerinfo-item"]}>
            <div className="">
              <p>Order Information</p>
            </div>
            <div className={styles["customerinfo-table"]}>
              <div className={styles["customerinfo-table-head"]}>
                <div className=""> </div>
                <div className="">Product name </div>
                <div className="">Qty </div>
                <div className="">Amount </div>
                <div className="">Purchase Date </div>
              </div>

              {hasOrderItems ? (
                orderdatafilter.orderItems.map((val, key) => {
                  // Check if products is available and not undefined
                  return (
                    <div
                      className={styles["customerinfo-table-content"]}
                      key={val.orderItemId}
                    >
                      <div className="">
                        <img 
                        src={val.productImageUrl} 
                        alt="" width={45} />
                      </div>
                      <div className={styles["customerinfo-table-product"]}>
                        <span>
                          {val.productName} 
                          </span>
                      </div>
                      <div className={styles["customerinfo-table-item"]}>
                        <span>{val.productQuatity} </span>
                      </div>
                      <div className={styles["customerinfo-table-item"]}>
                        <span>{formatPrice(val.totalPrice)} </span>
                      </div>
                      <div className={styles["customerinfo-table-item"]}>
                        <span>{formatDate( val.createdAt)} </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                // Render a message if orderItems is empty
                <div className={styles["customerinfo-table-content"]}>
                  <span>No items in order</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <button
          className={`--btn ${styles["vendoradmin-button"]}`}
          onClick={onHandleToggleOrderDetail}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

//ModalOrderCancelReason
export const ModalOrderCancelReason = ({
  onHandleToggle,
  onHandleRejectReason,
  OrderData,
  isLoading,
}) => {
  // console.log("vendorData:", vendorData);
  const [reason, setReason] = useState("");

  const handleReasonChange = (event) => {
    const newReason = event.target.value;
    // console.log("New Reason:", newReason);
    setReason(newReason);
  };
  const handleRejectClick = async (event) => {
    event.preventDefault(); // Prevent form submission
    // console.log("Submitting Reason:", reason); // Check if reason is set

    // Call onHandleRejectReason with the current reason value
    onHandleRejectReason(reason, OrderData.orderId); // Pass the reason to the parent component's function
  };
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggle}></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["modalvendorreason-content"]}>
          <p>Kindly specify reason for rejection</p>
          <form onSubmit={handleRejectClick}>
            <textarea
              placeholder="why are you rejecting this Order?"
              value={reason}
              onChange={handleReasonChange}
            />
            <div className={styles["vendorreason-button"]}>
              <div className={styles["vendorreason-content-button"]}>
                <button
                  className={`--btn --btn-success ${styles["admin-yes-button"]}`}
                  type="submit"
                  disabled={isLoading}
                  style={{
                    cursor: isLoading ? "not-allowed" : "pointer",
                    width: isLoading ? "80px" : "auto",
                  }}
                >
                  {isLoading ? (
                    <>
                      <FaSpinner className={styles["spin"]} />
                    </>
                  ) : (
                    "Decline"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

//ModalBrandDetail
export const ModalBrandDetail = ({
  onHandleToggleBrandDetail,
  onHandleToggleEditBrandDetail,
  branddata,
}) => {
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggleBrandDetail}></div>
      <div className={styles["modalbranddetail-container"]}>
        <div className={styles["vendordetail-modal-content"]}>
          <div className={styles["vendordetail-modal-header"]}>
            <div className="">
              <h3>Brand Detail</h3>
            </div>
            <p onClick={onHandleToggleBrandDetail}>
              <AiOutlineClose
                color="#8b8b8b"
                size={25}
                style={{ cursor: "pointer" }}
              />{" "}
            </p>
          </div>
          <hr className={styles.line} />
          <div className={styles["branddetail-modal-field"]}>
            <div className={styles["branddetail-modal-field-item1"]}>
              <p>Brand Name</p>
              <h3>{branddata.brandName}</h3>
            </div>
            <div className={styles["branddetail-modal-field-item"]}>
              <p>Brand Logo</p>
              <img
                src={branddata.image}
                alt=""
                width={40}
                className={styles["branddetail-modal-field-item-img"]}
              />
            </div>
            <div className={styles["branddetail-modal-field-item"]}>
              <p>Date of Creation</p>
              <span>03 July 2022 | 22:00:42</span>
            </div>
            <div className={styles["branddetail-modal-field-item"]}>
              <p>Status</p>
              <p className={styles["branddetail-modal-field-item-Approved"]}>
                Approved
              </p>
            </div>
          </div>
        </div>
        <div className={styles["brand-button"]}>
          <button
            className={`--btn ${styles["brandedit-button"]}`}
            onClick={() => onHandleToggleEditBrandDetail(branddata)}
          >
            Edit Brand
          </button>
          <button
            className={`--btn ${styles["vendoradmin-button"]}`}
            onClick={onHandleToggleBrandDetail}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

//ModalEditBrandDetail
export const ModalEditBrandDetail = ({
  onHandleToggleEditBrandDetail,
  updateBrand,
  rowData,
}) => {
  const [formData, setFormData] = useState({
    brandId: rowData?.brandId || "",
    brandName: rowData?.brandName || "",
    image: rowData?.image || "",
    status: "Active",
  });

  // console.log("FormData to be sent:", formData);
  const imageInputRef = useRef(null);
  const handleImageUpload = (file) => {
    if (file) {
      const reader = new FileReader();

      // Define what should happen when the file reading is successful
      reader.onload = () => {
        // Set the result of the file reading (data URL) as the image property
        setFormData({ ...formData, image: reader.result });
      };

      // Define what should happen if there's an error during file reading
      reader.onerror = (error) => {
        // console.error("Error reading the file: ", error);
      };

      // Start reading the file as a data URL
      reader.readAsDataURL(file);
    }
  };
  const queryClient = useQueryClient();
  const { mutate: updateBrandData, isLoading } = useMutation(updateBrand, {
    // On success, invalidate the 'brands' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("brands");
      if (data.responseCode === "99") {
        // console.log("Error updating brand:", data.responseMessage);
        toast.error(data.responseMessage);
        onHandleToggleEditBrandDetail();
      } else {
        // console.log("Brand updated successfully.");
        toast.success("Brand updated successfully.");
        onHandleToggleEditBrandDetail();
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error updating brand:", error);
      toast.error("Error occurred while updating the brand.");
    },
  });
  const handleUpdate = (e) => {
    e.preventDefault();
    updateBrandData({
      brandId: formData.brandId,
      brandName: formData.brandName,
      image: formData.image,
      status: formData.status,
    });
  };

  return (
    <div className={styles.modalvendordetail}>
      <div
        className={styles.overlay}
        onClick={onHandleToggleEditBrandDetail}
      ></div>
      <div className={styles["modalbranddetail-container"]}>
        <div className={styles["vendordetail-modal-content"]}>
          <div className={styles["vendordetail-modal-header"]}>
            <div className="">
              <h3>Brand Detail</h3>
            </div>
            <p onClick={onHandleToggleEditBrandDetail}>
              <AiOutlineClose
                color="#8b8b8b"
                size={25}
                style={{ cursor: "pointer" }}
              />
            </p>
          </div>
          <hr className={styles.line} />
          <div className={styles["branddetail-modal-field"]}>
            <div className={styles["branddetail-modal-field-item"]}>
              <p className={styles["branddetail-modal-field-item-name"]}>
                Brand Name
              </p>
              <input
                type="text"
                name="brandName"
                value={formData.brandName}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    brandName: e.target.value,
                  })
                }
              />
            </div>
            <div className={styles["branddetail-modal-field-item"]}>
              <p className={styles["branddetail-modal-field-item-logo"]}>
                Brand Logo
              </p>
              <div className={styles["branddetail-modal-field-item-img-link"]}>
                <label
                  htmlFor="imageInput"
                  className={styles["image-upload-label"]}
                >
                  <img
                    src={formData.image || uploadimg}
                    alt="Selected img"
                    className=""
                    width={40}
                  />
                  <div className={styles["branddetail-modal-field-item-link"]}>
                    <span>Change Image</span>
                    <input
                      id="imageInput"
                      type="file"
                      accept="image/*"
                      ref={imageInputRef}
                      style={{ display: "none" }}
                      onChange={(e) => handleImageUpload(e.target.files[0])}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className={styles["branddetail-modal-field-item"]}>
              <p>Date of Creation</p>
              <span>03 July 2022 | 22:00:42</span>
            </div>
            <div className={styles["branddetail-modal-field-item"]}>
              <p>Status</p>
              <p className={styles["branddetail-modal-field-item-Approved"]}>
                Approved
              </p>
            </div>
          </div>
        </div>
        <div className={styles["brand-button"]}>
          <button
            className={`--btn ${styles["brandcancel-button"]}`}
            onClick={onHandleToggleEditBrandDetail}
          >
            Cancel
          </button>
          <button
            className={`--btn ${styles["brandsave-button"]}`}
            onClick={handleUpdate}
            disabled={isLoading}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              width: isLoading ? "80px" : "auto",
            }}
          >
            {isLoading ? (
              <>
                <FaSpinner className={styles["spin"]} />
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

//ModalADDBrandDetail
export const ModalFullEditBrandDetail = ({
  onHandleToggleAddBrandDetail,
  brandCreation,
  imageLink,
}) => {
  const [formData, setFormData] = useState({
    brandName: "",
    Image: "",
    fileType: "jpeg",
  });
  const [imageData, setImageData] = useState({
    postedFilebase64: "",
  });
  const [imageD, setImageD] = useState({
    postedFilebase64: "",
  });
  // console.log("FormData to be sent:", formData);
  // console.log("imageData to be sent:", imageData);
  const imageInputRef = useRef(null);
  const isFormDataNotEmpty = (formData) => {
    // Implement your own logic to check if formData is not empty
    return formData.brandName.trim() !== "" && formData.Image.trim() !== "";
  };
  const handleImageUpload = (file) => {
    if (file) {
      const reader = new FileReader();

      // Define what should happen when the file reading is successful
      reader.onload = () => {
        setImageD({ ...imageD, postedFilebase64: reader.result });
        // Convert the binary data to base64
        // const base64String = btoa(reader.result);
        const base64String = reader.result.split(',')[1];
        // console.log("base64String",base64String);
        // Set the base64 string as the image property
        setImageData({ ...imageData, postedFilebase64: base64String });
      };

      // Define what should happen if there's an error during file reading
      reader.onerror = (error) => {
        // console.error("Error reading the file: ", error);
      };

      // Start reading the file as a binary data
      reader.readAsDataURL(file);
    }
  };
  // Set default values for fileType  when empty
  // if (formData.fileType === "") {
  //   formData.fileType = "string";
  // }
  const queryClient = useQueryClient();
  const { mutate: imageLinkData } = useMutation(imageLink, {
    // On success, invalidate the 'brands' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("brands");
      if (data.responseCode === "99") {
        // console.log("Error adding brand:", data.responseMessage);
        toast.error(data.responseMessage);
      } else {
        // Update formData with the uploaded image data
        // console.log("data",data);
        const updatedFormData = { ...formData, Image: data.data };

        // Check if the updatedFormData is not empty before triggering brandCreationData
        if (isFormDataNotEmpty(updatedFormData)) {
          setFormData(updatedFormData);

          // Trigger the second mutation (brandCreation) after the image is uploaded
          brandCreationData(updatedFormData);
        } else {
          // Handle the case where updatedFormData is empty
          console.error("Updated formData is empty.");
        }

        // Trigger the second mutation (brandCreation) after the image is uploaded
        // brandCreationData(formData);
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error adding brand:", error);
      toast.error("Error occurred while adding the brand.");
    },
  });
  const { mutate: brandCreationData, isLoading } = useMutation(brandCreation, {
    // On success, invalidate the 'brands' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("brands");
      if (data.responseCode === "99") {
        // console.log("Error adding brand:", data.responseMessage);
        // console.log("data",data);
        toast.error(data.responseMessage);
        onHandleToggleAddBrandDetail();
      } else {
        // console.log("data",data);
        // console.log("Brand Added successfully");
        toast.success("Brand Added successfully.");
        onHandleToggleAddBrandDetail();
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error adding brand:", error);
      toast.error("Error occurred while adding the brand.");
    },
  });
  const handleAddBrand = (e) => {
    e.preventDefault();
    imageLinkData(imageData);
  };

  return (
    <div className={styles.modalvendordetail}>
      <div
        className={styles.overlay}
        onClick={onHandleToggleAddBrandDetail}
      ></div>
      <div className={styles["fulleditbranddetail-container"]}>
        <div className={styles["vendordetail-modal-content"]}>
          <div className={styles["vendordetail-modal-header"]}>
            <div className="">
              <h3>Brand Detail</h3>
            </div>
            <p onClick={onHandleToggleAddBrandDetail}>
              <AiOutlineClose
                color="#8b8b8b"
                size={25}
                style={{ cursor: "pointer" }}
              />
            </p>
          </div>
          <hr className={styles.line} />
          <div className={styles["branddetail-modal-field"]}>
            <div className={styles["branddetail-modal-field-item"]}>
              <p className={styles["branddetail-modal-field-item-name"]}>
                Brand Name
              </p>
              <input
                type="text"
                name="brandName"
                value={formData.brandName}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    brandName: e.target.value,
                  })
                }
                placeholder="Chrisco"
              />
            </div>
            <div className={styles["branddetail-modal-field-item"]}>
              <p className={styles["branddetail-modal-field-item-logo"]}>
                Brand Logo
              </p>
              <div
                className={
                  styles["fulleditbranddetail-modal-field-item-img-link"]
                }
              >
                <label
                  htmlFor="imageInput"
                  className={styles["image-upload-labe"]}
                >
                  <img
                    src={imageD.postedFilebase64 || uploadimg}
                    alt="Selected img"
                    width={82}
                    className=""
                  />
                  <div
                    className={
                      styles["fulleditbranddetail-modal-field-item-link"]
                    }
                  >
                    <span>Upload Image</span>
                    <input
                      id="imageInput"
                      type="file"
                      accept="image/*"
                      ref={imageInputRef}
                      style={{ display: "none" }}
                      onChange={(e) => handleImageUpload(e.target.files[0])}
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className={styles["brand-button"]}>
          <button
            className={`--btn ${styles["brandcancel-button"]}`}
            onClick={onHandleToggleAddBrandDetail}
          >
            Cancel
          </button>
          <button
            className={`--btn ${styles["brandsave-button"]}`}
            onClick={handleAddBrand}
            disabled={isLoading}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              width: isLoading ? "80px" : "auto",
            }}
          >
            {isLoading ? (
              <>
                <FaSpinner className={styles["spin"]} />
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

//ModalBulkUpload
export const ModalBulkUpload = ({ onHandleToggleBulkUpload }) => {
  const [close, setClose] = useState(false);
  const [formData, setFormData] = useState([
    {
      BrandName: "",
      Image: "",
      FileType: "jpeg",
    },
  ]);
  console.log("formData:", formData);
  const closeProgress = () => {
    setClose(true);
  };
  const [excelFileError, setExcelFileError] = useState("");
  const [fileDetailsList, setFileDetailsList] = useState([]);
  // console.log("fileDetailsList", fileDetailsList);
  const getUnits = (bytes) => {
    const sizes = ["bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
  };
  const saveMultiForm = async () => {
    // console.log("formData:", formData);
    const response = await agent.brand.addMultipleBrand(formData);
    return response;
  };
  const queryClient = useQueryClient();
  const { mutate: MultiBrandData, isLoading } = useMutation(saveMultiForm, {
    // On success, invalidate the 'Brands' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("brands");
      if (data.responseCode === "99") {
        // console.log("Error creating Multiple Brand:", data.responseMessage);
        toast.error(data.responseMessage);
        onHandleToggleBulkUpload();
      } else {
        // console.log("Multiple Brand created successfully");
        toast.success("Multiple Brand created successfully!");
        onHandleToggleBulkUpload();
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error creating Multiple Brand:", error);
      toast.error("Error creating Multiple Brand . Please try again.");
    },
  });

  const handleAddMultiBrand = async () => {
    MultiBrandData(formData);
  };

  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggleBulkUpload}></div>
      <div className={styles["modalbulkUpload-container"]}>
        <div className={styles["vendordetail-modal-header"]}>
          <div className={styles["bulkUpload-modal-subhead"]}>
            <h3>File Upload</h3>
          </div>
          <p onClick={onHandleToggleBulkUpload}>
            <AiOutlineClose size={40} style={{ cursor: "pointer" }} />
          </p>
        </div>
        <div className={styles["modalbulkUpload-modal-content"]}>
          <div className={styles["modalbulkUpload-filebox"]}>
            <div className="">
              <VscFilePdf size={40} />
            </div>
            <div className={styles["modalbulkUpload-filebox-mes"]}>
              <BrandBulkUploadinputs
                setExcelFileError={setExcelFileError}
                setFileDetailsList={setFileDetailsList}
                setFormData={setFormData}
              />
            </div>
          </div>
          {fileDetailsList.length > 0 ? (
            // Conditionally render this content when fileDetailsList has items
            <>
              <span style={{ color: "red" }}>{excelFileError}</span>

              {/* The rest of your code that should be displayed when fileDetailsList has items */}
            </>
          ) : (
            // Render something else when fileDetailsList is empty
            // For example, you can display an instruction to upload a file
            <p>Upload a file to get started.</p>
          )}
          <div className={styles["modalprodutUpload-sample-file"]}>
            {/* Place the CSVLink component here */}
            <CSVLink
              filename={"MulitpleBrandTemplate.csv"}
              data={brandCsvData}
              className={styles.sterlinks}
            >
              Download sample file
            </CSVLink>
          </div>
          {fileDetailsList.length > 0
            ? fileDetailsList.map((fileDetails, index) => {
                return (
                  <>
                    <div
                      className={
                        close
                          ? styles["hidden"]
                          : styles["modalbulkUpload-content-mes"]
                      }
                    >
                      <div className="">
                        <RiFileList2Fill size={40} color="#32c36c" />
                      </div>
                      <div className="">
                        <div className="">
                          <p>{fileDetails.name}</p>
                        </div>
                        <BorderLinearProgress
                          variant="determinate"
                          value={100}
                          valueBuffer={95}
                        />
                        <div
                          className={styles["modalbulkUpload-content-progress"]}
                        >
                          <p>{getUnits(fileDetails.size)}</p>
                          <p> 100%</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        close
                          ? styles["hidden"]
                          : styles["modalprodutUpload-content-progress-close"]
                      }
                    >
                      <AiOutlineClose
                        size={13}
                        style={{ cursor: "pointer" }}
                        onClick={closeProgress}
                      />
                    </div>
                  </>
                );
              })
            : null}

          <button
            className={`--btn --btn-success ${styles["Upload-button"]}`}
            onClick={() => handleAddMultiBrand()}
            disabled={isLoading}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              width: isLoading ? "80px" : "auto",
            }}
          >
            {isLoading ? (
              <>
                <FaSpinner className={styles["spin"]} />
              </>
            ) : (
              " Upload"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

//ModalImageUpload
export const ModalImageUpload = ({
  onHandleToggleImageUpload,
  onImageSelected,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageSelect = (event) => {
    const selectedImage = event.target.files[0];
    setSelectedImage(selectedImage);
  };

  const handleUpload = () => {
    if (selectedImage) {
      onImageSelected(selectedImage); // Pass the selected image to the parent component
      onHandleToggleImageUpload();
    } else {
      // Handle the case where no image is selected
      // You can show an error message or perform other actions
    }
  };

  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggleImageUpload}></div>
      <div className={styles["modalbulkUpload-container"]}>
        <div className={styles["vendordetail-modal-header"]}>
          <div className={styles["bulkUpload-modal-subhead"]}>
            <h3>Image Upload</h3>
          </div>
          <p onClick={onHandleToggleImageUpload}>
            <AiOutlineClose size={40} style={{ cursor: "pointer" }} />
          </p>
        </div>
        <div className={styles["modalbulkUpload-modal-content"]}>
          <div className={styles["modalbulkUpload-filebox"]}>
            <div className="">
              <VscFilePdf size={40} />
            </div>
            <div className={styles["modalbulkUpload-filebox-mes"]}>
              <p>
                Drag and drop or <span>browse</span> your files
              </p>
            </div>
          </div>

          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageSelect}
          />

          <label
            htmlFor="imageInput"
            className={`--btn --btn-success ${styles["Upload-button"]}`}
          >
            Upload
          </label>
          <input
            id="imageInput"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleUpload}
          />
        </div>
      </div>
    </div>
  );
};

//ModalLog
export const ModalLog = ({ onHandleToggleLog, logdatafilter }) => {
  const logData = logdatafilter;
  // console.log("modalData:", logData);
  // Split date from time
  const dateParts = logData.Date.split(" | ");
  const formattedDate = dateParts.length > 0 ? dateParts[0] : "";
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggleLog}></div>
      <div className={styles["modallog-container"]}>
        <div className={styles["vendordetail-modal-content"]}>
          <div className={styles["vendordetail-modal-header"]}>
            <div className="">
              <h3>Logs</h3>
            </div>
            <p onClick={onHandleToggleLog}>
              <AiOutlineClose
                color="#8b8b8b"
                size={25}
                style={{ cursor: "pointer" }}
              />
            </p>
          </div>
          <hr className={styles.line} />
          <div className={styles["log-body"]}>
            <div className={styles["log-name-date"]}>
              <div className="">
                <span>Full name</span>
                <h3>{logData.name}</h3>
              </div>
              <div className="">
                <span>Date</span>
                <p>{formattedDate} </p>
              </div>
            </div>
            <div className={styles["log-narration"]}>
              <span>Narration</span>
              <p>{logData.narration}</p>
              <button
                className={`--btn  ${styles["log-button"]}`}
                onClick={onHandleToggleLog}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// ModalViewImg
export const ModalViewImg = ({ onHandleToggleViewImg, images }) => {
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggleViewImg}></div>
      <div className={styles["viewImg-container"]}>
        {images.map((image, key) => {
          const { ticketImagesId, ticketImageUrl } = image; // Destructure the ticketImagesId
          return (
            <div key={ticketImagesId} className={styles["viewImg-img"]}>
              <img src={ticketImageUrl} alt="" width={850} />
            </div>
          );
        })}
        <button
          className={`--btn  ${styles["viewImg-button"]}`}
          onClick={onHandleToggleViewImg}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

// ModalViewticketAuthorize
export const ModalViewticketAuthorize = ({ onHandleToggleViewAuthorize }) => {
  return (
    <div className={styles.modalvendordetail}>
      <div
        className={styles.overlay}
        onClick={onHandleToggleViewAuthorize}
      ></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["vendorauthorize-modal-content"]}>
          <div className="">
            <img src={tomsimg} alt="" width={120} />
          </div>
          <div className={styles["vendorauthorize-content-mes"]}>
            <p>You are attempting to resolve the opened ticket</p>
          </div>
        </div>
        <div className={styles["vendorauthorize-button"]}>
          <div className={styles["vendorauthorize-content-button"]}>
            <button
              className={`--btn ${styles["admin-decline-button"]}`}
              onClick={onHandleToggleViewAuthorize}
            >
              No, don't
            </button>
            <button
              className={`--btn --btn-success ${styles["admin-yes-button"]}`}
              onClick={onHandleToggleViewAuthorize}
            >
              Yes, Please do!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

//ModalAddcategory
export const ModalAddcategory = ({ onHandleToggle }) => {
  const [formData, setFormData] = useState({
    categoryName: "",
    banner: "",
    brandHeaderImage: "",
    commision: "",
  });
  const categorycreation = async (formData) => {
    // console.log("formData:", formData);
    const response = await agent.category.categoryCreation(formData);
    return response;
  };
  const queryClient = useQueryClient();
  const { mutate: createCategoryData, isLoading } = useMutation(
    categorycreation,
    {
      // On success, invalidate the 'categories' query to refetch the data
      onSuccess: (data) => {
        queryClient.invalidateQueries("categories");
        if (data.responseCode === "99") {
          // console.log("Error creating category:", data.responseMessage);
          toast.error(data.responseMessage);
          onHandleToggle();
        } else {
          // console.log("category created successfully");
          toast.success("category created successfully!");
          onHandleToggle();
        }
      },
      // On error, show an error toast
      onError: (error) => {
        // console.error("Error creating category:", error);
        toast.error("Error creating category . Please try again.");
      },
    }
  );
  const imageInputRef = useRef(null);
  const handleImageUpload = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        setFormData({ ...formData, banner: reader.result });
      };

      reader.onerror = (error) => {
        // console.error("Error reading the file: ", error);
      };
    }
  };
  const handleImageUpload2 = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        setFormData({ ...formData, brandHeaderImage: reader.result });
      };

      reader.onerror = (error) => {
        // console.error("Error reading the file: ", error);
      };
    }
  };
  const handleAddCategory = async (e) => {
    e.preventDefault();
    createCategoryData(formData);
  };
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggle}></div>
      <div className={styles["modalbranddetail-container"]}>
        <div className={styles["vendordetail-modal-content"]}>
          <div className={styles["vendordetail-modal-header"]}>
            <div className="">
              <h3>Add Category</h3>
            </div>
            <p onClick={onHandleToggle}>
              <AiOutlineClose
                color="#8b8b8b"
                size={25}
                style={{ cursor: "pointer" }}
              />
            </p>
          </div>
          <hr className={styles.line} />
          <div className={styles["branddetail-modal-field"]}>
            <div className={styles["branddetail-modal-field-content"]}>
              <p className={styles["branddetail-modal-field-content-name"]}>
                Brand Header Image
              </p>
              <label
                htmlFor="imageInput1"
                // className={styles["image-upload-label"]}
              >
                <img
                  src={formData.brandHeaderImage || bimgpng}
                  alt="Selected img"
                  style={{ width: "230px", height: "60px", display: "block" }}
                  className=""
                />
                <input
                  id="imageInput1"
                  type="file"
                  accept="image/*"
                  ref={imageInputRef}
                  style={{ display: "none" }}
                  onChange={(e) => handleImageUpload2(e.target.files[0])}
                />
              </label>
            </div>
            <div className={styles["branddetail-modal-field-content"]}>
              <p className={styles["branddetail-modal-field-content-logo"]}>
                Brand Image
              </p>
              <div
                className={styles["branddetail-modal-field-content-img-link"]}
              >
                <label
                  htmlFor="imageInput2"
                  // className={styles["image-upload-label"]}
                >
                  <img
                    src={formData.banner || bhimg}
                    alt="Selected img"
                    height={60}
                    className=""
                  />
                  <input
                    id="imageInput2"
                    type="file"
                    accept="image/*"
                    ref={imageInputRef}
                    style={{ display: "none" }}
                    onChange={(e) => handleImageUpload(e.target.files[0])}
                  />
                </label>
              </div>
            </div>
            <div className={styles["branddetail-modal-field-content"]}>
              <p>Percentage Commission</p>
              <input
                type="text"
                placeholder="5"
                required
                name="commision"
                value={formData.commision}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    commision: e.target.value,
                  })
                }
              />
            </div>
            <div className={styles["branddetail-modal-field-content"]}>
              <p>Root Category</p>
              <input
                type="text"
                placeholder="Category Name"
                name="categoryName"
                required
                value={formData.categoryName}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    categoryName: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className={styles["brand-button"]}>
          <button
            className={`--btn ${styles["brandcancel-button"]}`}
            onClick={onHandleToggle}
          >
            Cancel
          </button>
          <button
            className={`--btn ${styles["brandsave-button"]}`}
            onClick={handleAddCategory}
            disabled={isLoading}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              width: isLoading && "50px",
            }}
          >
            {isLoading ? (
              <>
                <FaSpinner className={styles["spin"]} />
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
//ModalEditcategory
export const ModalEditcategory = ({ onHandleToggle, categorydatafilter }) => {
  // console.log("categorydatafilter:", categorydatafilter);
  const rowData = categorydatafilter;
  const [formData, setFormData] = useState({
    categoryId: rowData.categoryId || "",
    categoryName: rowData.category || "",
    banner: rowData.banner || "",
    brandHeaderImage: rowData.brandHeaderImage || "",
    commision: rowData.commision || "",
  });

  const categoryUpdate = async (formData) => {
    // console.log("formData:", formData);
    const response = await agent.category.updateCategory(formData);
    return response;
  };
  const queryClient = useQueryClient();
  const { mutate: updateCategoryData, isLoading } = useMutation(
    categoryUpdate,
    {
      // On success, invalidate the 'categories' query to refetch the data
      onSuccess: (data) => {
        queryClient.invalidateQueries("categories");

        if (data.responseCode === "99") {
          // console.log("Error updating category:", data.responseMessage);
          toast.error(data.responseMessage);
          onHandleToggle();
        } else {
          // console.log("category Updated successfully");
          toast.success("category Updated successfully!");
          onHandleToggle();
        }
      },
      // On error, show an error toast
      onError: (error) => {
        // console.error("Error updating category:", error);
        toast.error("Error updating category . Please try again.");
      },
    }
  );
  const imageInputRef = useRef(null);
  const handleImageUpload = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        setFormData({ ...formData, banner: reader.result });
      };

      reader.onerror = (error) => {
        // console.error("Error reading the file: ", error);
      };
    }
  };
  const handleImageUpload2 = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        setFormData({ ...formData, brandHeaderImage: reader.result });
      };

      reader.onerror = (error) => {
        // console.error("Error reading the file: ", error);
      };
    }
  };
  const handleUpdateCategory = async (e) => {
    e.preventDefault();
    updateCategoryData(formData);
  };
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggle}></div>
      <div className={styles["modalbranddetail-container"]}>
        <div className={styles["vendordetail-modal-content"]}>
          <div className={styles["vendordetail-modal-header"]}>
            <div className="">
              <h3>Edit Category</h3>
            </div>
            <p onClick={onHandleToggle}>
              <AiOutlineClose
                color="#8b8b8b"
                size={25}
                style={{ cursor: "pointer" }}
              />
            </p>
          </div>
          <hr className={styles.line} />
          <div className={styles["branddetail-modal-field"]}>
            <div className={styles["branddetail-modal-field-content"]}>
              <p className={styles["branddetail-modal-field-content-name"]}>
                Brand Header Image
              </p>
              <label
                htmlFor="imageInput1"
                // className={styles["image-upload-label"]}
              >
                <img
                  src={formData.brandHeaderImage || bimgpng}
                  alt="Selected img"
                  style={{ width: "220px", height: "60px", display: "block" }}
                  className=""
                />
                <input
                  id="imageInput1"
                  type="file"
                  accept="image/*"
                  ref={imageInputRef}
                  style={{ display: "none" }}
                  onChange={(e) => handleImageUpload2(e.target.files[0])}
                />
              </label>
            </div>
            <div className={styles["branddetail-modal-field-content"]}>
              <p className={styles["branddetail-modal-field-content-logo"]}>
                Brand Image
              </p>
              <div
                className={styles["branddetail-modal-field-content-img-link"]}
              >
                <label
                  htmlFor="imageInput2"
                  // className={styles["image-upload-label"]}
                >
                  <img
                    src={formData.banner || bhimg}
                    alt="Selected img"
                    height={60}
                    className=""
                  />
                  <input
                    id="imageInput2"
                    type="file"
                    accept="image/*"
                    ref={imageInputRef}
                    style={{ display: "none" }}
                    onChange={(e) => handleImageUpload(e.target.files[0])}
                  />
                </label>
              </div>
            </div>
            <div className={styles["branddetail-modal-field-content"]}>
              <p>Percentage Commission</p>
              <input
                type="text"
                name="commision"
                value={formData.commision}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    commision: e.target.value,
                  })
                }
              />
            </div>
            <div className={styles["branddetail-modal-field-content"]}>
              <p>Root Category</p>
              <input
                type="text"
                name="categoryName"
                value={formData.categoryName}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    categoryName: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className={styles["brand-button"]}>
          <button
            className={`--btn ${styles["brandcancel-button"]}`}
            onClick={onHandleToggle}
          >
            Cancel
          </button>
          <button
            className={`--btn ${styles["brandsave-button"]}`}
            onClick={handleUpdateCategory}
            disabled={isLoading}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              width: isLoading && "50px",
            }}
          >
            {isLoading ? (
              <>
                <FaSpinner className={styles["spin"]} />
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

//ModalAddSubcategory
export const ModalAddSubcategory = ({ onHandleToggle, categorydatafilter }) => {
  // console.log("categorydatafilter:", categorydatafilter);
  const rowData = categorydatafilter;
  // const { categories } = useCategories();
  const [formData, setFormData] = useState({
    categoryId: rowData.categoryId || "",
    subCategoryName: "",
    image: "string",
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const {
    data: categories,
    isLoading: SelectLoading,
    error,
    refetch,
  } = useSelectCategories({ page, pageSize });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const scrolledToBottom =
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight;

    if (scrolledToBottom) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const addSubcategory = async (formData) => {
    // console.log("formData:", formData);
    const response = await agent.category.addSubCategory(formData);
    return response;
  };
  const queryClient = useQueryClient();
  const { mutate: addSubCategoryData, isLoading } = useMutation(
    addSubcategory,
    {
      // On success, invalidate the 'categories' query to refetch the data
      onSuccess: (data) => {
        queryClient.invalidateQueries("categories");
        if (data.responseCode === "99") {
          // console.log("Error creating Subcategory:", data.responseMessage);
          toast.error(data.responseMessage);
          onHandleToggle();
        } else {
          // console.log("Subcategory created successfully");
          toast.success("Subcategory created successfully!");
          onHandleToggle();
        }
      },
      // On error, show an error toast
      onError: (error) => {
        // console.error("Error creating Subcategory:", error);
        toast.error("Error creating Subcategory . Please try again.");
      },
    }
  );

  const handleaddSubcategory = async (e) => {
    e.preventDefault();
    addSubCategoryData(formData);
  };

  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggle}></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["AddSubcategory-modal-content"]}>
          <div className={styles["vendordetail-modal-header"]}>
            <div className="">
              <h3>Add Sub-category</h3>
            </div>
            <p onClick={onHandleToggle}>
              <AiOutlineClose
                color="#8b8b8b"
                size={25}
                style={{ cursor: "pointer" }}
              />
            </p>
          </div>
          <hr className={styles.line} />
          <div className={styles["addsubcategory-modal-field-content"]}>
            <div className={styles["branddetail-modal-field-content"]}>
              <p>Select Category</p>
              <select
                name="categoryId"
                value={formData.categoryId}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    categoryId: e.target.value,
                  })
                }
              >
                <option selected disabled>
                  -- Select Category --
                </option>
                {categories &&
                  categories.map((category) => (
                    <option
                      key={category.categoryId}
                      value={category.categoryId}
                    >
                      {category.category}
                    </option>
                  ))}
              </select>
            </div>
            <div className={styles["branddetail-modal-field-content"]}>
              <p>Add Sub-category</p>
              <input
                type="text"
                name="subCategoryName"
                value={formData.subCategoryName}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    subCategoryName: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className={styles["vendorauthorize-button"]}>
          <div className={styles["vendorauthorize-content-button"]}>
            <button
              className={`--btn ${styles["admin-decline-button"]}`}
              onClick={onHandleToggle}
            >
              Cancel
            </button>
            <button
              className={`--btn --btn-success ${styles["admin-yes-button"]}`}
              onClick={handleaddSubcategory}
              disabled={isLoading}
              style={{
                cursor: isLoading ? "not-allowed" : "pointer",
                width: isLoading && "50px",
              }}
            >
              {isLoading ? (
                <>
                  <FaSpinner className={styles["spin"]} />
                </>
              ) : (
                " Save"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
//ModalDeletecategory
export const ModalDeletecategory = ({
  onHandleToggleDeletecategory,
  categorydatafilter,
  isLoading,
  onhandleremovecategory,
}) => {
  const rowData = categorydatafilter;
  // console.log("rowData:", rowData);
  return (
    <div className={styles.modalvendordetail}>
      <div
        className={styles.overlay}
        onClick={onHandleToggleDeletecategory}
      ></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["vendorauthorize-modal-content"]}>
          <div className="">
            <img src={iceimg} alt="" width={120} />
          </div>
          <div className="">
            <h4>Delete Category?</h4>
          </div>
          <div className={styles["vendorauthorize-content-mes"]}>
            <p>Are you sure you want to delete the category?</p>
          </div>
        </div>
        <div className={styles["vendorauthorize-button"]}>
          <div className={styles["vendorauthorize-content-button"]}>
            <button
              className={`--btn --btn-success ${styles["admin-yes-button"]}`}
              onClick={() => onhandleremovecategory(rowData)}
              disabled={isLoading}
              style={{
                cursor: isLoading ? "not-allowed" : "pointer",
                width: isLoading && "50px",
              }}
            >
              {isLoading ? (
                <>
                  <FaSpinner className={styles["spin"]} />
                </>
              ) : (
                "Yes, Delete"
              )}
            </button>
            <button
              className={`--btn ${styles["admin-decline-button"]}`}
              onClick={onHandleToggleDeletecategory}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// ModalReviewAuthorize
export const ModalReviewAuthorize = ({
  onHandleToggle,
  selectedReview,
  onhandleApprovereview,
  isLoading,
}) => {
  const rowData = selectedReview;
  // console.log("rowData :", rowData);
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggle}></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["vendorauthorize-modal-content"]}>
          <div className="">
            <img src={tomsimg} alt="" width={120} />
          </div>
          <div className={styles["vendorauthorize-content-mes"]}>
            <p>You are attempting to authorize this campaign</p>
          </div>
        </div>
        <div className={styles["vendorauthorize-button"]}>
          <div className={styles["vendorauthorize-content-button"]}>
            <button
              className={`--btn --btn-success ${styles["admin-yes-button"]}`}
              onClick={() => onhandleApprovereview(rowData)}
              disabled={isLoading}
              style={{
                cursor: isLoading ? "not-allowed" : "pointer",
                width: isLoading && "50px",
              }}
            >
              {isLoading ? (
                <>
                  <FaSpinner className={styles["spin"]} />
                </>
              ) : (
                " Yes, Please do!"
              )}
            </button>
            <button
              className={`--btn ${styles["admin-decline-button"]}`}
              onClick={onHandleToggle}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

//ModalUserDetail
export const ModalUserDetail = ({ onHandleToggle, usersdatafilter }) => {
  const usersData = usersdatafilter;
  // console.log("modalData:", usersData);
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggle}></div>
      <div className={styles["modalvendordetail-container"]}>
        <div className={styles["vendordetail-modal-content"]}>
          <div className={styles["vendordetail-modal-header"]}>
            <div className="">
              <h3>User Detail</h3>
            </div>
            <p onClick={onHandleToggle}>
              <AiOutlineClose
                color="#8b8b8b"
                size={25}
                style={{ cursor: "pointer" }}
              />
            </p>
          </div>
          <hr className={styles.line} />
          <div className={styles["vendordetail-subheade"]}>
            <div className={styles.home}>
              <div className={styles["userdetail-sub"]}>
                <div className={styles["userdetail-name"]}>
                  <span>Enter Firstname</span>
                  <div className={styles["userdetail-icon-name"]}>
                    <FiUser size={16} className={styles[""]} />
                    <p>{usersData.firstName} </p>
                  </div>
                </div>
                <div className={styles["userdetail-name"]}>
                  <span>Enter Lastname</span>
                  <div className={styles["userdetail-icon-name"]}>
                    <FiUser size={16} className={styles[""]} />
                    <p>{usersData.lastName} </p>
                  </div>
                </div>
              </div>
              <div className={styles["userdetail-sub-email"]}>
                <div className={styles["userdetail-name"]}>
                  <span>Email Address</span>
                  <div className={styles["userdetail-icon-name"]}>
                    <GoMail size={16} className={styles[""]} />
                    <p>{usersData.email} </p>
                  </div>
                </div>
                <div className={styles["userdetail-name"]}>
                  <span>Mobile Number</span>
                  <div className={styles["userdetail-icon-name"]}>
                    <BsTelephone size={16} className={styles[""]} />
                    <p>{usersData.phoneNumber} </p>
                  </div>
                </div>
              </div>

              <div className={styles["userdetail-sub-email"]}>
                <div className={styles["userdetail-name"]}>
                  <span>Admin Role</span>
                  <div className={styles["userdetail-icon-name"]}>
                    <PiScrollLight size={16} className={styles[""]} />
                    <p>{usersData.adminName} </p>
                  </div>
                </div>
              </div>
              <div className={styles["userdetail-button"]}>
                <button
                  className={`--btn ${styles["useradminCancel-button"]}`}
                  onClick={onHandleToggle}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//ModalViewUserDetail
export const ModalViewUserDetail = ({ onHandleToggle, usersdatafilter, handleAdminApprove, handleAdminReject, isLoadingapprove,isLoadingreject,  }) => {
  const usersData = usersdatafilter;

  // console.log("modalData:", usersData);
  let imgSrc; // Variable to store the image source
  if (
    usersData.gender.toLowerCase() === "male" ||
    usersData.gender.toLowerCase() === "m"
  ) {
    imgSrc = maleheadpng;
  } else if (
    usersData.gender.toLowerCase() === "female" ||
    usersData.gender.toLowerCase() === "f"
  ) {
    imgSrc = femaleheadpng;
  }
  const adminName = usersData.adminName

  let pages = PagesDatas(adminName)

  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggle}></div>
      <div className={styles["modalviewuserdetail-container"]}>
        <div className={styles["vendordetail-modal-content"]}>
          <div className={styles["vendordetail-modal-header"]}>
            <div className="">
              <h3>User Detail</h3>
            </div>
            <p onClick={onHandleToggle}>
              <AiOutlineClose
                color="#8b8b8b"
                size={25}
                style={{ cursor: "pointer" }}
              />
            </p>
          </div>
          <hr className={styles.line} />
          <div className={styles["modalviewuserdetail-container-body"]}>
          <div className="">
            <img src={imgSrc} alt="" />
           </div>
           <div className={styles["modalviewuserdetail-fullName-adminName"]}>
            <h4>{usersData.fullName}</h4>
            <p>{usersData.adminName}</p>
           </div>
           <div className={styles["modalviewuserdetail-email-phone"]}>
            <div className="">
              <h5>Email</h5>
              <p>{usersData.email}</p>
            </div>
            <div className="">
              <h5>Phone Number</h5>
              <p>{usersData.phoneNumber}</p>
            </div>
           </div>
            <div className="">
            <h5>Access Status</h5>
             <div className={styles.accessStatus}>
              {pages.map((val,key) => {
                return (
                  <div key={key} className="">
               <span id={styles["modalviewuserdetail-icon"]}>{val.icon}</span>
               <span>{val.title}</span>
              </div>
                )
              })}
             </div>
            </div>
            <div className={styles["modalviewuserdetail-approve-reject-cancel"]}>
              {/* <div className={styles["modalviewuserdetail-approve-reject"]}> */}
              <div className={ usersData.status === 'Pending' || usersData.status === 'Awaiting approval' ? styles["modalviewuserdetail-approve-reject"] : styles["modalviewuserdetail-noapprove-noreject"] }>
              <button
              className={`--btn --btn-success ${styles["admin-yes-button"]}`}
              onClick={() => handleAdminApprove(usersData)}
              disabled={isLoadingapprove}
              style={{
                cursor: isLoadingapprove ? "not-allowed" : "pointer",
                width: isLoadingapprove && "50px",
              }}
            >
              {isLoadingapprove ? (
                <>
                  <FaSpinner className={styles["spin"]} />
                </>
              ) : (
                " Approve"
              )}
            </button>
            <button
              className={`--btn --btn-danger ${styles["admin-yes-button"]}`}
              onClick={() => handleAdminReject(usersData)}
              disabled={isLoadingreject}
              style={{
                cursor: isLoadingreject ? "not-allowed" : "pointer",
                width: isLoadingreject && "50px",
              }}
            >
              {isLoadingreject ? (
                <>
                  <FaSpinner className={styles["spin"]} />
                </>
              ) : (
                "Reject"
              )}
            </button>     
              </div>


              <div className={ usersData.status === 'Awaiting inactive' ? styles["modalviewuserdetail-approve-reject"] : styles["modalviewuserdetail-noapprove-noreject"] }>
              <button
              className={`--btn --btn-success ${styles["admin-yes-button"]}`}
              onClick={() => handleAdminReject(usersData)}
              disabled={isLoadingreject}
              style={{
                cursor: isLoadingreject ? "not-allowed" : "pointer",
                width: isLoadingreject && "50px",
              }}
            >
              {isLoadingreject ? (
                <>
                  <FaSpinner className={styles["spin"]} />
                </>
              ) : (
                " Approved"
              )}
            </button>
            <button
              className={`--btn --btn-danger ${styles["admin-yes-button"]}`}
              onClick={() => handleAdminApprove(usersData)}
              disabled={isLoadingapprove}
              style={{
                cursor: isLoadingapprove ? "not-allowed" : "pointer",
                width: isLoadingapprove && "50px",
              }}
            >
              {isLoadingapprove ? (
                <>
                  <FaSpinner className={styles["spin"]} />
                </>
              ) : (
                "Reject"
              )}
            </button>     
              </div>

              



              <button
                  className={`--btn ${styles["admin-yes-button"]}`}
                  onClick={onHandleToggle}
                >
                  Cancel
                </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
//ModalAddUser
export const ModalAddUser = ({ onHandleToggle }) => {
  const [formData, setFormData] = useState({
    Firstname: "",
    Lastname: "",
    email: "",
    phoneNumber: "",
    gender: "",
    username: "",
    roleId: "",
    approvalLevel: "",
  });

  // console.log("formData:", formData);
  const { roles } = useAdminRoles();
  // console.log("roles", roles)
  const userroles = [
    {
      userRoleId: 0,
      userRoleName: "select-user-role",
    },
    {
      userRoleId: 1,
      userRoleName: "Maker",
    },
    {
      userRoleId: 2,
      userRoleName: "Checker",
    },
  ];

  const adminCreation = async (formData) => {
    // console.log("formData:", formData);
    const response = await agent.userManagment.adminCreation(formData);
    return response;
  };

  const queryClient = useQueryClient();
  const { mutate: adminData, isLoading } = useMutation(adminCreation, {
    // On success, invalidate the 'usersdata' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("usersdata");

      if (data.responseCode === "99") {
        // console.log("Error creating Admin:", data.responseMessage);
        toast.error(data.responseMessage);
        onHandleToggle();
      } else {
        // console.log("Admin created successfully");
        toast.success("Admin created successfully!");
        onHandleToggle();
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error creating Admin:", error);
      toast.error("Error creating Admin. Please try again.");
    },
  });
  
  const handleAddAdmin = async (e) => {
    e.preventDefault();
    adminData(formData);
  };
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggle}></div>
      <div className={styles["modalvendordetail-container"]}>
        <div className={styles["vendordetail-modal-content"]}>
          <div className={styles["vendordetail-modal-header"]}>
            <div className="">
              <h3>New Admin</h3>
            </div>
            <p onClick={onHandleToggle}>
              <AiOutlineClose
                color="#8b8b8b"
                size={25}
                style={{ cursor: "pointer" }}
              />
            </p>
          </div>
          <hr className={styles.line} />
          <div className={styles["vendordetail-subhead"]}>
            <form onSubmit={handleAddAdmin}>
              <div className={styles["name-category"]}>
                <div className={styles["name-header"]}>
                  <label htmlFor="Firstname">Enter Firstname</label>
                  <div className={styles["Firstname-icon"]}>
                    <input
                      type="text"
                      name="Firstname"
                      value={formData.Firstname}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          Firstname: e.target.value,
                        })
                      }
                      placeholder="eg. Christopher"
                    />
                    <FiUser size={16} className={styles["name-icon"]} />
                  </div>
                </div>
                <div className={styles["name-header"]}>
                  <label htmlFor="Lastname">Enter Lastname</label>
                  <div className={styles["Firstname-icon"]}>
                    <input
                      type="text"
                      name="Lastname"
                      value={formData.Lastname}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          Lastname: e.target.value,
                        })
                      }
                      placeholder="eg. Dunkwu"
                    />
                    <FiUser size={16} className={styles["name-icon"]} />
                  </div>
                </div>
              </div>
              <div className={styles["name-category"]}>
                <div className={styles["name-header"]}>
                  <label htmlFor="email">Email Address</label>
                  <div className={styles["Firstname-icon"]}>
                    <GoMail size={16} className={styles["name-icon-email"]} />
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          email: e.target.value,
                        })
                      }
                      placeholder="j***@altbank.ng"
                    />
                  </div>
                </div>
                <div className={styles["name-header"]}>
                  <label htmlFor="phoneNumber">Mobile Number</label>
                  <div className={styles["Firstname-icon"]}>
                    <input
                      type="text"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          phoneNumber: e.target.value,
                        })
                      }
                      placeholder="070****90"
                    />
                    <BsTelephone size={16} className={styles["name-icon"]} />
                  </div>
                </div>
              </div>
              <div className={styles["name-category"]}>
                <div className={styles["name-header"]}>
                  <label htmlFor="gender">Gender</label>
                  <div className={styles["Firstname-icon"]}>
                    <input
                      type="text"
                      name="gender"
                      value={formData.gender}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          gender: e.target.value,
                        })
                      }
                      placeholder="male"
                    />
                    <FiUser size={16} className={styles["name-icon"]} />
                  </div>
                </div>
                <div className={styles["name-header"]}>
                  <label htmlFor="username">Username</label>
                  <div className={styles["Firstname-icon"]}>
                    <input
                      type="text"
                      name="username"
                      value={formData.username}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          username: e.target.value,
                        })
                      }
                      placeholder="Dunkwurc"
                    />
                    <FiUser size={16} className={styles["name-icon"]} />
                  </div>
                </div>
              </div>
              <div className={styles["name-category"]}>
                <div className={styles["name-header"]}>
                  <label htmlFor="roleId">Admin Role</label>
                  <div className={styles["Firstname-icon"]}>
                    <select
                      name="roleId"
                      value={formData.roleId}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          roleId: e.target.value,
                        })
                      }
                    >
                      <option value="" disabled>
                        -- Choose Admin Role --
                      </option>
                      {roles &&
                        roles.map((role) => (
                          <option
                            key={role.adminRoleId}
                            value={role.adminRoleId}
                          >
                            {role.adminRoleName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
               
                {formData.roleId === "1" ? (
                <div className={styles["name-header"]}>
                <label htmlFor="approvalLevel">User Role</label>
                <div className={styles["Firstname-icon"]}>
                 <select
                  name="approvalLevel"
                 value={formData.approvalLevel}
                 onChange={(e) =>
               setFormData({
               ...formData,
                approvalLevel: e.target.value,
               })
              }
             >
              <option selected disabled>
               -- Choose User Role --
             </option>
              {userroles &&
                userroles.map((userrole) => (
              <option
               key={userrole.userRoleId}
               value={userrole.userRoleName}
             >
                {userrole.userRoleName}
              </option>
            ))}
         </select>
       </div>
     </div>
   ) : null}
              </div>
              <div className={styles["useradmin-button"]}>
                <button
                  className={`--btn ${styles["useradminsave-button"]}`}
                  type="submit"
                  disabled={isLoading}
                  style={{
                    cursor: isLoading ? "not-allowed" : "pointer",
                    width: isLoading && "50px",
                  }}
                >
                  {isLoading ? (
                    <>
                      <FaSpinner className={styles["spin"]} />
                    </>
                  ) : (
                    "Save"
                  )}
                </button>
                <button
                  className={`--btn ${styles["useradminCancel-button"]}`}
                  onClick={onHandleToggle}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};



//ModalPromotionDetail
export const ModalPromotionDetail = ({
  onHandleTogglePromotionDetail,
  promotiondatafilter,
  onHandleToggle,
  onHandleTogglePromotionReject,
}) => {
  const promotionData = promotiondatafilter;
  // console.log("modalData:", promotionData);
  const viewAPromotion = async () => {
    const promotionId = promotionData.promotionId;
    const response = await agent.promotion.viewAPromotion(promotionId);
    return response.data;
  };
  const { data: aPromotion = {} } = useQuery("aPromotion", viewAPromotion);
  // console.log("aPromotion:", aPromotion);

  return (
    <div className={styles.modalvendordetail}>
      <div
        className={styles.overlay}
        onClick={onHandleTogglePromotionDetail}
      ></div>
      <div className={styles["modalvendordetail-container"]}>
        <div className={styles["vendordetail-modal-content"]}>
          <div className={styles["vendordetail-modal-header"]}>
            <div className="">
              <h3>Promotion Detail</h3>
            </div>
            <p onClick={onHandleTogglePromotionDetail}>
              <AiOutlineClose
                color="#8b8b8b"
                size={25}
                style={{ cursor: "pointer" }}
              />
            </p>
          </div>
          <hr className={styles.line} />
          <div className={styles["promotiondetail-item"]}>
            <div className="">
              <span>Campaign Title</span>
              <h4>{promotionData.title}</h4>
            </div>

            <div className={styles["promotiondetail-item-description"]}>
              <span>Campaign Description</span>
              <p>{promotionData.description}</p>
            </div>
            <div className="">
              <span>Image</span>
              <div className={styles["promotiondetail-modal-images"]}>
                {promotionData.promotionImage.map((image, index) => (
                  <img
                    key={index}
                    src={image.promotionImageUrl} // Replace 'imageUrl' with the actual property name in your 'promotionImage' object
                    alt={`Image ${index}`}
                    width={100}
                  />
                ))}
              </div>
            </div>

            <div className={styles["promotiondetail-modal-campaign"]}>
              <div>
                <span>Campaign Type</span>
                <p>{promotionData.type}</p>
              </div>
              <div className="">
                <span>Campaign Category</span>
                <p>{promotionData.category}</p>
              </div>
              <div className="">
                <span>Status</span>
                {promotionData.status === "Pending" && (
                  <Box
                    width="65px"
                    marginTop="3px"
                    p="4px"
                    display="flex"
                    justifyContent="center"
                    backgroundColor="#fbf7e7"
                    color="#f5ba69"
                    borderRadius="2px"
                  >
                    <TbPointFilled />
                    {promotionData.status}
                  </Box>
                )}
                {promotionData.status === "Approved" && (
                  <Box
                    width="65px"
                    p="4px"
                    display="flex"
                    justifyContent="center"
                    backgroundColor="#f4fdf8"
                    color="#32c36c"
                    borderRadius="2px"
                  >
                    <TbPointFilled />
                    {promotionData.status}
                  </Box>
                )}
                {promotionData.status === "Rejected" && (
                  <Box
                    width="65px"
                    p="4px"
                    display="flex"
                    justifyContent="center"
                    backgroundColor="#f9ebe8"
                    color="#e23411"
                    borderRadius="2px"
                  >
                    <TbPointFilled />
                    {promotionData.status}
                  </Box>
                )}
              </div>
            </div>

            <div className={styles["promotiondetail-modal-start-end"]}>
              <div className="">
                <span>Start Date</span>
                <p>{promotionData.startDate}</p>
              </div>
              <div className="">
                <span>End Date</span>
                <p>{promotionData.endDate}</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles["promotiondetail-modal-button"]}>
          <div
            className={styles["promotiondetail-modal-approve-reject-button"]}
          >
            <button
              className={`--btn ${styles["promotiondetail-approve-button"]} `}
              onClick={() => onHandleToggle(promotionData)}
            >
              Approve
            </button>
            <button
              className={`--btn ${styles["promotiondetail-reject-button"]}`}
              onClick={() => onHandleTogglePromotionReject(promotionData)}
            >
              Reject
            </button>
          </div>
          <div className="">
            <button
              className={`--btn ${styles["promotiondetail-cancel-button"]}`}
              onClick={onHandleTogglePromotionDetail}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

//ModalPromotionReject
export const ModalPromotionReject = ({
  onHandleTogglePromotionReject,
  promotiondatafilter,
  onhandleRejectpromotion,
  isLoading,
}) => {
  const promotionData = promotiondatafilter;
  // console.log("modalData:", promotionData);
  const [reason, setReason] = useState("");

  const handleReasonChange = (event) => {
    const newReason = event.target.value;
    // console.log("New Reason:", newReason);
    setReason(newReason);
  };
  const handleRejectClick = async (event) => {
    event.preventDefault(); // Prevent form submission
    // console.log("Submitting Reason:", reason); // Check if reason is set

    // Call onhandleRejectpromotion with the current reason value
    onhandleRejectpromotion(reason, promotionData.promotionId);
  };
  return (
    <div className={styles.modalvendordetail}>
      <div
        className={styles.overlay}
        onClick={onHandleTogglePromotionReject}
      ></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["modalvendorreason-content"]}>
          <p>Reason for rejection</p>
          <form onSubmit={handleRejectClick}>
            <textarea
              placeholder="why are you rejecting this application?"
              value={reason}
              onChange={handleReasonChange}
            />
            <div className={styles["vendorreason-button"]}>
              <div className={styles["vendorreason-content-button"]}>
                <button
                  className={`--btn --btn-success ${styles["admin-yes-button"]}`}
                  type="submit"
                  disabled={isLoading}
                  style={{
                    cursor: isLoading ? "not-allowed" : "pointer",
                    width: isLoading ? "80px" : "auto",
                  }}
                >
                  {isLoading ? (
                    <>
                      <FaSpinner className={styles["spin"]} />
                    </>
                  ) : (
                    "Save"
                  )}
                </button>
                <button
                  className={`--btn ${styles["vendoradmin-button"]}`}
                  onClick={onHandleTogglePromotionReject}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

// ModalPromotionApprove
export const ModalPromotionApprove = ({
  onHandleToggle,
  promotiondatafilter,
  onhandleApprovepromotion,
  isLoading,
}) => {
  const promotionData = promotiondatafilter;
  // console.log("modalData:", promotionData);
  return (
    <div className={styles.modalvendordetail}>
      <div className={styles.overlay} onClick={onHandleToggle}></div>
      <div className={styles["modalvendorauthorize-container"]}>
        <div className={styles["vendorauthorize-modal-content"]}>
          <div className="">
            <img src={tomsimg} alt="" width={120} />
          </div>
          <div className={styles["vendorauthorize-content-mes"]}>
            <p>You are attempting to authorize this campaign</p>
          </div>
        </div>
        <div className={styles["vendorauthorize-button"]}>
          <div className={styles["vendorauthorize-content-button"]}>
            <button
              className={`--btn --btn-success ${styles["admin-yes-button"]}`}
              onClick={() => onhandleApprovepromotion(promotionData)}
              disabled={isLoading}
              style={{
                cursor: isLoading ? "not-allowed" : "pointer",
                width: isLoading && "50px",
              }}
            >
              {isLoading ? (
                <>
                  <FaSpinner className={styles["spin"]} />
                </>
              ) : (
                " Yes, Please do!"
              )}
            </button>
            <button
              className={`--btn ${styles["admin-decline-button"]}`}
              onClick={onHandleToggle}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
