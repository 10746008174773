import axios from "axios";
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "../pages/auth/auth";
import {encryptRequest, decryptResponse} from "./cryptos";


// Create an instance of axios
export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    accept: "text/plain",
    "Content-Type": "application/json",
    "x-clientid": process.env.REACT_APP_ClientId,
  },
});


// Use window.location.href  to access the Login Page
const relogin = () => {
  window.location.href = "/";
};

api.interceptors.request.use(  
  async (config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    config.transformRequest = [
      (data, headers) => {
        return data;
      },
    ];
    // // Encrypt request data
    // config.data = encryptRequest(config.data);
    //  console.log("config:",config)
    return config;
  },
  async (error) => {
    // Intercept any request errors here (optional)
    return Promise.reject(error);
  }
);



// Token refresh function
const refreshToken = async (oldRefreshToken, userId) => {
  // console.log("Refreshing token for userId:", userId, "and refreshToken:", oldRefreshToken);
  const requestBody = {
    refreshToken: oldRefreshToken,
    userId:userId,
  }
  // console.log("Request Body:", JSON.stringify(requestBody));
  try {
    const response = await requests.post("/Admin/refreshtoken", requestBody );
    // console.log("Refresh token request successful. Response:", response.data);
    const newAccessToken = response.data.accessToken;
    const newRefreshToken = response.data.refreshToken;
    setAccessToken(newAccessToken);
    setRefreshToken(newRefreshToken, userId);
    return newAccessToken;
  } catch (error) {
    // Handle error refreshing token
    // Redirect to login or show an error message
    // relogin(); // Navigate to the login page
    throw new Error("Failed to refresh access token");
  }
};

// API response interceptor for decryption and token refresh
api.interceptors.response.use(
  async (response) => {
    // // Decrypt response data
    // const decryptedData = decryptResponse(response.data.data);
    // response.data.data = decryptedData;
    // console.log("decryptedData: ", decryptedData);
   

    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshTokenData = getRefreshToken();
      if (
        refreshTokenData &&
        refreshTokenData.expirationTime > new Date().getTime()
      ) {
        try {
          // console.log("2Refreshing token for userId:",refreshTokenData.userId, "and refreshToken:",   refreshTokenData.refreshToken); 
          const newAccessToken = await refreshToken(
            refreshTokenData.refreshToken,
            refreshTokenData.userId
          );
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          // Retry the original request with the new access token
          return api(originalRequest);
        } catch (error) {
          // Handle error refreshing token
          // Redirect to login or show an error message
          relogin(); // Navigate to the login page
          return Promise.reject(error);
        }
      } else {
        // Handle expired refresh token or no refresh token
        // Redirect to login or show an error message
        relogin(); // Navigate to the login page
        return Promise.reject(
          new Error("Refresh token expired or not available!!!!")
        );
      }
    }
    return Promise.reject(error);
  }
);


const responseBody = (response) => decryptResponse(response.data);

const requests = {
  get: (url) => api.get(url).then(responseBody),
  post: (url, body) => api.post(url, encryptRequest(body), {
    headers: { "Content-type": "application/json;" },
  }).then(responseBody),
  postUrl: (url, body) => api.post(url, encryptRequest(body)).then(responseBody),
  put: (url, body) => api.put(url, encryptRequest(body), {
    headers: { "Content-type": "application/json;" },
  }).then(responseBody),
  patch: (url) => api.patch(url).then(responseBody),
};




const auth = {
  login: (username, password, browser) => requests.post("/Admin/Login", { username, password, browser}),
  validateMToken: (token, userName ) => requests.post("/Admin/validateonetoken", { token, userName}),
    logout:() => requests.patch("/Admin/adminlogout"),
    
  // validateMToken: (userName, mToken) => requests.post("/Admin/validatemtoken", {
  //     userName: userName,
  //     mToken: mToken,
  //   }),
    
};
const dashboard = {
  getAllMatrix: () => requests.get("/Admin/adminmatrix"),
};

const vendor = {
  // getAllVendors: () => requests.get("/Admin/getAllVendors"),
  getAllVendors: (PageNumber, PageSize) => requests.get(`/Admin/getAllVendors?PageNumber=${PageNumber}&PageSize=${PageSize}`),
  approveVendor: (vendorId, status, reason) =>
    requests.put(
      `/Admin/updateVendorStoreStatus?vendorId=${vendorId}&status=${status}&reason=${reason}`
    ),
  rejectVendor: (vendorId, status, reason) =>
    requests.put(
      `/Admin/updateVendorStoreStatus?vendorId=${vendorId}&status=${status}&reason=${reason}`
    ),
  lockVendor: (vendorId, status, reason) =>
    requests.put(
      `/Admin/updateVendorStoreStatus?vendorId=${vendorId}&status=${status}&reason=${reason}`
    ),
  blockVendor: (vendorId, status, reason) =>
    requests.put(
      `/Admin/blacklistvendor?vendorId=${vendorId}&status=${status}&reason=${reason}`
    ),
  unblockVendor: (vendorId, status, reason) =>
    requests.put(
      `/Admin/blacklistvendor?vendorId=${vendorId}&status=${status}&reason=${reason}`
    ),
  approveMultipleVendor: (vendorId, status) =>
    requests.put(
      `/Admin/updateMultipleVendorStatus?vendorId=${vendorId.join(
        "&vendorId="
      )}&status=${status}`
    ),
  rejectMultipleVendor: (vendorId, status) =>
    requests.put(
      `/Admin/updateMultipleVendorStatus?vendorId=${vendorId.join(
        "&vendorId="
      )}&status=${status}`
    ),
  lockMultipleVendor: (vendorId, status) =>
    requests.put(
      `/Admin/updateMultipleVendorStatus?vendorId=${vendorId.join(
        "&vendorId="
      )}&status=${status}`
    ),
};

const customer = {
  // getAllCustomers: () => requests.get("/Admin/getallcustomers"),
  getAllCustomers: (PageNumber, PageSize) => requests.get(`/Admin/getallcustomers?PageNumber=${PageNumber}&PageSize=${PageSize}`),
  getACustomer: (customerId) =>
    requests.get(`/Admin/getacustomerorder?customerId=${customerId}`),
  blacklistCustomer: (requestBody) =>
    requests.put( `/Admin/blacklistcustomer`, requestBody),
  unblacklistCustomer: (requestBody) =>
    requests.put(`/Admin/blacklistcustomer`, requestBody),
  blacklistMultipleCustomer: (requestBody) =>
    requests.post("/Admin/blacklistmuliplecustomers", requestBody),
  unblacklistMultipleCustomer: (requestBody) =>
    requests.post("/Admin/blacklistmuliplecustomers", requestBody),
};
const order = {
  // getAllOrders: () => requests.get("/Admin/getallorders"),

  getAllOrders: (PageNumber, PageSize) => requests.get(`/Admin/getallorders?PageNumber=${PageNumber}&PageSize=${PageSize}`),
  cancelOrder: (OrderId, OrderStatus, Reason) =>
    requests.put(`/Admin/updateorderstatus?OrderId=${OrderId}&OrderStatus=${OrderStatus}&Reason=${Reason}`),
    approveOrder: (OrderId, OrderStatus, Reason) =>
    requests.put(`/Admin/updateorderstatus?OrderId=${OrderId}&OrderStatus=${OrderStatus}&Reason=${Reason}`),
};

const product = {
  getAllProducts: (PageNumber, PageSize) => requests.get(`/Admin/admingetallproducts?PageNumber=${PageNumber}&PageSize=${PageSize}`),
  approveProduct: (productId, status, reason) =>
    requests.put(
      `/Admin/updateproductstatus?productId=${productId}&status=${status}&reason=${reason}`),
  rejectProduct: (productId, status, reason) =>
    requests.put(
      `/Admin/updateproductstatus?productId=${productId}&status=${status}&reason=${reason}`
    ),
  updateproductotopdeal: (productId) =>
    requests.patch(`/Admin/updateproductotopdeal?productId=${productId}`),
  productodisabled: (productId) =>
    requests.patch(`/Admin/updateproductodisabled?productId=${productId}`),
  removeproduct: (productId) =>
    requests.put(`/Product/RemoveProduct?productId=${productId}`),
  productcreation: (formData) =>
    requests.post("/Admin/adminproductcreation", formData),
  updateProduct: (formData) => requests.put("/Product/updateProduct", formData),
  addMultipleProducts: (formData) =>
    requests.post("/Product/CreateMultipleProducts", formData),
};

const brand = {
  // getAllBrands: () => requests.get("/ProductBrand/getallproductbrands"),
  getAllBrands: (PageNumber, PageSize) => requests.get(`/ProductBrand/getallproductbrands?PageNumber=${PageNumber}&PageSize=${PageSize}`),
  imageLink: (imageData) => requests.post("/Imal/NewUploadFile", imageData),
  brandcreation: (formData) =>
    requests.post("/ProductBrand/createproductbrand", formData),
  updateBrand: (formData) =>
    requests.put("/ProductBrand/updateproductbrand", formData),
  removeBrand: (BrandId) =>
    requests.patch(`/ProductBrand/removeproductbrand?BrandId=${BrandId}`),
  addMultipleBrand: (formData) =>
    requests.post("/ProductBrand/createmulitpleproductbrand", formData),
};

const category = {
  // getAllcategories: () => requests.get("/Category/getallcategories"),
  // getAllSubcategories: () => requests.get("/SubCategory/getallsubcategories"),
  getAllcategories: (PageNumber, PageSize) => requests.get(`/Category/getallcategories?PageNumber=${PageNumber}&PageSize=${PageSize}`),
  getAllSubcategories: (PageNumber, PageSize) => requests.get(`/SubCategory/getallsubcategories?PageNumber=${PageNumber}&PageSize=${PageSize}`),
  categoryCreation: (formData) =>
    requests.post("/Category/createcategory", formData),
  updateCategory: (formData) =>
    requests.put("/Category/updatecategoryproduct", formData),
  addSubCategory: (formData) =>
    requests.post("/SubCategory/createsubcategory", formData),
  removeCategory: (categoryId) =>
    requests.patch(`/Category/removeproductcategory?categoryId=${categoryId}`),
};
const review = {
  // getAllreviews: () => requests.get("/Admin/getallcustomereviews"),
  getAllreviews: (PageNumber, PageSize) => requests.get(`/Admin/getallcustomereviews?PageNumber=${PageNumber}&PageSize=${PageSize}`),
  approveReview: (status, reviewId) =>
    requests.put(
      `/Admin/updatereviewstatus?status=${status}&reviewId=${reviewId}`
    ),
  rejectReview: (status, reviewId) =>
    requests.put(
      `/Admin/updatereviewstatus?status=${status}&reviewId=${reviewId}`
    ),
};

const userManagment = {
  getAllroles: () => requests.get("/Admin/getadminroles"),
  getAllAdmin: (PageNumber, PageSize) => requests.get(`/Admin/getAllAdmin?PageNumber=${PageNumber}&PageSize=${PageSize}`),
  adminCreation: (formData) => requests.post("/Admin/createAdmin", formData),
  // editAdmin: (formData) => requests.post("/Admin/createAdmin", formData),
  approveAdmin: (AdminId, status) =>
    requests.patch(
      `/Admin/updateAdminStatus?AdminId=${AdminId}&status=${status}`
    ),
  rejectAdmin: (AdminId, status) =>
    requests.patch(
      `/Admin/updateAdminStatus?AdminId=${AdminId}&status=${status}`
    ),
};

const ticket = {
  // getAllTickets: () => requests.get("/Admin/getalltickets"),
  getAllTickets: (PageNumber, PageSize) => requests.get(`/Admin/getalltickets?PageNumber=${PageNumber}&PageSize=${PageSize}`),
  approveTicket: (status, ticketId) =>
    requests.put(
      `/Admin/updateticketstatus?status=${status}&ticketId=${ticketId}`
    ),
  rejectTicket: (status, ticketId) =>
    requests.put(
      `/Admin/updateticketstatus?status=${status}&ticketId=${ticketId}`
    ),
  replymessage: (replyData) => requests.post("/Admin/replymessage", replyData),
};
const promotion = {
  // getAllPromotions: () => requests.get("/Admin/getallpromotions"),
  getAllPromotions: (PageNumber, PageSize) => requests.get(`/Admin/getallpromotions?PageNumber=${PageNumber}&PageSize=${PageSize}`),
  promotionCreation: (formData) =>
    requests.post("/Vendor/createeventforpromotion", formData),
  approvePromotion: (status, promotionId, reason) =>
    requests.put(
      `/Admin/updatepromotionstatus?status=${status}&promotionId=${promotionId}&reason=${reason}`
    ),
  rejectPromotion: (status, promotionId, reason) =>
    requests.put(
      `/Admin/updatepromotionstatus?status=${status}&promotionId=${promotionId}&reason=${reason}`
    ),
  viewAPromotion: (promotionId) =>
    requests.get(`/Admin/getpromotion?promotionId=${promotionId}`),
  removePromotion: (promotionId) =>
    requests.put(`/Admin/deletepromotion?promotionId=${promotionId}`),
};
const notification = {
  getNotifications: (searchDate) =>
    requests.get(`/Admin/getNotification?searchDate=${searchDate}`),
};
const agent = {
  auth,
  dashboard,
  vendor,
  customer,
  product,
  order,
  brand,
  category,
  review,
  userManagment,
  ticket,
  promotion,
  notification,
};

export default agent;
