import React, { useState } from "react";
import styles from "./UserManagement.module.css";
import { Header, Loader, Pagination, Sidebar } from "../../components";
import { usersdata } from "../../components/tabledata";
import { TbPointFilled } from "react-icons/tb";
import { SlOptionsVertical } from "react-icons/sl";
import { IoIosAddCircleOutline } from "react-icons/io";
import { ModalAddUser, ModalUserDetail, ModalViewUserDetail } from "../../components/modal/Modal";
import femaleimg from "../../assets/female.png";
import maleimg from "../../assets/maleface.png";
import agent from "../../api/agent";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAdmin } from "../../customHooks/useAdmin";
import toast from "react-hot-toast";
import { CiSearch } from "react-icons/ci";
const UserManagement = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [modalAddUser, setModalAddUser] = useState(false);
  const [modalUserDetail, setModalUserDetail] = useState(false);
  const [modalViewUserDetail, setModalViewUserDetail] = useState(false);
  const [dropdown, setDropdown] = useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);
 
  const handletoggleuserDetail = () => {
    setModalUserDetail(!modalUserDetail);
  };
  const handletoggleviewuserDetail = () => {
    setModalViewUserDetail(!modalViewUserDetail);
  };
  const handletoggleaddUser = () => {
    setModalAddUser(!modalAddUser);
  };
  // API CALL
  const approveAdmin = async (adminId) => {
    const status = "Active";
    const AdminId = adminId;
    // console.log("adminId:", AdminId, "status:", status);
    
    const response = await agent.userManagment.approveAdmin(AdminId, status);
    return response;
  };
  const rejectAdmin = async (adminId) => {
    const status = "inactive";
    const AdminId = adminId;
    // console.log("adminId:", AdminId, "status:", status);
    const response = await agent.userManagment.rejectAdmin(AdminId, status);
    return response;
  };
  const queryClient = useQueryClient();
  const { usersdata, isLoading, isError, hasNextPage, estimatedTotalPages } = useAdmin({
    PageNumber: currentPage,
    PageSize: postsPerPage,
  });

  //React Query Mutate state Function
  const { mutate: adminApprove, isLoadingapprove } = useMutation(approveAdmin, {
    // On success, invalidate the 'Adminusersdata' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("usersdata");
      if (data.responseCode === "99") {
        // console.log("Error Activating a user:", data.responseMessage);
        toast.error(data.responseMessage);
        setModalViewUserDetail(false);
      } else {
        // console.log("Activate user successfully ");
        toast.success("Activate user successfully!");
        setModalViewUserDetail(false);
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error Activating a user:", error);
      toast.error("Error Activating a user. Please try again.");
    },
  });
  const { mutate: adminReject, isLoadingreject } = useMutation(rejectAdmin, {
    // On success, invalidate the 'Adminusersdata' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("usersdata");
      if (data.responseCode === "99") {
        // console.log("Error Revoking a user access:", data.responseMessage);
        toast.error(data.responseMessage);
        setModalViewUserDetail(false);
      } else {
        // console.log("Revoke user access successfully ");
        toast.success("Revoke user access successfully!");
        setModalViewUserDetail(false);
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error Revoking a user access:", error);
      toast.error("Error Revoking a user access. Please try again.");
    },
  });
  //  Meun onClick Function
  const handleAdminApprove = ({ adminId }) => {
    // console.log("adminId", adminId);
    adminApprove(adminId);
  };
  const handleAdminReject = ({ adminId }) => {
    // console.log("adminId", adminId);
    adminReject(adminId);
  };
  let usersdatafilter = [];
  let currentPosts = [];
  //change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (usersdata) {
    usersdatafilter = usersdata.filter((val) => {
      if (selectedFilter === "All") {
        return val.fullName.toLowerCase().includes(searchTerm.toLowerCase());
      } else {
        return (
          val.fullName.toLowerCase().includes(searchTerm.toLowerCase()) &&
          val.status === selectedFilter
        );
      }
    });
    //Get Current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    currentPosts = usersdatafilter.slice(indexOfFirstPost, indexOfLastPost);
    // console.log("try", currentPosts);
  }
  let activeCount = 0;
  let inactiveCount = 0;

  if (usersdata) {
    const statusCounts = usersdata.reduce((counts, users) => {
      const status = users.status;
      counts[status] = (counts[status] || 0) + 1;
      return counts;
    }, {});

    // console.log("Status Counts:", statusCounts);

    // Update the count variables

    activeCount = statusCounts["Active"] || 0;
    inactiveCount = statusCounts["Inactive"] || 0;
  }
  const userRole = localStorage.getItem("approvalLevel");
  // const [userRole, setUserRole] = useState("marker");

  const toggle = (adminId) =>
    setDropdown(dropdown === adminId ? undefined : adminId);
  return (
    <div className={styles.vendor}>
      {modalUserDetail ? (
        <ModalUserDetail
          onHandleToggle={handletoggleuserDetail}
          usersdatafilter={selectedRow}
        />
      ) : null}
      {modalViewUserDetail ? (
        <ModalViewUserDetail
          onHandleToggle={handletoggleviewuserDetail}
          usersdatafilter={selectedRow}
          handleAdminApprove={handleAdminApprove}
          handleAdminReject={handleAdminReject}
          isLoadingapprove={isLoadingapprove}
          isLoadingreject={isLoadingreject}
        />
      ) : null}
      {modalAddUser ? (
        <ModalAddUser onHandleToggle={handletoggleaddUser} />
      ) : null}
      <Header />
      <div className={styles["vendor-body"]}>
        <Sidebar />
        <div className={styles["vendor-container"]}>
          <div className={styles["vendor-title"]}>
            <h2>User Management</h2>
            <div className={ userRole === "Checker" ? styles["nocreate-button"] : styles["create-button"]}>
              <button
                className="--btn --btn-success "
                onClick={() => handletoggleaddUser()}
              >
                <p>New User</p>
                <span>
                  <IoIosAddCircleOutline size={20} />
                </span>
              </button>
            </div>
          </div>
          <div className={styles["vendor-subtitle-search"]}>
            <div className={styles.subtitle}>
              <div
                className={`${styles["vendor-subtitle-head"]}  ${
                  selectedFilter === "All" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("All")}
              >
                <p>All Users</p>
              </div>
              <div
                className={`${styles["vendor-active"]} ${
                  selectedFilter === "Active" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Active")}
              >
                <p>Active</p>
                <span>{activeCount}</span>
              </div>
              <div
                className={`${styles["vendor-inactive"]}  ${
                  selectedFilter === "Inactive" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Inactive")}
              >
                <p>Inactive </p>
                <span>{inactiveCount}</span>
              </div>
            </div>
            <div className={styles.search}>
              <form
                className="--form-control"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              >
                <span className={styles.icon}>
                  <CiSearch size={17} />
                </span>
                <input placeholder="Search Admin Name" />
              </form>
            </div>
          </div>
          <div className={styles["vendor-content"]}>
            {isLoading ? (
              <Loader />
            ) : isError ? (
              <div className={styles["no-rows"]}>Error fetching data</div>
            ) : usersdatafilter.length === 0 ? (
              <div className={styles["no-rows"]}>No rows</div>
            ) : (
              usersdatafilter.map((val, index) => {
                const {
                  adminId,
                  gender,
                  firstName,
                  lastName,
                  email,
                  adminName,
                  createdAt,
                  status,
                } = val;
                // Calculate the index of the first post on the current page
                const indexOfFirstPost = (currentPage - 1) * postsPerPage;

                // Calculate SN (sequential number)
                const sn = indexOfFirstPost + index + 1;
                let imgSrc; // Variable to store the image source
                if (
                  gender.toLowerCase() === "male" ||
                  gender.toLowerCase() === "m"
                ) {
                  imgSrc = maleimg;
                } else if (
                  gender.toLowerCase() === "female" ||
                  gender.toLowerCase() === "f"
                ) {
                  imgSrc = femaleimg;
                }
                return (
                  <div className={styles.users} key={adminId}>
                    <div className={styles["users-id-img-name-depart"]}>
                      <div className="">
                        <p>{sn}</p>
                      </div>
                      <div className={styles.img}>
                        <p>{<img src={imgSrc} alt="" width={27} />}</p>
                      </div>
                      <div className="">
                        <p>
                          {firstName} {lastName}{" "}
                        </p>
                        <span>{email}</span>
                      </div>
                      <div className={styles.depart}>
                        <span>{adminName}</span>
                      </div>
                    </div>
                    <div className={styles["users-date-icon"]}>
                      <div>
                        <p className={styles.date}>{createdAt}</p>
                        {status === "Active" && (
                          <span className={styles["users-status-active"]}>
                            <TbPointFilled /> {status}
                          </span>
                        )}
                        {status === "Inactive" && (
                          <span className={styles["users-status-inactive"]}>
                            <TbPointFilled /> {status}
                          </span>
                        )}
                        {status === "Awaiting inactive" && (
                          <span className={styles["users-status-awaiting"]}>
                            <TbPointFilled /> {status}
                          </span>
                        )}
                        {status.toLowerCase() === "awaiting approval" && (
                          <span className={styles["users-status-awaiting"]}>
                            <TbPointFilled /> {status}
                          </span>
                        )}
                        {status === "Pending" && (
                          <span className={styles["users-status-awaiting"]}>
                            <TbPointFilled /> {status}
                          </span>
                        )}
                      </div>
                      <div className={styles["users-icon"]}>
                        <div
                          className={
                            dropdown === adminId
                              ? styles["ticket-icon2"]
                              : styles["ticket-icon"]
                          }
                          onClick={() => toggle(adminId)}
                        >
                          <SlOptionsVertical size={12} />
                        </div>
                        {/* {dropdown === adminId ? (
                          <div
                            className={styles["dropdown-container"]}
                            onClick={() => toggle(adminId)}
                          >
                            <div
                              className={styles["dropdown-item"]}
                              onClick={() => {
                                handletoggleuserDetail(val);
                                setSelectedRow(val);
                              }}
                            >
                              <p>View details</p>
                            </div>
                            <div
                              className={styles["dropdown-item"]}
                              onClick={() => handleAdminReject(val)}
                            >
                              <p>Revoke access</p>
                            </div>
                            <div
                              className={styles["dropdown-item"]}
                              onClick={() => handleAdminApprove(val)}
                            >
                              <p>Activate user</p>
                            </div>
                          </div>
                        ) : (
                          " "
                        )} */}

                        {userRole === "Checker" ? (
                          // Render these items if userRole is "checker"
                          dropdown === adminId ? (
                            <div
                              className={styles["dropdown-container"]}
                              onClick={() => toggle(adminId)}
                            >
                              <div
                                className={styles["dropdown-item"]}
                                onClick={() => handleAdminApprove(val)}
                              >
                                <p>Approve</p>
                              </div>
                              <div
                                className={styles["dropdown-item"]}
                                onClick={() => handleAdminReject(val)}
                              >
                                <p>Reject</p>
                              </div>
                              <div
                                className={styles["dropdown-item"]}
                                onClick={() => {
                                  handletoggleviewuserDetail(val);
                                  setSelectedRow(val);
                                }}
                              >
                                <p>View </p>
                              </div>  
                            </div>
                          ) : (
                            " "
                          )
                        ) : // Render these items if userRole is not "checker"
                        dropdown === adminId ? (
                          <div
                            className={styles["dropdown-container"]}
                            onClick={() => toggle(adminId)}
                          >
                            <div
                              className={styles["dropdown-item"]}
                              onClick={() => {
                                handletoggleuserDetail(val);
                                setSelectedRow(val);
                              }}
                            >
                              <p>View details</p>
                            </div>
                            <div
                              className={styles["dropdown-item"]}
                              onClick={() => handleAdminReject(val)}
                            >
                              <p>Revoke access</p>
                            </div>
                            <div
                              className={styles["dropdown-item"]}
                              onClick={() => handleAdminApprove(val)}
                            >
                              <p>Activate user</p>
                            </div>
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
            <div
              className={
                usersdatafilter.length > 0
                  ? styles["pagination-item"]
                  : styles["pagination-item-length"]
              }
            >
              <Pagination
                postsPerPage={postsPerPage}
                // totalPosts={usersdata ? usersdata.length : 0}
                currentPage={currentPage}
                isLoading={isLoading}
                estimatedTotalPages={estimatedTotalPages}
                paginate={paginate}
                hasNextPage={hasNextPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
