import React, { useEffect, useState } from "react";
import styles from "./Order.module.css";
import { Header, Loader, Sidebar } from "../../components";
import { CiSearch } from "react-icons/ci";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Menu, MenuItem, Typography, styled } from "@mui/material";
import { TbPointFilled } from "react-icons/tb";
import { RiArrowDownSFill } from "react-icons/ri";
import { RxDownload } from "react-icons/rx";
import { HiOutlineBars3BottomLeft } from "react-icons/hi2";
import { LuCreditCard } from "react-icons/lu";
import { ModalOrderDetail, ModalOrderCancelReason } from "../../components/modal/Modal";
import agent from "../../api/agent";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setOrders } from "../../redux/slice/orderSlice";
import FileSaver from "file-saver";
import { utils, write } from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { toast } from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useOrders } from "../../customHooks/useOrders";
import fi from "../../assets/trylogo.png"
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  width: "180px",
  padding: "19px 19px",
  borderBottom: "1px solid #f1f1f1",
  fontSize: "13px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#32c36c",
    color: "#fff",
  },
}));

const Order = () => {
  const [modalOrderDetail, setModalOrderDetail] = useState(false);
  const [modalOrderCancelReason, setModalOrderCancelReason] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorElMap, setAnchorElMap] = useState({});
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [cancelData, setCancelData] = useState({
    OrderId: "",
    OrderStatus: "",
    Reason: "",
  });
  const [approveData, setApproveData] = useState({
    OrderId: "",
    OrderStatus: "",
  });
  const handletoggleOrderDetail = () => {
    setModalOrderDetail(!modalOrderDetail);
  };
  const handleOrderDetail = (rowData) => {
    setSelectedRow(rowData);
    setModalOrderDetail(!modalOrderDetail);
    setAnchorElMap({});
  };
  const handleOrderCancelReason = (rowData) => {
    setSelectedRow(rowData);
    setModalOrderCancelReason(!modalOrderCancelReason);
    setAnchorElMap({});
  };

  const handleButtonClick = (event, rowId) => {
    event.stopPropagation();
    setAnchorElMap((prevMap) => ({ ...prevMap, [rowId]: event.currentTarget }));
  };
  const handleMenuClose = (rowId) => {
    setAnchorElMap((prevMap) => ({ ...prevMap, [rowId]: null }));
  };

  useEffect(() => {
    const handleCloseMenus = () => {
      setAnchorElMap({});
    };
    document.addEventListener("click", handleCloseMenus);
    return () => {
      document.removeEventListener("click", handleCloseMenus);
    };
  }, []);
  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 10
      }
    }
  };

  const [paginationModel, setPaginationModel] = useState(initialState.pagination.paginationModel);
  
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };
  // API Calls
  const approveOrder = async (updatedApproveData) => {
    // console.log("updatedApproveData:", updatedApproveData);
    const OrderId = updatedApproveData.OrderId;
    const OrderStatus = updatedApproveData.OrderStatus;
    const  Reason = updatedApproveData.Reason
    const response = await agent.order.approveOrder(OrderId, OrderStatus, Reason);
    return response;
  };
  const cancelOrder = async (updatedCancelData) => {
    // console.log("updatedCancelData:", updatedCancelData);
    const OrderId = updatedCancelData.OrderId;
    const OrderStatus = updatedCancelData.OrderStatus;
    const  Reason = updatedCancelData.Reason
    const response = await agent.order.cancelOrder(OrderId, OrderStatus, Reason);
    return response;
  };
  // React Query Functions
  const queryClient = useQueryClient();
  const { orders, isLoading, error, refetch } = useOrders(paginationModel);
  // console.log(orders);
  if (error) {
    // Handle error state
    return <div>Error loading brands</div>;
  }
  const { mutate: cancelOrderData, isLoading: cancelLoading } = useMutation(cancelOrder, {
    // On success, invalidate the 'orders' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("orders");

      if (data.responseCode === "99") {
        // console.log("Error Cancelling order:", data.responseMessage);
        toast.error(data.responseMessage);
        handleOrderCancelReason()
      } else {
        // console.log("order Cancelled successfully");
        toast.success("order Cancelled successfully!");
        handleOrderCancelReason()
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error Cancelling order:", error);
      toast.error("Error Cancelling order. Please try again.");
    },
  });
  const { mutate: approveOrderData } = useMutation(approveOrder, {
    // On success, invalidate the 'orders' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("orders");

      if (data.responseCode === "99") {
        // console.log("Error Completing order:", data.responseMessage);
        toast.error(data.responseMessage);
      } else {
        // console.log("order Completed successfully");
        toast.success("order Completed successfully!");
      }
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error Completing order:", error);
      toast.error("Error Completing order. Please try again.");
    },
  });

  const handlecancelOrder = (reason, orderId ) => {
    // console.log("handlecancelOrder", orderId, "reason:", reason);
    const updatedCancelData = {
      ...cancelData,
      OrderId: orderId,
      OrderStatus: "Cancelled",
      Reason: reason,
    };

    setCancelData(updatedCancelData);

    // Call the approveOrder function with the updated data
    cancelOrderData(updatedCancelData);
  };
  const handleapproveOrder = ({ orderId }) => {
    // console.log("handlecancelOrder", orderId);
    const updatedApproveData = {
      ...approveData,
      OrderId: orderId,
      OrderStatus: "Completed",
      Reason:"Approve"
    };

    setApproveData(updatedApproveData);

    // Call the approveOrder function with the updated data
    approveOrderData(updatedApproveData);
  };
  //
  // Define a function to format the price
  function formatPrice(price) {
    // Assuming price is a number
    const formattedPrice = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(price);

    return `${formattedPrice}`;
  }
  // Define a function to format the amount
  function formatAmount(price) {
    // Assuming price is a number
    const formattedPrice = new Intl.NumberFormat("en-NG", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);

    // Remove "NG" and ".00"
    return formattedPrice.replace("NG", "").replace(".00", "");
  }

  const exportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = utils.json_to_sheet(orderdatafilter);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "orders" + fileExtension);
  };

  const handleExportToDoc = () => {
    const doc = new jsPDF();

    doc.text("Order Details", 20, 10);

    const columnsForTable = columns
      .filter((col) => col.field !== "action")
      .map((col) => ({
        ...col,
        dataKey: col.field,
      }));
    const rowsForTable = orderdatafilter.map((row, index) => ({
      sn: index + 1,
      orderNumber: row.orderNumber,
      customerName: `${row.customerFirstName} ${row.customerLastName}`,
      totalQuantity: row.totalQuantity,
      amount: formatAmount(row.amount),
      customerPhoneNumber: row.customerPhoneNumber,
      status: row.status,
      dateCreated: row.dateCreated,
    }));
    const columnStyles = {
      orderNumber: { columnWidth: 30 }, // Adjust the width of the customerName column to fit content
      customerName: { columnWidth: 40 }, // Adjust the width of the customerName column to fit content
    };
    doc.autoTable({
      columns: columnsForTable,
      body: rowsForTable,
      head: [columnsForTable.map((col) => col.headerName)], // Add the header to the table
      columnStyles: columnStyles,
    });

    doc.save("orders.pdf");
  };

  const getRowId = (order) => order.orderId;

  const columns = [
    {
      field: "sn",
      headerName: "SN",
      width: 5,
      cellClassName: "name-column--cell",
    },
    {
      field: "orderNumber",
      headerName: "Order Number",
      width: 100,
      cellClassName: "name-column--cell",
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 150,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <div>
          {params.row.customerFirstName} {params.row.customerLastName}
        </div>
      ),
    },
    {
      field: "totalQuantity",
      headerName: "Quantity",
      width: 20,
      cellClassName: "name-column--cell",
    },
    {
      field: "amount",
      headerName: "Price",
      width: 120,
      cellClassName: "name-column--cell",
      valueGetter: (params) => formatPrice(params.value),
    },
    {
      field: "customerPhoneNumber",
      headerName: "Phone Number",
      width: 120,
      cellClassName: "name-column--cell",
    },
    {
      field: "paymentMethod",
      headerName: "Payment Mode",
      width: 90,
      align: "center",
      // headerClassName: "name-column--head",
      renderCell: ({ row: { paymentMethod } }) => {
        if (paymentMethod === "Pay Now") {
          return (
            <Box
              width="65px"
              // mr="50px "
              // m="0 auto"
              p="4px"
              display="flex"
              justifyContent="center"
              // backgroundColor="#f4fdf8"
              // color="#32c36c"
              // borderRadius="2px"
            >
              {paymentMethod === "Pay Now" && <LuCreditCard />}
              {paymentMethod === "Pay Later" && <img src={fi} alt="" />}
              
              <Typography sx={{ ml: "2px" }}>
                {paymentMethod === "Pay Now" && "Card"}
                {paymentMethod === "Pay Later" && "Financing"}
              </Typography>
            </Box>
          );
        } else if (paymentMethod === "Pay Later") {
          return (
            <Box
              width="65px"
              // m="0 auto"
              // mr="90px "
              p="4px"
              display="flex"
              justifyContent="center"
              // backgroundColor="#f9ebe8"
              // color="#e23411"
              // borderRadius="2px"
            >
              {paymentMethod === "Pay Now" && <LuCreditCard />}
              {paymentMethod === "Pay Later" && <img src={fi} alt="" />  }
             <Typography sx={{ ml: "2px" }}>
                {paymentMethod === "Pay Now" && "Card"}
                {paymentMethod === "Pay Later" && "Financing"}
              </Typography>
            </Box>
          );
        }
      }
      },
    {
      field: "status",
      headerName: "Status  ",
      width: 150,
      align: "center",
      headerClassName: "name-column--head",
      renderCell: ({ row: { status } }) => {
        if (status === "Completed") {
          return (
            <Box
              width="65px"
              // mr="50px "
              // m="0 auto"
              p="4px"
              display="flex"
              justifyContent="center"
              backgroundColor="#f4fdf8"
              color="#32c36c"
              borderRadius="2px"
            >
              {status === "Completed" && <TbPointFilled />}
              {status === "Cancelled" && <TbPointFilled />}
              {status === "Pending" && <TbPointFilled />}
              <Typography sx={{ ml: "2px" }}>
                {status === "Completed" && "Completed"}
                {status === "Cancelled" && "Cancelled"}
                {status === "Pending" && "Pending"}
              </Typography>
            </Box>
          );
        } else if (status === "Cancelled") {
          return (
            <Box
              width="65px"
              // m="0 auto"
              // mr="90px "
              p="4px"
              display="flex"
              justifyContent="center"
              backgroundColor="#f9ebe8"
              color="#e23411"
              borderRadius="2px"
            >
              {status === "Completed" && <TbPointFilled />}
              {status === "Cancelled" && <TbPointFilled />}
              {status === "Pending" && <TbPointFilled />}

              <Typography sx={{ ml: "2px" }}>
                {status === "Completed" && "Completed"}
                {status === "Cancelled" && "Cancelled"}
                {status === "Pending" && "Pending"}
              </Typography>
            </Box>
          );
        } else if (status === "Pending") {
          return (
            <Box
              width="65px"
              // m="0 auto"
              // mr="50px "
              p="4px"
              display="flex"
              justifyContent="center"
              backgroundColor="#fbf7e7"
              color="#f5ba69"
              borderRadius="2px"
            >
              {status === "Completed" && <TbPointFilled />}
              {status === "Cancelled" && <TbPointFilled />}
              {status === "Pending" && <TbPointFilled />}

              <Typography sx={{ ml: "2px" }}>
                {status === "Completed" && "Completed"}
                {status === "Cancelled" && "Cancelled"}
                {status === "Pending" && "Pending"}
              </Typography>
            </Box>
          );
        }
      },
    },
    {
      field: "dateCreated",
      headerName: "Date of Creation",
      width: 140,
      // cellClassName: "name-column--cell",
    },
    {
      field: "action",
      headerName: "Action",
      width: 180,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => ActionButton(params),
    },
  ];

  const ActionButton = (params) => {
    const rowId = params.row.orderId;
    const anchorEl = anchorElMap[rowId];

    return (
      <>
        <Button
          className={styles["action-button"]}
          style={{
            marginLeft: 16,
            width: 100,
            height: 30,
            color: "#000",
            backgroundColor: "#ebf4fe",
          }}
          onClick={(event) => handleButtonClick(event, rowId)}
          aria-controls={`actions-menu-${rowId}`}
          aria-haspopup="true"
        >
          Action
          <RiArrowDownSFill style={{ marginLeft: 16 }} size={13} />
        </Button>
        <Menu
          id={`actions-menu-${rowId}`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleMenuClose(rowId)}
        >
          <StyledMenuItem onClick={() => handleapproveOrder(params.row)}>
            Approve Order
          </StyledMenuItem>

          <StyledMenuItem onClick={() => handleOrderCancelReason(params.row)}>
            Cancel Order
          </StyledMenuItem>

          <StyledMenuItem onClick={() => handleOrderDetail(params.row)}>
            View Order
          </StyledMenuItem>
        </Menu>
      </>
    );
  };

  let orderdatafilter = [];
  let orderdatafilters = [];
  // Combine orderdatafilter and orderdatafilters arrays
  // let combinedOrderData = orderdatafilter.concat(orderdatafilters);

  const [dropdown, setDropdown] = useState(false);
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  // Helper function to convert month name to month number
  function getMonthNumber(monthName) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const index = months.indexOf(monthName);
    return index !== -1 ? index : null;
  }
  if (orders) {
    const currentDate = new Date(); // Get the current date once to avoid multiple calls

    if (selectedFilter === "ThisMonth") {
      // Filter orders for the current month
      orderdatafilter = orders.filter((order) => {
        const dateParts = order.dateCreated.split(", ");
        const day = parseInt(dateParts[0]);
        const month = getMonthNumber(dateParts[1]);
        const year = parseInt(dateParts[2]);

        return (
          month === currentDate.getMonth() && year === currentDate.getFullYear()
        );
      });
      // console.log("ThisMonth data:", orderdatafilter);
    } else if (selectedFilter === "LastMonth") {
      // Filter orders for the previous month
      orderdatafilter = orders.filter((order) => {
        const dateParts = order.dateCreated.split(", ");
        const day = parseInt(dateParts[0]);
        const monthAndYear = dateParts[1].split(" ");
        const month = getMonthNumber(monthAndYear[0]);
        const year = parseInt(monthAndYear[1]);

        const lastMonth = new Date(currentDate);
        lastMonth.setMonth(currentDate.getMonth() - 1);

        // console.log("LastMonth date:", lastMonth.toISOString());
        // console.log("Order date:", new Date(year, month, day).toISOString());

        return (
          (month === lastMonth.getMonth() &&
            year === lastMonth.getFullYear()) ||
          (month === 11 && year === lastMonth.getFullYear() - 1)
        );
      });
      // console.log("LastMonth data:", orderdatafilter);
    } else if (selectedFilter === "All") {
      // Show all orders (no filtering)
      orderdatafilter = orders;
    }
  }

  if (orders) {
    orderdatafilter = orders.filter((val) => {
      if (
        selectedFilter === "All" ||
        // selectedFilter === "ThisMonth" ||
        selectedFilter === "LastMonth"
      ) {
        return (
          val.customerFirstName
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase()) ||
          val.customerLastName
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase()) ||
          val.orderId
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase()) ||
          val.orderNumber
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase())
        );
      } else {
        return (
          (val.customerFirstName
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase()) ||
            val.customerLastName
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase()) ||
            val.orderId
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase()) ||
            val.orderNumber
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase())) &&
          val.status === selectedFilter
        );
      }
    });
  }
  let completedCount = 0;
  let pendingCount = 0;
  let cancelledCount = 0;

  if (orders) {
    const statusCounts = orders.reduce((counts, orders) => {
      const status = orders.status;
      counts[status] = (counts[status] || 0) + 1;
      return counts;
    }, {});

    // console.log("Status Counts:", statusCounts);

    // Update the count variables

    completedCount = statusCounts["Completed"] || 0;
    pendingCount = statusCounts["Pending"] || 0;
    cancelledCount = statusCounts["Cancelled"] || 0;
  }

  return (
    <div className={styles.vendor}>
      {modalOrderDetail ? (
        <ModalOrderDetail
          onHandleToggleOrderDetail={handletoggleOrderDetail}
          orderdatafilter={selectedRow}
          paginationModel={paginationModel}
        />
      ) : null}
      {modalOrderCancelReason ? (
        <ModalOrderCancelReason
          onHandleToggle={handleOrderCancelReason}
          onHandleRejectReason={handlecancelOrder}
          OrderData={selectedRow}
          isLoading={cancelLoading}
        />
      ) : null}
      <Header />
      <div className={styles["vendor-body"]}>
        <Sidebar />
        <div className={styles["vendor-container"]}>
          <div className={styles["vendor-title"]}>
            <h2>Orders</h2>
          </div>
          <div className={styles["vendor-subtitle-search"]}>
            <div className={styles.subtitle}>
              <div
                className={`${styles["vendor-subtitle-head"]} ${
                  selectedFilter === "All" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("All")}
              >
                <p>All Orders</p>
              </div>
              <div
                className={`${styles["vendor-pending"]} ${
                  selectedFilter === "Pending" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Pending")}
              >
                <p>Pending</p>
                <span>{pendingCount}</span>
              </div>
              <div
                className={`${styles["vendor-rejected"]} ${
                  selectedFilter === "Cancelled" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Cancelled")}
              >
                <p>Cancelled</p>
                <span>{cancelledCount}</span>
              </div>
              <div
                className={`${styles["vendor-locked"]} ${
                  selectedFilter === "Completed" ? styles.active : ""
                }`}
                onClick={() => setSelectedFilter("Completed")}
              >
                <p>Completed</p>
                <span>{completedCount}</span>
              </div>
            </div>
            <div className={styles.search}>
              <div className={styles["action-button"]}>
                <Button
                  style={{
                    marginLeft: 16,
                    width: 100,
                    height: 40,
                    color: "#2f2f2f",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => setFilterDropdown(!filterDropdown)}
                >
                  <HiOutlineBars3BottomLeft
                    style={{ marginRight: 12 }}
                    size={13}
                  />
                  Filter by
                </Button>
                {filterDropdown ? (
                  <div
                    className={styles["newbrand-filterdropdown-container"]}
                    onClick={() => setFilterDropdown(!filterDropdown)}
                  >
                    <div
                      className={styles["newbrand-dropdown-item"]}
                      onClick={() => setSelectedFilter("All")}
                    >
                      <p>All</p>
                    </div>
                    <div
                      className={styles["newbrand-dropdown-item"]}
                      onClick={() => setSelectedFilter("ThisMonth")}
                    >
                      <p>This month only</p>
                    </div>
                    <div
                      className={styles["newbrand-dropdown-item"]}
                      onClick={() => setSelectedFilter("LastMonth")}
                    >
                      <p>Last month only</p>
                    </div>
                  </div>
                ) : (
                  " "
                )}
                <Button
                  style={{
                    marginLeft: 16,
                    width: 100,
                    height: 40,
                    color: "#fff",
                    backgroundColor: "#595959",
                  }}
                  onClick={() => setDropdown(!dropdown)}
                >
                  Export
                  <RxDownload style={{ marginLeft: 16 }} size={13} />
                </Button>
                {dropdown ? (
                  <div
                    className={styles["newbrand-dropdown-container"]}
                    onClick={() => setDropdown(!dropdown)}
                  >
                    <div
                      className={styles["newbrand-dropdown-item"]}
                      onClick={exportToExcel}
                    >
                      <p>Export as .xlsx</p>
                    </div>
                    <div
                      className={styles["newbrand-dropdown-item"]}
                      onClick={handleExportToDoc}
                    >
                      <p>Export as .pdf</p>
                    </div>
                  </div>
                ) : (
                  " "
                )}
              </div>
              <form
                className="--form-control"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              >
                <span className={styles.icon}>
                  <CiSearch size={17} />
                </span>
                <input
                  placeholder="Search customer name"
                  value={searchTerm}
                />
              </form>
            </div>
          </div>
          <div className={styles["vendor-content"]} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
            {isLoading ? (
              <Loader />
            ) : (
              <Box
                sx={{
                  // width: {
                  //   xs: 445,
                  //   sm: 545,
                  //   md: 713,
                  //   lg: 945,
                  //   xl: 1200,
                  // },
                  height: "87%",
                  "& .MuiDataGrid-root": {
                    border: "none",
                    fontSize: " 13px",
                    fontFamily: "Montserrat",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders":
                    {
                      backgroundColor: "#32c36c",
                      color: "#fff",
                    },
                  "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate":
                    {
                      color: "#32c36c",
                    },
                  " & .css-12u49z-MuiDataGrid-root .MuiDataGrid-row.Mui-selected":
                    {
                      backgroundColor: "#ecfaf0",
                    },
                  " .MuiDataGrid-row.Mui-selected:hover, .css-12u49z-MuiDataGrid-root .MuiDataGrid-row.Mui-selected.Mui-hovered":
                    {
                      backgroundColor: "#ecfaf0",
                    },

                  "& .name-column--head": {
                    marginLeft: "40px",
                  },
                }}
              >
                <div className={styles["data-grid"]}>
                  <DataGrid
                    rows={orderdatafilter.map((row, index) => ({
                      ...row,

                      sn: index + 1 + paginationModel.page * paginationModel.pageSize,
                    }))}
                    columns={columns}
                    options={{}}
                    getRowId={getRowId}
                    pagination
                     paginationMode="server"
                    //  pageSizeOptions={[10, 20, 50]}
                   paginationModel={paginationModel}
                   onPaginationModelChange={handlePaginationModelChange}
                    rowCount={1000}
                    loading={isLoading}
                    
                  />
                </div>
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
