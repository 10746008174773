import { useQuery } from "react-query";
import agent from "../api/agent";
// const fetchCustomers = async () => {
//     const response = await agent.customer.getAllCustomers();
//     return response.data;
//   };



  const fetchCustomers = async ({ page, pageSize }) => {
    // console.log("PageNumber:",page, "PageSize:",pageSize);
    // Adjust PageNumber based on page value
    // const PageNumber = page === 0 ? 1 : page;
    const PageNumber = page + 1;
    const PageSize = pageSize
    // console.log("PageNumber2:",PageNumber, "PageSize2:",pageSize);
    const response = await agent.customer.getAllCustomers(PageNumber, PageSize );
    return response.data;
  };
  
 
// export const useCustomers = () => {
//     const {
//       isLoading,
//       error,
//       data: customers,
//     } = useQuery({
//       queryKey: ["customers"],
//       queryFn: fetchCustomers,
//     });
//     return { isLoading, error, customers };
//   };

  export const useCustomers = ({ page, pageSize } = {}) => {
    const { isLoading, error, data: customers, refetch } = useQuery({
      queryKey: ['customers', { page, pageSize }],
      queryFn: () => fetchCustomers({ page, pageSize }),
    });
  
    return { isLoading, error,customers, refetch };
  };