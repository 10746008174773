import { useQuery } from "react-query";
import agent from "../api/agent";

//  Function to get the current date in the desired format (e.g., "yyyy-MM-dd").
const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const fetchNotifications = async (searchDate) => {
  const response = await agent.notification.getNotifications(searchDate);
  return response.data;
};

export const useNotifications = () => {
  const searchDate = getCurrentDate(); // Get the current date.
  const {
    isLoading,
    error,
    data: notificationsdata,
  } = useQuery({
    queryKey: ["notificationsdata"],
    queryFn: () => fetchNotifications(searchDate), // Pass the searchDate as an argument.
  });
 // Calculate the total count of notificationsdata.
 const totalCount = notificationsdata ? notificationsdata.length : 0;

  return { notificationsdata,totalCount, isLoading, error };
};