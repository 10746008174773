import React, { useState, useRef, useEffect } from "react";
import styles from "./ProductList.module.css";
import { Header, Sidebar } from "../../components";
import { BsArrowLeftCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import uploadimg from "../../assets/upload.png";
import { addProduct } from "../../redux/slice/productSlice";
import { useDispatch } from "react-redux";
import agent from "../../api/agent";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Radio,
  RadioGroup,
  Checkbox,
  TextField,
  FormControlLabel,
  styled,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { FaSpinner } from "react-icons/fa";
import { useBrands, useSelectBrands } from "../../customHooks/useBrands";
import { useCategories, useSelectCategories } from "../../customHooks/useCategories";
import { useSelectVendors, useVendors } from "../../customHooks/useVendors";
import { AsyncPaginate } from 'react-select-async-paginate';
const GreenRadio = styled(Radio)(({ theme }) => ({
  color: "#000000",
  "&.Mui-checked": {
    color: "#32c36c", // Change the color of the checked radio button
  },
}));
// Define a custom styled Checkbox
const GreenCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#000000", // Unchecked color
  "&.Mui-checked": {
    color: "#32c36c", // Checked color
  },
}));

const customStyles = {
  control: (provided, state) => ({
      ...provided,
      borderRadius: '5px',
      border: '1px solid black',
      fontSize: '14px', // Set font size to 28px
  }),
  option: (provided, state) => ({
      ...provided,
      // backgroundColor: state.isFocused ? '#3699FF' : null,
      // color: state.isFocused ? 'white' : null,
      fontSize: '14px', // Set font size to 28px
  }),
};
const UpdateProduct = () => {
  const location = useLocation();
  const rowData = location.state;
  const dispatch = useDispatch();
  // Now you can use rowData to access the data from the clicked row
  // console.log(rowData);
  
  const [clickedImageIndex, setClickedImageIndex] = useState(-1);
  const [formData, setFormData] = useState({
    productId: rowData.productId || "",
    productName: rowData.productName || "",
    rating: 0,
    brandId: rowData.brandId || "",
    categoryId: rowData.categoryId || "",
    vendorId: rowData.vendorId || "",
    subCategoryId: rowData.subCategoryId || "",
    productPrimaryImageUrl: rowData.productPrimaryImageUrl,
    multipleProductImageUrl: rowData.productImages.map(image => image.productImageUrl),
    multipleProductImageIds: rowData.productImages.map(image => image.productImageId),
    productSpecification: rowData.productSpecification || "",
    installationFee: rowData.installationFee || 0,
    buyerNote: rowData.buyerNote || "",
    weight: rowData.weight || "",
    slug: rowData.slug || "",
    shortDescription: rowData.shortDescription || "",
    longDescription: rowData.longDescription || "",
    monthlyFinancing: rowData.monthlyFinancing || 0,
    productCode: "string",
    productDescription: rowData.longDescription || "",
    productEnabled: true,
    isWarranty: rowData.isWarranty || "",
    productCurrentPrice: rowData.productCurrentPrice || "",
    productPreviousPrice: rowData.productPreviousPrice || "",
    applyVat: rowData.applyVat || "",
    isDeliveryCarryOut: rowData.isDeliveryCarryOut || "",
    productQuantity: rowData.productQuantity || "",

    // ...other fields
  });
  
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  
  const [value, setValue] = useState({
    value: formData.brandId,
    label: rowData.brandName
  });
  const [valueCat, setValueCat] = useState({
    value: formData.categoryId,
    label: rowData.categoryName
  });
  const [valueVen, setValueVen] = useState({
    value: formData.vendorId,
    label: rowData.vendorName
  });
// console.log("value",value);
  // Function to handle brand selection change
  const handleBrandChange = (selectedBrand) => {
    // console.log("selectedBrand",selectedBrand);
    setFormData({
      ...formData,
      brandId: selectedBrand.value,
    });
    setValue({
      value: selectedBrand.value,
      label: selectedBrand.label
    });
  };
  const handleCategoryChange = (selectedCategory) => {
    // console.log("selectedCategory",selectedCategory);
    setFormData({
      ...formData,
      categoryId: selectedCategory.value,
    });
    setValueCat({
      value: selectedCategory.value,
      label: selectedCategory.label
    });
  };
  const handleVendorChange = (selectedVendor) => {
    // console.log("selectedVendor",selectedVendor);
    setFormData({
      ...formData,
      vendorId: selectedVendor.value,
    });
    setValueVen({
      value: selectedVendor.value,
      label: selectedVendor.label
    });
  };

  // Define the loadOptions function
  const loadOptions = async (search, loadedOptions, { page }) => {
    const response = await agent.brand.getAllBrands(page, pageSize)
    // console.log('Response data:', response.data); 
    return {
      options: response.data.map(brand => ({ label: brand.brandName, value: brand.brandId })),
      hasMore: response.data.length === pageSize, // Assume has more if data length equals page size
      additional: {
        page: page + 1,
      },
    };
  };
  const loadOptionsCat = async (search, loadedOptions, { page }) => {
    const response = await agent.category.getAllcategories(page, pageSize)
    // console.log('Response data:', response.data); 
    return {
      options: response.data.map(category => ({ label:category.category, value:category.categoryId })),
      hasMore: response.data.length === pageSize, // Assume has more if data length equals page size
      additional: {
        page: page + 1,
      },
    };
  };
  const loadOptionsVen = async (search, loadedOptions, { page }) => {
    const response = await agent.vendor.getAllVendors(page, pageSize)
    // console.log('Response data:', response.data); 
    return {
      options: response.data.map(vendor => ({ label: `${vendor.firstName} ${vendor.lastName}`, value: vendor.vendorId })),
      hasMore: response.data.length === pageSize, // Assume has more if data length equals page size
      additional: {
        page: page + 1,
      },
    };
  };

  
  


  const navigate = useNavigate();

  const backToProduct = () => {
    return navigate("/product-list");
  };
  const handleImageUpload = (files) => {
    if (files) {
      const reader = new FileReader();
      const firstFile = files[0];

      reader.onload = (e) => {
        const imageUrl = e.target.result;

        if (clickedImageIndex !== -1) {
          // Replace the clicked image if an image is clicked
          const newMultipleImages = [...formData.multipleProductImageUrl];
          newMultipleImages[clickedImageIndex] = imageUrl;
          setFormData((prevData) => ({
            ...prevData,
            productPrimaryImageUrl: imageUrl,
            multipleProductImageUrl: newMultipleImages,
          }));
          setClickedImageIndex(-1); // Reset the clicked image index
        } else {
          setFormData((prevData) => ({
            ...prevData,
            productPrimaryImageUrl: imageUrl,
            multipleProductImageUrl: [
              ...prevData.multipleProductImageUrl,
              imageUrl,
            ],
          }));
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading the file: ", error);
      };

      reader.readAsDataURL(firstFile);

      for (let i = 1; i < files.length; i++) {
        reader.readAsDataURL(files[i]);
      }
    }
  };

  const handleImageClick = (index) => {
    setClickedImageIndex(index); // Set the clicked image index
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Convert the value to an integer if it's not an empty string and matches the specified fields
    let parsedValue = value;
    if (
      [
        "weight",
        "productCurrentPrice",
        "productPreviousPrice",
        "productQuantity",
      ].includes(name)
    ) {
      // Remove commas from the value and then parse it into an integer
      parsedValue = value.replace(/,/g, "");
      parsedValue = parsedValue !== "" ? parseInt(parsedValue) : "";
    }

    setFormData({ ...formData, [name]: parsedValue });
  };
  const imageInputRef = useRef(null);
  const updateproduct = async (formData) => {
    // console.log("formData:", formData);
    const response = await agent.product.updateProduct(formData);
    return response;
  };

  const queryClient = useQueryClient();
  const { mutate: productUpdateData, isLoading } = useMutation(updateproduct, {
    // On success, invalidate the 'products' query to refetch the data
    onSuccess: (data) => {
      queryClient.invalidateQueries("products");

      if (data.responseCode === "99") {
        // console.log("Error updating Product:", data.responseMessage);
        toast.error(data.responseMessage);
      } else {
        // console.log("Product Updated successfully");
      toast.success("Product Updated successfully!");
      navigate("/product-list");
      }
      
    },
    // On error, show an error toast
    onError: (error) => {
      // console.error("Error updating Product:", error);
      toast.error("Error updating Product . Please try again.");
    },
  });

  const handleUpdate = (e) => {
    e.preventDefault();
    productUpdateData(formData);
  };




  return (
    <div className={styles.vendor}>
      <Header />
      <div className={styles["form-upload-body"]}>
        <Sidebar />
        <div className={styles["addproduct-container"]}>
          <div
            className={styles["addproduct-title"]}
            onClick={() => backToProduct()}
          >
            <BsArrowLeftCircle size={30} style={{ cursor: "pointer" }} />
            <p>Go back</p>
          </div>
          <div className={styles["vendor-subtitle-search"]}>
            <div className={styles.subtitle}>
              <div className={styles["vendor-subtitle-head"]}>
                <h3>Update Product</h3>
              </div>
            </div>
          </div>
          <div className={styles["form-upload-content"]}>
            <div className={styles["form-upload"]}>
              <form>
                {/*Select Vendor name- Select category */}
                <div className={styles["name-category"]}>
                  <div className={styles["Product-name"]}>
                    <label htmlFor="vendorId">Vendor</label>
                    <div className={styles.categoryvendorPaginate}> 
                    <AsyncPaginate
                       value={valueVen}
                       onChange={handleVendorChange}
                        loadOptions={loadOptionsVen} // Pass the loadOptions function
                        isSearchable={false}
                        // placeholder="Select House"
                        additional={{
                          page: 1, // Initial page
                        }}
                        styles={customStyles}
                      />
                    </div>
                   </div>
                  <div className={styles.categorybrand}>
                    <label htmlFor="categoryId">Category</label>
                    <div className={styles.categorybrandPaginate}> 
                    <AsyncPaginate
                       value={valueCat}
                       onChange={handleCategoryChange}
                        loadOptions={loadOptionsCat} // Pass the loadOptions function
                        isSearchable={false}
                        // placeholder="Select House"
                        additional={{
                          page: 1, // Initial page
                        }}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                </div>
                {/*Product name- Select brand */}
                <div className={styles["name-category"]}>
                  <div className={styles["Product-name"]}>
                    <label htmlFor="productName">Product name</label>
                    <input
                      type="text"
                      name="productName"
                      value={formData.productName}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          productName: e.target.value,
                        })
                      }
                      placeholder="Christopher Dunkwu"
                    />
                  </div>
                  <div className={styles.categorybrand}>
                    <label htmlFor="brandId">Brand</label>
                     {/* Render AsyncPaginate for brands selection without search */}
                     <div className={styles.categorybrandPaginate}>
                     <AsyncPaginate
                       value={value}
                       onChange={handleBrandChange}
                        loadOptions={loadOptions} // Pass the loadOptions function
                        isSearchable={false}
                        // placeholder="Select House"
                        additional={{
                          page: 1, // Initial page
                        }}
                        styles={customStyles}
                      />
                     </div>
                    
                  </div>
                </div>
                {/*productDescription - buyerNote */}
                <div className={styles["name-category-pb"]}>
                  <div className={styles["actual-Price"]}>
                    <label htmlFor="productDescription">
                      Product description
                    </label>
                    <TextField
                      multiline
                      name="productDescription"
                      rows={4} // Adjust the number of rows as needed
                      value={formData.productDescription}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setFormData({
                          ...formData,
                          productDescription: newValue,
                          longDescription: newValue,
                        });
                      }}
                      placeholder="Write a description about this product"
                    />
                  </div>
                  <div className={styles["sales-Price"]}>
                    <label htmlFor="buyerNote">
                      Buyer's note (input in bullet points)
                    </label>
                    <TextField
                      multiline
                      name="buyerNote"
                      rows={4} // Adjust the number of rows as needed
                      value={formData.buyerNote}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          buyerNote: e.target.value,
                        })
                      }
                      placeholder="Write a note about the products"
                    />
                  </div>
                </div>
                {/*Weight- Warrant */}
                <div className={styles["name-category"]}>
                  <div className={styles.weight}>
                    <label htmlFor="weight">Shipping weight</label>
                    <input
                      type="text"
                      name="weight"
                      value={formData.weight}
                      onChange={(e) =>
                        setFormData({ ...formData, weight: e.target.value })
                      }
                      placeholder="kg"
                    />
                  </div>
                  <div className="">
                    <div className={styles["warranty-head"]}>
                      <span>Warranty</span>
                    </div>
                    <RadioGroup
                      value={formData.isWarranty}
                      onChange={(e) => {
                        // console.log("Radio Button Changed:", e.target.value);
                        setFormData({
                          ...formData,
                          isWarranty: e.target.value === "true",
                        });
                      }}
                    >
                      <div className={styles["custom-radio"]}>
                        <FormControlLabel
                          value="true"
                          control={<GreenRadio />}
                          label={<span style={{ color: "#000000" }}>Yes</span>}
                        />
                        <FormControlLabel
                          value="false"
                          control={<GreenRadio />}
                          label={<span style={{ color: "#000000" }}>No</span>}
                        />
                      </div>
                    </RadioGroup>
                  </div>
                </div>
                {/*currentprice- previousprice- productquantity*/}
                <div className={styles["name-category"]}>
                  <div className={styles.currentprice}>
                    <label htmlFor="productCurrentPrice">
                      Product current price
                    </label>
                    <input
                      type="text"
                      name="productCurrentPrice"
                      value={
                        formData.productCurrentPrice !== ""
                          ? Number(formData.productCurrentPrice).toLocaleString(
                              "en-NG"
                            )
                          : ""
                      }
                      onChange={handleInputChange}
                      placeholder="₦"
                    />
                  </div>
                  <div className={styles["previousprice"]}>
                    <label htmlFor="productPreviousPrice">
                      Product previous price
                    </label>
                    <input
                      type="text"
                      name="productPreviousPrice"
                      value={
                        formData.productPreviousPrice !== ""
                          ? Number(
                              formData.productPreviousPrice
                            ).toLocaleString("en-NG")
                          : ""
                      }
                      onChange={handleInputChange}
                      placeholder="₦"
                    />
                  </div>
                  <div className={styles["productquantity"]}>
                    <label htmlFor="productQuantity">productquantity</label>
                    <input
                      type="text"
                      name="productQuantity"
                      value={
                        formData.productQuantity !== ""
                          ? Number(formData.productQuantity).toLocaleString(
                              "en-NG"
                            )
                          : ""
                      }
                      onChange={handleInputChange}
                      placeholder="₦"
                    />
                  </div>
                </div>
              </form>
              <hr />
              <div className={styles["upload-section"]}>
                <div className="">
                  <p>Upload Pictures</p>
                  <div className={styles["upload-section-img"]}>
                    <div className={styles["upload-pic"]}>
                      <label
                        htmlFor="imageInput"
                        className={styles["image-upload-label"]}
                      >
                        {formData.multipleProductImageUrl.map(
                          (imageUrl, index) => (
                            <img
                              key={index}
                              src={imageUrl}
                              alt={`Selected img ${index}`}
                              height={90}
                              onClick={() => handleImageClick(index)}
                            />
                          )
                        )}
                        {formData.multipleProductImageUrl.length < 5 && (
                          <img
                            src={uploadimg}
                            alt="Click to add images"
                            height={90}
                          />
                        )}
                        <input
                          id="imageInput"
                          type="file"
                          accept="image/*"
                          ref={imageInputRef}
                          style={{ display: "none" }}
                          multiple
                          onChange={(e) => handleImageUpload(e.target.files)}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className={styles["delivery-warranty"]}>
                  <div className="">
                    <div className={styles["delivery-warranty-head"]}>
                      <span>
                        Would you like to carry out the delivery of this product
                      </span>
                    </div>
                    <RadioGroup
                      value={formData.isDeliveryCarryOut}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          isDeliveryCarryOut: e.target.value === "true",
                        })
                      }
                    >
                      <div className={styles["custom-radio"]}>
                        <FormControlLabel
                          value="true"
                          control={<GreenRadio />}
                          label={<span style={{ color: "#000000" }}>Yes</span>}
                        />
                        <FormControlLabel
                          value="false"
                          control={<GreenRadio />}
                          label={<span style={{ color: "#000000" }}>No</span>}
                        />
                      </div>
                    </RadioGroup>
                  </div>

                  <div className={styles["vat"]}>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={formData.applyVat}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              applyVat: e.target.checked,
                            })
                          }
                        />
                      }
                      label={
                        <span style={{ color: "#000000" }}>
                          Apply VAT on product
                        </span>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className={`--btn ${styles["form-upload-button"]}`}
            onClick={handleUpdate}
            disabled={isLoading}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              width: isLoading ? "80px" : "auto",
            }}
          >
            {isLoading ? (
              <>
                <FaSpinner className={styles["spin"]} />
              </>
            ) : (
              "Update Product"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateProduct;
