import { useQuery } from "react-query";
import agent from "../api/agent";

const fetchRoles = async () => {
    const response = await agent.userManagment.getAllroles();
    return response.data;
  };

export const useAdminRoles = () => {
    const {
      isLoading,
      error,
      data: roles,
    } = useQuery({
      queryKey: ["roles"],
      queryFn: fetchRoles ,
    });
    return { isLoading, error, roles };
  }; 