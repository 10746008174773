import React from "react";
import styles from "./Pagination.module.css";
const Pagination = ({ postsPerPage, currentPage, estimatedTotalPages, paginate, hasNextPage, isLoading  }) => {
  // console.log("postsPerPage:",postsPerPage);
  // console.log("totalPosts:",totalPosts);
  // console.log("paginate:",paginate);
  const pageNumbers = [];
  for (let i = 1; i <= estimatedTotalPages; i++) {
    pageNumbers.push(i);
  }
  return (
    <nav>
      <ul className={styles.pagination}>
        {pageNumbers.map((pageNumber) => (
          <li key={pageNumber} className={styles["page-item"]}>
            <a
              onClick={() => paginate(pageNumber)}
              className={styles["page-link"]}
            >
              {pageNumber}
            </a>
          </li>
        ))}
        {/* Render "Previous" button if currentPage is greater than 1 */}
        {!isLoading && currentPage > 1 &&(
          <li className={styles["page-item"]}>
            <a
              onClick={() => paginate(currentPage - 1)} // Navigate to the previous page
              className={styles["page-link"]}
            >
              Previous
            </a>
          </li>
        )}
        {/* Only render next page number if hasNextPage is true */}
        {hasNextPage && (
          <li className={styles["page-item"]}>
            <a
              onClick={() => paginate(currentPage + 1)}
              className={styles["page-link"]}
            >
              Next
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
