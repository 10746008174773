import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vendors: [],
  loading: false,
  error: null,
  vendorsCount: 0,
  activeCount: 0,
  pendingCount: 0,
  inactiveCount: 0,
  rejectedCount: 0,
  lockedCount: 0,
};

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setVendors: (state, action) => {
      state.vendors = action.payload;
      // Calculate the total number of vendors and update the vendorsCount
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setVendors, setLoading, setError } = vendorSlice.actions;

export default vendorSlice.reducer;
