import { useQuery } from "react-query";
import agent from "../api/agent";

const fetchCategories =async ({page = 0, pageSize = 1000 }) => {
  // console.log("PageNumber:",page, "PageSize:",pageSize);
  // Adjust PageNumber based on page value
  // const PageNumber = page === 0 ? 1 : page;
  const PageNumber = page + 1;
  const PageSize = pageSize
  // console.log("PageNumber2:",PageNumber, "PageSize2:",pageSize);
  const categoryResponse = await agent.category.getAllcategories(PageNumber, PageSize);
  const subcategoryResponse = await agent.category.getAllSubcategories(PageNumber, PageSize);

  // Check if subcategoryResponse.data is null or undefined
  if (subcategoryResponse.data === null || subcategoryResponse.data === undefined) {
    console.log('Subcategory data is null or undefined');
    // Handle the situation when subcategory data is null or undefined
    const categories = categoryResponse.data.map((category) => ({
      ...category,
      Subcategory: 'Unknown',
    }));
    // console.log('Categories:', categories);
    return categories;
  } else if (Array.isArray(subcategoryResponse.data) && subcategoryResponse.data.length > 0) {
    // Check if subcategoryResponse.data is a non-empty array
    const categories = categoryResponse.data.map((category) => {
      const subcategory = subcategoryResponse.data.find(
        (subcategory) => category.categoryId === subcategory.categoryId
      );
      // console.log('Category:', category);
      // console.log('Subcategory:', subcategory);
      return {
        ...category,
        Subcategory: subcategory ? `${subcategory.subCategoryName}` : 'Unknown',
      };
    });
    // console.log('Categories:', categories);
    return categories;
  } else {
    // Handle the situation when subcategory data is an empty array
    const categories = categoryResponse.data.map((category) => ({
      ...category,
      Subcategory: 'Unknown',
    }));
    // console.log('Categories:', categories);
    return categories;
  }
};

// export const useCategories = () => {
//   const {
//     isLoading,
//     error,
//     data: categories,
//   } = useQuery({
//     queryKey: ["categories"],
//     queryFn: fetchCategories,
//   });
//   return { isLoading, error, categories };
// };

export const useCategories = ({ page, pageSize } = {}) => {
  const { isLoading, error, data: categories, refetch } = useQuery({
    queryKey: ['categories', { page, pageSize }],
    queryFn: () => fetchCategories({ page, pageSize }),
  });

  return { isLoading, error,categories, refetch };
};

export const useSelectCategories = ({ page, pageSize }) => {
  const fetchSelectCategories = async () => {
    const PageNumber = page ;
  const PageSize = pageSize
    try {
      const response = await agent.category.getAllcategories(PageNumber, PageSize);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch categories");
    }
  };

  return useQuery({
    queryKey: ['categories', { page, pageSize }],
    queryFn: fetchSelectCategories,
  });
};