import React from "react";
import styles from "./NotFound.module.css";
import space from "../../assets/space.png";
import planet from "../../assets/planet.png";
import { GoStar } from "react-icons/go";
import { BsStars, BsFillMoonStarsFill } from "react-icons/bs";
import { VscStarFull } from "react-icons/vsc";
import { IoTelescope } from "react-icons/io5";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
const NotFound = () => {
  const navigate = useNavigate();
  const roleName = localStorage.getItem("rolename");
  const handleHome = () => {
    if (roleName === "Auditor") {
      navigate("/vendor");
  } else if (roleName === "Platform Manager" || roleName === "IT Control" || roleName === "superadmin") {
      navigate("/dashboard");
  } else if (roleName === "Customer Care") {
      navigate("/customer");
  } else {
      toast.error("Role not recognized.");
      navigate("/");
  }
  }

  return (
    <div className={styles.vendor}>
      <div className={styles["planet-body"]}>
        <div className={styles.start}>
          <GoStar className={styles.gostar1} />
          <BsStars className={styles.bsstar1} />
          <BsStars className={styles.bsstar2} />
          <GoStar className={styles.gostar2} />
          <IoTelescope className={styles.telescope} />
          <BsFillMoonStarsFill className={styles.moonstar1} />
          <GoStar className={styles.gostar3} />
          <GoStar className={styles.gostar4} />
          <GoStar className={styles.gostar5} />
        </div>
        <div className={styles.middle}>
          <div className={styles.img}>
            <img
              src={space}
              alt=""
              width={80}
              height={80}
              className={styles["img-space"]}
            />
            <img src={planet} alt="" width={160} height={160} />
          </div>
          <div className={styles["img-body"]}>
            <h1>404</h1>
          </div>
        </div>
        <div className={styles.end}>
          <GoStar className={styles.gostar1} />
          <BsFillMoonStarsFill className={styles.moonstar1} />
          <BsStars className={styles.bsstar1} />
          <BsFillMoonStarsFill className={styles.moonstar2} />
          <GoStar className={styles.gostar2} />
          <VscStarFull className={styles.vsstar} />
        </div>
      </div>
      <div className={styles.word}>
        <p>Hey captain! looks like you're heading to the wrong planet!</p>
      </div>
      <div className={styles.button} onClick={handleHome}>
        <button>
          <p>Take Me Back to the Homepage</p>
        </button>
      </div>
    </div>
  );
};

export default NotFound;
