import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Login,
  Dashboard,
  TokenLogin,
  Vendor,
  Customer,
  ProductList,
  Category,
  Order,
  Brands,
  Reviews,
  Log,
  UserManagement,
  Ticket,
  Promotion,
  NotFound,
} from "./pages";
import AddProduct from "./pages/productList/AddProduct";
import ViewTicket from "./pages/tickets/ViewTicket";
import UpdateProduct from "./pages/productList/UpdateProduct";
import AddPromotion from "./pages/promotions/AddPromotion";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useEffect, useState } from "react";
import { withOneTabEnforcer } from "react-one-tab-enforcer";
import headerlogo from "./assets/image 2.png";

import { useSelector } from "react-redux";



// Setup caching with react-query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // set the time interval that query will be refetched (1mins)
      // staleTime: 60 * 1000,
      // set the time interval that query will be refetched (0min)
      staleTime: 0,
    },
  },
});

const DifferentWarningComponent = () => (
  <>
  <div className="logo1">
  
  <img src={headerlogo} alt="Alt-Power-Logo" />
  <img src={headerlogo} alt="Alt-Power-Logo" />
  </div>
  <div className="error">
    <h1>Error </h1>
    <p>Oops - Multiple Tabs not Supported. You Have an Active Session! </p>
  </div>
  <div className="logo2">
  
  <img src={headerlogo} alt="Alt-Power-Logo" />
  <img src={headerlogo} alt="Alt-Power-Logo" />
  </div>
  </>
);

function App() {

const {  user } = useSelector((state) => state.auth);
// console.log("user", user);

const roleName = localStorage?.getItem("rolename") || user?.roleName || "";

  return (
    <QueryClientProvider client={queryClient}>
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/token" element={<TokenLogin />} />
           {roleName && getRoutesForRole(roleName)}
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </>
    </QueryClientProvider>
  );
}

// Function to return appropriate routes based on roleName
const getRoutesForRole = (roleName) => {
  // console.log("role",roleName === 'superadmin');
  if (roleName === 'Auditor') {
    return (
      <>
        <Route path="/vendor" element={<Vendor />} />
        <Route path="/customer" element={<Customer />} />
        <Route path="/order" element={<Order />} />
      </>
    );
  } else if (roleName === 'Platform Manager') {
    return (
      <>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/vendor" element={<Vendor />} />
        <Route path="/customer" element={<Customer />} />
        <Route path="/product-list" element={<ProductList />} />
        <Route path="/product-list/Add-product" element={<AddProduct />} />
        <Route path="/product-list/update-product" element={<UpdateProduct />} />
        <Route path="/category" element={<Category />} />
        <Route path="/order" element={<Order />} />
        <Route path="/brand" element={<Brands />} />
        <Route path="/review" element={<Reviews />} />
        <Route path="/ticket" element={<Ticket />} />
        <Route path="/ticket/view-ticket" element={<ViewTicket />} />
        <Route path="/promotion" element={<Promotion />} />
        <Route path="/promotion/Add-promotion" element={<AddPromotion />} />
      </>
    );
  } else if (roleName === 'Customer Care') {
    return (
      <>
        <Route path="/customer" element={<Customer />} />
        <Route path="/vendor" element={<Vendor />} />
        <Route path="/order" element={<Order />} />
      </>
    );
  } else if (roleName === 'IT Control') {
    return (
      <>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/vendor" element={<Vendor />} />
        <Route path="/customer" element={<Customer />} />
        <Route path="/product-list" element={<ProductList />} />
        <Route path="/category" element={<Category />} />
        <Route path="/order" element={<Order />} />
        <Route path="/brand" element={<Brands />} />
        <Route path="/review" element={<Reviews />} />
        <Route path="/ticket" element={<Ticket />} />
        <Route path="/ticket/view-ticket" element={<ViewTicket />} />
        <Route path="/promotion" element={<Promotion />} />
        <Route path="/promotion/Add-promotion" element={<AddPromotion />} />
      </>
    );
  } else if (roleName === 'superadmin') {
    return (
      <>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/vendor" element={<Vendor />} />
        <Route path="/customer" element={<Customer />} />
        <Route path="/product-list" element={<ProductList />} />
        <Route path="/product-list/Add-product" element={<AddProduct />} />
        <Route path="/product-list/update-product" element={<UpdateProduct />} />
        <Route path="/category" element={<Category />} />
        <Route path="/order" element={<Order />} />
        <Route path="/brand" element={<Brands />} />
        <Route path="/review" element={<Reviews />} />
        <Route path="/user-management" element={<UserManagement />} />
        <Route path="/ticket" element={<Ticket />} />
        <Route path="/ticket/view-ticket" element={<ViewTicket />} />
        <Route path="/promotion" element={<Promotion />} />
        <Route path="/promotion/Add-promotion" element={<AddPromotion />} />
      </>
    );
  } else {
    return null;
  }
};


export default withOneTabEnforcer({
  appName: "Altpower",
  OnlyOneTabComponent: DifferentWarningComponent,
})(App);
